import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useContext, useEffect, useState} from 'react';
import {ProfileContext, getCourseRoleNumber} from 'contexts/ProfileContext';
import {isMinimumRole} from 'contexts/profileUtil';
import {
  MeetingsContext,
  MeetingsProvider,
} from 'course/contexts/MeetingsContext';
import {EventCard} from 'course/editors/eventsEditor/EventSplash';
import {useServerTimeOnce} from 'contexts/TimeContext';

export const CourseEvents = () => {
  const courseId = useCourseId();

  return (
    <MeetingsProvider courseId={courseId}>
      <CoursePageBodyContainer
        mainColumn={<EventsMain />}
        rightColumn={<></>}
        singleColumn={<EventsMain />}
      />
    </MeetingsProvider>
  );
};

const EventsMain = () => {
  const {userData} = useContext(ProfileContext);
  const {meetingsMap} = useContext(MeetingsContext);
  const courseId = useCourseId();
  const userRole = userData.courseRole;
  const [events, setEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  const serverTimeMs = useServerTimeOnce();

  useEffect(() => {
    const eventsList = [];
    Object.keys(meetingsMap).map(id => {
      eventsList.push({
        ...meetingsMap[id],
        eventKey: id,
      });
    });
    const sortedEvents = eventsList.sort((a, b) => {
      const aStart = new Date(a.start);
      const bStart = new Date(b.start);
      if (isNaN(aStart.getTime()) || isNaN(bStart.getTime())) {
        return 0;
      } else {
        return bStart.getTime() - aStart.getTime();
      }
    });
    setEvents(sortedEvents);
  }, [meetingsMap]);

  useEffect(() => {
    if (!events || events.length === 0 || !serverTimeMs) {
      return;
    }
    const upcoming = [];
    const past = [];
    events.map(event => {
      const start = new Date(event.start);
      if (isNaN(start.getTime())) {
        return;
      }
      if (start.getTime() > serverTimeMs) {
        upcoming.push(event);
      } else {
        past.push(event);
      }
    });
    // reverse the order of upcoming events
    upcoming.sort((a, b) => {
      const aStart = new Date(a.start);
      const bStart = new Date(b.start);
      return aStart.getTime() - bStart.getTime();
    });

    setUpcomingEvents(upcoming);
    setPastEvents(past);
  }, [serverTimeMs, events]);

  return (
    <>
      <h3 className="mt-3 splashCardRightTitle">Upcoming Events</h3>
      <div className="row">
        {upcomingEvents.map((event, i) => {
          if (
            !isMinimumRole(userRole, getCourseRoleNumber(event.minimumRole))
          ) {
            return <></>;
          }
          return (
            <div key={i} className="col-12 col-md-6 mt-3">
              <EventCard
                event={event}
                courseId={courseId}
                eventKey={event.eventKey}
              />
            </div>
          );
        })}
        {/* {
        events.map((event, i) => {
          if(!isMinimumRole(userRole, getCourseRoleNumber(event.minimumRole))) {
            return <></>
          }
          return <div key={i} className="col-12 col-md-6 mt-3">
            <EventCard event={event} courseId={courseId} eventKey = {event.eventKey}/>
          </div>
        })
      } */}

        <h3 className="mt-3 splashCardRightTitle">Past Events</h3>
        {pastEvents.map((event, i) => {
          if (
            !isMinimumRole(userRole, getCourseRoleNumber(event.minimumRole))
          ) {
            return <></>;
          }
          return (
            <div key={i} className="col-12 col-md-6 mt-3">
              <EventCard
                event={event}
                courseId={courseId}
                eventKey={event.eventKey}
              />
            </div>
          );
        })}
      </div>
      <div style={{height: 20}} />
      <hr />
      <div style={{height: 20}} />
    </>
  );
};
