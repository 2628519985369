import {
  PYODIDE_BUILD,
  INIT_SYSTEM_CODE,
  RUN_MAINAPP,
  RESET_MODULES,
  SET_STEPPING_LISTS,
  SETUP_SCRIPT,
  REPL_SETUP,
  REPL_TRACE,
  RESET_REPL,
  CHECK_IMPORTS,
  RM_MODS,
} from './PyodideUtils';
import {} from './PyodideGlobals';
import {deepCopy} from '@firebase/util';
import {getFirestore, collection, addDoc} from 'firebase/firestore';
import Swal from 'sweetalert2';
import {Karel} from './KarelLib/karelapi';
import {IKarelState} from './KarelLib/karelInterfaces';
import {
  IdeFileData,
  ProjectFileId,
  ProjectFilesCode,
  ProjectFileStructure,
} from 'ide/types';
import {GradingScripts} from 'assignments/models/unitTests/advancedConsole';
import {
  AdvancedAutograderState,
  DEFAULT_ADVANCED_AUTOGRADER_STATE,
} from 'assignments/unitTest/advancedConsole/advancedConsoleUtils';

// Inits global variables
// See PyodideGlobals.ts for info

self.karelInfo = {
  client: undefined,
  active: false,
  initCount: 0,
  startState: null,
  setState: null,
  sleepTime: 0.2,
};

self.canvasInfo = {
  client: undefined,
  active: false,
  initCount: 0,
  state: {},
  getImage: null,
  id: 'canvas',
  mouseDownPromise: undefined,
};

self.stepInfo = {
  frames: [],
  stdout: [],
  offset: 5,
  active: true,
  currlp: -1,
  error: [],
  error_message: [],
};

self.testState = {
  isTesting: false,
  testLock: undefined,
  isAdvancedAutograder: false,
};

self.replInput = message => {
  handleOutput(message);
  const data = prompt(message);
  handleOutput(data, false);
  return data;
};

// Step tracking
let runningPromise = null;
let initialGlobals = null;
// Output functions:
let handleOutput = (stdout, nl = true) => console.log(stdout);
let handleError = stderr => console.log(stderr);
let code = '';

declare function loadPyodide(config: any): Promise<any>;
let pyodide: any | undefined;

export async function setPyodide() {
  // check if pyodide already exists
  if (pyodide) {
    return 'Pyodide Already Initialized';
  }

  // Load pyodide and packages
  try {
    pyodide = await loadPyodide({
      indexURL: PYODIDE_BUILD,
      stderr: stderr => handleError(stderr),
      stdout: stdout => handleOutput(stdout),
    });
    await pyodide.loadPackage(`${PYODIDE_BUILD}numpy.js`);
    await pyodide.loadPackage(`${PYODIDE_BUILD}unthrow.js`);
    await pyodide.loadPackage(`${PYODIDE_BUILD}pyparsing.js`);
    await pyodide.loadPackage(`${PYODIDE_BUILD}packaging.js`);
    await pyodide.loadPackage(`${PYODIDE_BUILD}micropip.js`);
  } catch (e) {
    // statements to handle TypeError exceptions
    Swal.fire({
      title: 'Uh Oh! Something went wrong.',
      html: `
          <p><strong>Here are a issues that may be causeing the problem</strong></p>
          <ul>
            <li>
            <p>Check to see if any browser extensions (ad blockers for example) could be causing the issue.</p>
            </li>
            <li>
            <p>If you use Internet Download Manager, you may need to follow these steps:</p>
            <p>Open IDM -> Options -> File Types -> Edit List</p>
            <p>Then add: <b>https://codeinplace.stanford.edu/pyodide/build/pyodide_py.tar</b></p>
            </li>
            <li>
            <p>If neither of those work, please email codeinplace@stanford.edu and we can work to solve this issue!</p>
            </li>
          </ul>
          `,
    });
  }

  if (!pyodide) {
    console.error('Pyodide is not initialized');
    return 'Pyodide is not initialized';
  }

  // Run Initialization code
  await pyodide.runPythonAsync(INIT_SYSTEM_CODE);

  // Get globals
  pyodide._module.on_fatal = async () => {
    const db = getFirestore();
    const assnRef = collection(db, `pyodide/fatal/errorreports`);
    await addDoc(assnRef, {
      code: code,
    }).catch(() => {
      Swal.fire('Uh Oh! Something went wrong, please reload the page');
      document.location.reload();
    });
    Swal.fire('Uh Oh! Something went wrong, please reload the page');
    document.location.reload();
  };
  initialGlobals = new Set(pyodide.globals.toJs().keys());
  return 'Pyodide Initialization Complete';
}

/**
 * The desired behavior when stdout is received from the Pyodide interpreter.
 * @param stdout - The stdout to handle.
 * @param nl - Whether to print stdout on a newline.
 */
export type PyodideUserDefinedStdoutHandler = (
  stdout: string,
  nl: boolean,
) => void;
/**
 * The desired behavior when stderr is received from the Pyodide interpreter.
 * @param stderr - The stderr to handle.
 * @param code - The code that was run.
 */
export type PyodideUserDefinedStderrHandler = (
  stderr: string,
  code: string,
) => Promise<string>;
/**
 * The desired behavior when stdin is received from the Pyodide interpreter.
 * @param input - The stdin to handle.
 */
export type PyodideUserDefinedStdinHandler = (input: string) => void;
/**
 * The desired behavior when an image is requested from the Pyodide interpreter.
 * @param file - The file to get the image for.
 * @return The image url.
 */
export type PyodideUserDefinedImgHandler = (file: string) => string;
/**
 * The desired behavior when the run ends.
 * @param errorState - The error state to handle.
 * @param registeredVars - The registered variables to handle.
 * @param stopRequested - Whether the stop button was clicked.
 */
export type PyodideUserDefinedEndHandler = (
  errorState: any,
  registeredVars: any,
  stopRequested: boolean,
) => void;

// Class handles pyodide logic
export class PyodideApi {
  private isPythonRunning: boolean;
  private userDefStdoutHandler: PyodideUserDefinedStdoutHandler;
  private userDefStdinHandler: PyodideUserDefinedStdinHandler;
  private userDefStderrHandler: PyodideUserDefinedStderrHandler;
  private userDefEndHandler: PyodideUserDefinedEndHandler;
  private userDefImgHandler: PyodideUserDefinedImgHandler;
  private errorPromQueue: any[] = [];

  constructor() {
    this.isPythonRunning = false;
  }

  handleStdout(stdout: string, nl = true) {
    this.userDefStdoutHandler(stdout, nl);
  }

  async handleStderr(stderr: string) {
    // Returns object with promise member and resolve member
    let resolveFunc;
    const errprom = {
      promise: new Promise(function (resolve) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
    this.errorPromQueue.push(errprom);
    if (self.stepInfo.active) {
      self.stepInfo.error.push(stderr);
    }

    // Grab the error message given to the student
    const errorMessage = await this.userDefStderrHandler(stderr, code);
    self.stepInfo.error_message.push(errorMessage);

    errprom.resolve();
    this.setRunningFlag();
  }

  async handleStdin(opt = '') {
    return await this.userDefStdinHandler(opt);
  }

  async handleRunEnd(stopRequested: boolean) {
    // Pyodide Autograder: Must extract variables before any other python scripts are run
    let registeredVars = pyodide.globals
      .get('__autograde_registered_vars')
      ?.toJs();
    let autogradeErrorState = pyodide.globals
      .get('__autograde_error_state')
      ?.toJs();

    // If in step mode, set step list
    while (this.errorPromQueue.length > 0) {
      await this.errorPromQueue[this.errorPromQueue.length - 1].promise;
      this.errorPromQueue.pop();
    }
    if (self.stepInfo.active && !self.testState.isTesting) {
      await this._setStepList();
      self.stepInfo.frames = self.stepInfo.frames.map(frame =>
        Object.fromEntries(frame),
      );
      const stdoutOffset = self.stepInfo.currlp - 1000;
      if (stdoutOffset > 0) {
        for (let i of self.stepInfo.frames) {
          i['logptr'] = i['logptr'] - stdoutOffset;
          i['locals'] = Object.fromEntries(i['locals']);
        }
      }
      if (self.stepInfo.error) {
        self.stepInfo.stdout.push(...self.stepInfo.error);
      }
      const newInfo = {
        lineno: -1,
        logptr: self.stepInfo.currlp + 1,
        codenm: 'Program Ended',
        locals: {},
      };

      if (self.karelInfo.active) {
        newInfo['karel'] = {
          state: deepCopy(self.karelInfo.client.currentState),
        };
      } else if (self.canvasInfo.active) {
        newInfo['graphics'] = deepCopy(self.canvasInfo.client.canvasObjects);
      }

      self.stepInfo.frames.push(newInfo);
    }
    // Reset globals/Remove prior imports
    try {
      await pyodide.runPython(RESET_MODULES);
    } catch (e) {}
    this.setRunningFlag();
    try {
      // Remove files
      this._resetPyodideFS();
    } catch (e) {
      await this.handleStderr(e.message);
    }
    if (runningPromise) {
      runningPromise.resolve();
    }

    // Pass Pyodide Autograder error info to handler so we can return it from test
    if (autogradeErrorState && registeredVars) {
      this.userDefEndHandler(
        autogradeErrorState,
        registeredVars,
        stopRequested,
      );
    } else {
      this.userDefEndHandler(null, null, stopRequested);
    }
  }

  async runPython(
    codeToRun: string,
    activeFile: IdeFileData,
    stepmode = true,
    uninterrupted = 3000,
    inputSize = 0,
    canvasId: string = 'canvas',
    gradingScripts: GradingScripts | {} = {},
  ): Promise<{
    karel?: IKarelState;
    graphics?: any;
    error?: string[];
    output?: string[];
    error_message?: string[];
  }> {
    this.setCanvasId(canvasId);
    code = codeToRun;
    self.stepInfo.active = stepmode;
    self.currentFile = activeFile.name;
    if (runningPromise) {
      await runningPromise.promise;
    }
    let resolveFunc;
    runningPromise = {
      promise: new Promise(function (resolve) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
    if (pyodide) {
      // If libraries were used in last run, set as unused
      this._resetLibraries();
      this._resetStep();
      // load imports
      const {isSafe, mods} = await this.checkSpecialImports(codeToRun);
      if (isSafe) {
        await pyodide.loadPackagesFromImports(codeToRun);
      }
      // formate and exec scripts
      // Pydodide Autograder means `_formatSetupScript` is supplied `gradingScripts` ({} if not supplied) */
      const mainApp = this._formatUserCodeFunction(codeToRun);
      const setupScript = this._formatSetupScript(
        self.stepInfo.active,
        self.testState.isTesting,
        uninterrupted,
        inputSize,
        gradingScripts,
      );

      await this._executeScripts(mainApp, setupScript, isSafe, mods);
      await runningPromise.promise;
      const endStates = {
        karel: self.karelInfo.client
          ? deepCopy(self.karelInfo.client.currentState)
          : null,
        graphics: deepCopy(self.canvasInfo.state),
        error: [...self.stepInfo.error],
        output: [...self.stepInfo.stdout],
        error_message: [...self.stepInfo.error_message],
      };

      return endStates;
    } else {
      // Indicate that Python is still Loading
      console.warn('Python Is Still Loading');
    }
    return {};
  }

  async loadFiles(filesToLoad: IdeFileData[], filesCode: ProjectFilesCode) {
    try {
      for (let i = 0; i < filesToLoad.length; i++) {
        const entry = filesToLoad[i];
        await this.loadFile(entry.name, filesCode[entry.id]?.content ?? ``);
      }
    } catch (error) {
      await this.handleStderr(error.message);
      return false;
    }
    return true;
  }

  async loadFile(path: string, contents: string) {
    if (!pyodide) {
      return false;
    }
    const formattedContents = `
${contents}
`;
    const mkdir = await this._mkdir(path);
    if (!mkdir) {
      return false;
    }
    try {
      await pyodide.FS.writeFile(path, formattedContents);
    } catch (error) {
      await this.handleStderr(error.message);
      return false;
    }

    return true;
  }

  setInitialGlobals(ig: Set<string>) {
    initialGlobals = ig;
  }

  setRunningFlag(flagValue: boolean = false) {
    if (self.canvasInfo && self.canvasInfo.mouseDownPromise) {
      self.canvasInfo.mouseDownPromise.resolve();
    }
    this.isPythonRunning = flagValue;
  }

  setUserDefinedFuncs(
    onRunEnd: PyodideUserDefinedEndHandler,
    onOutput: PyodideUserDefinedStdoutHandler,
    onError: PyodideUserDefinedStderrHandler,
    onInput: PyodideUserDefinedStdinHandler,
    onImage: PyodideUserDefinedImgHandler,
  ) {
    this.userDefEndHandler = onRunEnd;
    this.userDefStdoutHandler = onOutput;
    this.userDefStderrHandler = onError;
    this.userDefStdinHandler = onInput;
    this.userDefImgHandler = onImage;

    self.canvasInfo.getImage = (file: string) => {
      return this.userDefImgHandler(file);
    };
    handleOutput = (stdout, nl = true) => {
      if (self.stepInfo.active) {
        self.stepInfo.currlp += 1;
        self.stepInfo.stdout.push(stdout);
        if (self.stepInfo.stdout.length > 1000) {
          self.stepInfo.stdout.shift();
        }
      }
      this.handleStdout(stdout, nl);
    };
    handleError = async (stderr: string) => {
      await this.handleStderr(stderr);
    };
  }

  setKarelInfo(
    startState: any,
    setWorldState: (state: any) => {},
    sleepTime: any,
  ) {
    if (self.karelInfo.client) {
      self.karelInfo.client.resetKarel(startState, setWorldState);
    } else {
      self.karelInfo.client = new Karel(setWorldState, startState);
      self.karelInfo.startState = startState;
      self.karelInfo.setState = setWorldState;
    }
    self.karelInfo.sleepTime = sleepTime;
  }

  setCanvasId(id: string) {
    self.canvasInfo.id = id;
  }

  getRunningFlag() {
    return this.isPythonRunning;
  }

  getStepListLength() {
    if (self.stepInfo.active) {
      return self.stepInfo.frames.length;
    } else {
      return 0;
    }
  }

  getStepOutput(ptr) {
    if (self.stepInfo.active) {
      return self.stepInfo.stdout.slice(0, self.stepInfo.frames[ptr]['logptr']);
    } else {
      return [];
    }
  }

  private _formatUserCodeFunction(code: string) {
    const mainDef = `
def mainApp(___arg):
    pass`;
    const codeSplit = code.split('\n');
    let indentedCode = ``;
    let imports = ``;
    let lineNo = 0;
    for (let line of codeSplit) {
      if (
        line.substring(0, 4) !== 'from' &&
        line.substring(line.length - 1) !== '*'
      ) {
        indentedCode = indentedCode + `    ${line}\n`;
      } else if (line !== '') {
        imports = imports + `${line}\n`;
      }
      lineNo += 1;
    }

    const formattedUserCodeFunction = `
${imports}
${mainDef}
${indentedCode}
`;

    return formattedUserCodeFunction;
  }

  private _formatSetupScript(
    stepOn: boolean,
    testOn: boolean,
    uninterrupted: number = 2000,
    inputSize = 0,
    gradingScripts = {},
  ) {
    return SETUP_SCRIPT(
      stepOn && !testOn,
      testOn,
      uninterrupted,
      inputSize,
      self.testState.isAdvancedAutograder,
      gradingScripts,
    );
  }

  private async _executeScripts(
    mainAppScript: string,
    setupScript: string,
    isSafe: boolean = true,
    mods: any = true,
  ) {
    try {
      this.setRunningFlag(true);
      await pyodide.runPython(setupScript);
      if (!isSafe) {
        for (let mod of RM_MODS) {
          if (mods.includes(mod)) {
            await pyodide.runPython(
              `raise ModuleNotFoundError(\"No module named \\'${mod}\\'\")`,
            );
          }
        }
      }
      // if not safe, the error will trigger the catch statement and this code won't be run
      await pyodide.runPython(mainAppScript);

      await this._runResumerCallback();
    } catch (e) {
      await this.handleStderr(e.message);
      await this.handleRunEnd(false);
      this.setRunningFlag();
    }
  }

  private async _runResumerCallback() {
    try {
      await pyodide.runPython(RUN_MAINAPP(self.testState.isAdvancedAutograder));
    } catch (e) {
      await this.handleStderr(e.message);
      await this.handleRunEnd(false);
      this.setRunningFlag();
      return;
    }

    const userCmd = pyodide.globals.get('__unthrowActiveCommand__').toJs();
    const runFinished = pyodide.globals.get('finished');
    if (!this.isPythonRunning) {
      this.handleStdout('KeyboardInterrupt');
      await this.handleRunEnd(true);
    } else if (runFinished) {
      await this.handleRunEnd(false);
    } else {
      await this._handleUnthrow(userCmd);
    }
  }

  private async _handleUnthrow(userCmdMap: any) {
    switch (userCmdMap.get('cmd')) {
      case 'sleep':
        const s = userCmdMap.get('data');
        if (!self.testState.isTesting) {
          setTimeout(this._runResumerCallback.bind(this), 1000 * s);
        } else {
          setTimeout(this._runResumerCallback.bind(this), 0);
        }
        break;
      case 'input':
        const printMsg = userCmdMap.get('data');
        const result = await this.handleStdin(printMsg);
        self.stepInfo.stdout[self.stepInfo.stdout.length - 1] =
          self.stepInfo.stdout[self.stepInfo.stdout.length - 1] + result;
        pyodide.globals.set('____unthrowActiveInput', result);
        setTimeout(this._runResumerCallback.bind(this));
        break;
      case 'awaitclick':
        await self.canvasInfo.client.wait_for_click();
        setTimeout(this._runResumerCallback.bind(this));
        break;
      case 'output':
        const data = userCmdMap.get('data');
        setTimeout(this._runResumerCallback.bind(this));
        break;
      default:
        setTimeout(this._runResumerCallback.bind(this));
        break;
    }
  }

  private async _setStepList() {
    await pyodide.runPython(SET_STEPPING_LISTS);
    self.stepInfo.frames = pyodide.globals.get('step_list').toJs();
  }

  private async _mkdir(filePath: string) {
    const slashIndex = filePath.lastIndexOf('/');
    const dir = filePath.substring(0, slashIndex);
    if (filePath.indexOf('/') !== -1) {
      this._mkdir(dir);
    }
    if (dir.length > 0) {
      try {
        await pyodide.FS.mkdir(dir);
      } catch (error) {
        await this.handleStderr(error.message);
        return false;
      }
    }

    return true;
  }

  private _resetPyodideFS() {
    for (const globalKey of pyodide.globals.toJs().keys()) {
      if (!initialGlobals.has(globalKey)) {
        pyodide.globals.delete(globalKey);
      }
    }
  }

  private _resetLibraries() {
    self.karelInfo.initCount = 0;
    self.canvasInfo.initCount = 0;
    if (self.karelInfo.active) {
      self.karelInfo.active = false;
      self.karelInfo.client.resetKarel();
    }
    if (self.canvasInfo.active) {
      self.canvasInfo.client = undefined;
      self.canvasInfo.active = false;
      self.canvasInfo.state = {};
    }
  }

  private _resetStep() {
    self.stepInfo = {
      frames: [],
      stdout: [],
      offset: 5,
      active: self.stepInfo.active,
      currlp: 0,
      error: [],
      error_message: [],
    };
  }

  async test(
    code: string,
    input: string[],
    file: IdeFileData,
    gradingScripts: GradingScripts | null = null,
  ): Promise<{
    karel: IKarelState;
    graphics: any;
    error: string[];
    output: string[];
    input: Record<number, any>;
    advancedAutograderState: AdvancedAutograderState;
  }> {
    if (runningPromise) {
      await runningPromise.promise;
    }

    self.testState.isTesting = true;

    // Enable/Disable Advanced Autograder State
    self.testState.isAdvancedAutograder = gradingScripts !== null;

    // Init testing lock to await
    // Possibly lock run code functionality. Need to add infinite loop detection
    // Init return vals
    const output = [];
    const error = [];
    const inputObjs: Record<number, any> = {};
    let inputPtr = -1;

    // Track Pyodide Autograder state, so we can return it */
    let advancedAutograderState: AdvancedAutograderState =
      DEFAULT_ADVANCED_AUTOGRADER_STATE;

    // Save user funcs
    const preFuncs = this._getUserDefFuncs();

    // Set handlers
    this.setUserDefinedFuncs(
      //Collect autograder state at run end
      (errorState, registeredVars, stopRequested) => {
        if (errorState && registeredVars)
          advancedAutograderState = {
            errorState: deepCopy(errorState),
            registeredVars: deepCopy(registeredVars),
            stopRequested,
          };
      },
      stdout => {
        output.push(stdout);
      },
      async (stderr: string) => {
        error.push(stderr);
        return stderr;
      },
      () => {
        inputPtr++;
        inputObjs[output.length - 1] = input[inputPtr];
        return input[inputPtr];
      },
      null,
    );

    // Run code
    try {
      await this.runPython(
        code,
        file,
        false,
        2000,
        input.length,
        'canvas',
        gradingScripts,
      );
    } catch (e) {
      error.push(e.message);
    }

    // Await lock
    // Reset funcs
    this.setUserDefinedFuncs(
      preFuncs.end,
      preFuncs.out,
      preFuncs.err,
      preFuncs.inp,
      preFuncs.img,
    );

    // Turn off testing mode
    self.testState.isTesting = false;
    self.testState.isAdvancedAutograder = false;

    // Return end states
    const endStates = {
      karel: deepCopy(self.karelInfo?.client?.currentState) ?? null,
      graphics: deepCopy(self.canvasInfo.state),
      error: [...error],
      output: [...output],
      input: inputObjs,
      advancedAutograderState: advancedAutograderState, // Pyodide Autograder
    };

    return endStates;
  }

  private _getUserDefFuncs() {
    return {
      end: this.userDefEndHandler,
      out: this.userDefStdoutHandler,
      err: this.userDefStderrHandler,
      inp: this.userDefStdinHandler,
      img: this.userDefImgHandler,
    };
  }

  private async checkSpecialImports(codeToRun) {
    const imports = await pyodide.runPython(CHECK_IMPORTS(codeToRun));
    let allImports = [];
    const importsJs = imports.toJs();
    allImports = [...importsJs];
    const directory = '/home/pyodide';
    if (importsJs.length > 0) {
      const allFiles = this.listFiles(directory);
      for (let file of allFiles) {
        const fileWithoutEnding = file.substring(0, file.indexOf('.'));
        if (importsJs.includes(fileWithoutEnding)) {
          const filePath = `${directory}/${file}`;
          const content = pyodide.FS.readFile(filePath, {encoding: 'utf8'});
          const addImports = await pyodide.runPython(CHECK_IMPORTS(content));
          const addImportsJs = addImports.toJs();
          allImports = [...allImports, ...addImportsJs];
        }
      }
    }

    return {
      isSafe:
        !allImports.includes('js') &&
        !allImports.includes('micropip') &&
        !allImports.includes('webbrowser') &&
        !allImports.includes('pyodide'),
      mods: allImports,
    };
  }

  private listFiles(directory) {
    let files = [];
    let items = pyodide.FS.readdir(directory);

    items.forEach(item => {
      if (item !== '.' && item !== '..') {
        let fullPath = `${directory}/${item}`;
        let stats = pyodide.FS.stat(fullPath);
        if (pyodide.FS.isDir(stats.mode)) {
          files = files.concat(this.listFiles(fullPath));
        } else {
          files.push(fullPath);
        }
      }
    });

    return items;
  }

  private namespace: any = {};
  private repr_shorten: any = undefined;
  private await_fut: any = undefined;
  private pyconsole: any = undefined;
  private clear_console: any = undefined;
  private promptType: boolean = false;
  private exitRepl: any = undefined;

  async enterReplMode(stopRepl) {
    this.exitRepl = stopRepl;
    if (!pyodide) {
      return;
    }
    this.namespace = pyodide.globals.get('dict')();
    let resolveFunc;
    runningPromise = {
      promise: new Promise(function (resolve) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
    pyodide.runPython(REPL_SETUP, this.namespace);

    this.repr_shorten = this.namespace.get('repr_shorten');
    this.await_fut = this.namespace.get('await_fut');
    this.pyconsole = this.namespace.get('pyconsole');
    this.clear_console = this.namespace.get('clear_console');
    this.promptType = true;
    this.namespace.destroy();

    runningPromise.resolve();
  }

  async interpreter(command) {
    await pyodide.runPython(REPL_TRACE);
    if (runningPromise && runningPromise.promise) {
      await runningPromise.promise;
    }
    let resolveFunc;
    runningPromise = {
      promise: new Promise(function (resolve) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
    // multiline should be splitted (useful when pasting)
    for (const c of command.split('\n')) {
      let fut = this.pyconsole.push(c);
      fut.syntax_check === 'incomplete'
        ? (this.promptType = false)
        : (this.promptType = true);
      switch (fut.syntax_check) {
        case 'syntax-error':
          this.handleStderr(fut.formatted_error.trimEnd());
          continue;
        case 'incomplete':
          continue;
        case 'complete':
          break;
        default:
          throw new Error(`Unexpected type ${fut.syntax_check}`);
      }
      // In JavaScript, await automatically also awaits any results of
      // awaits, so if an async function returns a future, it will await
      // the inner future too. This is not what we want so we
      // temporarily put it into a list to protect it.
      let wrapped = this.await_fut(fut);
      // complete case, get result / error and print it.
      try {
        let [value] = await wrapped;
        if (value !== undefined) {
          this.handleStdout(
            this.repr_shorten.callKwargs(value, {
              separator: '\n[[;orange;]<long output truncated>]\n',
            }),
          );
        }
        if (pyodide.isPyProxy(value)) {
          value.destroy();
        }
      } catch (e) {
        if (e.constructor.name === 'PythonError') {
          const message = fut.formatted_error || e.message;
          const lines = message.split('\n');
          if (
            lines.length > 1 &&
            lines[lines.length - 2].includes('SystemExit')
          ) {
            this.exitRepl();
          } else {
            this.handleStderr(message.trimEnd());
          }
        } else {
          throw e;
        }
      } finally {
        fut.destroy();
        wrapped.destroy();
      }
    }

    while (this.errorPromQueue.length > 0) {
      await this.errorPromQueue[this.errorPromQueue.length - 1].promise;
      this.errorPromQueue.pop();
    }
    runningPromise.resolve();
    return this.promptType;
  }

  async endRepl() {
    await pyodide.runPython(RESET_REPL);
  }
}
