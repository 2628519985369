import React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {ModuleCard, CertificateCard, EndOfCourseCard} from './ModuleCard';
import {RoadmapContext} from 'course/contexts/RoadmapContext';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {CourseContext, calcCurrWeek} from 'contexts/CourseContext';
import {getLocalUTCTimezone} from 'components/timezones/timezoneHelper';
import {isMinimumRole, isStudent} from 'contexts/profileUtil';
import {useCompletionMap} from 'hooks/user/useCompletion';
import {useCourseId} from 'hooks/router/useUrlParams';
import {TreasureMap} from './TreasureMap';
import {Calendar} from '../calendar/Calendar';
import {roleToFirebaseString} from 'contexts/profileUtil';

import './module-styles.css';
import {TimeContext} from 'contexts/TimeContext';

export const ModuleTodosSection = ({filterRole}) => {
  // this is all the data of the roadmap
  const {roadmapItemMap} = useContext(RoadmapContext);
  const {getServerTimeMs} = useContext(TimeContext);
  // filter out roadmap items that are not for students
  const filteredRoadmap = useFilterRoadmap(roadmapItemMap, filterRole);

  const moduleRefs = useRef({});

  // create a ref for each of the modules in the roadmap
  // moduleRefs[moduleId] will be a ref to the module with id moduleId
  const containerRef = useRef(null);

  // this controls the icon which is visualized, it should not be used to
  // move the view
  const [selectedModuleId, setSelectedModuleId] = useState('');

  // check whether user has perms to edit roadmap announcement / overview tiptaps
  const {userData} = useContext(ProfileContext);
  const {currCourseWeek, isFoothill} = useContext(CourseContext);
  const courseRole = userData.courseRole;
  const courseId = useCourseId();
  const hasEditPermissions = isMinimumRole(courseRole, Role.TA);

  const certificateUrl = `/${courseId}/certificate`;
  const certificateActive = showCertificate(
    filteredRoadmap,
    roadmapItemMap,
    courseId,
  );

  const currCalendarWeek = calcCurrWeek('2024-04-21T00:00', getServerTimeMs());
  const isLiveCourse = isFoothill || courseId === 'cip4';

  const scrollToCard = moduleId => {
    setSelectedModuleId(moduleId);
    const container = containerRef.current;
    const moduleCards = moduleRefs.current;

    // make sure you are ready to scroll
    if (!container) return;
    if (!moduleCards) return;
    if (!(moduleId in moduleCards)) return;

    const moduleCard = moduleRefs.current[moduleId].current; // TODO @miranda: bug where on first click of icon in treasure map on teaching home, moduleCard is null

    container.scroll({
      top: moduleCard.offsetTop - 51, // 46 is the height of the coursenav
      // make this automatic, no animation
      behavior: 'auto',
    });
  };

  useEffect(() => {
    if (isLiveCourse) {
      // this is careful about the state of the refs
      scrollToCard(filteredRoadmap[currCourseWeek]);
    }
  }, [containerRef.current, moduleRefs.current]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', findIntersectedDiv);
    return () => {
      if (!container) return;
      container.removeEventListener('scroll', findIntersectedDiv);
    };
  }, [containerRef.current]);

  function findIntersectedDiv() {
    const container = containerRef.current;
    const children = container.children;
    const intersectionPoint =
      container.offsetHeight * 0.2 + container.scrollTop;

    for (let child of children) {
      const childTop = child.offsetTop;
      const childBottom = childTop + child.offsetHeight;

      if (intersectionPoint >= childTop && intersectionPoint <= childBottom) {
        setSelectedModuleId(child.id);
      }
    }
  }

  /**
   * @description Allow scrolling from outside of main column
   */
  useEffect(() => {
    const externalDiv = document.getElementById('splash-container-broad');
    if (!externalDiv) return;
    const childDiv = containerRef.current;
    if (!childDiv) return;

    const onWheel = e => {
      childDiv.scrollTop += e.deltaY;
      e.preventDefault(); // Prevent the default scrolling behavior
    };

    externalDiv.addEventListener('wheel', onWheel, {passive: false});

    return () => {
      if (!externalDiv) return;
      externalDiv.removeEventListener('wheel', onWheel);
    };
  }, [containerRef.current]);

  return (
    <div>
      <TreasureMap
        certificateUrl={certificateUrl}
        certificateActive={certificateActive}
        roadmap={filteredRoadmap}
        currModuleId={selectedModuleId}
        goToCard={scrollToCard}
        containerRef={containerRef}
      />
      <div
        className="module-cards-container w-100"
        style={{scrollbarWidth: 'none'}}
        ref={containerRef}
        id="todo-container"
      >
        {
          // TODO: Determine if its just CIP or if its all sync classes
          courseId === 'cip3' ? (
            <Calendar
              firstCalendarDay={16}
              nWeeks={8}
              events={[]}
              startWeek={7}
              isStudentCal={isStudent(courseRole)}
            />
          ) : null
        }
        {
          // TODO: Determine if its just CIP or if its all sync classes
          courseId === 'cip4' ? (
            <Calendar
              firstCalendarDay={21}
              nWeeks={8}
              events={[]}
              startWeek={currCalendarWeek - 1}
              isStudentCal={true}
            />
          ) : null
        }
        {filteredRoadmap.map(moduleId => {
          // Create a new ref if it does not yet exist
          if (!moduleRefs.current[moduleId]) {
            moduleRefs.current[moduleId] = React.createRef();
          }

          return (
            <ModuleCard
              key={moduleId}
              courseId={courseId}
              moduleId={moduleId}
              moduleItem={roadmapItemMap[moduleId]}
              innerRef={moduleRefs.current[moduleId]}
              hasEditPermissions={hasEditPermissions}
            />
          );
        })}
        <CertificateCard
          certificateUrl={certificateUrl}
          certificateActive={certificateActive}
        />

        {/* Aweful hack to solve the scroll issue */}
        <div style={{height: '200px'}}></div>
      </div>
    </div>
  );
};

const showCertificate = (filteredRoadmap, roadmapItemMap, courseId) => {
  if (courseId === 'cip3') {
    return true;
  }

  // extract all roadmap items into flat list
  const allRoadmapItems = filteredRoadmap.reduce((acc, moduleId) => {
    return acc.concat(roadmapItemMap[moduleId]['items']);
  }, []);
  const {completionMap} = useCompletionMap(allRoadmapItems);
  // return true only if all items are complete (all elements in completionMap are true)
  return Object.keys(completionMap).every(itemId => completionMap[itemId]);
};

const useFilterRoadmap = (roadmapItemMap, courseRole) => {
  const [filteredRoadmap, setFilteredRoadmap] = useState([]);

  useEffect(() => {
    setFilteredRoadmap(filterRoadmap(roadmapItemMap, courseRole));
  }, [roadmapItemMap, courseRole]);

  return filteredRoadmap;
};

const filterRoadmap = (roadmapItemMap, courseRole) => {
  return Object.keys(roadmapItemMap)
    .filter(
      key =>
        roleToFirebaseString(courseRole) === roadmapItemMap[key]['roadmapType'],
    ) // filter out modules that are not for students
    .filter(key => {
      // filter out modules that have not started yet
      const startDate = new Date(roadmapItemMap[key]['startDate']);
      const currentDate = new Date();
      return startDate <= currentDate;
    })
    .sort((a, b) => {
      const aDate = new Date(roadmapItemMap[a]['startDate']);
      const bDate = new Date(roadmapItemMap[b]['startDate']);
      // NOTE changed the order here -- oldest -> newest now, unlike in TodoListSection
      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0;
      }
    });
};
