import Swal from 'sweetalert2';
import {FaCopy} from 'react-icons/fa';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/esm/types';
interface CopyButtonProps {
  /** The text to copy to the clipboard */
  toCopy: string;
  /** The icon to display. Optional, defaults to FaCopy */
  icon?: React.ReactNode;
  /** The tooltip to display. Optional. If not provided, the button will not
   * have a tooltip */
  tooltip?: string;
  /** The tooltip placement. Optional, defaults to 'right' */
  tooltipPlacement?: Placement;
}
export const CopyButton = ({
  toCopy,
  tooltip,
  icon = <FaCopy />,
  tooltipPlacement = 'right',
}: CopyButtonProps) => {
  const onCopy = () => {
    navigator.clipboard.writeText(toCopy);
    // I want a tiny swal
    Swal.fire({
      title: 'Copied to clipboard',
      text: toCopy,
      icon: 'success',
    });
  };
  const CopyButton = (
    <Button onClick={onCopy} className="btn-sm" variant="light">
      {icon}
    </Button>
  );
  if (tooltip) {
    return (
      <OverlayTrigger
        placement={tooltipPlacement}
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        {CopyButton}
      </OverlayTrigger>
    );
  }
  return CopyButton;
};
