import {doc, getFirestore, setDoc} from 'firebase/firestore';
import React, {useContext, useEffect} from 'react';
import {useCourseId} from 'hooks/router/useUrlParams';
import {ProfileContext} from 'contexts/ProfileContext';
import {isStaffRole} from 'contexts/profileUtil';
import Swal from 'sweetalert2';

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

const defaultData = {
  applicantList: [],
  gradebook: {},
  secondReviewRequests: {},
  graders: {},
  calibration: {},
  reviewLoading: true,
  gradeDataError: null,
};

export const ReviewContext = React.createContext(defaultData);

export const ReviewProvider = ({children}) => {
  const courseId = useCourseId();
  const {userData, loading} = useContext(ProfileContext);

  const gradebookRef = doc(
    getFirestore(),
    `course/${courseId}/slReview/gradebook`,
  );
  const secondReviewRef = doc(
    getFirestore(),
    `course/${courseId}/slReview/secondReview`,
  );
  const gradersRef = doc(getFirestore(), `course/${courseId}/slReview/graders`);
  const applicantsRef = doc(
    getFirestore(),
    `course/${courseId}/slReview/applicants`,
  );
  const calibrationRef = doc(
    getFirestore(),
    `course/${courseId}/slReview/graders/calibration/${userData.id}`,
  );

  const [gradeData, gradebookLoading, gradeDataError] =
    useDocumentData(gradebookRef);
  const [secondReview, secondReviewLoading, secondReviewError] =
    useDocumentData(secondReviewRef);
  const [gradersData, gradersLoading, gradersError] =
    useDocumentData(gradersRef);
  const [calibrationData, calibrationLoading, calibrationError] =
    useDocumentData(calibrationRef);
  const [applicantsData, applicantsLoading, applicantsError] =
    useDocumentData(applicantsRef);

  const applicantList = applicantsData?.list || [];
  // the raw format has keys like "userId.graderId" which we expand into a nested object
  const gradebook = constructGradebook(gradeData);
  const secondReviewRequests = secondReview || {};
  const graders = gradersData || {};
  const calibration = calibrationData || {};

  const reviewLoading =
    gradebookLoading ||
    secondReviewLoading ||
    gradersLoading ||
    calibrationLoading ||
    applicantsLoading ||
    loading;

  useEffect(() => {
    if (gradersLoading || loading) return;
    const role = userData?.courseRole;
    // watch out!!
    console.log(userData);
    if (!isStaffRole(role)) return;
    // if you as a user are not in the graders list, add yourself with your initials
    const userId = userData.id;
    if (!(userId in graders)) {
      setInitials(gradersRef, userData);
    }
  }, [gradersLoading, loading]);

  return (
    <ReviewContext.Provider
      value={{
        applicantList,
        gradebook,
        graders,
        calibration,
        secondReviewRequests,
        reviewLoading,
        gradeDataError,
      }}
    >
      {children}
    </ReviewContext.Provider>
  );
};

const setInitials = async (gradersRef, userData) => {
  const displayName = userData.displayName;
  const nameParts = displayName.split(' ');
  let initials = nameParts.map(part => part[0]).join('');
  if (initials.length < 2) {
    const {value: inputInitials} = await Swal.fire({
      title: 'Please enter your initials',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: false,
      confirmButtonText: 'Submit',
    });
    initials = inputInitials;
  }

  setDoc(gradersRef, {[userData.id]: initials}, {merge: true});
};

const constructGradebook = gradeData => {
  if (!gradeData) {
    return {};
  }
  const gradebook = {};
  for (const gradeKey in gradeData) {
    // gradeKey is ${userId}.${graderId}
    const [userId, graderId] = gradeKey.split('.');
    if (!gradebook[userId]) {
      gradebook[userId] = {};
    }
    gradebook[userId][graderId] = gradeData[gradeKey];
  }
  console.log(gradebook);
  return gradebook;
};
