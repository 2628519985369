import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Swal from 'sweetalert2';
import {markAssnCorrect} from './runUnitTestsAndReportResults';
import {useContext, useEffect} from 'react';
import {NavigationHistoryContext} from 'contexts/NavigationContext';
import {useUserId} from 'hooks/user/useUserId';
import {useNavigate, useParams} from 'react-router';
import {PyodideContext} from 'components/pyodide/PyodideProvider';
import {getDefaultFile, IDEContext} from 'ide/contexts/IDEContext';
import {useCourseId} from 'hooks/router/useUrlParams';
import {
  checkIsProjectConsole,
  checkIsProjectGraphics,
  checkIsProjectKarel,
  isCreativeProject,
} from 'ide/utils/general';
import {inferUnitTestType} from './runUnitTests';
import {isAssignmentUnitTestType} from 'assignments/types';
import {useCodeFuncs} from 'ide/hooks/ideHooks';
import {FaClipboardCheck} from 'react-icons/fa';
import {useIsMobile, useIsTablet} from 'utils/general';
import {useBreakpoint} from 'react-use-size';

export const UnitTestButton = () => {
  const ideContext = useContext(IDEContext);
  const {isRunning, setIsRunning, aiAutograderRunning} = ideContext;
  const {isPyodideLoading} = useContext(PyodideContext);
  const userId = useUserId();
  const serverUnitTestType = ideContext.assnData.metaData.unitTestType;
  const isTablet = useIsTablet();

  const unitTestType = isAssignmentUnitTestType(serverUnitTestType)
    ? serverUnitTestType
    : inferUnitTestType(ideContext.assnData, ideContext.projectData);
  const isKarel = checkIsProjectKarel(
    ideContext.projectData,
    ideContext.assnData,
  );
  const isConsole = checkIsProjectConsole(
    ideContext.projectData,
    ideContext.assnData,
  );
  const isGraphics = checkIsProjectGraphics(
    ideContext.projectData,
    ideContext.assnData,
  );
  const {testCode} = useCodeFuncs(
    userId,
    ideContext.projectData,
    ideContext.currentFile,
    ideContext.filesCode,
    ideContext.fileStructure,
    isKarel,
    isConsole,
    isGraphics,
  );
  const mainFile = getDefaultFile(ideContext.projectData);
  useEffect(() => {});
  if (isCreativeProject(ideContext.projectData)) {
    return <></>;
  }

  const testButtonClicked = async () => {
    if (!mainFile) {
      console.error('No main file found');
      return;
    }
    testCode(mainFile);
  };

  const isPyodideBlocked =
    (isRunning || isPyodideLoading) && unitTestType !== 'ai';
  const isAiGraderBlocked = aiAutograderRunning && unitTestType === 'ai';
  const isDisabled = isPyodideBlocked || isAiGraderBlocked;

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{show: 100, hide: 100}}
        overlay={
          <Tooltip id="button-tooltip">
            Run tests that will check if your code meets the specifications.
          </Tooltip>
        }
      >
        <button
          className="btn btn-primary btn-sm mr-2"
          id="run-unit-test-button"
          style={{
            backgroundColor: isDisabled ? 'gray' : 'var(--bs-success)',
            borderColor: isDisabled ? 'gray' : 'var(--bs-success)',
          }}
          onClick={testButtonClicked}
          disabled={isDisabled}
        >
          <FaClipboardCheck size={16} />{' '}
          {unitTestType !== 'none'
            ? 'Check' + (isTablet ? '' : ' Correct')
            : 'Mark Complete'}
        </button>
      </OverlayTrigger>
    </>
  );
};

export const MarkCorrectButton = () => {
  const courseId = useCourseId();
  const userId = useUserId();
  const {urlKey} = useParams();
  const {getPreviousLocation} = useContext(NavigationHistoryContext);
  const navigate = useNavigate();

  const markCorrectClicked = async () => {
    await markAssnCorrect(userId, courseId, urlKey);
    await Swal.fire({
      title: 'You did it!',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Onwards!',
      cancelButtonText: 'Keep Working',
      preConfirm: () => {
        navigate(getPreviousLocation());
      },
    });
  };

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{show: 100, hide: 100}}
        overlay={
          <Tooltip id="button-tooltip">
            Mark this assignment as correct.
          </Tooltip>
        }
      >
        <button
          className="btn btn-outline-dark btn-sm "
          onClick={markCorrectClicked}
        >
          {'Mark Correct'}
        </button>
      </OverlayTrigger>
    </>
  );
};
