/**
 * Finds the MLE (Maximum Likelihood Estimator) for a sample taken from a Binomial Distribution
 * @param data - the sample
 * @returns The MLE estimate of the distribution's parameter p [0, 1]
 */
export function binomialMLE(data) {
    // Cool fact! For binomial distribution, log likelihood maximized when p = # successes / # samples
    const n = data.length;
    
    // Since values are either 0 or 1, can just sum all to get num successes
    const succcesses = data.reduce((sum, x) => sum + x, 0);
    
    return succcesses / n;
}