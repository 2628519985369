import {useState, useContext} from 'react';
import {Button, ProgressBar} from 'react-bootstrap';
import {FaHome, FaGraduationCap} from 'react-icons/fa';
import styled from 'styled-components';
import {ReactComponent as BalloonIcon} from 'icons/balloon.svg';
import {ReactComponent as SpyglassIcon} from 'icons/spyglass.svg';
import {ReactComponent as UniversityIcon} from 'icons/university.svg';
import {useNavigate} from 'react-router';
import {welcomeData} from './welcomeData';
import {useIsMobile} from 'utils/general';
import {DuoLesson} from 'components/duolessons/DuoLessons';
import {EnrollmentContext} from 'contexts/EnrollmentContext';
import {roleToDisplayString} from 'contexts/profileUtil';
import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import {Role} from 'types/role';

export const WelcomeLanding = ({}) => {
  return (
    <DuoLesson
      pages={welcomeData}
      exitPath={undefined}
      completePath="/welcome/choice"
      prevPath={'/'}
    />
  );
};

export const WelcomeChoice = () => {
  const isMobile = useIsMobile();
  const hideFooter = isMobile;
  const showFooter = !hideFooter;

  return (
    <OuterBody>
      <InnerBody>
        <FullContent>
          <WelcomeChoiceProgress />
          <div className="d-flex mt-3 mb-3">
            <KarelSmall src="/karel192.png" />
            <RightSpeechBubble text="Let's find the best place to start learning to code!" />
          </div>
          <div style={{flexGrow: 1}} />
          <CenteredContent>
            {/* <ButtonCard
            to="/public/join/cip4"
            title="Apply to Code in Place 2024"
            text="A festival of learning. April 22nd - May 31st. "
            icon={<UniversityIcon style={{ width: '50px', height: '50px' }} />}
          /> */}

            <EnrolledCourses />

            {/* 
          This is what we used to display
          <ButtonCard
            to="/public"
            title="Explore the curriculum"
            text="Preview the content of the course."
            icon={<SpyglassIcon style={{ width: '50px', height: '50px' }} />}
          /> 
          */}
            <ButtonCard
              to="/public"
              title="Code in Place - Public"
              text="Course lessons, assignments and more."
              icon={<SpyglassIcon style={{width: '50px', height: '50px'}} />}
            />
            <ButtonCard
              to="/public/learn/welcome-to-karel"
              title="Start the first lesson"
              text="The very first step in the coding course."
              icon={<BalloonIcon style={{width: '50px', height: '50px'}} />}
            />

            <AlumniCenter />

            <div style={{height: 50}} />

            {/* <SLApplicationLink /> */}
          </CenteredContent>
          <div style={{flexGrow: 2}} />
        </FullContent>
      </InnerBody>
      {/* {showFooter && <hr />}
    {showFooter &&
      <Footer>
        <ContinueButton />
      </Footer>
    } */}
    </OuterBody>
  );
};

const SLApplicationLink = () => {
  const navigate = useHistoryNavigate();
  return (
    <button
      onClick={() => {
        navigate(`/public/applyteach/cip4`);
      }}
      className="btn btn-link"
    >
      Apply to Teach for Code in Place 2024
    </button>
  );
};

const EnrolledCourses = () => {
  const {roles} = useContext(EnrollmentContext);

  // these are the courses to check. Display in this order
  const courseIds = ['cip4', 'cip3'];
  const enrolledCourses = [];
  courseIds.forEach(courseId => {
    // as long as it isn't an empty string!
    if (roles[courseId] && roles[courseId] !== Role.MENTOR) {
      enrolledCourses.push(courseId);
    }
  });

  if (enrolledCourses.length === 0) {
    return <></>;
  }

  // return all the button cards
  const courseHtml = enrolledCourses.map(courseId => {
    const roleEnum = roles[courseId];
    const roleStr = roleToDisplayString(roleEnum);
    // for now we are going to figure out the course title from the course id
    const courseTitle =
      courseId === 'cip3' ? 'Code in Place - 2023' : 'Code in Place - 2024';
    return (
      <ButtonCard
        to={`/${courseId}`}
        title={courseTitle}
        text={`Role: ${roleStr}.`}
        icon={<UniversityIcon style={{width: '50px', height: '50px'}} />}
        key={courseId}
      />
    );
  });

  return (
    <>
      <YourCoursesTitle>Enrolled Courses</YourCoursesTitle>
      {courseHtml}
      <CourseContentHr />
    </>
  );

  // for now we only show code in place 3
  // if (roles['cip3']) {
  //   const roleEnum = roles['cip3'];
  //   console.log('roleEnum', roleEnum)
  //   const roleStr = roleToString(roleEnum);
  //   return <ButtonCard
  //     to="/cip3/learn"
  //     title="Enter Code in Place 2023"
  //     text={`Role: ${roleStr}.`}
  //     icon={<BlackboardIcon style={{ width: '50px', height: '50px' }} />}
  //   />
  // } else {
  //   return <></>
  // }
};

const AlumniCenter = () => {
  const {roles} = useContext(EnrollmentContext);

  // these are the courses to check. Display in this order
  const isAlum = 'alumni' in roles;
  const roleStr = isAlum ? roleToDisplayString(roles['alumni']) : '';

  if (!isAlum) {
    return <></>;
  }

  return (
    <ButtonCard
      to={`/alumni`}
      title={'Code in Place Alumni Center'}
      text={`Role: ${roleStr}.`}
      icon={<FaGraduationCap style={{width: '50px', height: '50px'}} />}
      key={'alumni'}
    />
  );
};

const ButtonCard = ({title, text, icon, to}) => {
  // on click you should navigate to the href passed as "to"
  const navigate = useHistoryNavigate();
  return (
    <ButtonCardOuter className="card mb-3" onClick={() => navigate(to)}>
      <div className="row g-0">
        <div className="col">
          <ButtonCardInner>
            <div style={{width: 80, display: 'flex', justifyContent: 'center'}}>
              {icon}
            </div>
            <div className="">
              <div className="card-body" style={{paddingLeft: '0px'}}>
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
              </div>
            </div>
          </ButtonCardInner>
        </div>
      </div>
    </ButtonCardOuter>
  );
};

const WelcomeChoiceProgress = ({}) => {
  const [now, setNow] = useState(100); // Initial progress
  const navigate = useNavigate();
  return (
    <WelcomeProgressOuter>
      <button
        aria-label="back button"
        onClick={() => navigate('/')}
        className="btn btn-light mr-1"
      >
        <FaHome />
      </button>
      <ProgressBar now={now} className="flex-grow-1" />
    </WelcomeProgressOuter>
  );
};

const TopSpeechBubble = ({text}) => (
  <SpeechBubbleContainer style={{minHeight: 80}}>
    <SpeechBubbleText tabIndex={0}>{text}</SpeechBubbleText>
    <TailBottom />
  </SpeechBubbleContainer>
);

const RightSpeechBubble = ({text}) => (
  <SpeechBubbleContainer>
    <SpeechBubbleText tabIndex={0}>{text}</SpeechBubbleText>
    <TailLeft />
  </SpeechBubbleContainer>
);

const ContinueButton = () => {
  return (
    <Button disabled={true} size="lg">
      Continue
    </Button>
  );
};

const ButtonCardOuter = styled.button`
  max-width: 500px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0; /* Change to your desired hover background color */
  }
`;

const ButtonCardInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const WelcomeProgressOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const KarelLarge = styled.img`
  max-width: 200px;
`;

const KarelSmall = styled.img`
  max-width: 90px;
`;

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const Footer = styled.div`
  height: 145px;
  width: 100%;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: end;
  flex-direction: column;
  max-width: 1200px;
  justify-content: center;

  @media (max-width: 768px) {
    /* Adjusts the width for mobile devices */
    height: 90px; /* Height for mobile */
    align-items: center; /* Aligns the buttons to the center */
  }
`;

const SpeechBubbleContainer = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
`;

const SpeechBubbleText = styled.p`
  margin: 0;
  color: #333333;
  font-size: 16px;
  text-align: center;
`;

const TailBottom = styled.div`
  position: absolute;
  bottom: -10px; // The tip of the tail will align with the bottom of the bubble
  left: 50%;
  transform: translateX(-50%) rotate(225deg);
  background: #ffffff;
  width: 20px; // Width of the tail square before rotation
  height: 20px; // Height of the tail square before rotation
  content: '';
  clip-path: polygon(0 0, 100% 0, 0 100%); // Clip the square to form a triangle
  border: 1px solid grey;
`;

const TailLeft = styled.div`
  position: absolute;
  top: 50%; // Align the center of the tail with the center of the bubble's side
  left: -10px; // The tip of the tail will align with the left side of the bubble
  transform: translateY(-50%) rotate(315deg); // Adjust rotation to point left
  background: #ffffff;
  width: 20px; // Width of the tail square before rotation
  height: 20px; // Height of the tail square before rotation
  content: '';
  clip-path: polygon(0 0, 100% 0, 0 100%); // Clip the square to form a triangle
  border: 1px solid grey;
`;

const OuterBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;

  @media (max-width: 768px) {
    /* Adjusts the width for mobile devices */
    padding: 0px; /* Padding for mobile */
  }
`;

const InnerBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
`;

const CourseContentHr = styled.hr`
  width: 500px;
  margin: 20px 0;
  margin-top: 10px;
  margin-bottom: 25px;
  border: black solid 1px;
`;

const YourCoursesTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
`;
