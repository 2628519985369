import {getApp} from 'firebase/app';
import {collection, getFirestore} from 'firebase/firestore';

import type {
  CollectionReference,
  Firestore,
  FirestoreDataConverter,
} from 'firebase/firestore';

export const getCollectionRef = <T>(
  collectionName: string,
  converter: FirestoreDataConverter<T>,
): CollectionReference<T> => {
  return collection(getFirestore(getApp()), collectionName).withConverter(
    converter,
  );
};

export function getFirestoreInstance(): Firestore {
  return getFirestore(getApp());
}
