import {useContext, useState, useEffect, useRef} from 'react';
import {IDEContext} from '../contexts/IDEContext';
import {Editor, OnMount} from '@monaco-editor/react';
import {ProfileContext} from 'contexts/ProfileContext';
import {addCommentBox} from 'course/instructorFeedback/EditorZones';
import {setupButtonListeners} from 'course/instructorFeedback/AddButton';
import {loadFeedbacks} from 'course/instructorFeedback/FirebaseUtils';
import {FaEdit} from 'react-icons/fa';
import Gate from 'contexts/Gate';
import {CourseContext} from 'contexts/CourseContext';
import {useCourseId} from 'hooks/router/useUrlParams';
import {getFirestore, doc, getDoc, setDoc} from 'firebase/firestore';
import {isMinimumRole} from 'contexts/profileUtil';
import {Role} from 'types/role';
import {Button} from 'react-bootstrap';

export const ResizableCodeInput = ({
  onChange,
  isReadOnly,
  code = '',
  isOffline = false,
}) => {
  const {
    editorRef,
    editorLoaded,
    setEditorLoaded,
    editorFontSize,
    projectData,
    selectedTab,
  } = useContext(IDEContext);
  const {courseHasGrades} = useContext(CourseContext);
  const courseId = useCourseId();

  // Particularly used for inline comments
  const {userData} = useContext(ProfileContext);
  const canEdit = isMinimumRole(userData.courseRole, Role.SECTION_LEADER);
  const viewZoneIds = useRef({});
  const domNodeRefs = useRef({});

  // determining the visibility of the comments
  const [visibility, setVisibility] = useState(false);
  const [visibilityLoaded, setVisibilityLoaded] = useState(false);
  const assnId = projectData.assnId;
  const authorId = projectData.editors[0];
  const visibilityDocPath = `submissions/${courseId}/assignments/${assnId}/users/${authorId}/feedback/visibility`;

  const db = getFirestore();

  // fetch comment visibility document from firestore for course with grades
  useEffect(() => {
    if (!courseHasGrades) {
      setVisibilityLoaded(true);
      return;
    }
    const fetchVisibility = async () => {
      try {
        const visibilityDocRef = doc(db, visibilityDocPath);
        const docSnap = await getDoc(visibilityDocRef);
        if (docSnap.exists()) {
          setVisibility(docSnap.data().visibility);
        } else {
          console.log('No visibility document found');
          // create grading document
          await setDoc(visibilityDocRef, {
            visibility: false,
          });
          console.log('Visibility document created');
        }
        setVisibilityLoaded(true);
      } catch (e) {
        console.error('Error fetching visibility document', e);
        setVisibilityLoaded(true);
      }
    };

    fetchVisibility();
  }, [visibilityDocPath]);

  if (!visibilityLoaded) return <></>;

  const onMount: OnMount = editor => {
    editorRef.current = editor;
    setEditorLoaded(!editorLoaded);

    // Add right-click command
    if (!courseHasGrades) return;

    editorRef.current.addAction({
      id: 'add-comment-id',
      label: 'Add Comment',
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: function (ed) {
        addCommentBox(
          true,
          editorRef,
          editor.getPosition().lineNumber,
          viewZoneIds,
          domNodeRefs,
          projectData,
          userData,
          courseId,
        );
      },
    });
    // Setup button listeners for inline comments
    if (canEdit) {
      setupButtonListeners(
        editorRef,
        viewZoneIds,
        domNodeRefs,
        projectData,
        userData,
        courseId,
      );
    }

    // Load inline comments from Firestore
    loadFeedbacks(
      courseId,
      projectData,
      editorRef,
      viewZoneIds,
      domNodeRefs,
      userData,
      visibility,
    );
  };

  if (isOffline) {
    <div className="h-100 w-100 d-flex overflow-hidden">
      <Editor
        height="100%"
        defaultLanguage="python"
        onChange={onChange}
        onMount={onMount}
        options={{
          fontSize: editorFontSize,
          minimap: {enabled: false},
          readOnly: isReadOnly,
        }}
        width={'100%'}
        value={code}
      />
    </div>;
  }
  const instructorFeedbackButtonStyle = {
    display: selectedTab === 'Feedback' ? 'block' : 'none',
  };
  return (
    <div className="h-100 w-100 d-flex overflow-hidden">
      {isMinimumRole(userData.courseRole, Role.SECTION_LEADER) && (
        <Button
          id="selectionButton"
          style={instructorFeedbackButtonStyle}
          variant="light"
        >
          <FaEdit />
        </Button>
      )}
      <Editor
        height="100%"
        defaultLanguage="python"
        onChange={onChange}
        onMount={onMount}
        options={{
          fontSize: editorFontSize,
          minimap: {enabled: false},
          readOnly: isReadOnly,
        }}
        width={'100%'}
      />
    </div>
  );
};
