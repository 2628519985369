// ASSN PROMPT

import {useContext} from 'react';
import {FaLock, FaTrash, FaUnlock} from 'react-icons/fa';
import Swal from 'sweetalert2';
import {TipTap} from '../../components/richTextEditor/TipTap/TipTap';
import {IDEContext} from '../contexts/IDEContext';
import styled from 'styled-components';
import {ShowSolutionBehavior} from 'assignments/types';
import {CompletionContext} from 'contexts/CompletionContext';

interface AssnPromptProps {
  promptPath: string;
  solnPath: string;
  assnTitle: string;
  isAssignmentCompleted: boolean;
  showSolutionBehavior: ShowSolutionBehavior;
  revertToStarterFn: () => Promise<void>;
  bottomBarRef?: React.RefObject<HTMLDivElement>;
}

const AssnPrompt = ({
  promptPath,
  solnPath,
  showSolutionBehavior,
  isAssignmentCompleted,
  revertToStarterFn,
  bottomBarRef,
}: AssnPromptProps) => {
  const bottomBarBuffer =
    bottomBarRef && bottomBarRef.current
      ? bottomBarRef.current?.clientHeight || 0
      : 0;
  const shouldShowSolution =
    showSolutionBehavior === 'always' ||
    (showSolutionBehavior === 'if_student_solved' && isAssignmentCompleted);

  return (
    <>
      <div
        className="w-100"
        style={{
          paddingLeft: '10px',
          paddingRight: '20px',
          display: 'flex',
          maxWidth: '550px',
          justifyContent: 'space-between',
          paddingTop: '10px',
        }}
      >
        <TitleBar
          isPractice={showSolutionBehavior === 'always'}
          revertToStarterFn={revertToStarterFn}
        />
      </div>
      <div
        className="w-100 h-100"
        style={{
          flexGrow: 2,
          paddingLeft: '10px',
          paddingRight: '20px',
          textAlign: 'justify',
          fontSize: '14px',
          overflow: 'auto',
          paddingBottom: bottomBarBuffer,
        }}
      >
        {shouldShowSolution ? <SolutionSection solnPath={solnPath} /> : <></>}
        <TipTap
          editable={false}
          collaborative={false}
          firebaseDocPath={promptPath}
          onServerWrite={() => {}}
          buttonBar={() => {}}
        />
      </div>
    </>
  );
};

interface TitleBarProps {
  /** Whether to display the title as "Practice" or "Assignment" */
  isPractice: boolean;
  revertToStarterFn: () => Promise<void>;
}
const TitleBar = ({isPractice, revertToStarterFn}: TitleBarProps) => {
  function onRestartAssn() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-primary mr-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: `Are you sure you want to restart this assignment?`,
        text: 'Your code will revert to the "starter code" and your current code will be deleted.',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Yes, delete my current code!',
        cancelButtonText: 'Never mind',
      })
      .then(async result => {
        if (result.isConfirmed) {
          console.log('revert to starter');
          await revertToStarterFn();
        }
      });
  }

  return (
    <div className="d-flex justify-content-between w-100 align-items-baseline">
      <h1 className="ideHeadingLight">
        {isPractice ? 'Practice' : 'Assignment'}
      </h1>

      <button className="btn btn-sm btn-light" onClick={() => onRestartAssn()}>
        <div className="d-flex flex-row align-items-center">
          <FaTrash className="mr-1" />
          Restart
        </div>
      </button>
    </div>
  );
};

interface SolutionSectionProps {
  solnPath: string;
}
const SolutionSection = ({solnPath}: SolutionSectionProps) => {
  const {showSoln, setShowSoln} = useContext(IDEContext);
  const showSolnText = (
    <>
      <FaUnlock /> Show solution
    </>
  );
  const hideSolnText = (
    <>
      <FaLock /> Hide solution
    </>
  );

  return (
    <>
      <button
        className="btn btn-light btn-sm mb-2"
        onClick={() => {
          setShowSoln(!showSoln);
        }}
      >
        {showSoln ? hideSolnText : showSolnText}
      </button>
      {showSoln && (
        <>
          <SolutionTitle>Example Solution: </SolutionTitle>
          <TipTap
            editable={false}
            collaborative={false}
            firebaseDocPath={solnPath}
            onServerWrite={() => {}}
            buttonBar={() => {}}
          />
          <hr />
        </>
      )}
    </>
  );
};

const SolutionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export default AssnPrompt;
