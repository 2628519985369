import {useState, useContext} from 'react';
import {IDEContext} from 'ide/contexts/IDEContext';
import {FaCheck, FaQuestionCircle} from 'react-icons/fa';
import Swal from 'sweetalert2';
import {UnitTestButton} from '../UnitTest/RunUnitTestButton';
import {
  ConsoleUnitTest,
  getPostconditionFromDialogue,
  isConsoleUnitTest,
} from '../../assignments/models/unitTests/console';
import {inferUnitTestType} from 'ide/UnitTest/runUnitTests';

const LINE_START = '';
const successColor = '#defcee';
const failColor = '#ffc299';

export const TestList = () => {
  const ideContext = useContext(IDEContext);
  const {assnData, consoleTestResults, isDiagnostic} = ideContext;

  const unitTests = assnData?.unitTests?.unitTests;
  const assnId = assnData?.metaData?.uid;
  const unitTestType = inferUnitTestType(assnData, ideContext.projectData);
  if (isDiagnostic || !assnId) {
    return <></>;
  }
  const shouldShowTestResults =
    unitTests && consoleTestResults && unitTestType === 'console';
  const consoleUnitTests = Array.isArray(unitTests)
    ? unitTests.filter(test => {
        if (!isConsoleUnitTest(test)) {
          console.warn('Ignoring non-console unit test: ', test);
          return false;
        }
        return true;
      })
    : [];
  return (
    <div style={{overflow: 'auto'}}>
      <div className="d-flex" style={{justifyContent: 'space-between'}}>
        <TestInfoButton />
      </div>

      {shouldShowTestResults ? (
        <TestResults
          consoleTestResults={consoleTestResults}
          unitTests={consoleUnitTests}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

interface TestResultsProps {
  consoleTestResults: any[];
  unitTests: ConsoleUnitTest[];
}

// @TJ this used to be a "nested component" but it caused a strange bug where
// anytime anything changed in the editor (eg student types) this whole component
// would re-mount.
const TestResults = ({consoleTestResults, unitTests}: TestResultsProps) => {
  let resultsList = [];
  for (const res of consoleTestResults) {
    if (res.output.length > 50) {
      resultsList.push({
        output: res.output.slice(0, 20),
        cutoff: true,
        error: res.error,
        input: res.input,
      });
    } else {
      resultsList.push({
        output: res.output,
        cutoff: false,
        error: res.error,
        input: res.input,
      });
    }
  }

  return (
    <div>
      {resultsList.map((val, idx) => {
        return (
          <TestView
            userOutput={val.output}
            userError={val.error}
            testOutput={getPostconditionFromDialogue(unitTests[idx])}
            testName={unitTests[idx]?.name}
            testOutcome={consoleTestResults[idx].state ?? 'loading'}
            cutoff={val.cutoff}
            key={unitTests[idx]?.name}
            dialogue={unitTests[idx]?.dialogue ?? []}
            description={unitTests[idx]?.description ?? ''}
            userInput={val.input ?? {}}
          />
        );
      })}
    </div>
  );
};

export const TestView = ({
  userOutput,
  userError,
  testOutput,
  testName,
  testOutcome,
  cutoff,
  dialogue,
  description,
  userInput,
}) => {
  // testViews should be expanded by default if they fail
  const isSuccess = testOutcome == 'success';
  const [expanded, setExpanded] = useState(!isSuccess);
  const backgroundColor = isSuccess
    ? successColor
    : testOutcome == 'failure'
      ? failColor
      : 'lightgray';
  const borderColor = isSuccess
    ? '#198754'
    : testOutcome == 'failure'
      ? '#fd7e14'
      : 'lightgray';
  const resultIcon = isSuccess ? (
    <FaCheck />
  ) : testOutcome == 'failure' ? (
    <span className="badge text-bg-danger">Incomplete</span>
  ) : (
    <></>
  );

  return (
    <div
      style={{
        transition: '0.5s',
        borderRadius: '5px',
        marginTop: '10px',
        border: `1px solid ${borderColor}`,
        fontSize: '12px',
      }}
      className="refCode "
    >
      <div
        onClick={() => setExpanded(!expanded)}
        className="mb-0 p-2 "
        style={{
          cursor: 'pointer',
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          borderBottomLeftRadius: expanded ? '0px' : '5px',
          borderBottomRightRadius: expanded ? '0px' : '5px',
          backgroundColor: backgroundColor,
        }}
      >
        {' '}
        <code>{testName}</code>{' '}
        <span style={{fontSize: '1.02rem', marginLeft: '5px'}}>
          {resultIcon}
        </span>
      </div>
      {expanded ? (
        <div>
          {description && description.length > 0 ? (
            <p className="mb-0 border rounded p-1 m-1">
              <strong>Hints: </strong>
              {description}
            </p>
          ) : null}
          <div>
            <DiffMap
              contentList={testOutput}
              diffList={userOutput}
              user={false}
              cutoff={false}
              dialogue={dialogue}
            />
          </div>
          <div>
            <DiffMap
              contentList={userOutput}
              diffList={testOutput}
              user={true}
              userError={userError}
              cutoff={cutoff}
              input={userInput}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const DiffMap = ({
  contentList,
  diffList,
  user,
  cutoff,
  userError = [],
  dialogue = [],
  input = {},
}) => {
  return (
    <div className="p-2 font-monospace">
      <div>
        <p className="mb-0 text-nowrap overflow-scroll">
          <strong>{user ? 'Observed Output' : 'Expected Output'}</strong>
        </p>
        <div style={{overflowY: 'scroll'}}>
          {contentList.map((txt, idx) => {
            if (
              idx >= diffList.length ||
              diffList[idx].replace(/\s+/g, '') !=
                contentList[idx].replace(/\s+/g, '')
            ) {
              return (
                <p
                  key={idx}
                  className="overflow-auto mb-0"
                  style={{
                    whiteSpace: 'pre-wrap',
                    minWidth: '1px',
                    minHeight: '15px',
                  }}
                >
                  <strong>{LINE_START}</strong>
                  <mark style={{backgroundColor: '#ffc299'}}>{txt}</mark>
                  <strong style={{color: 'blue'}}>
                    {!!dialogue[idx] && dialogue[idx].type == 'input'
                      ? ' ' + dialogue[idx].input
                      : ''}
                    {!!input && input[idx] ? ' ' + input[idx] : ''}
                  </strong>
                </p>
              );
            }
            return (
              <p key={idx} className="text-nowrap overflow-auto mb-0">
                <strong>{LINE_START}</strong>
                {txt}
                <strong style={{color: 'blue'}}>
                  {!!dialogue[idx] && dialogue[idx].type == 'input'
                    ? ' ' + dialogue[idx].input
                    : ''}
                  {!!input && input[idx] ? ' ' + input[idx] : ''}
                </strong>
              </p>
            );
          })}
          {cutoff ? <p className="mb-0">...</p> : null}
        </div>
      </div>
      {userError.length > 0 ? (
        <div
          className="rounded border mt-1"
          style={{backgroundColor: 'rgba(255, 0, 0, 0.2)'}}
        >
          <p className="mb-0 text-nowrap overflow-scroll bg-light">
            <strong>Errors:</strong>
          </p>
          <div style={{overflowY: 'scroll'}}>
            {userError.map((error, idx) => {
              return (
                <>
                  <code key={idx} className="mb-0">
                    {error}
                  </code>
                  <br />
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const TestInfoButton = () => {
  return (
    <div
      onClick={() => {
        Swal.fire({
          title: '<strong>Unit Testing</strong>',
          icon: 'info',
          html: unitTestInfo,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Great!',
        });
      }}
      className={`btn radius`}
    >
      <FaQuestionCircle size={24} />
    </div>
  );
};

const unitTestInfo = `
<p>Unit tests are a way to check if your program meets the specifications. When these unit tests are run, your program is given a predetermined set of inputs to see if it behaves as expected.</p>

<p>If there are differences between the expected and actual outputs, those lines will be <mark style="background-color: ${failColor};">highlighted</mark>. It is important to note that unit tests can be very strict, so even small differences can be flagged as errors.</p>`;
