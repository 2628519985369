import {
  doc,
  DocumentData,
  DocumentSnapshot,
  FirestoreDataConverter,
  getFirestore,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';
import {
  ConsoleUnitTestDialogueType,
  isConsoleAutograderDialogueType,
} from 'assignments/types';
import {AssignmentId} from 'assignments/types';
import {CourseId} from 'types/common';
import {isNullOrUndefined} from 'utils/general';

/**
 * A single entry (either input or output) in the dialogue for a unit test.
 */
export interface ConsoleUnitTestDialogueEntry {
  /** The input to feed at the given step. Only used when type is "input". */
  input?: string;
  /** The output expected at the given step. */
  output: string;
  /** Whether the autograder is expected an output ("print") or input ("input"). */
  type: ConsoleUnitTestDialogueType;
}
export interface ConsoleUnitTest {
  /** The display name for the unit test. */
  name: string;
  /** A longer human readable description. */
  description: string;
  /** A unique (per-assignment) key to the unit test. Originally stored as
   * a number, but we now store it as a string.
   */
  key: string | number;
  /** The sequence of inputs and outputs to run for the autograder. */
  dialogue: ConsoleUnitTestDialogueEntry[];
}

/**
 * Gets the postcondition of the dialogue, i.e. the set of all expected outputs.
 */
export function getPostconditionFromDialogue(
  unitTest: ConsoleUnitTest,
): string[] {
  return unitTest?.dialogue?.map(val => val.output) ?? [];
}

/**
 * Gets the precondition of the dialogue, i.e. the set of all expected inputs.
 */
export function getPreconditionFromDialogue(
  unitTest: ConsoleUnitTest,
): string[] {
  return (
    unitTest?.dialogue
      ?.filter(data => data.type === 'input')
      .map(val => val.input) ?? []
  );
}

export function isConsoleUnitTest(
  testObject: any,
): testObject is ConsoleUnitTest {
  return (
    testObject &&
    typeof testObject === 'object' &&
    typeof testObject.name === 'string' &&
    typeof testObject.description === 'string' &&
    (typeof testObject.key === 'string' ||
      typeof testObject.key === 'number') &&
    Array.isArray(testObject.dialogue) &&
    testObject.dialogue.every(
      dialogue =>
        typeof dialogue === 'object' &&
        (isNullOrUndefined(dialogue.input) ||
          typeof dialogue.input === 'string') &&
        typeof dialogue.output === 'string' &&
        isConsoleAutograderDialogueType(dialogue.type),
    )
  );
}

const CONSOLE_UNIT_TEST_DOC_CONVERTER: FirestoreDataConverter<
  ConsoleUnitTest[]
> = {
  toFirestore(unitTests: ConsoleUnitTest[]): DocumentData {
    return {
      unitTestsConsole: unitTests.map(unitTest => {
        return {
          dialogue: unitTest.dialogue.map(dialogue => {
            return {
              ...(dialogue.input && {input: dialogue.input}),
              output: dialogue.output,
              type: dialogue.type,
            };
          }),
          name: unitTest.name,
          description: unitTest.description,
          key: unitTest.key,
        };
      }),
      lastUpdated: Timestamp.now(),
    };
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
    const data = snapshot.data(options);
    if (!data) {
      console.debug('No data found in snapshot');
      return [];
    }
    const unitTests = data.unitTestsConsole ?? data.unitTests;
    if (!unitTests || !Array.isArray(unitTests)) {
      console.debug('No branches found in snapshot');
      return [];
    }
    return unitTests
      .map(unitTest => {
        try {
          return {
            ...unitTest,
            // Legacy unit test keys are stored as numbers.
            key: String(unitTest.key),
          };
        } catch (e) {
          console.warn('Failed to convert unit test key to string', unitTest);
          return null;
        }
      })
      .filter(unitTest => {
        if (!isConsoleUnitTest(unitTest)) {
          console.warn('Invalid unit test found in snapshot', unitTest);
          return false;
        }
        return true;
      });
  },
};

export function getConsoleUnitTestReference(
  courseId: CourseId,
  assignmentId: AssignmentId,
) {
  return doc(
    getFirestore(),
    `/assns/${courseId}/assignments/${assignmentId}/docs/unitTests`,
  ).withConverter(CONSOLE_UNIT_TEST_DOC_CONVERTER);
}
