/**
 * @fileoverview Code here allows for the creation, modification, and deletion
 * of standard (non-random/non-creative) graphics programming assignment unit
 * tests. These operations are centered primarily around
 * interactions with the solution code, interacting with a separate solution map
 * (used for the user-side tests)
 * when necessary.
 */

import {deepCopy} from '@firebase/util';
import {setDoc} from 'firebase/firestore';
import {useEffect, useRef, useState} from 'react';
// For generating solution map
import {PyodideClient} from '../../../components/pyodide/PyodideProvider';
import {
  getGraphicsUnitTestRef,
  GraphicsUnitTest,
  isGraphicsUnitTest,
  pyodideGraphicsDataToShapeMap,
} from '../../models/unitTests/graphics';
import {areShapeMapsEqual, GraphicsShape} from 'types/graphics';
import {
  DEFAULT_GRAPHICS_UNIT_TEST_NAME,
  type LoadedGraphicsUnitTestData,
} from './types';
import {uuidv4} from 'lib0/random';
import {Alert, Button} from 'react-bootstrap';
import {FaTrash} from 'react-icons/fa';
import {useDebounce} from 'use-debounce';
import {WithEditorData} from '../UnitTestSchema';
import Swal from 'sweetalert2';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {ExpandableCodeEditor} from 'components/editor/ExpandableCodeEditor';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from '../constants';

// The id of the canvas used to draw the solution code, used to get the canvas
// data and to draw the solution code for verification.
const TEST_CANVAS_ID = 'test-canvas';

/**
 * A wrapper for graphics unit tests that allows for creation, modification,
 * and deletion.
 * @param __namedParameters {@inheritLink Graphics#LoadedGraphicsUnitTestData}
 */
export const GraphicsEditor = ({
  unitTestLoaderParameters: unitTestLoaderParams,
  loadedData,
  isEditable,
}: WithEditorData<LoadedGraphicsUnitTestData>) => {
  const {courseId, assignmentId} = unitTestLoaderParams;
  const {unitTests: serverUnitTests, starterCode} = loadedData ?? {
    unitTests: [],
    starterCode: {},
  };
  const initUnitTests: GraphicsUnitTest[] = !!serverUnitTests
    ? serverUnitTests.filter(isGraphicsUnitTest)
    : [];
  const [unitTests, setUnitTests] = useState<GraphicsUnitTest[]>(initUnitTests);
  const [previouslyUploadedUnitTests, setPreviouslyUploadedUnitTests] =
    useState<GraphicsUnitTest[]>(initUnitTests);
  const pyodideClientRef = useRef(new PyodideClient());

  // Set the handlers for the pyodide client (noops for all non-graphics fields)
  // and a warning to the user when images are drawn. This allows for the canvas
  // to be drawn in the unit test preview.
  useEffect(() => {
    pyodideClientRef.current.setHandlers(
      () => {},
      () => {},
      async () => '',
      () => {},
      () => {
        Swal.fire({
          title: 'Images are not supported in the unit test preview.',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        return '';
      },
    );
  }, []);

  function areUnitTestsEqual(
    a: GraphicsUnitTest[],
    b: GraphicsUnitTest[],
  ): boolean {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((unitTest, index) => {
      return (
        unitTest.code === b[index].code &&
        unitTest.key === b[index].key &&
        unitTest.name === b[index].name &&
        areShapeMapsEqual(unitTest.map, b[index].map)
      );
    });
  }
  const [debouncedUnitTests] = useDebounce(
    unitTests,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
    {
      equalityFn: areUnitTestsEqual,
    },
  );
  const [unitTestSolutionError, setUnitTestSolutionError] = useState<string>();
  const unitTestDocumentReference = getGraphicsUnitTestRef(
    courseId,
    assignmentId,
  );
  const [unitTestsDocument, unitTestsDocumentLoading, unitTestsDocumentError] =
    useDocumentDataOnce(unitTestDocumentReference);

  const deleteSolution = (removalIndex: number) => {
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      return [
        ...newUnitTests.slice(0, removalIndex),
        ...newUnitTests.slice(removalIndex + 1),
      ];
    });
  };

  async function editUnitTest<K extends keyof GraphicsUnitTest>(
    editIndex: number,
    key: K,
    value: GraphicsUnitTest[K],
  ) {
    if (editIndex < 0 || editIndex >= unitTests.length) {
      console.error(
        `Invalid editIndex ${editIndex}, unitTests.length is ${unitTests.length}`,
      );
      return;
    }
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests[editIndex][key] = value;
      return newUnitTests;
    });
  }

  // Adds the GraphicsAutograder unit test to the list of unit tests.
  // If the solution code is not provided, the default starter code is used.
  const addUnitTest = async () => {
    const mainCode = starterCode?.['main.py'] ?? '';
    const {shapes, errors} = await getGraphicsCanvasData(
      mainCode,
      pyodideClientRef.current,
    );
    if (errors.length > 0) {
      setUnitTestSolutionError(errors.join('\n'));
    } else {
      setUnitTestSolutionError('');
    }
    setUnitTests(
      deepCopy([
        ...unitTests,
        {
          name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
          code: mainCode,
          map: shapes,
          key: uuidv4(),
        },
      ]),
    );
  };

  // Load the unit tests from the database when the component is mounted.
  // Also run the solution code to generate the solution map (if possible).
  useEffect(() => {
    if (
      unitTestsDocumentLoading ||
      unitTestsDocumentError ||
      !unitTestsDocument
    ) {
      return;
    }
    setUnitTests(unitTestsDocument);
    setPreviouslyUploadedUnitTests(unitTestsDocument);
    if (unitTestsDocument.length > 0) {
      const mainCode = unitTestsDocument[0].code;
      getGraphicsCanvasData(mainCode, pyodideClientRef.current);
    }
  }, [unitTestsDocumentLoading]);
  const shouldSave =
    !unitTestsDocumentLoading &&
    !unitTestsDocumentError &&
    !areUnitTestsEqual(debouncedUnitTests, previouslyUploadedUnitTests);
  // Save the unit tests to the database when changes are made.
  useEffect(() => {
    if (!shouldSave) {
      return;
    }
    setDoc(unitTestDocumentReference, debouncedUnitTests)
      .then(() => {
        setPreviouslyUploadedUnitTests(debouncedUnitTests);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving unit tests',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving unit tests: ', error);
      });
  }, [shouldSave]);

  const updateCode = (testIndex: number, code: string) => {
    getGraphicsCanvasData(code, pyodideClientRef.current)
      .then(canvasData => {
        editUnitTest(testIndex, 'code', code);
        editUnitTest(testIndex, 'map', canvasData.shapes);
      })
      .catch(error => {
        console.error('Error running solution code: ', error);
        setUnitTestSolutionError(error.message);
        editUnitTest(testIndex, 'code', code);
        editUnitTest(testIndex, 'map', new Map());
      });
  };

  return (
    <div>
      <div className="d-flex flex-column mt-3">
        <p>
          Add the solution code to one example below and run it to generate the
          solution canvas map.
        </p>

        {unitTests.map(function (unitTest, testIndex) {
          if (!unitTest.key) {
            unitTest.key = uuidv4();
          }

          return (
            <EditableGraphicsUnitTest
              key={unitTest.key}
              code={unitTest.code}
              setCode={code => updateCode(testIndex, code)}
              deleteUnitTest={() => deleteSolution(testIndex)}
              isEditable={isEditable}
            />
          );
        })}
        {unitTestSolutionError && (
          <Alert variant="danger">
            <b>Error running solution code:</b>
            <br />
            {unitTestSolutionError}
          </Alert>
        )}
      </div>
      {unitTests.length === 0 && (
        <Button
          disabled={!isEditable}
          onClick={() => addUnitTest()}
          className="btn btn-secondary "
        >
          Add Solution Code
        </Button>
      )}
      <div>
        <b>Solution Preview:</b>
      </div>
      <canvas id={TEST_CANVAS_ID} role="image"></canvas>
    </div>
  );
};

interface EditableGraphicsUnitTestProps {
  /** The unit test data to edit. */
  code: string;
  /** Function to edit the unit test. */
  setCode: (code: string) => void;
  /** Function to delete the unit test. */
  deleteUnitTest: () => void;
  isEditable: boolean;
}
function EditableGraphicsUnitTest({
  code,
  setCode,
  deleteUnitTest,
  isEditable,
}: EditableGraphicsUnitTestProps) {
  return (
    <div className="border rounded p-2 mb-3 gap-2">
      <div className="d-flex flex-row ">
        <p className="form-control mb-2">{DEFAULT_GRAPHICS_UNIT_TEST_NAME}</p>
        <button
          onClick={deleteUnitTest}
          className="btn btn-light mb-2 ml-2"
          disabled={!isEditable}
        >
          <FaTrash />
        </button>
      </div>

      <b className="mt-2">Solution Code:</b>
      <ExpandableCodeEditor
        mode="python"
        value={code}
        onChange={setCode}
        readOnly={!isEditable}
      />
    </div>
  );
}

interface GraphicsCanvasData {
  shapes: Map<string, GraphicsShape>;
  errors: string[];
}
/**
 * @param solutionCode The solution code to run and get the canvasData from.
 * @returns A map from shape id to its data from the canvas.
 */
async function getGraphicsCanvasData(
  solutionCode: string,
  pyodideClient: PyodideClient,
): Promise<GraphicsCanvasData> {
  const pyodideData = await pyodideClient.runCode(
    solutionCode,
    {
      name: 'main.py',
      id: '',
    },
    false,
    // We do not need to specify uninterrupted here, as we are not running the
    // code in step mode.
    undefined,
    TEST_CANVAS_ID,
  );
  return {
    shapes: pyodideGraphicsDataToShapeMap(pyodideData.graphics),
    errors: pyodideData.error,
  };
}
