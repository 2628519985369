import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {PartialLoading} from 'components/loading/Loading';
import {getApp} from 'firebase/app';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
} from 'firebase/firestore';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {CourseContext} from 'contexts/CourseContext';
import {useContext, useEffect, useState} from 'react';
import {isMinimumRole} from 'contexts/profileUtil';
import NoAccess from 'components/errors/NoAccess';
import {AttendanceTable} from './AttendanceTable';

export const SLAttendance = () => {
  const {userData} = useContext(ProfileContext);
  const courseRole = userData?.courseRole;

  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return <NoAccess />;
  }

  return <GuardedSLAttendance />;
};

const GuardedSLAttendance = () => {
  const db = getFirestore(getApp());
  const {getSectionsSnapshot} = useContext(CourseContext);
  const [sectionCollection, setSectionCollection] = useState(null);
  const [attendanceData, setAttendanceData] = useState({});
  const [slNames, setSLNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // admin only!!

  useEffect(() => {
    async function getSections() {
      const querySnapshot = await getSectionsSnapshot();
      setSectionCollection(querySnapshot);
    }
    getSections();
  }, []);

  // fetch attendance data (which lives in a subcollection of each section) and retrieve SL display names (from user docs)
  useEffect(() => {
    async function fetchAttendanceForAllSections() {
      if (!sectionCollection) {
        return;
      }
      const attendancePromises = sectionCollection.docs.map(
        async sectionDoc => {
          const subCollectionRef = collection(sectionDoc.ref, 'attendance');
          const subCollectionSnapshot = await getDocs(subCollectionRef);
          return {
            sectionId: sectionDoc.id,
            attendance: subCollectionSnapshot.docs,
          };
        },
      );

      const allAttendanceDocs = await Promise.all(attendancePromises);
      const attendanceDict = allAttendanceDocs.reduce(
        (acc, {sectionId, attendance}) => {
          acc[sectionId] = attendance.map(doc => doc.data());
          return acc;
        },
        {},
      );

      setAttendanceData(attendanceDict);
    }

    async function getNames() {
      if (!sectionCollection) {
        return;
      }
      const userPromises = sectionCollection.docs.map(async sectionDoc => {
        const userDocPath = `users/${sectionDoc?.data()?.sectionLeader}`;
        // fetch user doc from db
        const userDoc = await getDoc(doc(db, userDocPath));
        return {
          sectionId: sectionDoc.id,
          displayName: userDoc?.data()?.displayName,
        };
      });

      const allUserDocs = await Promise.all(userPromises);
      const userDict = allUserDocs.reduce((acc, {sectionId, displayName}) => {
        acc[sectionId] = displayName;
        return acc;
      }, {});

      setSLNames(userDict);
    }

    fetchAttendanceForAllSections();
    getNames();
  }, [db, sectionCollection]);

  useEffect(() => {
    if (attendanceData && slNames) {
      setIsLoading(false);
    }
  }, [attendanceData, slNames]);

  if (!sectionCollection || isLoading) return <PartialLoading />;

  return (
    <CoursePageBodyContainer
      mainColumn={
        <AttendanceTable
          sectionCollection={sectionCollection}
          attendanceData={attendanceData}
          slNames={slNames}
        />
      }
      rightColumn={<></>}
      singleColumn={
        <AttendanceTable
          sectionCollection={sectionCollection}
          attendanceData={attendanceData}
          slNames={slNames}
        />
      }
    />
  );
};
