import {Alert} from 'react-bootstrap';
import {TagEditor} from './TagEditor';
import {Dispatch, SetStateAction} from 'react';

interface MiscellaneousFeaturesProps {
  onSubmitLink: string;
  setOnSubmitLink: Dispatch<SetStateAction<string>>;
  assignmentTags: string[];
  setAssignmentTags: Dispatch<SetStateAction<string[]>>;
  isEditable: boolean;
}
export function MiscellaneousFeatures({
  onSubmitLink,
  setOnSubmitLink,
  assignmentTags,
  setAssignmentTags,
  isEditable,
}: MiscellaneousFeaturesProps) {
  return (
    <div>
      <h4>Miscellaneous Features</h4>
      <Alert variant="warning">
        <b>Warning:</b> These features have limited support and should probably
        be used only if you know what you are doing with them.
      </Alert>
      <h5>On Submit</h5>
      <Alert variant="info">
        On Submit Link is for courses that want to direct students to some
        external link after submitting the assignment. If you are already using
        an integrated third party platform submission tool, you can ignore this
        field.
      </Alert>
      <b className="mt-2">On Submit Link:</b>
      <input
        type="text"
        className="form-control"
        placeholder="Enter link"
        value={onSubmitLink}
        onChange={e => {
          setOnSubmitLink(e.target.value);
        }}
        disabled={!isEditable}
      />
      <h5>Tags</h5>
      <TagEditor
        assignmentTags={assignmentTags}
        setAssignmentTags={setAssignmentTags}
        isEditable={isEditable}
      />
    </div>
  );
}
