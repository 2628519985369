/**
 * @fileoverview Types relating to the Stanford Graphics Library.
 *
 * Types extracted from the `canvasObjects` map in
 *  src/components/pyodide/GraphicsLib/graphics.js
 */

/**
 * Each constant represents a shape type, which is used in the `type` field of
 * the corresponding shape object to help identify the shape type at runtime.
 */
export const RECTANGLE_TYPE = 'rectangle' as const;
export const OVAL_TYPE = 'oval' as const;
export const LINE_TYPE = 'line' as const;
export const IMAGE_TYPE = 'image' as const;
export const TEXT_TYPE = 'text' as const;
export const POLYGON_TYPE = 'polygon' as const;

/**
 * Represents a rectangle Graphics Object.
 */
export interface Rectangle {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `RECTANGLE_TYPE` before use.
   */
  type: typeof RECTANGLE_TYPE;
  /** The x-coordinate of the left side of the rectangle. */
  leftX: number;
  /** The y-coordinate of the top side of the rectangle. */
  topY: number;
  /** The x-coordinate of the right side of the rectangle. */
  rightX: number;
  /** The y-coordinate of the bottom side of the rectangle. */
  bottomY: number;
  /** The fill color of the rectangle. */
  color: string;
  /** The outline color of the rectangle. */
  outline: string;
}

/**
 * Represents an oval Graphics Object.
 */
export interface Oval {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `OVAL_TYPE` before use.
   */
  type: typeof OVAL_TYPE;
  /** The x-coordinate of the left side of the oval. */
  leftX: number;
  /** The y-coordinate of the top side of the oval. */
  topY: number;
  /** The x-coordinate of the right side of the oval. */
  rightX: number;
  /** The y-coordinate of the bottom side of the oval. */
  bottomY: number;
  /** The fill color of the oval. */
  color: string;
  /** The outline color of the oval. */
  outline: string;
}

/**
 * Represents a line Graphics Object.
 */
export interface Line {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `LINE_TYPE` before use.
   */
  type: typeof LINE_TYPE;
  /** The starting coordinates of the line. Ordered as [x, y]. */
  start: [number, number];
  /** The ending coordinates of the line. Ordered as [x, y]. */
  end: [number, number];
  /** The color of the line. */
  color: string;
}

/**
 * Represents an image Graphics Object.
 */
export interface Image {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `IMAGE_TYPE` before use.
   */
  type: typeof IMAGE_TYPE;
  /** The x-coordinate of the top left corner of the image. */
  x: number;
  /** The y-coordinate of the top left corner of the image. */
  y: number;
  /** The URL of the image. */
  url: string;
  /** Optional. Constrains the width of the image. */
  width?: number;
  /** Optional. Constrains the height of the image. */
  height?: number;
}

/**
 * Represents a text Graphics Object.
 */
export interface Text {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `TEXT_TYPE` before use.
   */
  type: typeof TEXT_TYPE;
  /** The x-coordinate of the anchor point of the text. */
  x: number;
  /** The y-coordinate of the anchor point of the text. */
  y: number;
  /** The text to display. */
  text: string;
  /** The text anchor point. */
  anchor: string;
  /** The color of the text. */
  color: string;
  /** The font size of the text. */
  size: number;
  /** The font of the text. */
  font: string;
}

/**
 * Represents a polygon Graphics Object.
 */
export interface Polygon {
  /**
   * The shape type.
   * Ensures that at compile time, the type of the shape is known. Typescript
   * cannot infer the type of the shape at runtime, so this type MUST be
   * compared against the string constant `POLYGON_TYPE` before use.
   */
  type: typeof POLYGON_TYPE;
  /**
   * A list of numbers describing the vertices of the polygon. The polygon
   * is drawn by connecting the points in order. There must therefore be an
   * even number of elements in the list, with each pair of elements forming
   * an (x, y) coordinate.
   */
  points: number[];
  /** The fill color of the polygon. */
  color: string;
  /** The outline color of the polygon. */
  outline: string;
}

export type GraphicsShape = Rectangle | Oval | Line | Image | Text | Polygon;

export const isRectangle = (object: any): object is Rectangle => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== RECTANGLE_TYPE) {
    return false;
  } else if (
    typeof object.leftX !== 'number' ||
    typeof object.rightX !== 'number' ||
    typeof object.topY !== 'number' ||
    typeof object.bottomY !== 'number' ||
    typeof object.color !== 'string' ||
    typeof object.outline !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isOval = (object: any): object is Oval => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== OVAL_TYPE) {
    return false;
  } else if (
    typeof object.leftX !== 'number' ||
    typeof object.rightX !== 'number' ||
    typeof object.topY !== 'number' ||
    typeof object.bottomY !== 'number' ||
    typeof object.color !== 'string' ||
    typeof object.outline !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isLine = (object: any): object is Line => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== LINE_TYPE) {
    return false;
  } else if (
    !Array.isArray(object.start) ||
    object.start.length !== 2 ||
    !object.start.every(coordinate => typeof coordinate === 'number') ||
    !Array.isArray(object.end) ||
    object.end.length !== 2 ||
    !object.end.every(coordinate => typeof coordinate === 'number') ||
    typeof object.color !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isImage = (object: any): object is Image => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== IMAGE_TYPE) {
    return false;
  } else if (
    typeof object.x !== 'number' ||
    typeof object.y !== 'number' ||
    typeof object.url !== 'string' ||
    (object.width && typeof object.width !== 'number') ||
    (object.height && typeof object.height !== 'number')
  ) {
    return false;
  }
  return true;
};

export const isText = (object: any): object is Text => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== TEXT_TYPE) {
    return false;
  } else if (
    typeof object.x !== 'number' ||
    typeof object.y !== 'number' ||
    typeof object.text !== 'string' ||
    typeof object.anchor !== 'string' ||
    typeof object.color !== 'string' ||
    typeof object.size !== 'number' ||
    typeof object.font !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isPolygon = (object: any): object is Polygon => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (object.type !== POLYGON_TYPE) {
    return false;
  } else if (
    !Array.isArray(object.points) ||
    object.points.length % 2 !== 0 ||
    !object.points.every(coordinate => typeof coordinate === 'number') ||
    typeof object.color !== 'string' ||
    typeof object.outline !== 'string'
  ) {
    return false;
  }
  return true;
};

export const isGraphicsShape = (object: any): object is GraphicsShape => {
  if (!object || typeof object !== 'object') {
    return false;
  } else if (typeof object.type !== 'string') {
    return false;
  }

  switch (object.type) {
    case RECTANGLE_TYPE:
      return isRectangle(object);
    case OVAL_TYPE:
      return isOval(object);
    case LINE_TYPE:
      return isLine(object);
    case IMAGE_TYPE:
      return isImage(object);
    case TEXT_TYPE:
      return isText(object);
    case POLYGON_TYPE:
      return isPolygon(object);
    default:
      console.debug('Unknown graphics shape type:', object.type);
      return false;
  }
};

export const areShapesEqual = (
  shape: GraphicsShape,
  otherShape: GraphicsShape,
): boolean => {
  if (shape.type !== otherShape.type) {
    return false;
  }
  switch (shape.type) {
    case RECTANGLE_TYPE:
      return areRectanglesEqual(shape, otherShape as Rectangle);
    case OVAL_TYPE:
      return areOvalsEqual(shape, otherShape as Oval);
    case LINE_TYPE:
      return areLinesEqual(shape, otherShape as Line);
    case IMAGE_TYPE:
      return areImagesEqual(shape, otherShape as Image);
    case TEXT_TYPE:
      return areTextsEqual(shape, otherShape as Text);
    case POLYGON_TYPE:
      return arePolygonsEqual(shape, otherShape as Polygon);
  }
};

function areRectanglesEqual(
  rectangle: Rectangle,
  otherRectangle: Rectangle,
): boolean {
  return (
    rectangle.leftX === otherRectangle.leftX &&
    rectangle.topY === otherRectangle.topY &&
    rectangle.rightX === otherRectangle.rightX &&
    rectangle.bottomY === otherRectangle.bottomY &&
    rectangle.color === otherRectangle.color &&
    rectangle.outline === otherRectangle.outline
  );
}

function areOvalsEqual(oval: Oval, otherOval: Oval): boolean {
  return (
    oval.leftX === otherOval.leftX &&
    oval.topY === otherOval.topY &&
    oval.rightX === otherOval.rightX &&
    oval.bottomY === otherOval.bottomY &&
    oval.color === otherOval.color &&
    oval.outline === otherOval.outline
  );
}

function areLinesEqual(line: Line, otherLine: Line): boolean {
  return (
    line.start[0] === otherLine.start[0] &&
    line.start[1] === otherLine.start[1] &&
    line.end[0] === otherLine.end[0] &&
    line.end[1] === otherLine.end[1] &&
    line.color === otherLine.color
  );
}

function areImagesEqual(image: Image, otherImage: Image): boolean {
  return (
    image.x === otherImage.x &&
    image.y === otherImage.y &&
    image.url === otherImage.url &&
    (image.width || 0) === (otherImage.width || 0) &&
    (image.height || 0) === (otherImage.height || 0)
  );
}

function areTextsEqual(text: Text, otherText: Text): boolean {
  return (
    text.x === otherText.x &&
    text.y === otherText.y &&
    text.text === otherText.text &&
    text.anchor === otherText.anchor &&
    text.color === otherText.color &&
    text.size === otherText.size &&
    text.font === otherText.font
  );
}

function arePolygonsEqual(polygon: Polygon, otherPolygon: Polygon): boolean {
  return (
    polygon.points.length === otherPolygon.points.length &&
    polygon.points.every(
      (point, index) => point === otherPolygon.points[index],
    ) &&
    polygon.color === otherPolygon.color &&
    polygon.outline === otherPolygon.outline
  );
}
// A type alias for the key of a GraphicsShape in the canvasObjects map.
export type ShapeId = string;

export function areShapeMapsEqual(
  map: Map<ShapeId, GraphicsShape>,
  otherMap: Map<ShapeId, GraphicsShape>,
): boolean {
  if (!map || !otherMap) {
    return map === otherMap;
  }
  if (map.size !== otherMap.size) {
    return false;
  }
  const unassignedShapeIds = [...map.keys()];
  for (const [, shape] of otherMap) {
    const matchingShapeIndex = unassignedShapeIds.findIndex(shapeId =>
      areShapesEqual(shape, map.get(shapeId)),
    );
    if (matchingShapeIndex === -1) {
      return false;
    }
    unassignedShapeIds.splice(matchingShapeIndex, 1);
  }
  return true;
}
