import {AssignmentId, AssignmentUnitTestType} from 'assignments/types';
import {
  EMPTY_TEST_RESULT,
  UnitTestResults,
} from 'assignments/unitTest/unitTestResults';
import {UNIT_TEST_TYPE_TO_SCHEMA} from 'assignments/unitTest/UnitTestSchemaRegistry';
import {UnitTestLoaderParameters} from 'assignments/unitTest/UnitTestSchema';
import {
  checkIsProjectConsole,
  checkIsProjectGraphics,
  checkIsProjectKarel,
} from 'ide/utils/general';
import {CourseId} from 'types/common';
import {IDEContextData} from 'ide/contexts/IDEContext';
import {isProjectFileStructure, ProjectFileStructure} from 'ide/types';

/**
 * Runs unit tests for an assignment.
 *
 * This is the core function for running unit tests. It is used by the
 * `RunUnitTestsAndReportResults` function for user facing unit test runs.
 * See `UnitTestSchema` for more information on how unit tests are run.
 *
 * @param ideContext - The IDE context.
 * @param silent - Whether to run the unit tests silently.
 * @returns The results of the unit tests.
 */
export async function runUnitTests(
  ideContext: IDEContextData,
  silent: boolean,
): Promise<UnitTestResults> {
  const {
    assnData: serverAssignmentData,
    courseId,
    isDiagnostic,
    projectData,
    filesCode,
    fileStructure,
  } = ideContext;
  const assignmentId: AssignmentId = serverAssignmentData?.metaData?.uid;

  if (!isProjectFileStructure(projectData?.files)) {
    console.error(
      "runUnitTests: fileStructure is not a ProjectFileStructure. We'll try to ",
      'run the unit tests anyway, but this is likely to fail.',
      {
        fileStructure: projectData?.files,
      },
    );
  }

  if (!serverAssignmentData || isDiagnostic) {
    return EMPTY_TEST_RESULT;
  }
  const unitTestType: AssignmentUnitTestType =
    serverAssignmentData.metaData.unitTestType ??
    inferUnitTestType(serverAssignmentData, projectData);
  if (!unitTestType) {
    return EMPTY_TEST_RESULT;
  }
  const loaderParameters: UnitTestLoaderParameters = {
    serverAssignmentData,
    courseId,
    assignmentId,
  };
  const schema = UNIT_TEST_TYPE_TO_SCHEMA[unitTestType];
  // `any` is necessary here because the loaded data is schema-specific.
  const loadedData = ideContext.loadedUnitTestData;
  if (!loadedData) {
    console.warn(
      `No unit test data loaded for ${assignmentId} of type ${unitTestType}`,
    );
  }
  const pyodideClient = ideContext.pyodideClientRef?.current;
  return await schema.runner({
    loadedData: loadedData ?? {},
    unitTestLoaderParameters: loaderParameters,
    filesCode,
    fileStructure,
    pyodideClient,
    silent,
  });
}

/**
 * Gets the unit test type for an assignment. For older assignments that do not
 * have a unit test type, it will attempt infer the unit test type based on the
 * provided data.
 */
export function inferUnitTestType(
  assignmentData,
  projectData = null,
): AssignmentUnitTestType {
  if (!assignmentData) {
    return 'none';
  }
  if (assignmentData?.metaData?.unitTestType) {
    return assignmentData.metaData.unitTestType;
  }
  const isRandom = assignmentData?.metaData?.isRandom ?? false;
  if (isRandom) {
    return 'ai';
  }
  if (checkIsProjectKarel(projectData, assignmentData)) {
    return 'karel';
  } else if (checkIsProjectConsole(projectData, assignmentData)) {
    return 'console';
  } else if (checkIsProjectGraphics(projectData, assignmentData)) {
    return 'graphics';
  }
  console.error('No unit test type found for assignment. Defaulting to none.', {
    assignmentData,
    projectData,
  });
  return 'none';
}

export function inferUnitTestTypeFromMetadata(metadata) {
  return inferUnitTestType({metaData: metadata});
}
