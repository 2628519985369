import {SetStateAction, Dispatch, useEffect, useState} from 'react';
import {Alert, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaTimes, FaUpload} from 'react-icons/fa';
import {useFilePicker} from 'use-file-picker';

interface UploadFileProps {
  starterCode: Record<string, string>;
  setStarterCode: Dispatch<SetStateAction<Record<string, string>>>;
  isEditable: boolean;
}
export const UploadFile = ({
  starterCode,
  setStarterCode,
  isEditable,
}: UploadFileProps) => {
  const [fileName, setFileName] = useState('');
  const [fileContents, setFileContents] = useState('');
  const [recentlyUploadedFile, setRecentlyUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [openFileSelector, {plainFiles, loading: fileUploading}] =
    useFilePicker({
      accept: ['.txt', '.json', '.py', '.csv', '.w'],
      multiple: false,
      readFilesContent: false,
    });

  const handleFileUpload = event => {
    event.stopPropagation();

    if (!isUploading) {
      openFileSelector();
    }
  };

  useEffect(() => {
    if (recentlyUploadedFile) {
      if (
        recentlyUploadedFile.type === 'text/plain' ||
        recentlyUploadedFile.type === 'text/x-python-script' ||
        recentlyUploadedFile.type === 'application/json' ||
        recentlyUploadedFile.name.substring(
          recentlyUploadedFile.name.length - 2,
        ) === '.w' ||
        recentlyUploadedFile.type === 'text/csv'
      ) {
        if (
          recentlyUploadedFile.content !== fileContents ||
          recentlyUploadedFile.name !== fileName
        ) {
          setFileContents(recentlyUploadedFile.content);
          setFileName(recentlyUploadedFile.name);
        }
        setIsUploading(false);
      }
    }
  }, [recentlyUploadedFile]);

  useEffect(() => {
    if (plainFiles.length > 0 && !isUploading) {
      const firstFile = plainFiles[0];
      setIsUploading(true);
      if (
        firstFile.type === 'text/plain' ||
        firstFile.type === 'text/x-python-script' ||
        firstFile.type === 'application/json' ||
        firstFile.name.substring(firstFile.name.length - 2) === '.w' ||
        firstFile.type === 'text/csv'
      ) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const content = reader.result;
          setRecentlyUploadedFile({
            name: firstFile.name,
            content: content,
            type: firstFile.type,
          });
        };
        reader.readAsText(firstFile);
      }
    }
  }, [plainFiles]);

  useEffect(() => {
    if (isUploading || fileName === '') {
      return;
    }
    setStarterCode(prevCode => {
      const newPrevCode = {...prevCode};
      newPrevCode[fileName] = fileContents;
      return newPrevCode;
    });
  }, [fileName, fileContents, isUploading]);

  const removeFile = filename => {
    setStarterCode(prevCode => {
      const newPrevCode = {...prevCode};
      if (filename in newPrevCode) {
        delete newPrevCode[filename];
      }
      return newPrevCode;
    });
  };

  return (
    <div>
      <h4>Upload File</h4>
      <Alert variant="info">
        Note: After uploading file and revisiting project, you have to hit the
        "restart" button.
      </Alert>
      Currently accepts .txt, .json, and .py files.
      <h5>Current Files</h5>
      {!starterCode ? (
        <p>No files uploaded</p>
      ) : (
        <>
          {Object.keys(starterCode).map(name => {
            return (
              <div
                className="d-flex justify-content-left align-items-baseline"
                id={name}
                key={name}
              >
                <p>{name}</p>
                {isEditable && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        {name === 'main.py'
                          ? 'main.py cannot be deleted.'
                          : 'Remove file'}
                      </Tooltip>
                    }
                  >
                    <span>
                      <Button
                        variant="danger"
                        className="ml-2"
                        onClick={() => removeFile(name)}
                        disabled={name === 'main.py'}
                      >
                        <FaTimes />
                      </Button>
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            );
          })}
        </>
      )}
      <Button
        variant="secondary"
        onClick={handleFileUpload}
        disabled={!isEditable || fileUploading}
      >
        <FaUpload />
      </Button>
    </div>
  );
};
