import {getLocalUTCTimezone} from 'components/timezones/timezoneHelper';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole} from 'contexts/profileUtil';
import {TimeContext} from 'contexts/TimeContext';
import {CourseContext} from 'contexts/CourseContext';
import {functions} from 'firebaseApp';
import {useContext, useEffect, useState} from 'react';
import {FaVideo} from 'react-icons/fa';
import Swal from 'sweetalert2';
import {useCourseId} from 'hooks/router/useUrlParams';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useSectionPopup} from '../SectionPopup';

// open 15 mins before the start time, close 1 hour after
const OPEN_DELTA_MINS = 15;
const CLOSE_DELTA_MINS = -60;

export const JoinSectionZoomButton = ({
  sectionId,
  nextSectionDate,
  timeIndex,
}) => {
  const courseId = useCourseId();
  const {getServerTimeMs} = useContext(TimeContext);
  const {firstSectionTimestamp, getNextSectionIndex} =
    useContext(CourseContext);

  const serverTime = getServerTimeMs();
  const [isEnabled, setIsEnabled] = useState(
    checkIsOpen(nextSectionDate, serverTime),
  );

  const userTz = getLocalUTCTimezone();

  const [sectionWeekNumber, setSectionNumber] = useState(-1);
  useEffect(() => {
    if (!firstSectionTimestamp) return;
    setSectionNumber(getNextSectionIndex(userTz, timeIndex) + 1);
  }, [firstSectionTimestamp]);

  useSectionPopup(isEnabled);

  useEffect(() => {
    // recheck
    const timer = setInterval(() => {
      const serverTime = getServerTimeMs();
      setIsEnabled(checkIsOpen(nextSectionDate, serverTime));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [nextSectionDate]);

  const {userData} = useContext(ProfileContext);

  const courseRole = userData.courseRole;
  const isSectionLeader = isMinimumRole(courseRole, Role.MENTOR);

  if (sectionWeekNumber >= 7) {
    return <></>;
  }

  // TODO: JoinAlternativeSectionButton has this same function.
  // merge them (but not during a live class)
  const joinZoom = async () => {
    try {
      const createZoomMeeting = functions.httpsCallable('createZoomMeeting');

      Swal.fire({
        title: 'Joining Zoom meeting...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await createZoomMeeting({
        sectionId,
        courseId,
      });
      if ('errorMessage' in response.data) {
        Swal.fire({
          title: 'Error',
          text: `Unable to join the zoom meeting: ${response.data.errorMessage}`,
          icon: 'error',
        });
        return;
      }

      const {joinUrl, startUrl} = response.data;

      // Close the Swal after receiving the response
      Swal.close();

      const zoomUrl = isSectionLeader ? startUrl : joinUrl;

      console.log(startUrl, joinUrl, zoomUrl);

      if (zoomUrl) {
        window.location.href = zoomUrl;
        // window.open(zoomUrl, '_blank');
      } else {
        // Handle the case when the join URL is not provided
        Swal.fire({
          title: 'Error',
          text: 'Unable to join the zoom meeting, missing URL',
          icon: 'error',
        });
      }
    } catch (error) {
      // Close the Swal and handle the error when calling the Firebase function
      // Swal.close();
      console.error('Error calling createZoomMeeting:', error);
      Swal.fire({
        title: 'Error',
        text: `Unable to join the zoom meeting: ${error}`,
        icon: 'error',
      });
    }
  };

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{show: 0, hide: 0}}
      overlay={props => (
        <Tooltip id="resolve-tooltip" {...props}>
          Great tooltip
        </Tooltip>
      )}
    >
      <button
        disabled={!isEnabled}
        onClick={() => joinZoom()}
        className="btn btn-primary  position-relative mt-2"
      >
        {' '}
        <FaVideo style={{marginTop: -5}} /> Join Section Zoom
      </button>
    </OverlayTrigger>
  );
};

function checkIsOpen(nextSectionDate, serverNow) {
  if (!nextSectionDate) {
    return false;
  }
  const timeUntilMs = nextSectionDate.getTime() - serverNow;
  const timeUntilMins = timeUntilMs / 60000;
  return timeUntilMins < OPEN_DELTA_MINS && timeUntilMins > CLOSE_DELTA_MINS;
}

export const CustomizedZoomButton = ({zoomLink}) => {
  // this is a special case for foothill
  // don't worry about it for other courses :D

  const joinZoom = () => {
    window.open(zoomLink, '_blank');
  };

  return (
    <button
      onClick={() => joinZoom()}
      className="btn btn-primary  position-relative mt-2"
    >
      {' '}
      <FaVideo style={{marginTop: -5}} /> Join Section Zoom
    </button>
  );
};
