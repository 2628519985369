import {collection, doc, getDoc, getFirestore} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import 'firebase/compat/auth';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firebaseStringToRole, roleToDisplayString} from 'contexts/profileUtil';
import {Role} from 'types/role';
import {useCourseId} from 'hooks/router/useUrlParams';
import {functions} from 'firebaseApp';
import {useUserId} from 'hooks/user/useUserId';

const defaultData = {
  suggestions: [],
  otherUsersInfo: {},
  connectionsList: [],
  requestsList: [],
  isLoading: true,
};

export const ConnectionsContext = React.createContext(defaultData);

export const ConnectionsProvider = ({children}) => {
  const courseId = useCourseId();
  const userId = useUserId();
  const [suggestions, setSuggestions] = useState(null);
  const [otherUsersInfo, setOtherUsers] = useState({});

  const connectionsPath = `users/${userId}/docs/protected/connections`;
  const requestsPath = `users/${userId}/docs/protected/receivedRequests`;

  const [connections, isLoadingConnections, isErrorConnections] = useCollection(
    collection(getFirestore(), connectionsPath),
  );
  const [requests, isLoadingRequests, isErrorRequests] = useCollection(
    collection(getFirestore(), requestsPath),
  );

  // on load, fetch suggestions
  useEffect(() => {
    fetchConnectionSuggestions();
  }, []);

  // whenever a connection, or a request changes, make sure that we have all of the necessary user data
  useEffect(() => {
    const allUsers = getAllUsers(connections, requests, suggestions);
    for (const userId in allUsers) {
      const collectionData = allUsers[userId];
      const otherUsersData = otherUsersInfo[userId];
      fetchOtherUser(
        userId,
        courseId,
        collectionData,
        otherUsersData,
        setOtherUsers,
      );
    }
  }, [connections, requests, suggestions]);

  // function to get connection suggestions from the server
  async function fetchConnectionSuggestions() {
    const getConnectionSuggestions = functions.httpsCallable(
      'getConnectionSuggestions',
    );
    try {
      const response = await getConnectionSuggestions({courseId});
      if ('errorMessage' in response.data) {
        // fail silently
        console.error(response.data.errorMessage);
        return;
      }
      setSuggestions(response.data.suggestions);
    } catch (e) {
      console.error('Error fetching connection suggestions', e);
    }
  }

  const isLoading = isLoadingConnections || isLoadingRequests || !suggestions;

  const connectionsList = connections?.docs.map(doc => doc.id);
  const requestsList = requests?.docs.map(doc => doc.id);

  return (
    <ConnectionsContext.Provider
      value={{
        connectionsList,
        requestsList,
        suggestions,
        otherUsersInfo,
        isLoading,
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
};

async function fetchOtherUser(
  otherId,
  courseId,
  collectionData,
  otherUsersData,
  setOtherUsers,
) {
  const hasData = otherUsersData && otherUsersData?.role;
  if (hasData) {
    return;
  }
  const roleDoc = await getDoc(
    doc(getFirestore(), `users/${otherId}/roles/${courseId}`),
  );
  const userDoc = await getDoc(doc(getFirestore(), `users/${otherId}`));

  let courseRoleEnum = roleDoc.exists()
    ? firebaseStringToRole(roleDoc.data().role)
    : Role.UNREGISTERED;

  if (courseId === 'public' && courseRoleEnum === Role.UNREGISTERED) {
    courseRoleEnum = Role.STUDENT;
  }

  let courseRole: string = roleToDisplayString(courseRoleEnum);
  if (courseRole === 'Section Leader') {
    courseRole = 'SL';
  }

  const message = collectionData[`${otherId}_msg`];
  const contact = collectionData[`${otherId}_contact`];
  setOtherUsers(prev => {
    return {
      ...prev,
      [otherId]: {
        message,
        contact,
        ...userDoc.data(),
        courseRole,
      },
    };
  });
}

function getAllUsers(connections, requests, suggestions) {
  const allUsers = {};
  if (connections) {
    connections.forEach(doc => {
      allUsers[doc.id] = doc.data();
    });
  }
  if (requests) {
    requests.forEach(doc => {
      allUsers[doc.id] = doc.data();
    });
  }
  if (suggestions) {
    // this is a list of user ids
    suggestions.forEach(userId => {
      allUsers[userId] = {};
    });
  }
  return allUsers;
}

export function isOtherUserComplete(userData) {
  if (!userData) {
    return false;
  }
  if (!userData.displayName) {
    return false;
  }
  return true;
}
