import { useState, useEffect } from "react";
import "firebase/compat/firestore";
import { getFirestore, doc, getDoc, setDoc, getDocs } from "firebase/firestore";

export const useFirebaseDocOnce = (
  documentPath,
  defaultValue,
  createIfMissing = false
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [docData, setDocData] = useState(defaultValue);
  const [refreshCount, setRefreshCount] = useState(0);

  // load the project metaData
  useEffect(() => {
    async function getProjectData() {
      const db = getFirestore();
      const docRef = doc(db, documentPath);
      const response = await getDoc(docRef);
      if (response.exists()) {
        const data = response.data();
        setDocData(data);
      } else {
        if (createIfMissing) {
          await setDoc(docRef, defaultValue);
        }
        setDocData(defaultValue);
      }
    }
    getProjectData();
  }, [refreshCount]);

  // only set is loading to false when projectData is set
  useEffect(() => {
    if (docData != null) {
      setIsLoading(false);
    }
  }, [docData]);

  const refresh = () => {
    setIsLoading(true);
    setRefreshCount(refreshCount + 1);
  };

  return [isLoading, docData, refresh];
};

export const useFirestoreCollectionOnce = (firebaseQuery) => {
  const [isLoading, setIsLoading] = useState(true);
  const [collectionData, setCollectionData] = useState(null);
  const [error, setError] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);

  // load the project metaData
  useEffect(() => {
    async function getProjectData() {
      try {
        const collectionRef = firebaseQuery;
        const response = await getDocs(collectionRef);
        const data = response.docs.map((doc) => doc.data());
        setCollectionData(data);
      } catch (error) {
        setError(error);
      }
    }
    getProjectData();
  }, [refreshCount]);

  // only set is loading to false when projectData is set
  useEffect(() => {
    if (collectionData != null || error != null) {
      setIsLoading(false);
    }
  }, [collectionData, error]);

  const refresh = () => {
    setIsLoading(true);
    setRefreshCount(refreshCount + 1);
  };

  return [collectionData, isLoading, error, refresh];
};
