import NoAccess from 'components/errors/NoAccess';
import {ProfileContext, ProfileProvider} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole, isSectionLeader} from 'contexts/profileUtil';
import {getApp} from 'firebase/app';
import {doc, getFirestore} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Outlet} from 'react-router';
import {
  SLOnboardingContext,
  SLOnboardingProvider,
} from './contexts/SLOnboardingContext';
import {SLJoinNav} from './SLJoinNav';

export const SLOnboardingPage = () => {
  return (
    <ProfileProvider>
      <Onboarding />
    </ProfileProvider>
  );
};

const Onboarding = () => {
  const {loading, userData} = useContext(ProfileContext);
  if (loading) return <></>;
  return (
    <SLOnboardingProvider>
      <Inner />
    </SLOnboardingProvider>
  );
};

const Inner = () => {
  const userId = useUserId();
  const courseId = useCourseId();
  const {loading, userData} = useContext(ProfileContext);
  const {onboardingLoading} = useContext(SLOnboardingContext);

  if (loading || onboardingLoading) {
    return <></>;
  }

  // it only makes sense to show the onboarding if you are a section leader!
  if (isMinimumRole(userData.courseRole, Role.SECTION_LEADER)) {
    return (
      <>
        <SLJoinNav />
        <Outlet />
      </>
    );
  }

  return <NoAccess />;
};
