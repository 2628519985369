import {UnitTestResults} from 'assignments/unitTest/unitTestResults';
import {
  doc,
  FieldValue,
  FirestoreDataConverter,
  getFirestore,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';

/**
 * Interface representing a log entry for code execution in the IDE.
 * Used to track student code runs and test results.
 */
export interface IdeCodeLog {
  /** Project metadata and context for the code run. */
  projectData: any;
  /** The actual code that was executed. */
  code: string;
  /** Server timestamp when the log was created. */
  serverTimestamp: Timestamp | FieldValue;
  /** Optional unit test results if tests were run. */
  unitTestResults?: UnitTestResults;
}

/**
 * Firestore converter for IdeCodeLog objects.
 * Handles serialization/deserialization of logs to/from Firestore.
 */
const IDE_CODE_LOG_CONVERTER: FirestoreDataConverter<IdeCodeLog> = {
  toFirestore: (log: IdeCodeLog) => log,
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) =>
    snapshot.data(options) as IdeCodeLog,
};

/**
 * Adds a new code execution log to Firestore.
 * Logs are stored under ide_logs_v2/{userId}/logs/{timestamp}.
 *
 * @param userId - ID of user who ran the code
 * @param log - The log entry to store
 */
export const addLog = async (userId: string, log: IdeCodeLog) => {
  try {
    const db = getFirestore();
    const logUserId = userId || 'unknown';
    const logRef = doc(
      db,
      `ide_logs_v2/${logUserId}/logs/${new Date().toISOString()}`,
    ).withConverter(IDE_CODE_LOG_CONVERTER);
    await setDoc(logRef, log);
    console.log('Log added to Firestore');
  } catch (error) {
    console.error('Error adding log to Firestore: ', error);
  }
};
