import {useJoinedTNStudents} from 'course/teacherPuzzles/TNFirebase';
import {getNumTeachNowSessionsAndGratitude} from '../../firebase/realtime/TeachNow';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CoursePageBodyContainer} from '../../components/layout/CoursePageBodyContainer';
import {ProfileContext} from 'contexts/ProfileContext';
import {isAdmin} from 'contexts/profileUtil';
import NoAccess from 'components/errors/NoAccess';
import {Loading} from 'components/loading/Loading';
import {useUserId} from 'hooks/user/useUserId';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledHead = styled.thead`
  background-color: #f2f2f2;
`;

const StyledRow = styled.tr`
  background-color: ${props => (props.joined ? '#90ee90' : 'none')};
`;

const StyledCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
`;

const ViewJoinedStudentsInner = () => {
  const courseId = useCourseId(); // Get the courseId from the URL
  const [usersWithSessions, setUsersWithSessions] = useState(null);
  const users = useJoinedTNStudents(courseId); // Use the custom hook to get data
  const {userData} = useContext(ProfileContext);

  const currUserId = useUserId();

  useEffect(() => {
    const fetchSessionData = async () => {
      const promises = users.map(async user => {
        const sessionData = await getNumTeachNowSessionsAndGratitude(
          user.userId,
          courseId,
        );
        return {
          ...user,
          numSessions: sessionData.sessionInfo.length,
        };
      });
      const results = await Promise.all(promises);
      // Sort users so that those with studentTeachNow flag set to true come first
      const sortedResults = results
        .filter(user => !user.isMinor) // Filter out minors
        .filter(user => user.userId !== currUserId)
        .sort((a, b) => {
          const aValue = a.studentTeachNow ? 1 : 0;
          const bValue = b.studentTeachNow ? 1 : 0;
          return bValue - aValue;
        })
        .sort((a, b) => {
          const aNumSessions = a.numSessions;
          const bNumSessions = b.numSessions;
          return bNumSessions - aNumSessions;
        })
        .sort((a, b) => {
          const aValue = a.joined ? 1 : 0;
          const bValue = b.joined ? 1 : 0;
          return bValue - aValue;
        });
      setUsersWithSessions(sortedResults);
    };

    if (users.length > 0) {
      fetchSessionData();
    }
  }, [users, courseId]);

  const role = userData?.courseRole;

  if (!isAdmin(role)) {
    return <NoAccess />;
  }

  if (usersWithSessions === null) {
    return <Loading />;
  }

  return (
    <StyledTable>
      <StyledHead>
        <tr>
          <StyledCell>Index</StyledCell>
          <StyledCell>Display Name</StyledCell>
          <StyledCell>User ID</StyledCell>
          <StyledCell>Has Received Notification</StyledCell>
          <StyledCell>Has Joined</StyledCell>
          <StyledCell>Number of Sessions</StyledCell>
        </tr>
      </StyledHead>
      <tbody>
        {usersWithSessions.map((user, index) => (
          <StyledRow key={user.userId} joined={user.numSessions > 0}>
            <StyledCell>{index}</StyledCell>
            <StyledCell>{user.displayName}</StyledCell>
            <StyledCell>{user.userId}</StyledCell>
            <StyledCell>{user.studentTeachNow ? '✔️' : '❌'}</StyledCell>
            <StyledCell>{user.joined ? '✔️' : '❌'}</StyledCell>
            <StyledCell>{user.numSessions}</StyledCell>
          </StyledRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

export const ViewJoinedStudents = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<ViewJoinedStudentsInner />}
      rightColumn={<></>}
      singleColumn={<ViewJoinedStudentsInner />}
    />
  );
};
