import {Result, Skeleton} from 'antd';
import {TipTap} from 'components/richTextEditor/TipTap/TipTap';
import {EmailButtonBar} from 'components/richTextEditor/TipTap/buttonbars/EmailButtonBar';
import Gate from 'contexts/Gate';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {ForumContext} from 'course/forum/ForumContext';
import {useForumDataFetcher} from 'course/forum/firebase/ForumDataFetcher';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useContext, useEffect, useReducer, useState} from 'react';
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import {FaHome, FaPaperPlane} from 'react-icons/fa';
import {QueryClient, QueryClientProvider, useMutation} from 'react-query';
import {useParams, useNavigate} from 'react-router';
import {Link} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

const queryClient = new QueryClient();

type SendOptions = {
  shareToForum: boolean;
  pin: boolean;
  resolved: boolean;
};

type SendOptionsAction = 'TOGGLE_SHARE' | 'TOGGLE_PIN' | 'TOGGLE_RESOLVED';

export const SectionEmail = (props: {}) => {
  const {sectionId} = useParams();
  return (
    <ForumContext.Provider
      value={{
        forumId: sectionId,
        forumType: 'Section',
        privatePostThreshold: Role.SECTION_LEADER,
        setPinnedThreshold: Role.SECTION_LEADER,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <EmailComposer />
      </QueryClientProvider>
    </ForumContext.Provider>
  );
};

function sendOptionsReducer(
  state: SendOptions,
  action: SendOptionsAction,
): SendOptions {
  switch (action) {
    case 'TOGGLE_SHARE':
      if (state.shareToForum) {
        return {shareToForum: false, pin: false, resolved: false};
      } else {
        return {shareToForum: true, pin: true, resolved: true};
      }
    case 'TOGGLE_PIN':
      return {...state, pin: !state.pin};
    case 'TOGGLE_RESOLVED':
      return {...state, resolved: !state.resolved};
  }
}

const EmailComposer = (props: {}) => {
  const courseId = useCourseId();
  const {sectionId} = useParams();
  const navigate = useNavigate();
  const [emailSubject, setEmailSubject] = useState('');

  const [sendOptions, sendOptionsDispatch] = useReducer(sendOptionsReducer, {
    shareToForum: true,
    pin: true,
    resolved: true,
  });
  const {shareToForum, pin, resolved} = sendOptions;

  const [postContents, setPostContents] = useState({html: '', text: ''});
  const dataFetcher = useForumDataFetcher();
  const {userData} = useContext(ProfileContext);

  const [emailPostId, setEmailPostId] = useState('');

  useEffect(() => {
    const newPostId = uuidv4();
    setEmailPostId(newPostId);
  }, []);

  const functions = getFunctions();
  const sendEmail = httpsCallable(functions, 'sendEmailToSection');

  const sendEmailMutation = useMutation(
    async () => {
      await sendEmail({
        courseId,
        sectionId,
        subject: emailSubject,
        postId: emailPostId,
        postContents,
        postLink: `https://codeinplace.stanford.edu/${courseId}/sectionforum/${sectionId}?post=${emailPostId}`,
        shareToForum: shareToForum,
        pinPost: pin,
        resolvePost: resolved,
      });
    },
    {
      onSuccess: () => {
        setEmailSubject('');
        setPostContents({html: '', text: ''});
        setEmailPostId(uuidv4());
        if (shareToForum) {
          navigate(
            `/${courseId}/sectionforum/${sectionId}?post=${emailPostId}`,
          );
        } else {
          navigate(`/${courseId}/section/${sectionId}/`);
        }
      },
    },
  );

  if (!Gate.hasSectionLeaderRole(userData)) {
    return (
      <Result
        title="You don't have permissions for this page!"
        extra={
          <Button
            variant="primary"
            onClick={() => navigate(`/${courseId}/section/${sectionId}/`)}
          >
            Go back to section
          </Button>
        }
      />
    );
  }

  if (emailPostId === '') {
    return <Skeleton />;
  }

  const handleTipTapUpdate = (json, html, text) => {
    setPostContents({html, text});
  };

  const canSendEmail = emailSubject !== '' && postContents.html !== '';

  return (
    <div className="justify-content-center" style={{paddingTop: '20px'}}>
      <div style={{width: '70%', margin: 'auto'}}>
        <h3>Email Your Section </h3>
        <p>
          <em>
            Need to send an email to your section? Use this tool to do so! Your
            students won't be able to reply to your email, but if you'd like,
            you can also opt to share it on{' '}
            <Link to={`/${courseId}/section/${sectionId}/forum`}>
              your section forum
            </Link>{' '}
            where they'll be able to discuss it.
          </em>
        </p>
        <Form>
          <Form.Group className="mb-3" controlId="Email Subject">
            <Form.Control
              type="email"
              placeholder="Subject"
              value={emailSubject}
              onChange={e => setEmailSubject(e.target.value)}
            />
          </Form.Group>
        </Form>
        <TipTap
          editable={true}
          firebaseDocPath={dataFetcher.get_tiptap_doc_path(emailPostId)}
          onServerWrite={() => {}}
          handleUpdate={handleTipTapUpdate}
          buttonBar={EmailButtonBar}
          showLoadingSkeleton={false}
        />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Form.Check type="checkbox" style={{paddingRight: '10px'}}>
              <Form.Check.Input
                type="checkbox"
                onChange={() => sendOptionsDispatch('TOGGLE_SHARE')}
                checked={shareToForum}
              />
              <Form.Check.Label>Share to forum</Form.Check.Label>
            </Form.Check>
            <Form.Check type="checkbox" style={{paddingRight: '10px'}}>
              <Form.Check.Input
                type="checkbox"
                onChange={() => sendOptionsDispatch('TOGGLE_PIN')}
                disabled={!shareToForum}
                checked={pin}
              />
              <Form.Check.Label>Pin to top</Form.Check.Label>
            </Form.Check>
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                onChange={() => sendOptionsDispatch('TOGGLE_RESOLVED')}
                disabled={!shareToForum}
                checked={resolved}
              />
              <Form.Check.Label>Mark as resolved</Form.Check.Label>
            </Form.Check>
          </div>
          <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            {sendEmailMutation.isLoading ? (
              <Spinner animation="border" />
            ) : (
              <>
                <button
                  onClick={() => sendEmailMutation.mutate()}
                  className="btn btn-primary position-relative mt-2"
                  disabled={!canSendEmail || sendEmailMutation.isLoading}
                >
                  <FaPaperPlane style={{marginTop: -5}} /> Send
                </button>
                <BackButton />
              </>
            )}
          </div>
        </div>
        <CustomizationInfo />
      </div>
    </div>
  );
};

const BackButton = () => {
  const {sectionId} = useParams();
  const courseId = useCourseId();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(`/${courseId}/section/${sectionId}`)}
      className="btn btn-danger position-relative mt-2"
      style={{marginRight: 10}}
    >
      <FaHome style={{marginTop: -5}} /> Return to Section
    </button>
  );
};

const CustomizationInfo = () => (
  <Alert variant="info" style={{marginTop: '20px'}}>
    <Alert.Heading>Want to customize your email?</Alert.Heading>
    <p>Add these placeholders to your email subject or body!</p>
    <ul>
      <li>
        <p>
          To use your student's name, insert the text{' '}
          <code>{'{{Display Name}}'}</code>. For example, you can start your
          email with <code>{'Hi {{Display Name}},'}</code> to address your
          students personally. If you choose to share your email to the section
          forum, you'll be directed to it upon submission, where you can replace
          the placeholder with some other text of your choosing.
        </p>
      </li>
      <li>
        <em>More coming soon!</em>
      </li>
    </ul>
  </Alert>
);
