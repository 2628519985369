import {
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import {GraphicsShape, isGraphicsShape} from 'types/graphics';
import {CourseId} from 'types/common';
import {isEmptyObject} from 'utils/general';

/**
 * The representation of a graphics unit test for an assignment.
 */
export interface GraphicsUnitTest {
  /** The code to run in the graphics unit test. */
  code: string;
  /** The key of the unit test. */
  key: string;
  /** The map of shapes to draw in the graphics unit test. */
  map: Map<string, GraphicsShape>;
  /** The name of the unit test. */
  name: string;
}

/**
 * Validates that an object is a GraphicsUnitTest.
 */
export function isGraphicsUnitTest(
  testObject: any,
): testObject is GraphicsUnitTest {
  if (
    !testObject ||
    typeof testObject !== 'object' ||
    typeof testObject.code !== 'string' ||
    typeof testObject.key !== 'string' ||
    typeof testObject.map !== 'object' ||
    typeof testObject.name !== 'string'
  ) {
    return false;
  }
  return Object.entries(testObject.map).every(([key, value]) => {
    return typeof key === 'string' && isGraphicsShape(value);
  });
}

const GRAPHICS_UNIT_TEST_DOC_CONVERTER: FirestoreDataConverter<
  GraphicsUnitTest[]
> = {
  toFirestore: (unitTests: GraphicsUnitTest[]): DocumentData => {
    return {
      unitTestsGraphics: unitTests.map(unitTest => {
        return {
          code: unitTest.code,
          key: unitTest.key,
          map: Object.fromEntries(unitTest.map),
          name: unitTest.name,
        };
      }),
      lastUpdated: Timestamp.now(),
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    if (!data) {
      console.error('No data found in snapshot');
      return [];
    }
    const unitTests = data.unitTestsGraphics ?? data.unitTests;
    if (!unitTests || !Array.isArray(unitTests)) {
      console.error('No unitTests found in snapshot');
      return [];
    }
    return unitTests
      .map((unitTest: any) => {
        if (!isGraphicsUnitTest(unitTest)) {
          console.warn('Invalid unit test data', unitTest);
          return null;
        }
        const map = new Map<string, GraphicsShape>();
        for (const [key, value] of Object.entries(unitTest.map)) {
          if (!isGraphicsShape(value)) {
            console.warn('Invalid shape data', value);
            continue;
          }
          map.set(key, value);
        }
        return {
          code: unitTest.code,
          key: unitTest.key,
          name: unitTest.name,
          map: map,
        };
      })
      .filter((unitTest: GraphicsUnitTest) => unitTest !== null);
  },
};

/**
 * Gets a reference to the graphics unit tests for a given assignment.
 * @param courseId The course to search
 * @param assignmentName The assignment to search
 */
export function getGraphicsUnitTestRef(
  courseId: CourseId,
  assignmentName: string,
): DocumentReference<GraphicsUnitTest[]> {
  return doc(
    getFirestore(),
    'assns',
    courseId,
    'assignments',
    assignmentName,
    'docs',
    'unitTests',
  ).withConverter(GRAPHICS_UNIT_TEST_DOC_CONVERTER);
}

export function pyodideGraphicsDataToShapeMap(
  graphicsData: unknown,
): Map<string, GraphicsShape> {
  if (typeof graphicsData !== 'object' || isEmptyObject(graphicsData)) {
    console.error('Failed to generate canvas data.');
    return new Map<string, GraphicsShape>();
  }
  const shapeMap = new Map<string, GraphicsShape>();
  for (const [shapeId, shapeData] of Object.entries(graphicsData)) {
    if (!shapeData || typeof shapeData !== 'object') {
      console.error('Invalid shape data', shapeData);
      continue;
    }
    if (!isGraphicsShape(shapeData)) {
      console.error('Invalid shape data', shapeData);
      continue;
    }
    shapeMap.set(shapeId, shapeData);
  }
  return shapeMap;
}
