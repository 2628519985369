import {DEFAULT_LANGUAGE_CODE} from 'contexts/TranslationContext';
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import {useCipHttpsCallable} from '../../firebase/functions/use_cip_https_callable';
import {CourseId} from 'types/common';
import {LanguageCode, NamespaceId} from '../../components/translation/types';

export type TranslationNamespaceDocument = Map<string, string>;

/**
 * Metadata about translations available to the course.
 */
export interface CourseTranslationDocument {
  /** The set of languages that material is translated into for the course. */
  supportedLanguages: LanguageCode[];
}

const TRANSLATION_NAMESPACE_DOCUMENT_CONVERTER: FirestoreDataConverter<TranslationNamespaceDocument> =
  {
    toFirestore: (data: TranslationNamespaceDocument) => {
      return {
        translations: Object.fromEntries(data),
        lastUpdated: Timestamp.now(),
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      if (
        !data ||
        typeof data !== 'object' ||
        !('translations' in data) ||
        typeof data.translations !== 'object'
      ) {
        throw new Error('Invalid data in snapshot:' + JSON.stringify(data));
      }
      return new Map(Object.entries(data.translations));
    },
  };

const COURSE_TRANSLATION_DOCUMENT_CONVERTER: FirestoreDataConverter<CourseTranslationDocument> =
  {
    toFirestore: (data: CourseTranslationDocument) => {
      return {
        supportedLanguages: data.supportedLanguages,
        lastUpdated: Timestamp.now(),
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      if (
        !data ||
        typeof data !== 'object' ||
        !('supportedLanguages' in data)
      ) {
        throw new Error(
          'Invalid data in snapshot:' +
            (typeof data === 'object' ? JSON.stringify(data) : data),
        );
      }
      return {
        supportedLanguages: data.supportedLanguages,
      };
    },
  };

/**
 * Gets the collection of namespaced translation documents for a course and
 * language.
 */
export function getTranslationCollection(
  courseId: CourseId,
  language: LanguageCode,
): CollectionReference<TranslationNamespaceDocument> {
  courseId = courseId || 'unknown';
  language = language || DEFAULT_LANGUAGE_CODE;
  return collection(
    getFirestore(),
    `translation/${courseId}/${language}`,
  ).withConverter(TRANSLATION_NAMESPACE_DOCUMENT_CONVERTER);
}

/**
 * Gets a translation document for a course, language, and namespace.
 * @see {@link TranslationNamespaceDocument}
 */
export function getTranslationDocument(
  courseId: CourseId,
  language: LanguageCode,
  namespace: NamespaceId,
): DocumentReference<TranslationNamespaceDocument> {
  namespace = namespace || 'unknown';
  return doc(getTranslationCollection(courseId, language), namespace);
}

/**
 * Gets metadata about translations available to the course.
 * @see {@link CourseTranslationDocument}
 * @param courseId The course ID to get translations for.
 */
export function getCourseTranslationDocument(
  courseId: CourseId,
): DocumentReference<CourseTranslationDocument> {
  return doc(getFirestore(), `translation/${courseId}`).withConverter(
    COURSE_TRANSLATION_DOCUMENT_CONVERTER,
  );
}

interface TranslateAllMessagesRequest {
  /** The set of messages to translate. */
  messages: string[];
  /** The language to translate the messages into. */
  targetLanguage: LanguageCode;
}
/** The set of translated messages, represented as a map from the original
 * message to translation.
 */
type TranslateAllMessagesResponse = Promise<Record<string, string>>;
/**
 * Translates all messages in the provided array into the target language.
 * @see {@link TranslateAllMessagesRequest}
 */
export const useTranslateAllMessages = () =>
  useCipHttpsCallable<
    TranslateAllMessagesRequest,
    TranslateAllMessagesResponse
  >('translateallmessages');
