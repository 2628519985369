import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole} from 'contexts/profileUtil';
import {getApp} from 'firebase/app';
import {doc, getFirestore, updateDoc} from 'firebase/firestore';
import {useUserId} from 'hooks/user/useUserId';
import {useContext, useState} from 'react';
import {FaCamera} from 'react-icons/fa';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {uploadPhoto} from 'utils/uploadFile';

export const Banner = ({
  section,
  height = 300,
  rounded = true,
  editable = true,
}) => {
  const db = getFirestore(getApp());
  const sectionBannerUrl = section?.bannerImgUrl;
  const serverUrl = sectionBannerUrl ? sectionBannerUrl : '/splash3.jpeg';
  const {userData} = useContext(ProfileContext);

  const [isUploading, setIsUploading] = useState(false);
  const userId = useUserId();
  const sectionId = useParams().sectionId;

  const onImageUploaded = url => {
    const sectionPath = `sections/${sectionId}`;
    const sectionData = {
      bannerImgUrl: url,
    };
    updateDoc(doc(db, sectionPath), sectionData);
    setIsUploading(false);
  };

  const updateLoading = progress => {
    if (progress > 0 && progress < 100) {
      setIsUploading(true);
    }
  };

  const setErrorState = error => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'File was not uploaded',
    });
    setIsUploading(false);
  };

  const changeBannerPhoto = () => {
    const userId = useUserId();
    const storagePath = `section/${sectionId}/banner/${userId}`;
    setIsUploading(false);
    console.log(storagePath);
    uploadPhoto(storagePath, onImageUploaded, updateLoading, setErrorState);
  };

  const isMySection = section.sectionLeader === userId;
  const courseRole = userData?.courseRole;
  const isCourseAdmin = isMinimumRole(courseRole, Role.TA);
  const hasEditPermissions = (isMySection || isCourseAdmin) && editable;

  return (
    <>
      <div style={makeBannerImg(serverUrl, height, rounded)}>
        {isUploading && (
          <div
            className="spinner-grow"
            style={{backgroundColor: 'white'}}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {hasEditPermissions && (
          <button
            onClick={() => changeBannerPhoto()}
            className="btn btn-light"
            style={editBannerButtonStyle}
          >
            <FaCamera />
          </button>
        )}
      </div>
    </>
  );
};

const makeBannerImg = (imgUrl, height, rounded) => {
  return {
    backgroundImage: `url('${imgUrl}')`,
    width: '100%',
    height: `${height}px`,
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderBottomLeftRadius: rounded ? 10 : 0,
    borderBottomRightRadius: rounded ? 10 : 0,
    position: 'relative',
    backgroundColor: '#eee',
  };
};

const editBannerButtonStyle = {
  position: 'absolute',
  top: 10,
  right: 10,
};
