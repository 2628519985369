/**
 * Finds the MLE (Maximum Likelihood Estimator) for a sample taken from a Geometric Distribution
 * @param data - the sample
 * @returns The MLE estimate of the distribution's parameter p [0, 1]
 */
export function geometricMLE(data) {
    // Cool fact! For geometric distribution, log likelihood maximized when p = 1 / mean(x)
    const n = data.length;
    const sumX = data.reduce((sum, x) => sum + x, 0);
    return n / sumX;
}