import {useContext, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Swal from 'sweetalert2';
import {CoursePageBodyContainer} from '../../../components/layout/CoursePageBodyContainer';
import {AssnContext} from '../../contexts/AssnContext';
import {createNewAssignment} from './AssignmentEditor';
import {Loading} from 'components/loading/Loading';
import {AssignmentsTable} from './AssignmentsTable';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import {
  ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES,
  AssignmentUnitTestType,
  AssignmentType,
  AssignmentUnitTestBehavior,
} from 'assignments/types';
import {AssignmentId} from 'assignments/types';
import {CourseId} from 'types/common';
import AssignmentTypeSelector from './components/AssignmentTypeSelector';

export const AssignmentEditorSplash = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<AssignmentEditorSplashMain />}
      rightColumn={<></>}
      singleColumn={<AssignmentEditorSplashMain />}
    />
  );
};

export const AssignmentEditorSplashMain = () => {
  const {courseId} = useParams();

  const {assnMetaData} = useContext(AssnContext);

  return (
    <>
      <div className="splashCardMainTitle mt-4 d-flex align-items-center justify-content-between">
        Create new assignment
      </div>
      <AssignmentCreationForm courseId={courseId} navigateToAssignment={true} />
      <AssignmentsTable courseId={courseId} assnMetaData={assnMetaData} />
    </>
  );
};

interface AssignmentCreationFormProps {
  courseId: CourseId;
  onCompletionCallback?: (newAssignmentId: AssignmentId) => void;
  navigateToAssignment?: boolean;
}
export const AssignmentCreationForm = ({
  courseId,
  onCompletionCallback = () => {},
  navigateToAssignment = true,
}: AssignmentCreationFormProps) => {
  const [newAssignmentId, setNewAssignmentId] = useState('');
  const [newAssignmentTitle, setNewAssignmentTitle] = useState('');
  const [newAssignmentType, setNewAssignmentType] =
    useState<AssignmentType>('karel');
  const navigate = useNavigate();

  const {assnMetaData: assignmentMetadata, assnLoaded: assignmentLoaded} =
    useContext(AssnContext);
  const allAssnIds = new Set(Object.keys(assignmentMetadata));

  if (!assignmentLoaded) return <Loading />;

  const onAssignmentCreated = newAssignmentId => {
    const assnEditUrl = `/${courseId}/assnEditor/${newAssignmentId}`;
    // Because we navigate to the new assignment's page on creation, we do not need to manually tell the assignment editor to reload to reflect the new assignment.
    if (navigateToAssignment) {
      navigate(assnEditUrl);
    }

    onCompletionCallback(newAssignmentId);
  };

  return (
    <Stack direction="vertical" gap={3}>
      <Form.Control
        className="me-auto"
        placeholder="Enter the new assignment Title"
        value={newAssignmentTitle}
        onChange={e => setNewAssignmentTitle(e.target.value)}
      />
      <Form.Control
        className="me-auto"
        placeholder="Enter the new assignment ID"
        value={newAssignmentId}
        onChange={e => setNewAssignmentId(e.target.value)}
      />
      <AssignmentTypeSelector
        selectedType={newAssignmentType}
        setSelectedType={setNewAssignmentType}
      />
      <Button
        variant="primary"
        disabled={newAssignmentId === ''}
        onClick={() =>
          verifyAndAddAssn(
            courseId,
            newAssignmentId,
            newAssignmentTitle,
            newAssignmentType,
            allAssnIds,
            onAssignmentCreated,
          )
        }
      >
        Submit
      </Button>
    </Stack>
  );
};

async function verifyAndAddAssn(
  courseId: CourseId,
  newAssignmentId: AssignmentId,
  newAssignmentTitle: string,
  newAssignmentType: AssignmentType,
  allAssignmentIds: Set<AssignmentId>,
  callback: (newAssignmentId: AssignmentId) => void,
) {
  if (newAssignmentId === '') {
    Swal.fire('Oops...', "You can't have an empty assignment ID!", 'error');
    return;
  }

  if (newAssignmentTitle === '') {
    Swal.fire('Oops...', "You can't have an empty assignment Title!", 'error');
    return;
  }

  if (allAssignmentIds.has(newAssignmentId)) {
    Swal.fire(
      'Oops...',
      'There is already an assignment with this ID! Please pick another.',
      'error',
    );
    return;
  }

  await createNewAssignment(
    courseId,
    newAssignmentId,
    newAssignmentTitle,
    newAssignmentType,
  );

  callback(newAssignmentId);
}
