import {useState} from 'react';
import {FaSeedling} from 'react-icons/fa';
import {NotificationDrawerWithData} from 'course/components/notification/drawer/NotificationDrawer';
import {useNotifications} from './NoteHooks';
import {acknowledgeNotification} from './Types';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {Badge} from 'react-bootstrap';

export const NotificationsNav = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const {notifications, unreadNotifications} = useNotifications();
  const courseId = useCourseId();
  const userId = useUserId();

  const markAllRead = async () => {
    for (let note of notifications) {
      if (note.acknowledged) continue;
      await acknowledgeNotification(courseId, note.forumId, note.id, userId);
    }
  };

  return (
    <div style={{position: 'relative'}} title="notifications button">
      {' '}
      {/* Added relative positioning here */}
      <button
        className="btn"
        onClick={() => setShowNotifications(!showNotifications)}
      >
        <FaSeedling className="mainNavButton" />
        <Badge
          className="bg-danger p-1"
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}
        >
          {unreadNotifications > 0 ? unreadNotifications : ''}
        </Badge>
      </button>
      <div>
        <NotificationDrawerWithData
          notifications={notifications}
          open={showNotifications}
          closeHandler={() => setShowNotifications(false)}
          markAllRead={markAllRead}
        />
      </div>
    </div>
  );
};
