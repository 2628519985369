import {serverTimestamp} from 'firebase/firestore';
import {addLog, IdeCodeLog} from 'ide/firebase/models/ideCodeLog';
import {toFirebaseSafeObject} from 'utils/general';
import {UnitTestResults} from 'assignments/unitTest/unitTestResults';

/**
 * Parameters for logging code execution results.
 */
interface LogCodeRunParams {
  /** ID of the user who ran the code */
  userId: string;
  /** Project metadata and context for the code run */
  projectData: any;
  /** The actual code that was executed */
  code: string;
  /** Optional unit test results if tests were run */
  unitTestResults?: UnitTestResults | undefined;
}

/**
 * Logs the results of a code execution to Firebase. Converts data to
 * Firebase-safe format and creates a log entry.
 *
 * @param __namedParameters - Parameters containing user ID, project data, code,
 * and optional test results
 */
const logCodeRun = ({
  userId,
  projectData,
  code,
  unitTestResults = undefined,
}: LogCodeRunParams) => {
  let result: IdeCodeLog = {
    projectData: toFirebaseSafeObject(projectData),
    code: code,
    serverTimestamp: serverTimestamp(),
  };

  if (unitTestResults) {
    result.unitTestResults = toFirebaseSafeObject(unitTestResults);
  }

  addLog(userId, result);
};

/**
 * Safely logs a code execution to Firebase with error handling.
 * Wraps logCodeRun in a try-catch block and adds debug logging.
 *
 * @param __namedParameters - Parameters containing user ID, project data, code,
 * and optional test results
 * @see LogCodeRunParams for parameter details
 */
export const safeLogCodeRun = async ({
  userId,
  projectData,
  code,
  unitTestResults = undefined,
}: LogCodeRunParams) => {
  try {
    logCodeRun({userId, projectData, code, unitTestResults});
  } catch (e) {
    console.error(e);
  }
};
