import {AssignmentId} from 'assignments/types';
import {
  doc,
  DocumentReference,
  getFirestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import {CourseId, UserId} from 'types/common';

export interface AssignmentProgress {
  /** Whether the user has completed the assignment. */
  [assignmentId: AssignmentId]: boolean;
}

export function isAssignmentProgress(data: any): data is AssignmentProgress {
  return (
    typeof data === 'object' &&
    data !== null &&
    !Array.isArray(data) &&
    Object.keys(data).every(
      key => typeof key === 'string' && typeof data[key] === 'boolean',
    )
  );
}

const ASSIGNMENT_PROGRESS_DOC_CONVERTER = {
  toFirestore: (assignmentProgress: AssignmentProgress) => assignmentProgress,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ) => {
    const data = snapshot.data(options);
    if (!data) {
      console.warn(
        'No assignment progress data found at path: ',
        snapshot?.ref?.path,
      );
      return {};
    }
    if (!isAssignmentProgress(data)) {
      console.error(
        'Invalid assignment progress data. Silently failing.',
        data,
      );
    }
    return data;
  },
};

export function getAssignmentProgressDocumentReference(
  userId: UserId,
  courseId: CourseId,
): DocumentReference<AssignmentProgress> {
  return doc(
    getFirestore(),
    `users/${userId}/${courseId}/assnProgress`,
  ).withConverter(ASSIGNMENT_PROGRESS_DOC_CONVERTER);
}
