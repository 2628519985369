import {useTranslateFunction} from 'contexts/TranslationContext';
import {useState} from 'react';
import Button from 'react-bootstrap/Button';

export const Quotes = () => {
  const getRandomInt = max => Math.floor(Math.random() * max);
  const [quoteLikeIdx, setQuoteLikeIdx] = useState(
    getRandomInt(LIKE_QUOTES.length),
  );
  const [quoteRecommendIdx, setQuoteRecommendIdx] = useState(
    getRandomInt(RECOMMEND_QUOTES.length),
  );
  const t = useTranslateFunction('quotes');
  const nextLikeQuote = () => {
    setQuoteLikeIdx(getRandomInt(LIKE_QUOTES.length));
  };
  const nextRecommendQuote = () => {
    setQuoteRecommendIdx(getRandomInt(RECOMMEND_QUOTES.length));
  };

  const quoteLike = t(LIKE_QUOTES[quoteLikeIdx]);
  const quoteRecommend = t(RECOMMEND_QUOTES[quoteRecommendIdx]);

  return (
    <div>
      <b>
        {t('What did you like about Code in Place?')}{' '}
        <Button
          size="sm"
          variant="outline-primary"
          className="ml-2"
          onClick={nextLikeQuote}
        >
          {t('Next Quote')}
        </Button>
      </b>

      <MessageBubble
        messageText={quoteLike}
        senderName={t('Student in Code in Place')}
      />

      <b>
        {t('What would you say to someone thinking of taking the course?')}{' '}
        <Button
          variant="outline-primary"
          size="sm"
          className="ml-2"
          onClick={nextRecommendQuote}
        >
          {t('Next Quote')}
        </Button>
      </b>

      <MessageBubble
        messageText={quoteRecommend}
        senderName={t('Student in Code in Place')}
      />
    </div>
  );
};

export const MessageBubble = ({messageText, senderName}) => {
  /*
   * Borrowed from ChatRoom.tsx
   */

  const style = {
    display: 'flex',
    justifyContent: 'flex-start',
  };

  return (
    <div style={style}>
      <div
        style={{
          marginTop: '5px',
          width: '100%',
        }}
      >
        <div
          style={{
            whiteSpace: 'pre-line',
            color: 'white',
            backgroundColor: '#3e85f6',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          {messageText}
        </div>
        <div style={{fontSize: '14px', color: 'grey'}}>{senderName}</div>
      </div>
    </div>
  );
};

const LIKE_QUOTES = [
  'The lectures are very informative, and I love that we are able to have sections for any technical help.',
  'It was a very dynamic teaching approach through the discussion board, even if it was online. I liked the flexibility and adaptability of the course and its multicultural diversity. I also liked and appreciated that everyone was willing to help you achieve the assignments.',
  'I really liked how the content was organized! It was a great way to find structure to how to learn Python. As someone who already knows Java and others languages, the thing I struggle the most with is figuring out where to start learning and how to set up the language. Code in Place did a great job of covering all the essential topics.',
  'I loved how videos were prepared and the way they were explained. Loved the interaction of people in Ed and how international it was. As well as the different backgrounds of people.',
  'How motivated, intelligent, encouraging, and supportive the community was! Also, the excitement from all the section leaders and teachers!!',
  'The teaching! Chris and Mehran were awesome teachers who explained everything really well. Also, having weekly sections really distinguished this online course from others, as it gave you more motivation, and also it was fun to talk to others and learn from section leaders. Without sections, I think it would have been easier to lose focus / fall behind on course work',
  'It was very helpful to start with Karel. The concepts that seemed very hard to me in Python - Karel made it look/seem easy. Thank you very much.',
  'The interculturality, the contents, and the eagerness for teaching from Chris, Mehran, and Claire my section leader. Also, the good vibes between the students.',
  'I liked how everything was structured, the resources available, and section leaders answering our questions.',
  'I loved the experience of learning in a setting where the emphasis was not on getting a grade but rather learning a new skill with enjoyment.',
  'I liked how eveything was organized in a very clear way starting with the video lectures and then how the exercises helped to reinforced the knowledge.',
  'I loved the mix of recorded lectures I could refer to and the in person sessions. That was very useful!',
  'It was time free. We were able to manage our time about the lectures, assignments, etc. That was great.',
  'Taught me SO much- had absolute zero coding experience before I started but was able to individually do every assignment.',
  'I liked the clear and thorough explanation of the material in lectures. The lectures were divided intro smaller subtopics which made sense since we are learning online. I liked that the tutors was well-prepared each time. The learning experience was unique and everyone was helpful.',
  'I really loved the passion from the professors and section leaders. The whole course staff had so much enthusiasm and a passion for teaching that it really motivated me to do more and want to learn more!',
  'It was a great way to learn the basics of a coding language in a short period of time. I appreciate the wide overview of topics we covered.',
  'I really liked the course material. It was simple enough to digest. I also liked the challenge of doing certain assignments.',
  'The idea of trying to teach to as many students as possible this wonderful course and technology. I appreciate every single effort and thank you for a millions times for making this possible!',
  'The community and the fact that there was section leader who could mentor challenge and give us feedback.',
  'Great opportunity provided resources and guidance to allow me to successfully learn a new program as well as connect with others across the world.',
  `I was very appreciative of the opportunity to explore what coding is like without feeling stressed about grades. Also, feeling like I was able to get admitted into such a cool program made me feel confident that I had what it took to at least attempt the assignments. The staff did an excellent job of encouraging us and reminding us that it's a brand new way of thinking and it makes sense that we wouldn't be great at it at first.`,
  '1. Professors were very enthusiastic, encouraging and uplifting which provided a very comfortable learning environment. 2. Being able to discuss different issues on Ed with fellow students.',
  'I appreciate personal teaching in sections also I found helpful the availability to see the videos any time.',
  'I love how the class was at our pace online, and the forum to connect students was super helpful when I got stuck once or twice. I love the pace and difficulty of the problems presented.',
  'I loved the interactions of both amongst students and between students and the teachers (including section leaders). Well appreciated!',
  'The homework assignments were great; the foundational ones helped solidify what I learned in lectures, and the challenges were challenging and fun!',
  'The lectures were extremely well structured and easy to comprehend. The assignments were also made perfectly for the concepts we need to further practice. I am able to immediately apply what I have learned with the perfect level of challenge.',
  'I loved that the lectures included a lot of worked examples. While trying to teach myself to code online before this was aspect that I felt limited me as I needed the examples to understand concepts.',
  'The lectures were great. The effort that went it from the team top down was truly amazing. I cannot stress the quality of education provided enough (I wish the lectures would continue on!)',
  'Loved introduction to coding in simpler context using Karel. Milestones were nicely spaced everything felt connected Ed helped with questions from community and feels like a place for questions rather than out of place for asking questions. Lectures were nicely paced, and it was a great introduction to programming.',
  'Everything, from the enriching videos, the so very engaging lecturers and section leaders and the whole staff, the teaching environment of Ed, the weekly sections, and the very helpful assignments.',
  'I liked the pace of learning through videos. It was nice to have a variety of people teaching and providing helpful guidance.',
  'I enjoyed the format of the courses. Everything was well explained and all instructors and teaching leaders were extremely flexible, encouraging, and helpful. The amount of information we learned in 5 weeks was great, and I truly enjoyed the assignments.',
  'Sections were really helpful, not just with course material, but to help keep me motivated. The lectures were great! I liked the mix of exercises and ppt explanation.',
  `It's been really a great experience for me. The videos by instructors were so much fun and at the same time very valuable information. I couldn't start any better program during quarantine. I'm so happy to be chosen. Only since it was a really difficult time, I had the worst motivation, so I couldn't finish all the assignments. If it's going to be available with unlimited time, I'll definitely continue to complete all the program.`,
  'I appreciated that the assignments included the extensions. It made it easy to push yourself when you wanted to or scale up the difficulty to challenge yourself.',
  'Everything! The structure of the program, teachers and section leaders, and fellow learners are all very helpful.',
  'Being able to watch the lectures and complete the assignments at my own pace without any strict deadlines.',
  'Code in Place was such a wonderful experience because both teachers loved teaching which made the whole course more fun. Both Chris and Mehran broke down the problems in real world relatable ways which made it so much easier to understand. I liked that we all could help each other during the sections and even the Ed discussion!',
  'I liked that I could learn coding and work with others to find ways to solve the problems. I also loved that it was free.',
  'Method of teaching, connecting with students from around the world and different backgrounds. Structure of the class was easy to follow, and homework was challenging and it ensure you fully understood the concepts.',
  'Lecturers very engaging, interesting, & good teachers. Course curriculum and calendar was clear. Weekly posts on course webpage and ToDo list helped make it easy to know what to do each week.',
  'Despite the time table, the ability to follow at my own pace. Because it was tough since I had exams during the month of May. But now I can follow through the rest of the lectures at my own pace.',
  `I loved how we were learning Python online. It was a fun experience and everyone explained the material clearly. That helped a lot. I'm not really good at online learning, but this was great.`,
  'Pace was great, materials was easy to understand, and directions were clear. The information available to support the study was very good.',
  'The flexibility and the content. It really started at the right place with Karel and grew from there.',
  'It was amazing for the community to come together to teach everyone no matter what their lifestyle or background was. The small class structure was also a very big help.',
  'The community was a bright spot of the experience. The teaching team has done an exceptional job putting this course together and managing to successfully make this a great experience for thousands of students. I appreciate you all so much for this!',
  `I love the feeling of a community! Even though we didn't really know much about each other we still did our best to help each other out and encourage one another to do our best!`,
  `The well explained videos, useful material to understand the subjects, and my section's instructor. He was super patient and willing to answer every question.`,
  'Well first off, I enjoyed that it was free. Second, I liked how it was completely online and with a huge community. It was easy to gain help even without even asking at times. The Ed discussion forum was extremely useful.',
  'It gave me the opportunity to learn the foundations of computer science that was both accessible to me and affordable (free!). I loved the virtual learning community and the courses were very easy to follow and organized very well with the assignments.',
  'I like the direction community and organization. Above all I felt the joy and passion for teaching and helping others from every instructor and staff.',
  'It was designed to allow beginners to really understand the basic building blocks of coding. I feel like I could improve by myself now if I continue to keep coding and designing my own creative projects.',
  'Fundamentals for python and experiencing what it is like to be an engineer. The lectures were informative and the community really helped with the assignments.',
  'Everything! I really appreciate the effort put into this course - I loved the lectures, assignments, sections, and Ed.',
  'a) The community! It was incredible to have such a diverse learning environment. b) The pedagogy! It was an AMAZING learning experience, and the professors were so engaging and supportive.',
  'The shared chat room on Ed was incredibly helpful. The lectures were very clear and well made. The section meetings reinforced teaching very well.',
  'Very encouraging and informative profs/section leaders information was explained well. No pressure on assignments/deadlines and a focus on learning was great; overall just very satisfied with the course and its instructors.',
  'I liked the assignments. I was familiar with almost all of the concepts but the assignments were creative ways to practice them.',
  'I loved how supportive and welcoming all the instructors were including Chris and Mehran. Chris and Mehran made such an intimidating concept seem easy and absolutely fun. All the lectures and assignments always motivated me to keep learning and creating whatever code I set my mind to.',
  'Organization forum, small and concise lectures, the community, and you guys. Your teaching was amazing!',
  'It was 100% educational, and I confidently feel like I learned a new skill even if I didnt manage to finish the final project. The section leaders and the project leads were supportive and great in their delivery of instructional materials and making sure everything was easy to understand.',
  'It taught me in a short span of time the basics abilities to code. Also, the sections made the process more interactive.',
  'The lessons were a little quick, everything else was great. I am grateful for that. Sometimes I wish there were moments when I said I could get a letter of recommendation. I would love to receive a certificate. I would like to thank our teachers, Stanford University. Very lucky to work with teachers like you.',
  'I loved the sections the most. This is what kept me engaged and where I grew the most: working through problems collaboratively in a small group every week. I also like that the video lectures were short and humorous. The Ed platform was helpful.',
  'The instructors had great personalities and gave the impression they were happy to teach and eager for students to learn. This created a great environment.',
  `I liked the structure the short videos were engaging but helpful and section was great (the only reason I couldn't attend the last 2 was due to a new work schedule).`,
  'I loved the classes and the sections. The content was easy to digest and the assignments strengthened the lessons.',
  `I absolutely loved the teachers. Their credentials, enthusiasm, and involvement was superb. They made lessons exciting and relevant to today's world. I really liked the mixed learning style of lectures, sections, readings, and individual assignments. There were so many great examples and support materials.`,
  'I liked how the learning process was built. Everything was created for an easy and gradual understanding of the lessons. Also, teachers did a fantastic job!',
  'OMG! It was one of the best things to have happened. The material was provided in the right sized bytes, in a good frequency, and in an easy to understand manner. Semi self-paced was a plus point too!',
  'I really loved the time watching your lectures and working through the assignments. One thing I believe was really great was starting with Karel, that made things much more easy when starting with Python. I think the lectures were clear, straight to the point but also in a way that it was super easy to understand and really fun. Also, the fact that we could join in a live section once a week with the sections leaders and a small group to evacuate any doubts and work through the assignments and examples was very helpful.',
  'The in person sections were SO helpful for solidifying what I learned in the videos throughout the week. The assignments were always achievable but challenging and our section leader was supportive of our inquiries no matter how outlandish we got.',
  'It was great that it was like a real class with section times and also that the lectures were recorded so you could watch them whenever. The assignments were also really fun and illustrated the concepts very well.',
  'I appreciated the lectures and the handouts. I found it very good to listen to the lectures and then have the same info and additional info in written form in the handouts.',
  'The lectures, diagnostic, and assignments were really helpful and helped me build my skills. They were challenging but not so difficult that I wanted to quit.',
  'The lectures and examples that were solved. The assignments also were a good reflection and application of what we learnt.',
  'I liked that it had a structure and moments of required attendance. Other more free form course give me personally too much room to make excuses.',
  'Lectures, motivated teachers, different aspects of how Python can be used: from Karel to text processing.',
  'Compared to other free online courses, I enjoyed the course having lectures released as the course went on. It made the teaching more engaging and the whole class more motivating.',
  'Learning coding for the first time was indeed very challenging. However, since the first lecture everything has been so interesting. I especially really enjoyed listening to all of the lectures. Professors were very clear to the point and I really enjoyed their jokes. I liked how they were constantly trying to be interactive with students.',
  'The community aspect, especially in the sessions. Also the theoretical idea of decomposition and stuff. Beginning with karel was cool. Good vibe. It was clear everyone was trying to make the best course they could. Thank you!',
  'The guidance provided by the section leader and the sense of inclusion being apart of a smaller group.',
  `The weekly sections created accountability to stay on track with the lectures and assignments. I also loved Mehran and Chris's teaching methods and ways of lecturing - they are fantastic teachers. Also I am obsessed with Mehran's jokes. He's a hoot. I tried going through the next lecture slides from the actual CS106A (on nested loops and tuples) and the information could not stick. It just really shows you the immense value of having stellar teachers like Mehran and Chris to explain content versus just teaching yourself.`,
  'I loved the lectures that helped you build a foundation in Python syntax and allowed you to solve numerical problems and create numeric solutions',
  'Very well organized lots of things to work on. Automatic assignment checks. Very high quality teaching from professors. All very nice.',
  'The breadth of the code that we learned to topics such as graphics games or just simple tasks. I also really enjoyed the introduction with Karel.',
  'It was very nice to study with other people and share experiences. Also having a section every week and deadlines helped to not procrastinate.',
  'I loved the section meetings. I was enthusiastic for every new lecture. I love Chris and Mehran and I love their teaching the passion they have in the joy of learning. It was so filling and honestly so inspiring.',
  'The attitude of the professors the format of the class (online lectures plus weekly online sections), the community the way the material was taught by building on a foundation.',
  'I liked learning how to approach coding and developing algorithms (not just a bunch of syntax). This is something I was lacking from previous experience.',
  'That I could take the course mostly at my own pace. The videos were very entertaining and that made it fun to learn.',
  'The positive and enthusiastic team the challenge for me and that I overcame them the opportunity to grow and connect to others community feeling assignments with the option of how much time we can invest videos of lecture (as I can speed up the voice and stop where I need a little bit more time to think) input and exchange through Ed.',
  `Everything!! It has been the best course I have already taken (I am 35). I'm in my first semester at the University (CS) and haven't been so encouraged to code as I am now. Thank you, guys. Love you all for such a big heart! The sessions were very cool! Hey section leader, thanks a lot!! #gratitude!`,
  'The explanation and the many examples of code for each lesson. Also I enjoyed going through Ed and seeing how differently other people approached the homework. (To learn there is not just one way of doing things).',
  `I loved the structure and the flexibility to get the assignments done after about a week with a break in the middle since I'm also working and taking university courses.`,
  'The way it was structured starting with Karel. Made it much easier to understand especially having no experience.',
  'I loved the small sections! This is what separated this from all other MOOCs. I also loved the pacing of the class and the instructors. Ed forum is a great resource and way to see what everyone else is learning. All of the resources were really well done and I had a lot to read.',
  `I learned something new! I'm not a technical person at all but the material was presented and taught in a digestible way and allowed me to apply it immediately.`,
  `Honestly, loved that it wasn't graded and the timing was flexible but we were still able to see if we were doing things correctly with the assignment modules and the sections. The fact that we were encouraged to keep up but not punished if we fell behind made me feel really confident and comfortable with this course. In comparison to a similar graded course, I believe I've learned and retained more and had a better time doing so than with a graded equivalent.`,
  `Chris' and Mehran's lectures were very high quality. As a former lecturer, I know how much class preparation there's behind the scenes. I have also enjoyed feeling part of a learning community both here locally in the Bay Area and worldwide.`,
  'The live lectures the wonderful generous and humorous teachers the weekly pace the assignments that helped me really lock in the knowledge through application.',
  'The learning material is easy to understand and fun to learn. The support from the staff and the section leaders were remarkable.',
  'The sections being able to share ideas and echange knowledge through sections and Ed and learning while having all the possible fun.',
  'CiP had some incredible features. Great staff: professors + section leaders. Great infrastructure: Ed platform including forum, workplace, IDE, online submission + web portal with access to lectures, assignments, handouts. Live interaction via sections, which help cement the concepts learned during lecture. A sense of community: I personally got many answers from fellow students',
  `Honestly speaking everything! I really anticipated the lectures and Friday sections. I finished the guess the word project but I couldn't add additional features to it. So I wasn't happy to post it as a project.`,
  'The way to organize lessons, and for each lesson, give the assignment immediately after the lesson, give an opportunity to apply what you took in the lecture). The optional section on assignments adds ideas and opportunities for further research in the language. The presentation of the lectures was wonderful, unconventional, providing examples during the explanation and not just a theoretical explanation, we appreciate this effort.',
  `I thought the content and the presentation was paced well. The videos we great. I also like taking pictures of key slides as a reminder of content. The assigments were interesting and fun to do. I wasn't able to start the final assignment due to end of year commitments at my job at school.`,
  `I liked knowing I wasn't the only one clueless about coding and how there was a lot of people interested as well. I liked the instructors as well they definitely made the class very fun and interesting.`,
  'I really liked the course structure and the very high knowledge of the professors about the subject. And above all, I loved that professors were so funny and enthusiastic.',
  'I like the lecture on graphics and animation that make me think about how to use Python to a real-world project.',
  'The lessons and assignments were geared to make you think but also equipped you with the logic to do so.',
  'It opened my eyes to the world of coding. I used to be scared when they ask me to write a code but after attending this class I always see it as a challenge when I am asked to write a code.',
  'I appreciated the commitment of all the volunteers truly above & beyond very inspiring in these hard times.',
  'The professors and section leader enthusiasm on teaching and seeing us grow as both programmers and human beings made all the difference to me allowing me to learn more than I have in a whole semester of programming.',
  'It was a really fun way to learn how to code. I really enjoyed the experience, and I wish it was longer Q_Q',
  'I really liked doing the handouts and assignments. They were really awesome tasks and I wanted more.',
  'I actually learned a useful skill. There were goals to keep you on track yet it was possible to go at your own pace.',
  `Community, interactivity, and accountability via the zoom sections. Mehran and Chris' enthusiastic and kind lectures. The Ed page like a beginners StackOverflow answering elementary queries to the more complex. The Code In Place webpage jam packed with all the information you needed... I loved all of it!!!`,
  `I was able to learn a highly valuable skill from the experts (there is no available comparison course out there). The videos were very well done. Searchable Ed questions were very helpful. I'm also grateful the materials have been left up so I can reference them.`,
  'Learning a new skill. Thinking like a programmer. Supportive community. The challenges. Resolving the assignment. Informative and entertaining lectures.Focused discussion on topics during section.',
  'The pace was excellent. Having flexibility to watch the lectures when I had the time instead of a specific lecture time was invaluable. Although I did not have problems with the content, the general atmosphere of encouragement and helpfulness within the community and from the leaders was just incredible. I also appreciated the emphasis on writing "good code" (clean style lots of subroutines documentation etc.)!!',
  'I liked that it thoroughly covered the material and covered a lot of material as well. The professors were also engaging and knew how to teach well over zoom. I really enjoyed being a part of Code in Place.',
  'It made something that once felt very foreign (programming) accessible and fun. The teachers were enthusiastic and made the lectures and sections fun and interesting.',
  `Loved how engaging the lectures were. The collaborative setting of sections was also great too. I'm going to go back to the lectures and assignments to complete it in a couple of weeks. Just got backed up with medical school applications and my full-time job.`,
  'The teaching was phenomenal and easy to follow. I liked that there was an online community with Ed where questions could be asked and answered quickly and ideas shared.',
  'I thought that it gave good instructions access to examples and gave us a period to work with someone who understood what they were doing.',
  'I liked the structure of the course and the way it was organized. Having section leaders helped me to feel like I am in a class and also get guidance and learn from a professional. Mehran and Chris did a great job teaching Python lectures were both informative and fun. I liked the load of the course. It was just enough to keep up while learning new concepts and dealing with current uncertainties in life. Thanks for this great experience! I am glad that I was part of it.',
  'I like the way it has been organized and taught. From useful content to challenging assignments and Ed forum for discussion.',
  'Code in Place was simply fantastic. I was so thrilled to first get accepted and then the new topic area and ability to learn code from home was the perfect distraction from what was going on in the world. I loved all the sections that were taught virtually and I also loved the discussion sections as well. Being able to code myself was also very cool.',
  'I loved how there was a clear schedule/structure from section times to assignment due dates. The due dates on the assignments and having a weekly "in-person" section that I wanted to be prepared for and be able to contribute to made it so much easier to hold myself accountable to watching the lectures as they came out (instead of procrastinating) and staying on top of the coursework. I also loved Ed - every single question I had was already a question asked on Ed - the fellow students and the assignment leaders were so supportive and helpful.',
  'The sections were very helpful and kept me accountable. Also liked the lectures and the pace of the class.',
  'Lectures were high quality, explaining the concepts really simply. I liked the Ed board to see if people hit similar roadblocks and being able to solve problems together. The assignments were where I learned the most, I really appreciated the practical applications.',
  `I really loved Chris and Mehran's attitude. They never talk down to you you can tell they're eager about what they're doing and teaching. They make you want to be like them. They break down things and explain it in a way that doesn't make you feel dumb or like its an impossible mountain to climb.`,
  'I loved how logical it was to follow and if I had any questions there were many outlets I could use to make sure I mastered a topic. Also the professors and my section leader were absolutely amazing and made me want to go to class.',
  'I love everything about Code in Place. The lecture was fun. The section leader is amazing. The sections help me understand the lecture better and extra practice.',
  'The accessible teaching the flexibility and understanding about how we might not be able to finish all of the assignments the community and additional resources that sprung up :)',
  'The availability of instructors and peers. Ed sections having handouts that summarized the basic concepts a schedule for the videos (everything! very organized).',
  'I loved the sense of community that accompanied the forward progression of the assignments. It gave a wonderful push to keep going and learning.',
  'I loved the lectures and projects we worked on as well as the recorded lectures and having a community to ask and answer questions.',
  'The live sections, together with the examples given, both in the sections or in the Code in Place page were very helpful.',
  'It was such an amazing experience and a great opportunity to gain so much knowledge on coding. I just graduated from university last year as a mechanical engineering student and I can boldly say your teaching methods were completely different from anything I had experienced whole in school in my country, and because of this learning was much easier. Kudos to you guys down at Stanford, and thank you very much.',
  'It was a good refresher and a nice combination of recorded materials and the section leader encouragement.',
  `Love the lecture for sure!!! It was very easy to understand for the super beginners like myself to follow and understand each step in coding. Lecture helped so much. But just having an online lecture available wouldn't do much for me. Having a set of schedule accountability with all and section group are helpful to develop the discipline to complete this class just like completing regular college courses. I also loved the Ali and Maya's assignment help!!! I want to be good at coding so I can teach coding with these amazing educators!!! Who you have as teachers is so important!`,
  'Everything but especially the atmosphere. The learning attitude is positive and friendly. During Code in Place, I was able to feel a strong sense of unity within the community during this very difficult time. Thank you all very much for your dedication in helping to uplift the community spirit.',
  'Full of relevant knowledge to get the beginner programmer started and prepared to learn more from other resources.',
  'What I like about Code in Place was that I was able to learn with very great mentors that really love to teach. The lectures were easy to follow and I like that I had the chance to follow with exercise and was able to always have someone to help me in any step.',
  'I really liked how it was a big community trying to help each other out with no judgement. I also liked the teaching style incorporated by each instructor and how easy you guys made everything to understand. Another thing that I really liked was that there was no strict deadline/penalty for submission. This shows that you guys wanted us to actually learn and not just memorize code for an assignment but to actually practice and understand what we were doing.',
  'I love that there was flexibility. I also loved my section leader as well as the professors. Lectures were amazing and easy to understand. Even though it was the first time it was well organized.',
  'The lectures and resources on the class page were super clear and useful and provided a great and motivational space to learn Python.',
  `There is a section to discuss the assignment. I don't find any other courses offering this kind of facility.`,
  'The set up was great and it translated well to someone with no background. I have the foundation now to begin a coding career while still needing to find the time to get better at tieing the pieces together.',
  'Everything! It was a chance to learn something new the community was vibrant and supportive and the instructors were knowledgeable engaging and really good at breaking down the material into digestible chunks.',
  '1) Great teachers interactive learning environment content and form of presentation 2) live study sections are very useful 3) final projects are fun 4) The enthusiasm of the student community (judging by the chat on Ed) was really motivating!',
  'Some of the assignments were great!! The Karel ones especially challenged me. Also Mehran and Chris have a good sense of humor!',
  'Passionate and eloquent professors who can actually teach. Diverse and supportive student body. Small sections. Self paced.',
  'The generosity and spirit of the class especially Chris and Mehran my section leader and the students who chimed in. I loved the videos. They were easy to follow and funny!!!',
  'I could ask questions and get meaningful feedback through Ed. The section leaders were really on top of it.',
  'I have learned a lot from my instructors. The way to approach the problem. And split the problem in a small problem. All chapters were good for beginners.',
  'Lectures were excellent and assignments were fun challenging and instructive. Section was not useful at first but got better as the teacher figured out how to maximize the short time. The recorded sections were excellent though. The sense of community was also surprisingly enriching.',
  'Meeting people around the world but mostly learning Python with Chris and Mehran. It seemed so challenging to me but once they explained the concepts and showed examples everything clicked for me',
  'I loved it! Loved the lectures instructors working with section leaders. I learned a lot of a complicated concept in a simple way.',
  'I liked the easy access to course materials and the flexibility to learn at your own pace although there were suggested deadlines.',
  `I loved lecture videos. It was very well made short and right on point comprehensive explanations and a positive vibe. Especially, I enjoyed a mid semester video that Chris posted. It was very encouraging and made me believe I can finish the course even though it's not easy.`,
  `Professors Piech and Sahami were so welcoming and supportive and both gave first rate lectures. Code in Place was one of the best classes I've ever taken including those at Univsity of Wyoming (BSEE) and at Stanford (MSEE). The preparation and effort of the instructors was evident in every aspect of the class. I'm going to miss the camaraderie of the class and would sign up for Code In Place Part 2 without hesitation.`,
  `There was a lot of support available and the section leaders were very helpful in explaining any concepts we didn't get the first time around.`,
  'The lectures with humors are wonderful and easy to understand. Classmates are helpful and always willing to help. Assignments are challenging enough to have fun!',
  'it was simple instruction, with fun projects/assignments and a friendly environment. in contrast to many rigid, boring classes.Also, it taught a different way of thinking to solve a problem (breaking it down to small pieces).',
  'I really liked the sense of community: the staff and students were willing to help each other. The encouragement to focus on learning. Having a section leader was very helpful and an opportunity to learn with diverse people from other countries.',
  'Two things really impressed me. 1) the use of Karel to force using only certain aspects of programming and stressing functional programming. 2) Being able to use a library to do something not native in Python. This showed that researching and finding the right library can open worlds of possibilities.',
  'I loved how supportive and kind everyone was to one another. I also thought that sections were really helpful and gave a real sense of community for this class.',
  'The courses were very helpful specialy in how to approach problmes in order to solve them. Also liked how the sections worked for a group work.',
  'Enthusiastic instructors. Always positive and helpful. And the material was challenging but not impossible.',
  `Great! It was a great experience for me. I'm doing a lot of interviews at the moment and they all get very interested when I talk about it and why I took the course.`,
  'The entire experience was amazing. I learned about Python and more importantly you taught me the passion for learning. I did other online courses, but this has been special you made me love the course. I enjoyed your jokes and your ability to connect with me.',
  'Very easy to follow along with lectures and material. Assignments were extremely clear and submission portal made it easy to confirm accuracy of code.',
  `I like the opportunity it presented. I like the materials and the community it gave. I like that I'll have access to materials even after the course.`,
  'Everything. The enthusiasm of the teachers + students. The kindness and goodwill on Ed and the feeling of being at my good place despite the fact that I had no coding experience. I felt and was encouraged and supported to achieve things I would never though I could. This was and is a new beginning in my life. Thanks so much for this opportunity.',
  'I like how diverse the collection of students was. The live help sections were very helpful potentially moreso than our actual sections.',
  'Excellent lectures by the professors at Stanford. Easy to understand and work great as a reference when I needed to look back.',
  'Interesting lectures assignments and support from both teaching staff and other students on the forum to figure out assignments.',
  'The quality of the teachers/teaching and assignments. Access to very smart teachers / section leaders. Challenging assignments but the satisfaction when you solve the problem. The sections to keep you engaged and ask questions and community though Ed.',
  'This was such an amazing opportunity. The instructors are amazing my section leader took time to teach us and answer any of our questions. My section-mates are very supportive!',
  'Having a section/leader/other students in a team gave a good push to stick with the learning process and not lose interest. It was free (thanks so much!). High quality of teaching with many metaphors to help understand concepts.',
  'The lectures were very helpful and encouraging! My section leader was amazing and held office hours for help on the weekends. It was great being able to bring questions directly to him.',
  `I really enjoy this course. I've never done such thing before. Because of your course I loved technologies and learnt the new way of thinking. Way of communication: lectures and sections are very helpful in learning all of this huge staff. Easygoing teachers and volunteers who made my studying interesting and clear.I am so proud of being part of this.`,
  '- Lecturers and lectures - Coverage of the topic (Python in this case) - Assignments - Weekly classes to reflect on what we have learned',
  'The content - The environment - The community - Mehran and Chris!! - Ed - On a personal note, helping sponsoring other students touched me. The emails I received from them would be my best memory of Code in Place. Thank you so much Chris, Mehran, and the rest of the team for the great initiative :)',
  'The integration of section leaders and reviewing concepts. Also loved the enthusiasm showed by the team.',
  'So much content for an only one month and a half long course. I loved it! I feel I learned too much. Basically a one semester course from school I think. I liked that the course was an international opportunity in which money was not an obstacle and brilliant people who are thirsty for learning in these hard days (COVID-19) were able to take the opportunity to learn a new skill which in my opinion is the future of too many industries. Technology is friendly and can improve our lives.',
  `The diverse learning environment and interactive remote learning experience once a week. I like to check in the discussion forum from time to time and love reading other people's comments. It makes me feel like a community. Furthermore, the lecture videos were so high quality. The best online learning resources I've ever come across.`,
  'The lectures were of top quality. The introduction of a section leader brought the human connection. The section leader was motivating, encouraging and patient with all the learners in the group which enabled me to learn more. The assignments were challenging and made the foundation stronger.',
  'I loved it! Your style of teaching combined with the good energy you brought to each lesson was contagious in these uncertain times!',
  'I loved the sense of community that was built through the online forum and sections. Furthermore, the fact that the assignments were split into "mandatory" problems and challenge problems was a great way to keep students motivated while ensuring that those who were ahead had things to work on.',
  `Coming from a business background, I never had much experience coding, and, to be honest, I never thought I'd be able to, but Mehran and Chris have been the best instructors I've ever had. They made something so distant like watching lectures online feel so interactive and assignments were at a pace and level that was the perfect amount of challenging. It truly was a wonderful experience.`,
  'I thoroughly enjoyed the online lectures. They were a pleasure to watch and kept me engaged from beginning to end. They were well structured with pertinent examples and were presented with great humour and enthusiasm. I thought the online community was vital to my understanding and success through the course. Although I did not compete the full course, the work that I did complete I understood well. This was mainly because I received the help I needed by searching the Ed discussion thread.',
  `I appreciated how thorough the professors were in teaching the topics and addressing common mistakes. I love the energy the professors gave - they're not intimidating at all.`,
  'The structure and convenience stood out most to me. Also, having a community of new and eager learners was extremely encouraging.',
  'It was an incredible course for me. I studied systems in the best school of my time, and they never transmitted the idea of breaking the problem into smaller problems or trying to see it as fun. That would have greatly changed my experience in many years of my profession. Thanks to you this changed. Regarding the structure of the course, both Chris and Mehran motivated me to learn in an incredible way, the assignments were fair to learn and not stressful, and my group leader always motivated us to learn.',
  'I liked that the lectures were actually recorded for us and made references to things occurring in society. I really liked the sections. The small groups allowed for feedback. Inside of the sections, my favorite thing was having the breakout rooms. Breakout rooms in work spaces allowed for writing code in twos and threes. This gave me new perspective.',
  'I really liked the schedule of Code in Place and how simple lectures were and interesting assignments. Without coding experience, I was able to create something by my own.',
  'I loved the course. I thought it was awesome that section leaders volunteered and really cared about the learning experience. I enjoyed doing the projects and testing them as well.',
  `The opportunity to learn how to code from an amazing community and it was a lot of fun. I always looked forward to Prof. Chris and Prof. Mehran's lectures they explained the topics in a fun and entertainment way. I am very sad that it finished.`,
  'That apart from learning Python it inspired me to generate and try to solve new challenges. This is an aspect usually forgotten in coding courses.',
  'The lectures were very engaging funny and easy to follow. My section leader was amazing and the Ed community was extremely helpful and supportive.',
  'The teachers, the positive & supporting teaching style (lectures & sessions), the assignments (esp the building block approach), and having students from around the globe. The experience exceeded all my expectations!',
  `I really enjoyed the lectures & thought they were easy to follow along with. I'm glad we are able to reference them whenever needed. I also liked the weekly sessions with a smaller group and a teacher to ask questions to.`,
  `Everything. It's the most amazing course I've ever attended online. The section meeting really gave a classroom experience. The lectures were very good and the teaching style was very attractive. The teaching material, medium, assignments, everything was up to a very high standard. The dedication of all the people behind Code in Place is unbelievable!!!`,
  'It was amazing to see such a large and international community so passionate about programming. The professors were fantastic and extremely engaging.',
  `Chris and Mehran were super friendly funny informative and entertaining. My section leader was also friendly, patient, and knowledgeable, and I liked that the groups were small and personable. Oh and I can't forget Simba- Simba was the star of the course :)`,
  'I like the format that allowed me to work at my own pace. I found the discussion board really helpful in figuring out coding issues as well as the sections which let me elaborate on some of the new concepts we learned in lectures.',
  'I really liked how manageable it was. The lectures were very applicable to the assignments and extremely helpful (and fun).',
  `I loved the way you teach. Our lectures had been very well explained by both of them. And I really enjoyed the course by doing assignments. I took hardly 2-3 days to solve assignments, but I couldn't get time for the final project. I am happy that I learned a lot and interacted with so many people through sections and in discussion area.`,
  'It gave me confidence that I could develop programs on my own just understanding how libraries work. Thank you!',
  'The neat organization and division between lectures sections and especially the concept of section leaders who brought their own perspectives which further reinforced concepts.',
  'I loved the dedication put into the class. The section leaders and professors truly cared. Thank you all so much',
  'The well-designed course lectures covering main coding concepts Karel the robot the intentional real life applications in assignments (such as forest fire and green screen) Ed discussion forum the fact that the course is not graded.',
  `They enthusiasm of Mehran and Chris! Y'all's excitement about the program and the content helped me really find joy in the class and stoke my curiosity. (I also loved the Star Wars references)`,
  'Instructors! Lectures by Mehran and Chris info sessions with Ali and Maya our section leader Fah. Amazing teachers and students community.',
  'Learning from experts and people from many different walks of life. I especially appreciated the notes/code attached to lectures and that the lectures were broken up into smaller videos. I also appreciated how the course was largely practice-based (I learn best this way).',
  'An opportunity to learn a new programming language meet interesting people from all over the world and section leaders who took their time to make this course special.',
  'I really appreciated the live zoom sessions - it helped to keep me accountable for staying on top of lectures and assignments so that I could participate fully!',
  'Karel showed how to use few commands to do many things by combining them. Focus on decomposition. Full clarity in concepts, presented as a collective whole. Assignments helped to cement concepts. Professors showed happiness, smiles, and engaging attitudes while delivering. Many examples. Section leaders went through concepts and enthused discussions. Projects allowed us to make something of our own using lectures and other knowledge from web. Diagnostics allowed self assessment. Challenging problems were given for those who would want more. Code created thrust for doing more and move to next level.',
  `The lectures were really good... explained everything even for a person who has never coded in life. Also the assignments were challenging and it was fun to solve them. I liked the fact that you there were recorded sections by the team as well. I didn't love my section leader so those were quite helpful. It was well thought out.`,
  'I really enjoyed learning the fundamentals of coding best practices the instructors (loved their humor) my section leader assignments allowed me to practice.',
  'Chris and Mehran were great. I truly enjoyed their lectures and learned quite a bit. I had no prior coding experience and even though I had some trouble balancing the course with my job I was able to learn quite a bit. I liked it so much that I am now enrolled in a Udacity course.',
  'Code in Place introduced me to the world of computer science and the best thing was the collaborative learning environment.',
  'The lectures were very informative and served as reinforcement of things I had already learned about Python. It also helped me learn new some new things and apply it to different situations.',
  'Everything! The lectures were really really fun. There was a nice balance between the learning and fun.',
  'I loved the sections and I also loved the instructors. The positivity and encouragement we received was a unique experience in online learning. Note: I still plan to finish the assignments. Work has just gotten in the way...',
  'It was very well structured the schedule course videos access to a forum with other students to discuss and the weekly sections was useful for an online course.',
  `I really like this warm community. Everyone helps each other on Ed. This platform is amazing. Also I really like the teaching style of Chris and Mehran. Although it is an online cours,  I feel much more in it than in some courses I take in person in my university. I like when Chris and Mehran make some jokes while teaching; they're always making me laugh. Assignments are great! You guys make learning so simple! I couldn't imagine that I could understand Python in such a short time five weeks ago. But I did it! I also like the section part.`,
  'I really liked the pacing of the lectures and how the instructors and my section leader took the time to explain concepts like loops and dictionaries which were difficult for me to understand immediately.',
  'I liked the small class/section size, how personable everyone in my section and in the class as a whole are, and how the class created a great learning environment. There was a lot more quality in instruction and content in this online course than I expected especially from a free course.',
  'The classes were great. The sections were a fun way to learn and interact with people. Making it much better than other online courses.',
  'There are lots I enjoyed about the course but some highlights were: the community aspect, the quality of instruction, and the structure of the course.',
  `The ability to learn from Stanford professors who I otherwise wouldn't have had access to and the chance to study something that's new to me.`,
  `Great introduction to coding has really helped with my computer applications in finance course I'm currently enrolled in at Cal Poly. Really liked the first week intro with Karel.`,
  `It made the concepts of coding very easy to understand! I've tried to learn python before to start with coding and I felt it was very confusing and never got anywhere. Combination of lectures sections readers assignments with fun and passion for teaching from the team made me learn valuable things in very little time.`,
  `Everything! I really enjoyed the variety of times for section that students could sign up for the schedule with lectures being posted at the same time every week and the small size of section. I also enjoyed the Ed chats so students could answer each other's questions.`,
  `I liked your friendly and personable approach to keeping the community engaged and making them feel supported all the way through the course. It made learning CS much more enjoyable. The content was also very rich and digestible. Ed was a very clever and helpful platform in facilitating learning fostering discussions generating ideas and sharing resources/experiences. The live group coding functionality was incredibly useful. It instantly improved our section's productivity and enabled dynamic collaboration between the leader and students.`,
  'I liked the pace of the work the expertise of the instructors and the expertise and responsiveness of my section leader.',
  'It was a very beautifully organized course with excellent examples. Loved the forest fire and counterfeit medicine example. Above all, it tried to imbibe good qualities as a human being, which is focused on very less these days. This made the course one of a kind. The burning passion of the instructors was visible at each and every point of time and the whole community was thriving thanks to Code in Place teaching staff. It all started from basic and almost painlessly elevated to more complex ideas. It was a brilliant course.',
  'Lectures were interesting and at times funny. Everyone who I had any contact with was very friendly. I found the course to be well-structured too. Thank you so much <3',
  'I liked that I could refer back to the videos especially the code In the videos. The discussion was so helpful as well. Any questions I had could generally be found/answered in the discussion. The organization and the pace of the class made the material easier to grasp. The energy of the professors was outstanding.',
  'I liked how the lesson is organized the occasional humor injected into the lesson the open forum discussion the assignment and extra assignment! Also, how the video lesson is also available in PDF/Powerpoint the code for the video is also available for download. What I like the most of all is the lesson on decomposition, and how I should start out with small code and slowly grow it bigger to solve problems!',
  'Teachers were great, lectures were easy to understand, section leaders and staff were extremely helpful.',
  'I loved the TA sessions. I am generally a self-learning so appreciate having lectures recorded but really liked solving problems and 1:1 learning with a group (especially being isolated alone).',
];
const RECOMMEND_QUOTES = [
  `You won't find a better program to get an introduction to Python especially while working full time remotely.`,
  `Don't be afraid to apply! You won't regret it the community is great and the overall vibe is awesome :)`,
  'Do it. Take the first step. Because of Code in Place, I am more than willing to jump start into a new career change by dropping a load of money into a coding boot camp. I am no longer afraid of coding and confident facing the boot camp.',
  'If you are wiling to learn you cannot get a better way of teaching anywhere than this course for programming.',
  'I would definetly recommend you take this course even if you cant complete the whole thing. The basics you learn can apply to any language, so just the first half of the class is worth doing even if you dont do the cooler stuff at the end.',
  'Amazing material and amazing lectures. Having taken several Python courses this one really sets apart by introducing what some of the best practices are!',
  `Code in Place is a great intro to coding and to Python so if you're interested in either this is a great place to start.`,
  'You are very lucky to have this course as the teaching stuff are extremely polite creative and experience to take you into amazing world of Python! Enjoy and keep it fun!',
  `This course hits the sweet spot of application and theory. You learn how to write code AND think about code. It's truly a testament to the statement "anyone can code."`,
  `Sign up immediately and don't look back! Best step you can take towards your education in programming.`,
  'Just do it! It is intense and it does require the time and dedication but you will learn so much from such incredible lecturers/leaders as well as your peers. Who knows you might even surprise yourself with an unknown talent and a passion to continue your coding journey!',
  `So inspired by the creativity of my fellow classmates and the energy of the Code in Place lecturers and section leaders. I've never fully completed an online course before but doing this with the Code in Place community felt different - like I wasn't doing it alone but part of a group of people interested to just learn.`,
  'If you are interested in learning about programming with Python. Let this be the first course you take. It WILL change your life.',
  'Its a great kickstart to get your head around the principles of coding and see tons of new possibilities.',
  'Take it! I could follow since I had coded before, but my daughter struggled because she never took a coding class.',
  `Think of taking a class purely for learning a new skill and having 8000 other students eager to learn and help you if you are unsure. Imagine no discrimination whatsoever because you are all there for intelectual joy. Imagine this class being divided into section of only a dozen or so people with an expert to assist you and answers any curiosities. That's Code in Place.`,
  'Code in Place is perfect place where you can enter without knowledge and exit with understanding of what programming is and how it works.',
  'No doubt that course went far beyond my expectations. I started basically from scratch with Karel coding and five weeks later I coded a voice assistant translation tool...',
  'As a first course to take when entering the exciting world of Computer Science Code in Place would have to be the course which sets the standard. Considering that Karel the robot has been around longer than you have at the age you are most likely to begin this course should tell you that the people at Stanford know what they are talking about and know how to teach this at time complex subject to absolute beginners. I feel privileged to have taken part in this version of an excellent course.',
  `Take the opportunity to learn because we are in the age of technology. You will learn so much from lectures and not even think it's an extra class. This community is one that will not let you down.`,
  'I was close to not applying because I thought I would not have enough time. If you are in the same situation just sign up! This was a life-changing experience, and I am so grateful I could be a part of it!',
  `I thought it would be impossible for me to finish it and that once again I wouldn't learn anything. It was completely the opposite. I finish it together with my other sections friends (yes we become friends), and I actually learn something I create my final project from zero (it was a simple and silly one? yes but I had zero references). I never took a coding class till the end because I believed it wasn't for me and Code in Place showed to me that I am capable to code :)`,
  'This course has given me the opportunity to learn the fundamentals of Python in a fun and immersive way. I highly recommend taking Code in Place because you learn so much about coding the STEM industry as well as meet amazing people!',
  'Highly highly recommend! I really enjoyed my time during the course and learned even more than I was hoping I would at the beginning.',
  `Hey! If you already think about doing a fun and useful course like Code in Place just give it a try! It's a great experience and you will learn a lot and get inspired by other people's projects and be amazed what you can learn in such a short time!`,
  'This class is incredible in teaching you the basic concepts and applying them to projects from day 1. The teachers and section leaders were engaging and made learning about coding non-intimidating!',
  'These people care about coding and can help you learn. They are fantastic and you will have fun while doing so.',
  `I hope you can keep up learning and attending the section meetings. This is a super amazing experience that you don't want to miss.`,
  'If you have to start your journey through the universe of python programming start with "Code in Place". It really challenges you and teaches you to think computationally to solve real problems. The instructors are fun and have a deep command of what they teach us.',
  'Do it. You will be happy you did. A great way to get started with coding. This could motivate you to try something new with your career!',
  `I totally recommend this class! It's super challenging but super fun and you'll have a huge system of support from your professors, section leaders, assistants, and fellow classmates from all over the world.`,
  'Code in Place is an excellent way to experience how to code. Their learning objectives course content instructors are all excellently packaged. I strongly recommend to experience Code in Place.',
  'I took a couple of Python workshops before, but I can tell you Code in Place is much better than any one-shot workshop or your self-learning. Instructions and student assistants from Stanford University are super smart open-minded and patient. The most importantly they are super teachers. They make complicated concepts and codes interesting. They make me love coding after taking this class. I truly appreciate this great opportunity. And I hope you should take advantage of it for fun or for any reason.',
  'One of the best things that would happen to you in your life both personally and professionally. Chris, Mehran, and Ali (and the Code in Place team) are like pebbles in sand.',
  `Don't think about this twice. Code in Place is a great learning opportunity and a chance to explore a new language in today's world.`,
  `Code in Place isn't just a programming course. It's an opportunity to empower yourself and connect with diverse students globally. It will teach you not only the syntax of the Python language, but also how to think like a programmer and develop new approaches to problem-solving. Take the course. You won't regret it.`,
  'If you are serious about the desire to start coding - Code in Place is the best place to go! You will learn important fundamentals get to build and play with great programs and will have tremendous fun doing so.',
  'Code in Place is not an ordinary online course because you have real-time support and many people motivate you throughout the process.',
  'Good course if you do not have any programming experience. If you have worked as a programmer in past and just want to learn Python then I feel its not for you. As a programmer, you might already know the basics and just need to learn the syntax to start programming in Python. I think programming experienced people should take some other advanced course otherwise you will get bored after some time.',
  `Even if it becomes challenging: Don't give up! There are so many people who are willing to help you if you are stuck.`,
  'You have no idea how large a capacity you have to learn until you see yourself successfully create a program on your computer!',
  'This was fantastic. The instructors are all so passionate and understanding you can tell they want to inspire and keep you from being discouraged. You still need to put in the work but this has been the best free online class that I have taken. The live sections really helped me stay motivated.',
  'I had the time my life, and I cannot express how grateful I am to all the people who made this course possible.',
  'If you are looking for an online course with world class professors and a community to take it together with this is for you. Make sure you have time for it every week consistently.',
  'The course is a good starting point for someone who is trying to learn Python. It gives you an overall idea of Python concepts and if you are willing to work hard within 5 weeks you will be pro in basics of Python programming.',
  `You will be excited to play more with Karel in the beginning which will eventually turn to frustration and then (hopefully) euphoria when it all comes together in the end. That is just the beginning of the whirlwind adventure you embark on with Mehran and Chris! The material and their subsequent challenges ramp up and may leave you stumped for a while (if you have little to no coding experience beforehand), but no matter where you finish you should be able to code a small program that makes you feel accomplished - you did something you couldn't do before!`,
  'Amazing opportunity to learn about coding with no previous experience. It is an amazing journey focused on learning just for the sake of it. After the course you have the confidence to seek more courses and challenges to grow as a programmer. It is truly a wonderful experience.',
  'If you want to learn with the best methods that I ever seen with people that will make you understand things that you thought hard with a very complete course with fun and with amazing people, Code in Place is for you.',
  'This course is AMAZING! Every aspect of this course helped students with little to no prior coding experince feel more comfortable. It provided us with a great sense of community which made COVID lockdown a bit more bearable. The section leaders and the instructors are some of the most wonderful people on the planet! Take this course!',
  `Don't fear and try it! There is something in this course for everyone and you'll come out of it smarter happier and proud!`,
  'Fantastic program! The lectures were so well done and helped make complicated coding theories make sense.',
  'Amazing class with the best teachers and the small sections make it 100% worthwhile. I would recommend this to ANYONE!',
  'Code in Place gave me the best experience. If I get change to do another course, I will be ready to start right away.',
  'A great experience the program makes learning Python programming fun and you become part of a community that is always willing to help and solve your doubts.',
  `Take this course for Chris and Mehran's jokes! Just kidding -- really take it for yourself! You are more capable than you know.`,
  `Even if you don't want to code full time do it. It's a great way to challenge yourself and great way to learn something new.`,
  'It will be a great experience and you will learn new skills. This will make a new foundation for your future.',
  `Code in Place is really a gem! During these 6 weeks, I felt like our community was a big family where I could ask for help have fun and feel true coder! I'm really glad I was a part of this course from its start. Apart from fun the course has helped me learn the basics of coding in a super understandable way. That was worth it debugging and testing again and again!`,
  `If you've been wanting to get into coding for a while but have just been struggling to get past the barrier to entry - Code in Place is the course for you. It will hook you instantly with its pleasant and talented professors and intriguing and challenging programming assignments.`,
  'It is by far the best and most entertaining way to start learning about Computer Science and Python! BY FAR!!!!',
  'If you have any interest in coding regardless of technical proficiency this course will be worth your while.',
  'The course provides really good content and is easy to learn while having fun coding! I would totally sign up to more courses like this.',
  'This course will give you an appreciation for coding and introduce you to a supportive community of students and teachers.',
  `Go for it! Take this course not to learn about coding but to actually understand the concepts and think like a coder. Don't think twice before signing up.`,
  'Great experience to start a journey in programming. The staff is not only qualified but passionated about teaching and programming Best of all you can feel that you belong to a multicultural learning community.',
  'Think no more! Take Code in Place for Python and Karel for a really fun time and an awesome learning experience.',
  `I definitely recommend this course if you're thinking about getting into coding. The instructors teach the material well and the assignments are well thought out to reinforce the concepts taught. You can go at your own pace and challenge yourself as much or as little as you like. The main assignment problems make sure you've got the basic concepts down but there are always more difficult problems provided for you to tinker with and solve if you wanted a little more of a challenge.`,
  'Highly recommend this course. Very talented and dedicated teaching and support staff. For a mature student like me who had no online learning experience it was a transformation -- a person/student who had no clue about coding about 5 weeks ago to writing a code for my technical project!',
  'If you want to learn programming please take Code in Place I am really surprised how much I have learned.',
  `It often didn't feel like school or work. I felt like I was playing much of the time. For my Karel final project, I had Karel draw a heart and I sent it to my girlfriend. As you can imagine she loved it, but she also felt bad that the little robot had worked so hard (haha). It's a wonderful experience.`,
  'If you want to learn how to code, this is a great first step into learning key fundamentals in an extremely beginner friendly way! The professors are great! The material is fun!',
  'I could not recommend this class more. I had never taken any coding classes before and basically knew nothing about it but I was able to learn so much thanks to the amazing lectures from Chris and Mehran. This is coming',
  'I think this couse would be a great opportunity for you to learn computer science and programming with Python.',
  'It is a great community and experience with knowledgeable mentors who make learning how to code fun and easy to digest!',
  `If you have told yourself that you've wanted to learn the basics or get your feet wet this is for you. No pressure and extremely enjoyable!`,
  `Do it! Don't second guess yourself. You know nothing about coding? I'm jealous. You get to discover and learn all this amazing stuff for the first time from the best possible teachers. Enjoy every bit of it. Karibu :)`,
  'Do yourself a favor!!! Take the course do the work and get the knowledge. The instructors are very knowledgeable and I never felt as though I was being lectured. Teaching is an art form and the Code in Place instructors have it down to a tee! I thoroughly enjoyed the course and learn a new skill!',
  'Is one of my favorite latest experiences actually I am motivated to attend Stanford more than ever! You will learn important programming concepts but also enjoy.',
  'The learning never ends. The most important thing is a limitless curiousity about the world and how to influence and change it to make it better for all.',
  'I would highly recommend Code in Place. It is fast paced and challenging but also incredibly supportive and engaging.',
  'Knowing the basics of coding might change your life. Coding is the wave and Code in Place will make sure you have all the equipment you need to take on any coding challenge.',
  'I would tell someone who is thinking of taking Code in Place to come in with the mindset that they are able to learn the concepts in the class because it is really easy to get discouraged right off the bat, but, if you stick with it, you will succeed.',
  `The class is great for both new coders and already experienced programmers! It's really flexible in that you can adjust the homework and lectures to fit your schedule and you have the opportunity to really challenge yourself if you have more time to dedicate to the class.`,
  'Take this course-- you will seriously not regret it. The way the material is taught is effortless and you will learn so much.',
  'You will not regret this amazing opportunity! The teachers are all incredibly smart and explain the concepts so well.',
  `Do it! Dive in with both feet even if you're an absolute beginner to coding. The instructors are fantastic the community you get to be a part of is so necessary for rapid successful learning and you'll learn to see the world through a new lens with your new skills!!`,
  `Welcome to coding. If you're worried you can't, this fear will change after you take Code in Place. Good luck!`,
  `Do not hesitate to do it! If you're like me and have no coding experience you will find parts very challenging but you will be surprised how much you to grow to understand and retain.`,
  `The future of humanity lies in information technology. If you're offered a good opportunity, sieze it.`,
  'One awesome course for the beginners. Make use of this opportunity to learn from wonderful professors and section leads.',
  'Just follow the daily lectures and try to solve the assignments. Never miss your meeting with section leaders. Share your problems on forum or with your section leaders.',
  `I can not think of an easier and more enjoyable way of getting into programming. Get on board, you won't regret it.`,
  'This is a great course taught by professors that are actually interested in teaching. You will be surprised by the organization of the course how it allows you to go between individual self-study and classroom based learning as well as how much you will learn in such a short period of time!',
  'It is a great opportunity to pick up a new skill, and you will feel like you are part of a community of eager learners.',
  'This was an excellent crash course in Python. The lectures really know their stuff and love teaching it!',
  'You will find a supportive community with experienced instructors to teach you a ubiquitous skill with a flexible schedule to suit your needs. Do it.',
  `If you are trying to break into Python coding this is one of the best routes to take. As a person who has attempted twice and failed, I'm happy I was able to have the privilege to learn from Code in Place and their professors.`,
  'Code in Place was an exceptionally wonderful experience, and I loved learning Python from/with everyone in Code in Place. :)',
  'I am really thankful for the opportunity to be part of this course. I would definitely continue this course if there were more. This is one of the best programming course you can attend with its best teachers and section leaders and interesting assignments. I would definitely recommend it to people who are interested in programming.',
  `I never thought I would attempt to learn how to code again. I'd tried once and after a bitter experience with an online programming course I thought programming could never be learnt online. But then Mehran and Chris changed my life. If you get the opportunity to apply for this course apply immediately. The later you apply the more chances of your application not getting accepted and your missing out on the greatest experience of your life.`,
  `It's amazing! It's hard but worth every second spent. I am incredibly grateful for this opportunity!!`,
  `Best intro to Python and programming course you can take online. I've tried several and in just a few weeks I have learned so much more in this course than if I self-studied. The instructors community and overall structure of the program makes this course incredibly valuable. It is worth your time.`,
  `If you are interested in learning how to code, I highly recommend taking Code in Place. The instructors make learning Python the coding language accessible and easy with the well planned out syllabus where you will learn the basics of the language's capabilities which are laid out in each lesson. The lessons are then supplemented by problem examples and small group coaching online for free. It doesn't get better than this when it comes to a high-quality education. It's Stanford University.`,
  'If you are just getting started with coding this is a great course to really get you introduced and fully delve you into the world of coding and is really exciting!',
  `Hop on the ride and fasten your seatbelt cause its gonna get crazy. Also, welcome to the beautiful world of Python which you'll learn here with hard-working section leaders and mentors around to help 24/7!`,
  'This course is very challenging, but satisfying when you see what can be created. Certainly worth taking even if you have no interest in coding.',
  `This will be the best decision you could ever make for yourself. Coming from a business background, I never had much experience coding, and to be honest I never thought I'd be able to but Mehran and Chris have been the best instructors I've ever had. They made something so distant like watching lectures online feel so interactive, and assignments were at a pace and level that was the perfect amount of challenging. It truly was a wonderful experience.`,
  `Your professors and community are going to be amazing and you're going to get unparalleled quality of education! Just be prepared for an avalanche of intense information in a very short time period.`,
  'The curious mind is the wellspring of creativity. Code in Place gave me a safe kind and fun place to be curious and explore an entire new world with coding!',
  `Do it!! If you're interested in coding and enjoy problem solving this is definitely the place to start.`,
  `If you are thinking about it then do it. It's very rewarding and will help you feel good along with adding a skill.`,
  'If you are a beginner in programing and want to start exploring Python, Code in Place is a really great place to start. Great professors and community with a well structured curriculum and lectures.',
  `Hi there! I have made several attempts to learn very basic concepts of coding, and right after each lecture I just forgot what I learnt. It was a lonely and boring experience. However, this course is a miracle! You will never forget what you learnt, and there are great resources for anybody. I'm sure you will find what you are looking for in this class.`,
  `This was a absolutely phenomenal learning experience. I started Code in Place with no prior Computer Science experience and now I'm eager to learn so much more! Simply inspiring.`,
  `The entire faculty of Code in Place is doing a great job. I don't know how it is to study at Stanford, but I am sure this is very close. The quality is top notch and the faculty is always there to help. If you plan to learn basics of coding this is a perfect place to start.`,
  'This is a great course with a very thorough introduction to way to think about coding and not just teaching a coding language. I have learnt Python in a way that I can start using it right away and that has been amazing!',
  'Highly recommend the course for anyone who feels like learning something new and challenging yourself! Instructors Chris and Mehran were wonderful at explaining the concepts while still keeping things interesting. It was a fun and challenging course!',
  'Would highly recommend this course! Great content lecturers and tutorials. What a great opportunity to learn coding online!',
  'This is a great way to learn about Python! I enjoyed it a lot and hope you do too! As with everything in life what you put in is what you take from any experience so learn well!',
  'The only way to learn how to program is to practice! This course really helps you get a grasp of basic concepts and gives you fun assignments to apply your knowledge.',
  'I knew nothing about programming coming into code in place and I came out of it enjoying it. I would highly recommend Code in Place for everyone.',
  `I recommend this course because it's challenging. You get the opportunity to learn how to code from the basics and eventually find your way through it. Also, you'll find how coding was easy!`,
  'One of the best online courses for learning to code! It keeps you involved and always coming up with new ways to solve problems!',
  'Code in Place is the best class you could ever take to ignite your love and passion for coding with a solid foundation and a new perspective on problem solving that can be useful for the rest of your life.',
  `If anyone wants to take this course, there's no need to take second thought. This is the best for people across all walks of life to dive into the coding world. The journey begins at Code in Place. Thank you professors and everyone working hard for this project.`,
  'I went in with no knowledge of coding and I feel that after these six weeks I have a strong foundation that i can leverage going forward as I continue to learn different languages.',
  'If you want to learn this is it. Code in Place provides you with all the material to kick start your career with the best professors.',
  `Before starting I was very intimidated by the idea of coding. This course was comfortable and fun and I learned how to do a lot of really cool things I never thought I could do. I highly recommend taking this course if you've been wanting to learn how to code but perhaps were afraid to try.`,
  `If you're looking for a community to code together while you learn Code in Place is the course for you.`,
  `Really great course - it's structured and has a strong human interaction component which kept me on track / didn't give up halfway through!`,
  'DO IT!!! Whatever your background, whatever your aspirations, you will learn great things from Code in Place. I feel privileged to have been part of this amazing pedagogical experiment.',
  'Take Code in Place! The materials and instructors are fabulous. I am no longer intimidated by coding- I know I can do it!',
  'Good course material and instructors who care. If you work hard you will come out better in the end.',
  `Do it! If you put in any amount of effort you will learn something new add a skill to your resume and have fun without the stress of grades. You'll also virtually meet people from different backgrounds and skill levels willing to help so you won't feel like you're alone in this journey. Best wishes!`,
  'If you wish to learn how to code you have come to right place which full of extremely talented and helpful tutors. You will make new friends just as much eager as you to learn how to code but among all you will have one great friend who will follow all your commands his name is "Karel". Best thing about him he is like a book always helpful in achieving task by giving you knowledge. Karel is one of my best friend now hahaha',
  `The instructors and teaching staff have put their hearts and minds into crafting a course that superbly translates to a MOOC-style setting. The content and course direction are so strong that you'll brarely feel that you're one of thousands taking the class.`,
  'Code in Place is a great opportunity to learn with and from the best teachers in an awesome community.',
  `You'll learn coding, but that's only a small piece of the experience that is Code in Place! Take it and see.`,
  `It's a good opportunity to take part in the Code in Place community so don't hesitate and start learning a wonderful language from an awesome online courses "Code in Place".`,
  `I can't recommend this course enough! It has been an absolute pleasure to have this experience. Learning was made super easy because of how fun it is!! :)`,
  `Absolutely go for it! The quality of teaching is amazing for an online course - Chris and Mehran are as passionate and enthusiastic as they come, and it's hard not to be motivated by that! The group sections are the bit that sets this course apart though. It's like being in a real classroom at Stanford.`,
  'Join Code in Place. This is a great initiative from Chris, Mehran, and other instructors. Join Code in Place and have fun learning to program',
  `Code in Place starts at the very beginning, the very best place to start, and teaches you the foundations of Python. Very engaging course with passionate teachers. It's not something you'd want to miss!`,
  'An amazing opportunity to learn from attentive instructors and alongside an involved curious student body of all ages and from all over the world. Fantastic attention to detail and awareness on behalf of the organisers.',
  'Definitely give it a shot and pay particular attention to pacing of projects as the course perhaps gives students too much leeway in that regard.',
  'Code in Place is an amazing opportunity to exercise and challenge your brain with something new. The community of students and instructors is supportive and fun; I highly recommend doing it!',
  'Hi! I have just completed this course and would definitely recommend it to anyone who is considering an intro course for computer science. The class is fun interacting and full of passion for CS learning. I believe it has all for a new CS learner.',
  'Code in Place is truly a gift - the professors and section leaders are awesome and you will walk away having had fun while learning a new skill.',
  `Highly recommend and definitely don't underestimate the amount of time you will need to dedicate to the course in order to really understand and apply the concepts. Coding is great fun!`,
  `Go for it! If you've never coded before, please don't feel intimidated to try. The course is designed with complete novices in mind, and every step is manageable along the way. You'll be surprised at how much you learn!`,
  'Dedinitely go for it if you are honest about being able to spend 30 hours per week for several weeks on it.',
  'At first, I thought it will be an exhausting and scary course since it is from Stanford but it is not! Their teaching method is really fun and the community is very helpful and the diversity is really a special experience. We help each other through the discussion forum and the live sections led by voluntary section leaders. Special thanks to Code in Place teaching team and for voluntary section leaders!',
  'Coding might seem like a scary hurdle but all things good for you usually scare you a bit. Once you started to understand all the things that you can use coding for (little or big) you will feel that with time you can achieve so much. It is EXTREMELY rewarding see your work become something that is very much your own. Almost artistic in a way.',
  `This is a very accessible online course with plenty of support from peers and staff. You can definitely learn the basics of Python from this course if you're willing to devote a bit of time to it.`,
  'I would personally recommend that everyone across the globe should take this course once. This course has been a true game changer in my life. Before this course, I never liked coding and after this course I love it. Coding opens up plethora of dimensions in a professional career. Coding gives us a blank canvas where we can paint anything and everything under the sun.',
  'If you really want to learn and enhance your software engineering abilities this is the course for you!',
  `Don't second guess yourself. You can learn this; don't be overwhelmed. Take it slow, ask questions, read Ed boards when stuck, and have fun.`,
  'Thanks for arranging this kind of course... It really is so enjoyable... I really love this course...',
  'Have fun with it. Glance through the Ed board for interesting topics and search if you into problems. Usually someone else has the same question',
  'This course really got the ball rolling for me to learn programming. I discovered I liked it more than I thought I would.',
  'This is an amazing opportunity for you to dive into the world of coding!!! All the teachers and section leaders are wonderful!',
  `If you've never coded before, Code in Place is a transformative experience. The whole community: instructors, sections leaders, and other students are generous and ready to help.`,
  'Lectures were extremely clear engaging so easy to understand! Code in Place is a friendly highly accessible start for those with no previous experience.',
  'For introdution to Python, there is no better course to show you the ropes and get you hooked on Python.',
  'While programming feels overwhelming, this course is the first step that you need to start learning and coding.',
  'It was an amazing experience to be a part of Code in Place. If you want to learn how to code in a well organized environment just try Code in Place! It is a good start to learn coding.',
  `I always wanted to learn software programming but thought I wasn't good enough at mathematics to do it. This course took me through all the steps almost effortlessly. It was so much fun learning and finally doing that thing I had been so keen to finally get off my never ending list. The professors are really remarkable they'll make sure you get there whatever your level is. The course is fun too!`,
  `Hey! If you want to learn a new skill (you know which one), just dive into Code in Place. Because here it won't feel boring (like it did for me in college) and moreover you will be learning a trending skill.`,
  'Nowadays, I consider that learning the basics of a programming language is as fundamental as knowing how to read or write. This course will teach you those basics.',
  'Code in Place is really helpful and interesting at the same time. Really worth dedicating your time to.',
  'Code in Place will go give you or refresh your joy of learning with the help of passionate and patient instructors and professors.',
  'Code in Place delivers quality content about programming and is taught by a wonderful team of people. They made the experience much more fun than learning online typically is!',
  'This course has an ideal balance of practical application and theory. It teaches sound fundamentals that will serve you well in any programming language.',
  'It is a super helpful course and all instructors are extremely kind and professional. If I have chance I will definitely take an advance course.',
  `You won't regret participating in this course. It will open your eyes up to the world of coding in a way that makes it feel accessible and real. No matter your age, career, or level of experience, there is a place for you in Code in Place. You're going to be amazed by how much you can learn. Do it!!!`,
  'Inspiring for me as I am learning more coding now. It was great kick start for me, and I overcame all my fear of coding as a mechanical engineering graduate.',
  `This course helped me to understand how to think like a developer even though I wasn't able to really become one. The format was encouraging of any/all learning goals and learning styles.`,
  'If you want to overcome the fear of programming, this course is for you!!! I started loving programming during this course, and it has opened doors to numerous opportunities for me <3',
  'Thank you very much for the super cool experience. In particular to Mehran and Chris for teaching coding so joyful.',
  `This is a platform where you won't feel small or lost, even if you are just starting out. This is because all the instructors and the section leaders are kind and understanding and supportive. Trust yourself take a chance, and learn something so cool!!`,
  'You have to be willing to commit and not compare your progress with others. Finish what you started, since you will be tempted not to.',
  `Code in Place is a great experience. Having the chance to learn about Python and programming with people all over the world is amazing. The team is awesome. You'll have such a good times in lectures assignments and sections. Participate and you will not regret it.`,
  `If you want to learn to code and have fun while interacting with a supportive community sign up! You won't regret it!`,
  'Code in Place is an incredible opportunity to gain an introduction to Python and develop a potentially life changing skill.',
  `Don't expect to learn everything right away and be patient with your learning abilities. It will be continuously gratifying as you learn.`,
  'At first, I was on the fence about taking the course, but am so glad I did! The subject seems daunting but the support of the professors section leaders and other students made it fun and the course goal achievable.',
  `If you're looking for an opportunity, a chance, the right moment to finally learn coding... here it is. Take it!! Especially for beginners there is no better entry. All the different lectures, the weekly sections, the assignments, the community... all together they give many reasons to make a personal commitment to give it your best without any real obligations like in school or university. This way, the learning is more fun and is way more effective.`,
  `If you're hesitating - don't! I almost didn't sign up and boy that would have been a big mistake because Code in Place turned out to be one of the best and most rewarding classes I've ever taken. The teaching quality and commitment level from the teaching team were incredible.`,
  'Best course out there. Coolest teachers. Will definitely teach you everything you need to start off with coding!',
  'If you want to learn program, take Code in Place! If you want to meet excellent people all over the world, take Code in Place! If you want to have a fun learning experience, take Code in Place!',
  `Don't hesitate just take this opportunity. It wasn't my first attempt to learn Python, and every time I gave up, but Code in Place gave me the motivation and feeling that I can actually do it. It was an amazing experience!`,
  `Just don't hesitate! You will have a wonderful time and you're going to learn a lot! You will be impressed by the development in your skills after this course. Never stop learning and keep growing!`,
  'Great way to learn the fundamentals of coding. Chris is a sweetie and Mehran has some killer dad jokes!',
  'You should definitely take this course!!! Coding is very important and by understanding the basics you can go a long way! Not only does this course teach you how to code, but also it teaches you how to think critically and believe in yourself. Honestly, I never imagined to be able to code a game or console, but I got to do so by learning from Code in Place.',
  `You got this!!! Don't be intimidated by unfamiliar material or completely new concepts. You are so capable and will do amazing things.`,
  'An amazing learning experience! It almost felt like I was learning at Stanford campus. The teachers kept me engaged with a great sense of humor and a great platform for us to learn. I have no background in this field, but, honestly, I am now considering it going forward. This would not have happened without Code in Place! Loved it!!',
  `Nothing to lose, plenty to gain! Great and fun experience, even if you aren't sure you want to progress in a career in computer science.`,
  'Do it! Mehran and Chris are such great teachers that explain even the hardest things in an interesting and easy way. Such a great time learning from them!',
  `The course was somewhat a life-changing experience. I've never wrote the line of code in my life ever before but definitely can do it now. I've learned a lot of magical things that I hope to apply in real life. I also may a few great folks my teammates and we had some great online time together. The quality of lectures and materials is outstanding. The professors section leaders and all who've been supporting the course - thank you so much you are the best!`,
  'Signing up for Code In Place was one of the best decisions I made during the lockdown. It gave me a sense of purpose while I learnt something completely new to me with ease!',
  `Please do! It's a great start for anyone looking to get into programming. You will get more done than you expect without getting overwhelmed.`,
  `If you're not a technical person... DON'T BE AFRAID! I was intimidated at first for sure but Code in Place helped me take the material get comfortable with it and gain confidence that I could learn it and go deeper than I thought I could!`,
  'You should absolutely try this! It feels genuinely interactive, and each week is exciting with new problems to solve.',
  'This opportunity has been amazing. Thank you Chris and Mehran for taking the time and making it accesible to people like me who perhaps would have never been able to access Ivy League courses such as this.',
  'I cannot recommend this course enough. It is interactive and fun. A lot of the interactivity is with other people, and that is what makes this course such a great experience.',
  'An excellent way to introduce yourself to the wonderful world of programming. You will have fun if you have the time.',
  'Great opportunity to learn good programming and Python from excellent instructors and facilitators!',
  'Very informative and useful lessons. I applied them right away, and it made me want to pursue more courses in this same industry.',
  `If you ever wanted to learn to code you wouldn't get better instructors than Chris and Meeran and a better course than Code in Place. I absolutely loved it.`,
  'One of the great investments for you effort if you are thinking about getting a basic idea of Python and programming.',
  `This course is so well thought out that you don't need to worry about where you stand with programming prior to the course. It allows you to learn fundamental programming in a fun and interactive way.`,
  `Please do not miss this life-changing chance of being a student of Code in Place. It's a world class learning platform.`,
  `It doesn't matter if you have little or no experience with coding; this course will teach you exactly how much you need in order to understand and use the basics of Python. Actually, the less you know the more it will change the way you think!! Also - unlike many other lectures - your motivation will INCREASE over time. No idea how they do it, but they're damn good at it!`,
  `Programming isn't just lines of code but a very fun way to solving problems. And Code in Place is the perfect place to learn this.`,
  'This program was an amazing opportunity for me to not only learn beginning Python but also realize coding can be fun and exciting. I got to learn about how coding classes are and the course really challenged me to think critically outside of the box a lot. I also got to meet some great people from all around the world',
  `Learning for the pure joy of learning is a fulfilling experience! :) You won't regret learning how to code w/ Chris, Mehran, and your section leader!`,
  'This is a perfect online course for someone with no prior experience in computer coding of any kind. This is a course that gives you a solid foundation for starting to learn coding, especially if you intend to continue to learn programming.',
  'The structure of this course helps people learn how to program more efficiently than any other online free courses out there.',
  'I am thankful that, during this Covid-19 lockdown, I had the opportunity to learn coding with Stanford. Our professors and volunteer instructors were great and enthusiastic to help us. Although it was hard at first, the activities are fun to learn, especially once you have accomplished them. With the ongoing changes in our new normal, learning how to code and know how programming works is an essential key to future social and cultural advancements. I highly recommend this course!',
  `Go for it. Make sure you watch the videos and play around. Don't be afraid to play around screw up or ask for help.`,
  `This is a great opportunity to go deeper in coding. The lectures are funny and full of key concepts. You shouldn't doubt it; take Code in Place right now.`,
  'A super introduction to coding! You will learn so much in such a short time! Make the most of this opportunity.',
  'Code in Place was an amazing experience for me. It is just about learning. No tests, no stress. Just learning.',
  `I entered Code in Place with zero programming experience, but after six weeks of engaging lectures, fun assignments, and collaborative group sessions, I can't believe how far I've come. If you're interested in learning some basic coding skills or if you just want to try something new, do yourself a favor and take this course.`,
  `Don't miss the great opportunity. Go for it, you won't regret. You will be amazed to see how well you can code in Python by end of 5 weeks.`,
  `It is 10000% worth it. Don't get discouraged if it takes you a while to get the extra credit questions. Eventually, something will click and you'll get there.`,
  `This was one of the most unique experiences I've had. The professors made the subject matter so engaging and the global community was incredibly supportive.`,
  'If you are looking for a chance to evaluate whether coding is something for you, Code in Place is a great opportunity.',
  'I strongly recommed this amazing opportunity to every one (whatever you are doing or who you are). I am really looking forward to enrolling this kind of course. Even if it is the same course I would like to attend to improve my knowledge and enjoy the activities.',
  'When you start learning something for the first time, I suggest you choose the highest quality resource, particularly if you are an adult or matured learner. Code in Place has all the quality you need.',
  `Code in Place is a WONDERFUL oppurtunity to connect and learn. I really havent seen an online course quite like it. It is truly a pressure-free and nurturing learning environment. Give it a chance! There's little to lose, yet so much to gain!`,
  'This is a once in a lifetime opportunity to experience some of the best instruction available in the world! The education is fantastic but the experience itself goes beyond the expectation of a typical class. This course is a tantalizing rollicking introduction to programming that will feed your mind and your soul with fascinating useful rewarding skills and knowledge in an environment of authentic joy for learning and celebration of humanity. A course for brains taught from a core of hearts with a mission to proselytize and spread their love for programming and community.',
  'Code in Place is a very good program to take if you are absolutely new to anything programming. Even if you are not so new to programming, Code in Place will help you fall in love with programming all over again.',
  'Code in Place is such an amazing opportunity to learn some coding. Everyone is very friendly and helpful which created an excellent environment to learn.',
  'Highly recommended! Chris Piech and Mehran Sahami are great instructors and their passion for the subject is obvious.',
  'This is a great place to start. Be ready to keep learning through many other facets once you complete this course.',
  `I liked the Ed forum and the way how people try to help each other very much. But at some points, I felt lost because I thought coding is not something good or right for me. It's too difficult for me. But besides that, I'm really appreciate about your efforts to make the course happen!`,
  'Highly recommend this class! A productive and fun way to expand your skill set while working with the best instructors!',
  'If you are considering taking Code in Place and may be second guessing due to lack of coding experience, take a leap of faith, and I promise you will not regret it. This course is very rewarding and the fulfillment you get from finally understanding concepts is a feeling like no other.',
  `You will love this class! Please try to do all of the assignments, and don't be discouraged if you don't solve them all. Please reach out to your section leaders. They are there for you. The level of dedication to students' learning is unmatched. Please utilize all of the resources available.`,
  'Highly recommend. Everyone is very friendly and the instructors and section leaders are high quality.',
  `TAKE CODE IN PLACE!!!!! It was a fantastic experience! The instructors made learning something that was intimidating to me very enjoyable and clear. Prior to this class I had absolutely no coding experience and being able to actually write (simple) programs that worked was so exciting. One of the best learning experiences I've ever had!!`,
  `This is a perfect way to be introduced to coding. Long enough to learn something useful and applicable. Long enough to judge for yourself if it's worth continuing. Not so long that you get overwhelmed in the process.`,
  `If you're someone like me who has tried using online websites like Codecademy or watching tutorial videos and it didn't really click this is the class for you.`,
  'Code in Place was such an awesome experience. I have gained knowledge and confidence in my computer programming journey. I highly recommend this course to anyone who has the chance to take it.',
  'This is a great class for anyone who wants to begin coding. The instruction is very clear and it is a friendly and accepting environment.',
  `Make sure you take it! It's completely manageable in the current climate (even with full time job or studies) strongly recommend!`,
  'Stop thinking and do it!!! No matter what your level is or how much time you have to dedicate you WILL get something out of it and be a smarter more efficient and all around better person at the end of the course.',
  `If you have the opportunity do it! Karel is the best and so are the instructors. It's something you can take with you wherever you go.`,
  `Code in Place is perfect for Python novices, and people familiar with Python can also find fun. Anyway, it's really amazing!`,
];
