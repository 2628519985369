/* tslint:disable */


import * as Yup from "yup";



import { TextAreaInput } from "components/Forms/FormixComponents";
import {FormixFormWithData} from "components/Forms/FormixFormWithData"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter";

export const DebuggingForm = ({serverData, onSubmit, backUrl}) => {
    console.log(serverData)
    return <div className="d-flex justify-content-center mt-3 mb-3">
        <div style={{maxWidth:700, width:'calc(100% - 20px)'}}>
        <FormixFormWithData 
            serverData={serverData}
            backUrl={backUrl}
            onSubmit={onSubmit}
            formFormat={debuggingFormFormat}
        /> 
    </div>
    </div>
}



const karelProgram = `"""
Assumes that Karel starts in the bottom of a world filled with beepers
Picks up all the beepers in the first two rows
"""
def pick_up_all_beepers():
    pick_row()
    change_rows()
    pick_row()

def change_rows():
    turn_left()
    move()

def pick_row():
    while front_is_clear():
        pick_beeper()
        move()`

const consoleProgram = `def main():
    dictionary = {}
    dictionary["learning"] = "awesome"
    dictionary["coding"] = "fun"
    # ... Fill with more data
    remove_keys_containing_string(dictionary, "learn")

"""
This Python function takes in a dict and a string and  
removes all keys containing that string from the dict
"""
def remove_keys_containing_string(dictionary, remove):
    new_dictionary = {}
    for key in dictionary:
        if remove not in key:
            new_dictionary[key] = dictionary[key]`


const KarelDebugging = ({name}) => <>
<h4>Karel Debugging</h4>
<p>(1) Identify the problems in the code below and (2) Write 3 sentences of advice to the student who wrote this code. Rather than point out the bugs, consider how to guide the student toward diagnosing and correcting the problems on their own.</p>
<PythonHighlighter  code = {karelProgram}/>
<TextAreaInput
    label=""
    name={name}
    type="text"
    placeholder="Some problems in the code include..."
/>
</>

const PythonDebugging = ({name}) => <>
<h4>Console Debugging</h4>
<p>Identify the problems in the code below and (2) Write 3 sentences of advice to the student who wrote this code. Rather than point out the bugs, consider how to guide the student toward diagnosing and correcting the problems on their own.</p>
<PythonHighlighter  code = {consoleProgram}/>
<TextAreaInput
    label=""
    name={name}
    type="text"
    placeholder="Some problems in the code include..."
/>
</>

// note that yup uses "name" as the key to a form field
// each formItem has a name and input. 
// It can optionally have default, validation, and postProcess
//  https://github.com/jquense/yup#stringtrimmessage-string--function-schema
const debuggingFormFormat = [
    {
      name:"karelDebug",
      input:KarelDebugging,
      validation:Yup.string().required("Karel debugging is required"),
    },
    {
        name:"pythonDebug",
        input:PythonDebugging,
        validation:Yup.string().required("Console debugging is required"),
    },
  ]