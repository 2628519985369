import {database} from 'firebaseApp.js';
import {ERROR_CODES} from './Errors';
import {useContext, useEffect, useState} from 'react';
import {RoomInfo} from './types';
import {firestore} from '../../firebaseApp';
import {serverTimestamp} from 'firebase/firestore';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole} from 'contexts/profileUtil';

export const ROOM_JOINED = 'joined';
export const ROOM_EXITED = 'exited';
export const ROOM_FORCE_EXITED = 'force_exited';

const roomStatuses = [ROOM_JOINED, ROOM_EXITED, ROOM_FORCE_EXITED];

export const getRoomsPath = courseId => `${courseId}/teachnow/rooms`;
const getLearnersPath = courseId => `${courseId}/teachnow/learners`;

// OLD PATH TO CHECK FOR IN THE CODE BASE
// const getRoomsPath = (courseId) => `${courseId}/rooms`;

export const setRoomStatus = async (uid, courseId, roomId, newStatus) => {
  try {
    // if new status is not a valid status, return error
    if (!Object.values(roomStatuses).includes(newStatus)) {
      return {
        success: false,
        code: ERROR_CODES.ROOMS.INVALID_STATUS,
        error: 'Invalid room status',
      };
    }

    const roomsPath = getRoomsPath(courseId);
    const roomStatusPath = `${roomsPath}/members/${roomId}/${uid}/status`;
    console.log(roomStatusPath);
    const userStatusRef = database.ref(roomStatusPath);
    const userStatusSnap = await userStatusRef.once('value');
    if (!userStatusSnap.exists())
      return {
        success: false,
        code: ERROR_CODES.ROOMS.INVALID_ROOM,
        error: 'Trying to set status for user but they are not in the room',
      };
    await userStatusRef.transaction(currentStatus => {
      // can only change status if the current status is 'joined'
      if (currentStatus === ROOM_JOINED) {
        return newStatus;
      }
      return currentStatus;
    });
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      code: ERROR_CODES.GENERIC,
      error: 'Generic error: ' + error,
    };
  }
};

export const useActiveRoom = (uid, courseId): string | undefined => {
  const [activeRoom, setActiveRoom] = useState(undefined);

  useEffect(() => {
    if (uid) {
      const learnersPath = getLearnersPath(courseId);
      const activeRoomPath = `${learnersPath}/${uid}/activeRoom`;
      const activeRoomRef = database.ref(activeRoomPath);
      // const activeRoomRef = database.ref(`/${courseId}/learners/${uid}/activeRoom`)
      activeRoomRef.on('value', snap => {
        const val = snap.exists() ? snap.val() : null;
        setActiveRoom(val);
      });
      return () => {
        activeRoomRef.off();
      };
    }
  }, [uid, courseId]);
  return activeRoom;
};

export const useRoomInfoOnce = (courseId: string, roomId: string): RoomInfo => {
  const [roomInfo, setRoomInfo] = useState(undefined);

  useEffect(() => {
    const roomsPath = getRoomsPath(courseId);
    const roomInfoPath = `${roomsPath}/info/${roomId}`;
    const roomInfoRef = database.ref(roomInfoPath);
    // get room info once

    roomInfoRef.get().then(snap => {
      if (snap.exists()) {
        setRoomInfo(snap.val());
      } else {
        console.log('Room does not exist');
      }
    });
  }, [courseId, roomId]);

  return roomInfo;
};

export const useRoomStatus = (courseId, roomId, uid) => {
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    const roomsPath = getRoomsPath(courseId);
    const roomStatusPath = `${roomsPath}/members/${roomId}/${uid}/status`;
    const userStatusRef = database.ref(roomStatusPath);
    const userStatusSnap = userStatusRef.on('value', snap => {
      if (snap.exists()) {
        setStatus(snap.val());
      }
    });
    return () => {
      userStatusRef.off('value', userStatusSnap);
    };
  }, [courseId, roomId, uid]);

  return status;
};

export const useRole = (courseId, roomId, uid) => {
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    if (uid && roomId) {
      const roomsPath = getRoomsPath(courseId);
      const rolePath = `${roomsPath}/members/${roomId}/${uid}/role`;
      const pilotRef = database.ref(rolePath);
      pilotRef.on('value', snap => {
        if (snap.exists()) {
          // Roles: 'pilot', 'copilot', 'sl' (pilot/copilot for peer pilot/sl for office hours)
          setRole(snap.val());
        }
      });
    }
  }, [uid, roomId]);
  return role;
};

export const getRoomMembersUidToDisplayName = async (courseId, roomId) => {
  const roomsPath = getRoomsPath(courseId);
  const membersPath = `${roomsPath}/members/${roomId}`;
  const membersRef = database.ref(membersPath);
  const membersSnap = await membersRef.once('value');
  if (membersSnap.exists()) {
    const membersDict = membersSnap.val();
    const membersLst = Object.keys(membersDict);
    const uidToDisplayName = {};
    membersLst.forEach(m => {
      uidToDisplayName[m] = membersDict[m]['displayName'];
    });
    return uidToDisplayName;
  }
  return {};
};

export const useRoomMembers = (courseId, roomId) => {
  const [memberInfo, setMemberInfo] = useState(undefined);

  useEffect(() => {
    const roomsPath = getRoomsPath(courseId);
    const membersPath = `${roomsPath}/members/${roomId}`;
    const membersRef = database.ref(membersPath);
    const memberSnap = membersRef.on('value', snap => {
      if (snap.exists()) {
        const membersDict = snap.val();
        const membersLst = Object.keys(membersDict);
        const membersRequesting = membersLst.filter(
          m => membersDict[m]['isRequesting'],
        );
        const membersDisplayName = membersLst.map(
          m => membersDict[m]['displayName'],
        );
        const uidToPresenceData = {};
        const uidToDisplayName = {};
        membersLst.forEach(m => {
          uidToDisplayName[m] = membersDict[m]['displayName'];
          uidToPresenceData[m] = membersDict[m]['presence'];
        });
        const d = {
          members: membersLst,
          requesters: membersRequesting,
          displayNames: membersDisplayName,
          uidToDisplayName: uidToDisplayName,
          uidToPresenceData: uidToPresenceData,
        };

        setMemberInfo(d);
      }
    });
  }, [courseId, roomId]);

  return memberInfo;
};

export const addTeachingTeamToRoom = async (
  courseId,
  roomId,
  uid,
  displayName,
) => {
  const roomsPath = getRoomsPath(courseId);
  const roomMemberUidPath = `${roomsPath}/members/${roomId}/${uid}`;
  const teachingTeamRef = database.ref(roomMemberUidPath);
  try {
    await teachingTeamRef.set({
      displayName: displayName,
      isRequesting: false,
      role: 'extra-sl',
      status: 'joined',
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const useIsInRoom = (courseId, roomId, uid) => {
  const [isInRoom, setIsInRoom] = useState(undefined);

  useEffect(() => {
    const roomsPath = getRoomsPath(courseId);
    const membersPath = `${roomsPath}/members/${roomId}`;
    const membersRef = database.ref(membersPath);
    const onValueChange = snap => {
      if (snap.exists() && snap.hasChild(uid)) {
        setIsInRoom(true);
      } else {
        // if(isMinimumRole(courseRole, Role.MENTOR)) {

        //     membersRef.child(uid).set({
        //         displayName: userData.displayName,
        //         isRequesting: false,
        //         role: 'force-join-sl',
        //         status: 'joined'
        //     })
        //     setIsInRoom(true);
        // } else {
        //     setIsInRoom(false);
        // }
        setIsInRoom(false);
      }
    };

    // Attach the listener
    membersRef.on('value', onValueChange);

    // Cleanup the listener when the component is unmounted or courseId, roomId, or uid change
    return () => {
      membersRef.off('value', onValueChange);
    };
  }, [courseId, roomId, uid]);

  return isInRoom;
};

export const setPilotStatus = async (
  courseId,
  roomId,
  newPilotUid,
  currentPilotUid,
) => {
  const roomsPath = getRoomsPath(courseId);
  const newPilotRolePath = `${roomsPath}/members/${roomId}/${newPilotUid}/role`;
  const newPilotRoleRef = database.ref(newPilotRolePath);
  const newPilotRoleSnap = await newPilotRoleRef.once('value');
  await newPilotRoleRef.set('pilot');
  // Turn off the newPilotUid requesting
  await setRequestStatus(courseId, roomId, newPilotUid, false);

  // Set current pilot to be a learner
  const currPilotRolePath = `${roomsPath}/members/${roomId}/${currentPilotUid}/role`;
  const currentPilotRoleRef = database.ref(currPilotRolePath);
  const currentPilotRoleSnap = await currentPilotRoleRef.once('value');
  await currentPilotRoleRef.set('learner');
  return {
    success: true,
  };
};

export const setRequestStatus = async (courseId, roomId, uid, updatedVal) => {
  const roomsPath = getRoomsPath(courseId);
  const isRequestingPath = `${roomsPath}/members/${roomId}/${uid}/isRequesting`;
  const isRequestingRef = database.ref(isRequestingPath);
  const isRequestingSnap = await isRequestingRef.once('value');
  await isRequestingRef.set(updatedVal);
  return {
    success: true,
  };
};

export const submitSLFeedback = async (
  feedback,
  feedbackText,
  comment,
  stuckLevel,
  roomId,
  uid,
  courseId,
) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('slFeedback')
    .doc(uid);

  return ref.set({
    rating: feedback,
    ratingText: feedbackText,
    version: 'v1',
    studentStuckLevel: stuckLevel,
    additionalComments: comment,
    timestamp: Date.now(),
    timestampV2: serverTimestamp(),
  });
};

export const submitGratitude = async (gratitude, roomId, uid, courseId) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('gratitude')
    .doc(uid);

  return ref.set({
    gratitude: gratitude,
    version: 'v1',
    timestamp: Date.now(),
    timestampV2: serverTimestamp(),
  });
};

export const updateStudentComment = async (comment, roomId, uid, courseId) => {
  const ref = firestore
    .collection('teachnow')
    .doc(courseId)
    .collection('tickets')
    .doc(roomId)
    .collection('gratitude')
    .doc(uid);

  return ref.update({
    messageToSL: comment,
  });
};
