import {AssignmentId} from 'assignments/types';
import {getFirestore} from 'firebase/firestore';
import {
  DocumentReference,
  doc,
  FirestoreDataConverter,
} from 'firebase/firestore';

import {QueryDocumentSnapshot, SnapshotOptions} from 'firebase/firestore';

import {DocumentData} from 'firebase/firestore';
import {CourseId} from 'types/common';

/**
 * A map of file names to starter code, i.e. the code that is provided to the
 * student when loading the assignment.
 */
interface StarterCode {
  [fileName: string]: string;
}

const STARTER_CODE_DOC_CONVERTER: FirestoreDataConverter<StarterCode> = {
  toFirestore(starterCode: StarterCode): DocumentData {
    return {...starterCode};
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options: SnapshotOptions,
  ): StarterCode {
    const data = snapshot.data(options);
    if (!data) {
      console.debug('No data found in snapshot.');
      return null;
    }
    return data as StarterCode;
  },
};

export function getStarterCodeDocumentReference(
  courseId: CourseId,
  assignmentId: AssignmentId,
): DocumentReference<StarterCode> {
  return doc(
    getFirestore(),
    `assns/${courseId}/assignments/${assignmentId}/docs/starterCode`,
  ).withConverter(STARTER_CODE_DOC_CONVERTER);
}
