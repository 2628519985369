import {
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';
import {AssignmentId} from 'assignments/types';
import {CourseId} from 'types/common';

export const SUPPORTED_AI_HIDDEN_EXAMPLE_CORRECTNESS_VALUES = [
  'correct',
  'slightly wrong',
  'medium wrong',
  'very wrong',
] as const;
export type AiHiddenExampleCorrectness =
  typeof SUPPORTED_AI_HIDDEN_EXAMPLE_CORRECTNESS_VALUES[number];

export interface AiHiddenExample {
  /** The code of the example to pass. */
  code: string;
  /** Whether the example represents a passing or failing submission. */
  correctness: AiHiddenExampleCorrectness;
  /** A per-assignment unique key for the example. Previously a number, now a string. */
  key: string | number;
  /** A human-readable name for the example. */
  name: string;
}

/**
 * Validates that an object is an AiHiddenExample.
 */
export function isAiHiddenExample(
  exampleObject: any,
): exampleObject is AiHiddenExample {
  return (
    exampleObject &&
    typeof exampleObject === 'object' &&
    typeof exampleObject.code === 'string' &&
    isAiHiddenExampleCorrectness(exampleObject.correctness) &&
    (typeof exampleObject.key === 'string' ||
      typeof exampleObject.key === 'number') &&
    typeof exampleObject.name === 'string'
  );
}

export function isAiHiddenExampleCorrectness(
  correctness: any,
): correctness is AiHiddenExampleCorrectness {
  return (
    typeof correctness === 'string' &&
    SUPPORTED_AI_HIDDEN_EXAMPLE_CORRECTNESS_VALUES.includes(
      correctness as AiHiddenExampleCorrectness,
    )
  );
}

export const AI_HIDDEN_EXAMPLE_DOC_CONVERTER: FirestoreDataConverter<
  AiHiddenExample[]
> = {
  toFirestore(examples: AiHiddenExample[]): DocumentData {
    return {
      hiddenExamples: examples.map(example => {
        return {...example};
      }),
      lastUpdated: Timestamp.now(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options: SnapshotOptions,
  ): AiHiddenExample[] {
    const data = snapshot.data(options);
    if (!data) {
      console.debug('No data found in snapshot');
      return [];
    }
    if (!data.hiddenExamples || !Array.isArray(data.hiddenExamples)) {
      console.debug('No unitTests found in snapshot');
      return [];
    }
    const hiddenExamples = data.hiddenExamples.map((example: any) => {
      if (typeof example.key === 'number') {
        return {...example, key: String(example.key)};
      }
      return example;
    });
    return hiddenExamples.filter(isAiHiddenExample);
  },
};

export function getAiHiddenExamplesReference(
  courseId: CourseId,
  assignmentId: AssignmentId,
): DocumentReference<AiHiddenExample[]> {
  return doc(
    getFirestore(),
    `/assns/${courseId}/assignments/${assignmentId}/docs/hiddenExamples`,
  ).withConverter(AI_HIDDEN_EXAMPLE_DOC_CONVERTER);
}
