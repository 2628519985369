import {
  UnitTestLoaderParameters,
  UnitTestSchema,
  WithRunnerData,
} from '../UnitTestSchema';
import {
  EMPTY_TEST_RESULT,
  getFirstTestFailed,
  UnitTestResults,
} from '../unitTestResults';
import {getConsoleUnitTestReference} from '../../models/unitTests/console';
import {getDoc} from 'firebase/firestore';
import {areOutputsEqual} from 'components/pyodide/KarelLib/util';
import {ConsoleEditor} from './ConsoleEditor';
import {type LoadedConsoleUnitTestData} from './types';
import {getAllFileNames} from 'ide/utils/general';

async function loader({
  courseId,
  assignmentId,
}: UnitTestLoaderParameters): Promise<LoadedConsoleUnitTestData | null> {
  const consoleUnitTestReference = getConsoleUnitTestReference(
    courseId,
    assignmentId,
  );
  const consoleUnitTestDoc = await getDoc(consoleUnitTestReference);
  if (!consoleUnitTestDoc.exists()) {
    return null;
  }
  const unitTests = consoleUnitTestDoc.data();
  return {
    unitTests,
  };
}

async function runner({
  loadedData,
  pyodideClient,
  filesCode,
  fileStructure,
}: WithRunnerData<LoadedConsoleUnitTestData>): Promise<UnitTestResults> {
  const {unitTests} = loadedData ?? {unitTests: []};
  // make sure that the unitTests are valid
  if (!unitTests || unitTests.length === 0) {
    return EMPTY_TEST_RESULT;
  }
  const allFileNames = getAllFileNames(fileStructure);
  const mainFile = allFileNames.find(file => file.name === 'main.py');
  if (!mainFile) {
    return {
      testResults: [
        {
          isSuccess: false,
          name: 'Console Project Precheck',
          usedAi: false,
          rawResult: {message: 'No main.py file found'},
        },
      ],
      testsRun: true,
    };
  }
  const codeToRun = filesCode[mainFile.id]?.content ?? '';
  const testResults = [];
  for (let i = 0; i < unitTests.length; i++) {
    const unitTest = unitTests[i];
    const pre = unitTest.dialogue
      .filter(data => data.type === 'input')
      .map(val => val.input);
    const expectedPost = unitTest.dialogue.map(val => val.output);
    if (!pre) {
      console.error('unit test pre condition is empty');
      continue;
    }
    await pyodideClient.loadFiles(allFileNames, filesCode);
    const rawResult = await pyodideClient.testCode(codeToRun, mainFile, pre);
    const observedPost = rawResult.output;
    const correctOutput = areOutputsEqual(expectedPost, observedPost);
    const hasError = rawResult.error && rawResult.error.length > 0;
    const isSuccess = !hasError && correctOutput;
    testResults.push({
      isSuccess,
      name: unitTest.name,
      usedAi: false,
      rawResult: rawResult,
    });
  }
  return {testResults, testsRun: true};
}

function getFailureMessage(results: UnitTestResults): string {
  const firstTestFailed = getFirstTestFailed(results);
  return `The program finished running but the output wasn't what we expected. Check Test ${firstTestFailed}.`;
}

export const CONSOLE_UNIT_TEST_SCHEMA: UnitTestSchema<LoadedConsoleUnitTestData> =
  {
    loader,
    runner,
    EditorComponent: ConsoleEditor,
    unitTestType: 'console',
    displayName: 'Console Input/Output Script',
    tooltipDescription:
      'Compares a transcript of input/output pairs against the student code.',
    getFailureMessage,
  };
