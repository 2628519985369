import Swal from 'sweetalert2';
import {Modal, Form, Button} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {isValidId} from '../OnEventAlerts';
import {AssignmentCreationForm} from '../assignmentEditor/AssignmentEditorSplash';
import {useParams} from 'react-router';
import {AddLessonForm} from '../lessonEditor/LessonEditorSplash';
import {useContext} from 'react';
import {LessonsContext} from 'course/contexts/LessonsContext';
import {v4 as uuidv4} from 'uuid';
import {assnTypes, ROADMAP_CHOICES, ROADMAP} from './RoadmapSchema';
import {getCurrPTTime} from 'components/timezones/timezoneHelper';

export const showSelectRoadmapItemSwal = async () => {
  // Create a select input with the choices
  let htmlString = '<select class="swal2-select">';
  ROADMAP_CHOICES.forEach(choice => {
    htmlString += `<option value="${choice.key}">${choice.title}</option>`;
  });
  htmlString += '</select>';

  // Show the Swal
  const result = await Swal.fire({
    title: 'Choose a type',
    html: htmlString,
    focusConfirm: false,
    preConfirm: () => {
      const selectElem = Swal.getPopup().querySelector('.swal2-select');
      return selectElem ? selectElem.value : null;
    },
  });

  // Check if Swal was not dismissed
  if (result.isConfirmed) {
    return result.value;
  }
  return null;
};

export const EditModuleModal = ({
  itemType = '',
  defaultValues,
  onSubmit,
  onCancel,
  showModal,
  idList = [],
  isModule = false,
  isNew = false,
}) => {
  const [title, setTitle] = useState(defaultValues.title ?? '');

  const [startDate, setStartDate] = useState(
    defaultValues.startDate ?? getCurrPTTime(),
  );
  const [endDate, setEndDate] = useState(
    defaultValues.endDate ?? getCurrPTTime(),
  );
  const [startTiedToSection, setStartTiedToSection] = useState(
    defaultValues.startTiedToSection ?? false,
  );
  const [endTiedToSection, setEndTiedToSection] = useState(
    defaultValues.endTiedToSection ?? false,
  );
  const [completionId, setCompletionId] = useState(
    defaultValues.completionId ?? '',
  );
  const [urlId, setUrlId] = useState(defaultValues.urlId ?? '');
  const [id, setId] = useState(defaultValues.id ?? '');
  const [isOptional, setIsOptional] = useState(
    defaultValues.isOptional ?? false,
  );

  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [isNewLessonOrAssn, setIsNewLessonOrAssn] = useState(false);
  const {lessonsMap, addLessonToDB} = useContext(LessonsContext);
  const allLessonIds = new Set(Object.keys(lessonsMap));

  const {courseId} = useParams();

  useEffect(() => {
    setTitle(defaultValues.title ?? '');
    setStartDate(defaultValues.startDate ?? getCurrPTTime());
    setEndDate(defaultValues.endDate ?? getCurrPTTime());
    setStartTiedToSection(defaultValues.startTiedToSection ?? false);
    setEndTiedToSection(defaultValues.endTiedToSection ?? false);
    setCompletionId(defaultValues.completionId ?? '');
    setUrlId(defaultValues.urlId ?? '');
    setId(defaultValues.id ?? '');
    setIsOptional(defaultValues.isOptional ?? false);
  }, [showModal]);

  const onFormSubmit = e => {
    e.stopPropagation();
    if (!title) {
      setShowWarning(true);
      setWarningMessage('Title cannot be empty');
      return;
    }

    if (isModule) {
      onSubmit({
        title,
        startDate,
        endDate,
      });
    } else {
      if (
        (!urlId || !completionId) &&
        (itemType === ROADMAP.ASSIGNMENT.key || itemType === ROADMAP.LESSON.key)
      ) {
        setShowWarning(true);
        setWarningMessage('You must select a Lesson or Assignment');
        return;
      }
      const idToSubmit = id ? id : uuidv4();
      if (!idToSubmit || !isValidId(idToSubmit)) {
        setShowWarning(true);
        setWarningMessage(
          'The ID field cannot be empty and can only contain lowercase letters, numbers, and hyphens',
        );
        return;
      }

      onSubmit({
        itemType,
        title,
        startDate,
        endDate,
        startTiedToSection,
        endTiedToSection,
        completionId,
        urlId,
        isOptional,
        id: idToSubmit,
      });
      setShowWarning(false);
    }
  };

  const onLessonSubmit = async (lessonTitle, lessonId, lessonType) => {
    // validate all entries -- lesson id must be unique
    if (lessonTitle === '' || lessonId === '' || lessonType === '') {
      Swal.fire(
        'Oops...',
        'One or more of the fields is empty, which is not allowed!',
        'error',
      );
      return;
    }
    if (allLessonIds.has(lessonId)) {
      Swal.fire(
        'Oops...',
        'There is already a lesson with this ID! Please pick another.',
        'error',
      );
      return;
    }
    if (!isValidId(lessonId)) {
      Swal.fire(
        'Oops...',
        'The Lesson ID you chose is invalid. Please use only lowercase letters, numbers and hyphens',
        'error',
      );
      return;
    }

    // add lesson to db + navigate to new lesson editor page
    await addLessonToDB(lessonTitle, lessonId, lessonType); // note: this fn does no error handling
    const lessonEditUrl = `/${courseId}/lessonEditor/${lessonId}`;
    return true;
  };

  const getSpecificForm = () => {
    switch (itemType) {
      case ROADMAP.LESSON.key:
      case ROADMAP.ASSIGNMENT.key:
        return (
          <>
            <Form.Group>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label={`Use a ${
                  isNewLessonOrAssn ? 'New' : 'Preexisting'
                } ${itemType}`}
                checked={isNewLessonOrAssn}
                onChange={() => {
                  setIsNewLessonOrAssn(!isNewLessonOrAssn);
                }}
              />
              {isNewLessonOrAssn ? (
                <div>
                  {itemType === ROADMAP.LESSON.key ? (
                    <AddLessonForm
                      onSubmit={async (lessonTitle, lessonId, lessonType) => {
                        const success = await onLessonSubmit(
                          lessonTitle,
                          lessonId,
                          lessonType,
                        );
                        if (!success) return;
                        setCompletionId(lessonId);
                        setUrlId(lessonId);
                        setIsNewLessonOrAssn(false);
                      }}
                    />
                  ) : (
                    <AssignmentCreationForm
                      courseId={courseId}
                      navigateToAssn={false}
                      onCompletionCallback={newAssnId => {
                        setCompletionId(newAssnId);
                        setUrlId(newAssnId);
                        setIsNewLessonOrAssn(false);
                      }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <select
                    placeholder="Select an option"
                    onChange={e => {
                      setCompletionId(idList[e.target.value].completionId);
                      setUrlId(
                        idList[e.target.value].urlId
                          ? idList[e.target.value].urlId
                          : idList[e.target.value].completionId,
                      );
                    }}
                    className="form-control"
                    defaultValue={''}
                    value={idList.findIndex(
                      item => item.completionId === completionId,
                    )}
                  >
                    <option value={''}>Select an assignment type</option>
                    {idList.map((item, index) => (
                      <option key={index} value={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </Form.Group>
          </>
        );
      case ROADMAP.COMPLETION_LINK.key:
        return (
          <Form.Group>
            <Form.Label>Self Check Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter url"
              defaultValue={urlId}
              onInput={e => setUrlId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
            <Form.Label>Self Check Id</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter id"
              defaultValue={completionId}
              onInput={e => setCompletionId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
          </Form.Group>
        );
      case ROADMAP.CREATE_ASSIGNMENT.key:
        return (
          <Form.Group>
            <select
              placeholder="Select an assignment type"
              onChange={e => {
                const selectionIndex = e.target.value;
                const assnType = assnTypes[selectionIndex];
                setCompletionId(`create-${assnType}`);
                // setUrlId()
                // setCompletionId(idList[e.target.value].completionId)
                // setUrlId(idList[e.target.value].urlId ? idList[e.target.value].urlId : idList[e.target.value].completionId)
              }}
              className="form-control"
            >
              {assnTypes.map((item, index) => (
                <option key={index} value={index}>
                  {item}
                </option>
              ))}
            </select>
          </Form.Group>
        );
      case ROADMAP.SECTION.key:
        return (
          <Form.Group>
            <Form.Label>Section Index (0 is the first week)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter id"
              defaultValue={completionId}
              onInput={e => setCompletionId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
          </Form.Group>
        );
      case ROADMAP.UNTIED_LINK.key:
        return (
          <Form.Group>
            <p className="alert alert-warning">
              This Link will not be completed on click. The Completion Id is
              only useful if you have built a custom extension that sets it.
            </p>
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter url"
              defaultValue={urlId}
              onInput={e => setUrlId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
            <Form.Label>Completion Id</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter id"
              defaultValue={completionId}
              onInput={e => setCompletionId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
          </Form.Group>
        );
      case ROADMAP.AUTO_CHECK.key:
        return (
          <Form.Group>
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter url"
              defaultValue={urlId}
              onInput={e => setUrlId(e.target.value)}
              onClick={e => e.stopPropagation()}
            />
          </Form.Group>
        );
    }
  };

  return (
    <Modal show={showModal} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {itemType}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              defaultValue={title}
              onInput={e => {
                e.stopPropagation();
                setTitle(e.target.value);
              }}
              onClick={e => e.stopPropagation()}
            />
          </Form.Group>
          <Form.Group>
            <div className="p-1">
              <label htmlFor="is-optional">
                Make Item Optional for Completion of the Module
                <input
                  className="ml-1"
                  id="is-optional"
                  type="checkbox"
                  checked={isOptional}
                  onChange={e => setIsOptional(!isOptional)}
                />
              </label>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>First Calendar Date</Form.Label>
            {startTiedToSection ? (
              <></>
            ) : (
              <div>
                <Form.Control
                  type="datetime-local"
                  placeholder="Release Data"
                  defaultValue={startDate}
                  onInput={e => {
                    e.stopPropagation();
                    setStartDate(e.target.value);
                  }}
                  onClick={e => e.stopPropagation()}
                />
                {startDate ? (
                  <></>
                ) : (
                  <p className="alert alert-warning" role="alert">
                    Start Date Not Set
                  </p>
                )}
              </div>
            )}
            {isModule ? (
              <></>
            ) : (
              <>
                <Form.Label>Tie First Date to Section Time</Form.Label>
                <input
                  type="checkbox"
                  id="tied-to-section-start"
                  checked={startTiedToSection}
                  onInput={e => {
                    e.stopPropagation();
                    setStartTiedToSection(!startTiedToSection);
                  }}
                />
              </>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>End Calendar Date</Form.Label>

            {endTiedToSection ? (
              <></>
            ) : (
              <div>
                <Form.Control
                  type="datetime-local"
                  placeholder="End Date"
                  defaultValue={endDate}
                  onChange={e => {
                    e.stopPropagation();
                    setEndDate(e.target.value);
                  }}
                  onClick={e => e.stopPropagation()}
                />
                {endDate ? (
                  <></>
                ) : (
                  <p className="alert alert-warning" role="alert">
                    End Date Not Set
                  </p>
                )}
              </div>
            )}
            {isModule ? (
              <></>
            ) : (
              <>
                <Form.Label>Tie End Date to Section Time</Form.Label>
                <input
                  type="checkbox"
                  id="tied-to-section-end"
                  checked={endTiedToSection}
                  onInput={e => {
                    e.stopPropagation();
                    setEndTiedToSection(!endTiedToSection);
                  }}
                />
              </>
            )}
          </Form.Group>
          <hr />
          {isModule ? <></> : getSpecificForm()}
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {showWarning ? (
          <div className="alert alert-danger" role="alert">
            {warningMessage}
          </div>
        ) : (
          <></>
        )}
        <Button
          variant="secondary"
          onClick={e => {
            e.stopPropagation();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={e => onFormSubmit(e)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
