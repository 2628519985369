import {collection, getFirestore} from 'firebase/firestore';
import {createContext, useEffect, useState} from 'react';
import 'firebase/compat/auth';
import {getAuth} from 'firebase/auth';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firebaseStringToRole} from './profileUtil';

const defaultData = {
  roles: undefined,
  isLoadingRoles: true,
  errorLoadingRoles: undefined,
};

export const EnrollmentContext = createContext(defaultData);

/**
 * Loads all the user roles (from their roles collection) and allows any subpage to
 * access them. This is very high in the component tree, even higher than course so
 * that any subpage can access roles (including ones such as /welcome).
 *
 * Make sure that isLoadingRoles is false before using roles.
 */

export const EnrollmentProvider = ({children}) => {
  const [userId, setUserId] = useState(null); // State to store the user's ID

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid); // Set userId if user is logged in
      } else {
        setUserId(null); // Reset userId if user is logged out
      }
    });

    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, []); // The empty array ensures this effect only runs once when the component mounts

  // Define the collection reference dynamically based on the userId
  // Note: We don't need to create a reference if userId is null
  const userRolesRef = userId
    ? collection(getFirestore(), `users/${userId}/roles`)
    : null;

  // Use the useCollection hook only if userRolesRef is not null
  // This prevents trying to fetch a collection when there is no user
  const [rolesCollection, isLoadingRoles, errorLoadingRoles] =
    useCollection(userRolesRef);

  const roles = rolesCollection
    ? rolesCollection.docs.reduce(
        (acc, doc) => ({
          ...acc,
          [doc.id]: firebaseStringToRole(doc.data().role), // Assuming 'role' is the field you want from the document
        }),
        {},
      )
    : {};

  return (
    <EnrollmentContext.Provider
      value={{
        roles,
        isLoadingRoles,
        errorLoadingRoles,
      }}
    >
      {children}
    </EnrollmentContext.Provider>
  );
};
