import {
  ConsoleUnitTest,
  ConsoleUnitTestDialogueEntry,
  getConsoleUnitTestReference,
  isConsoleUnitTest,
} from '../../models/unitTests/console';
import {deepCopy} from '@firebase/util';
import {useState, useEffect} from 'react';
import {FaTrash, FaPlus, FaEraser} from 'react-icons/fa';
import {setDoc} from 'firebase/firestore';
import {type LoadedConsoleUnitTestData} from './types';
import {WithEditorData} from '../UnitTestSchema';
import {useDebounce} from 'use-debounce';
import {Button} from 'react-bootstrap';
import {uuidv4} from 'lib0/random';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import Swal from 'sweetalert2';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from '../constants';

export const ConsoleEditor = ({
  loadedData,
  unitTestLoaderParameters,
  isEditable,
}: WithEditorData<LoadedConsoleUnitTestData>) => {
  const {unitTests: serverUnitTests} = loadedData ?? {unitTests: []};
  const {courseId, assignmentId} = unitTestLoaderParameters;
  const [unitTests, setUnitTests] = useState<ConsoleUnitTest[]>(
    serverUnitTests?.filter(isConsoleUnitTest) ?? [],
  );
  const [previouslyUploadedUnitTests, setPreviouslyUploadedUnitTests] =
    useState(unitTests);
  const [debouncedUnitTests] = useDebounce(
    unitTests,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const firebaseUnitTestDocumentReference = getConsoleUnitTestReference(
    courseId,
    assignmentId,
  );
  const [unitTestsDocument, unitTestsDocumentLoading, unitTestsDocumentError] =
    useDocumentDataOnce(firebaseUnitTestDocumentReference);
  function hasUnsavedChanged() {
    if (unitTestsDocumentLoading || unitTestsDocumentError) {
      return false;
    }
    if (previouslyUploadedUnitTests.length !== debouncedUnitTests.length) {
      return true;
    }
    return !debouncedUnitTests.every((unitTest, unitTestIdx) => {
      const previousUnitTest = previouslyUploadedUnitTests[unitTestIdx];
      return (
        unitTest.key === previousUnitTest.key &&
        unitTest.name === previousUnitTest.name &&
        unitTest.description === previousUnitTest.description &&
        unitTest.dialogue.length === previousUnitTest.dialogue.length &&
        unitTest.dialogue.every((dialogue, dialogueIdx) => {
          return (
            dialogue.output === previousUnitTest.dialogue[dialogueIdx].output &&
            dialogue.input === previousUnitTest.dialogue[dialogueIdx].input &&
            dialogue.type === previousUnitTest.dialogue[dialogueIdx].type
          );
        })
      );
    });
  }

  useEffect(() => {
    if (
      unitTestsDocumentLoading ||
      unitTestsDocumentError ||
      !unitTestsDocument
    ) {
      return;
    }
    setUnitTests(unitTestsDocument);
    setPreviouslyUploadedUnitTests(unitTestsDocument);
  }, [unitTestsDocumentLoading]);

  const shouldSave = hasUnsavedChanged();
  useEffect(() => {
    if (!shouldSave) {
      return;
    }
    setDoc(firebaseUnitTestDocumentReference, debouncedUnitTests)
      .then(() => {
        setPreviouslyUploadedUnitTests(debouncedUnitTests);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving unit tests',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving unit tests: ', error);
      });
  }, [shouldSave]);

  const deleteUnitTest = i => {
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests.splice(i, 1);
      return newUnitTests;
    });
  };

  const addUnitTest = () => {
    setUnitTests(oldUnitTests => {
      // since newUnitTests is a compound object
      // we use this way of updating state (otherwise view
      // wont react)
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests.push({
        description: '',
        name: `New Console Test`,
        key: uuidv4(),
        dialogue: [],
      });
      return newUnitTests;
    });
  };

  const setTestName = (i: number, name: string) => {
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests[i].name = name;
      return newUnitTests;
    });
  };

  function setTestDescription(i: number, description: string) {
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests[i].description = description;
      return newUnitTests;
    });
  }

  function setDialogueList(
    i: number,
    dialogue: ConsoleUnitTestDialogueEntry[],
  ) {
    setUnitTests(oldUnitTests => {
      const newUnitTests = deepCopy(oldUnitTests);
      newUnitTests[i].dialogue = dialogue;
      return newUnitTests;
    });
  }

  return (
    <div>
      <div className="d-flex flex-column mt-3">
        {unitTests.map(function (unitTestData, i) {
          return (
            <EditableConsoleUnitTest
              unitTestData={unitTestData}
              deleteUnitTest={() => deleteUnitTest(i)}
              setTestName={name => setTestName(i, name)}
              setTestDescription={description =>
                setTestDescription(i, description)
              }
              setDialogueList={dialogue => setDialogueList(i, dialogue)}
              key={unitTests[i].key ?? uuidv4()}
              isEditable={isEditable}
            />
          );
        })}
      </div>
      <button
        onClick={() => addUnitTest()}
        className="btn btn-secondary "
        disabled={!isEditable}
      >
        Add Unit Test
      </button>
    </div>
  );
};

interface EditableConsoleUnitTestProps {
  unitTestData: ConsoleUnitTest;
  setTestName: (name: string) => void;
  setTestDescription: (description: string) => void;
  setDialogueList: (dialogue: ConsoleUnitTestDialogueEntry[]) => void;
  deleteUnitTest: () => void;
  isEditable: boolean;
}

export const EditableConsoleUnitTest = ({
  unitTestData,
  setDialogueList,
  setTestName,
  setTestDescription,
  deleteUnitTest,
  isEditable,
}: EditableConsoleUnitTestProps) => {
  const {dialogue: dialogueList, name, description} = unitTestData;

  function addDialogue(isPrint: boolean) {
    const data = {
      output: '',
      input: '',
      type: isPrint ? ('print' as const) : ('input' as const),
    };
    const newDialogueList = deepCopy(unitTestData.dialogue);
    newDialogueList.push(data);
    setDialogueList(newDialogueList);
  }

  function removeDialogue(idx: number) {
    const newDialogueList = deepCopy(unitTestData.dialogue);
    newDialogueList.splice(idx, 1);
    setDialogueList(newDialogueList);
  }

  function updateDialogue<K extends keyof ConsoleUnitTestDialogueEntry>(
    idx: number,
    key: K,
    value: ConsoleUnitTestDialogueEntry[K],
  ) {
    if (idx < 0 || idx >= dialogueList.length) return;
    const newDialogueList = deepCopy(dialogueList);
    newDialogueList[idx][key] = value;
    setDialogueList(newDialogueList);
  }

  const dialogue = dialogueList.map((val, idx) => {
    if (val.type === 'print') {
      return (
        <PrintUnit
          output={val.output}
          setOutput={output => updateDialogue(idx, 'output', output)}
          key={idx}
          removeSelf={() => removeDialogue(idx)}
          isEditable={isEditable}
        />
      );
    } else {
      return (
        <InputUnit
          data={val}
          key={idx}
          removeSelf={() => removeDialogue(idx)}
          output={val.output}
          setOutput={output => updateDialogue(idx, 'output', output)}
          input={val.input}
          setInput={input => updateDialogue(idx, 'input', input)}
          isEditable={isEditable}
        />
      );
    }
  });

  return (
    <div className="border rounded p-2 mb-3">
      <input
        type={'text'}
        className="form-control mb-2"
        placeholder="Test Name"
        value={name}
        onChange={e => {
          setTestName(e.target.value);
        }}
        disabled={!isEditable}
      />
      <input
        type={'text'}
        className="form-control mb-2"
        placeholder="Test Description"
        value={description}
        onChange={e => {
          setTestDescription(e.target.value);
        }}
        disabled={!isEditable}
      />
      {dialogue}
      <Button
        className="mb-3"
        variant="light"
        onClick={() => addDialogue(true)}
        disabled={!isEditable}
      >
        <FaPlus /> Print Prompt
      </Button>
      <Button
        className="mb-3 ml-3"
        variant="light"
        onClick={() => addDialogue(false)}
        disabled={!isEditable}
      >
        <FaPlus /> Input Prompt
      </Button>
      <Button
        onClick={deleteUnitTest}
        className="mb-3 ml-3"
        variant="light"
        disabled={!isEditable}
      >
        <FaTrash />
      </Button>
    </div>
  );
};

interface PrintUnitProps {
  output: string;
  setOutput: (output: string) => void;
  removeSelf: () => void;
  isEditable: boolean;
}
const PrintUnit = ({
  output,
  setOutput,
  removeSelf,
  isEditable,
}: PrintUnitProps) => {
  return (
    <div className="d-flex mt-3">
      <strong style={{minWidth: '80px'}}>Print</strong>
      <input
        type={'text'}
        className="form-control mb-2"
        placeholder={`Place expected output here!`}
        value={output}
        onChange={e => {
          setOutput(e.target.value);
        }}
        disabled={!isEditable}
      />
      <Button
        className="mb-2"
        variant="light"
        onClick={removeSelf}
        disabled={!isEditable}
      >
        <FaEraser />
      </Button>
    </div>
  );
};

interface InputUnitProps {
  data: ConsoleUnitTestDialogueEntry;
  removeSelf: () => void;
  output: string;
  setOutput: (output: string) => void;
  input: string;
  setInput: (input: string) => void;
  isEditable: boolean;
}
const InputUnit = ({
  data,
  removeSelf,
  setOutput,
  setInput,
  isEditable,
}: InputUnitProps) => {
  const {output, input} = data;
  return (
    <div className="d-flex mt-3">
      <strong style={{minWidth: '80px'}}>Input</strong>
      <input
        type={'text'}
        className="form-control mb-2"
        placeholder="Place Prompt!"
        value={output ?? ''}
        onChange={e => {
          setOutput(e.target.value);
        }}
        disabled={!isEditable}
      />

      <input
        type={'text'}
        className="form-control mb-2"
        placeholder="Place input data!"
        value={input ?? ''}
        onChange={e => {
          setInput(e.target.value);
        }}
        disabled={!isEditable}
      />
      <Button
        className="mb-2"
        variant="light"
        onClick={removeSelf}
        disabled={!isEditable}
      >
        <FaEraser />
      </Button>
    </div>
  );
};
