import {useEffect, useState} from 'react';
import {setDoc} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {Accordion} from 'react-bootstrap';
import {
  AdvancedConsoleUnitTest,
  getAdvancedConsoleUnitTestReference,
  isAdvancedConsoleUnitTest,
} from '../../models/unitTests/advancedConsole';
import {uuidv4} from 'lib0/random';
import {type LoadedAdvancedConsoleUnitTestData} from './advancedConsoleUtils';
import {WithEditorData} from '../UnitTestSchema';
import {useDebounce} from 'use-debounce';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import {ExpandableCodeEditor} from 'components/editor/ExpandableCodeEditor';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from '../constants';

const starterCodeInput = `
def grade_input(output_history, autograder_state):
    try:
        if autograder_state["should_do_input"]:
            return "Some Input"

    except:
        autograde_error("Unknown error in input")
`;

const starterCodeOutput = `
import re

def grade_output(output_history, autograder_state):
    # Helper function to match a regular expression to a string
    # If Successful: Returns (True, matched_string: str, captured_vars: List[])
    # If Unsuccessful:
    # - Calls autograde_error if error_on_fail=True
    # - Returns (False, None, None) otherwise
    def match_regex(regex_string, match_string, error_on_fail=False):
        regex = re.compile(regex_string)
        matched = regex.match(match_string)

        if matched is None:
            if error_on_fail: autograde_error(f"Output \\"{match_string}\\" did not match what was expected.")
            
            return (False, None, None)
        
        return (True, matched[0], matched.groups())
        
    try:
        # Initialize problem state
        if "problem_state" not in autograder_state: autograder_state["problem_state"] = "start"

        # Grab the previous line of output
        cur_line = output_history[-1]    
        
        # Check lines of output and extract variables
        matched, match_string, captures = match_regex(r"This is the line we expect to see. We can also capture variables ([0-9]+)!", cur_line)

        # Check for errors
        if not matched or len(captures) < 1:
            # Raise an error with a message that will be shown to the student (exits the program)
            autograde_error("You had the incorrect output :(")
          
        # Add captured variable to autograder_state for later use
        autograder_state["my_var"] = int(captures[0])

        # Can register variables for random distribution checking
        register_random(int(captures[0]), "var1", "uniform", {"min": 10, "max": 99})

        # Advance to the next problem state
        autograder_state["problem_state"] = "next"

    except:
        autograde_error("Unknown error in output")
`;

const starterCodeFinish = `
def grade_finish(output_history, autograder_state):
    # Use this function to verify any state that should be true at the end of a run
    # This helps prevent issues like empty solutions passing

    # Here's an example!
    if autograder_state["problem_state"] != "finished" or autograder_state["counter"] < 5:
        autograde_error("Your program did not finish execution!")
`;

export const AdvancedConsoleEditor = ({
  loadedData,
  unitTestLoaderParameters,
  isEditable,
}: WithEditorData<LoadedAdvancedConsoleUnitTestData>) => {
  const {unitTests: serverUnitTests} = loadedData ?? {unitTests: []};
  const {courseId, assignmentId} = unitTestLoaderParameters;

  // A `branch` is basically one "test case" for the advanced autograder. Each branch has different grade_input/output scripts
  const serverBranches: AdvancedConsoleUnitTest[] = Array.isArray(
    serverUnitTests,
  )
    ? serverUnitTests.filter(isAdvancedConsoleUnitTest)
    : [];
  const [branches, setBranches] =
    useState<AdvancedConsoleUnitTest[]>(serverBranches);
  const [previouslyUploadedBranches, setPreviouslyUploadedBranches] =
    useState<AdvancedConsoleUnitTest[]>(serverBranches);
  function areBranchesEqual(
    branches1: AdvancedConsoleUnitTest[],
    branches2: AdvancedConsoleUnitTest[],
  ) {
    return (
      branches1.length === branches2.length &&
      branches1.every((branch, index) => {
        const branch2 = branches2[index];
        return (
          branch.key === branch2.key &&
          branch.name === branch2.name &&
          branch.gradingScripts?.onInput === branch2.gradingScripts?.onInput &&
          branch.gradingScripts?.onOutput ===
            branch2.gradingScripts?.onOutput &&
          branch.gradingScripts?.onFinish === branch2.gradingScripts?.onFinish
        );
      })
    );
  }
  const [debouncedBranches] = useDebounce(
    branches,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
    {
      equalityFn: areBranchesEqual,
    },
  );
  const firebaseUnitTestDocumentReference = getAdvancedConsoleUnitTestReference(
    courseId,
    assignmentId,
  );
  const [
    advancedConsoleUnitTestDocument,
    advancedConsoleUnitTestDocumentLoading,
    advancedConsoleUnitTestDocumentError,
  ] = useDocumentDataOnce(firebaseUnitTestDocumentReference);

  const shouldSave =
    !advancedConsoleUnitTestDocumentLoading &&
    !advancedConsoleUnitTestDocumentError &&
    !areBranchesEqual(debouncedBranches, previouslyUploadedBranches);

  useEffect(() => {
    if (
      advancedConsoleUnitTestDocumentLoading ||
      advancedConsoleUnitTestDocumentError ||
      !advancedConsoleUnitTestDocument
    ) {
      return;
    }
    setBranches(advancedConsoleUnitTestDocument ?? []);
    setPreviouslyUploadedBranches(advancedConsoleUnitTestDocument ?? []);
  }, [advancedConsoleUnitTestDocumentLoading]);
  useEffect(() => {
    if (!shouldSave) return;
    setDoc(firebaseUnitTestDocumentReference, debouncedBranches)
      .then(() => {
        setPreviouslyUploadedBranches(debouncedBranches);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving advanced console unit tests',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving advanced console unit tests: ', error);
      });
  }, [shouldSave]);

  const addAdvancedBranch = () => {
    setBranches([
      ...branches,
      {
        name: 'New Branch',
        key: uuidv4(),
        gradingScripts: {
          onInput: starterCodeInput,
          onOutput: starterCodeOutput,
          onFinish: starterCodeFinish,
        },
      },
    ]);
  };

  const editAdvancedBranch = (i, newBranch) => {
    setBranches(branches.map((b, idx) => (idx === i ? newBranch : b)));
  };

  const deleteAdvancedBranch = i => {
    setBranches(branches.filter((b, idx) => idx !== i));
  };

  return (
    <div>
      <h4>Advanced Autograder</h4>
      <div className="mt-4">
        <h5>Custom Script Editor</h5>
        <p>Each script is used to parse the input and output line by line.</p>
        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Random Autograder Documentation</Accordion.Header>
            <Accordion.Body>
              <div className="text-xl">
                <code>grade_input(output_history, autograder_state)</code> runs
                every time the student's code calls <code>input</code>.
                <br />
                <code>grade_output(output_history, autograder_state)</code> runs
                every time the student's code calls <code>print</code>.
                <br />
                <br />
                Both functions have access to the following functions and
                variables:
                <ul>
                  <li>
                    <code>output_history</code>: List of previous stdouts. The
                    latest output (the one that called <code>grade_output</code>
                    ) will be at <code> output_history[-1]</code>.
                  </li>
                  <li>
                    <code>autograder_state</code>: Dictionary to store any data
                    you need to track during the autograder's execution.
                    <ul>
                      <li>
                        Persists across all <code>grade_output</code> and{' '}
                        <code>grade_input</code> calls.
                      </li>
                      <li>
                        Common uses are to manage a "problem state" or store
                        relevant variables captured from the output.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <code>autograde_error(message)</code>: Call under any
                    condition that qualifies as "failing". <code>message</code>{' '}
                    will appear in the student's test view.
                  </li>
                  <li>
                    <code>
                      register_random(rv, var_name, dist=None, params=None,
                      tolerance=0.0001)
                    </code>
                    : Adds a value to the list observations for a particular
                    random variable we'd like the autograder to verify the
                    distribution of.
                    <ul>
                      <li>
                        <code>rv</code> - the value just observed
                      </li>
                      <li>
                        <code>var_name</code> - a name we assign the variable to
                        add more observations to it later
                      </li>
                      <li>
                        <code>dist</code> - the type/distribution of the RV.
                        Supported values are:{' '}
                        <code>"uniform", "geometric", "binomial"</code>
                      </li>
                      <li>
                        <code>params</code> - dict of parameters defining the
                        distribution.
                        <br />
                        Uniform:{' '}
                        <code>{`{"min": <min value>, "max": <max value>, "tolerance": <max probability of false negative; Start with 0.0001, larger = faster test}`}</code>
                        <br />
                        Geometric:{' '}
                        <code>{`{"p": <probability defining the distribution>, "epsilon": <guaranteed max distance between the predicted p and actual p; Start with 0.025, larger = faster test>}`}</code>
                        <br />
                        Binomial:{' '}
                        <code>{`{"p": <probability defining the distribution>, "epsilon": <guaranteed max distance between the predicted p and actual p; Start with 0.025, larger = faster test>}`}</code>
                      </li>
                    </ul>
                    The first 4 arguments must be specified on the first call
                    for a given random variable (<code>var_name</code>).
                    Subsequent calls require only the first two.
                  </li>
                </ul>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {branches.map((b, i) => (
          <AdvancedConsoleCustomScript
            key={i}
            branch={b}
            editAdvancedBranch={newBranch => editAdvancedBranch(i, newBranch)}
            deleteAdvancedBranch={() => deleteAdvancedBranch(i)}
            isEditable={isEditable}
          />
        ))}
        <button
          className="btn btn-secondary my-2 mr-2"
          onClick={addAdvancedBranch}
          disabled={!isEditable}
        >
          Add New Test
        </button>
      </div>
    </div>
  );
};

interface AdvancedConsoleCustomScriptProps {
  branch: AdvancedConsoleUnitTest;
  editAdvancedBranch: (branch: AdvancedConsoleUnitTest) => void;
  deleteAdvancedBranch: () => void;
  isEditable: boolean;
}

export const AdvancedConsoleCustomScript = ({
  branch,
  editAdvancedBranch,
  deleteAdvancedBranch,
  isEditable,
}: AdvancedConsoleCustomScriptProps) => {
  const editName = name => {
    editAdvancedBranch({
      ...branch,
      name,
    });
  };

  const updateCode = (newCode, script) => {
    editAdvancedBranch({
      ...branch,
      gradingScripts: {
        ...branch.gradingScripts,
        [script]: newCode,
      },
    });
  };

  return (
    <div className="border rounded p-2 mb-3 gap-2">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="form-group d-flex flex-row align-items-center">
          <h3 className="mr-2">Name:</h3>
          <input
            type="text"
            className="form-control"
            value={branch.name}
            onChange={e => editName(e.target.value)}
            disabled={!isEditable}
          />
        </div>
        <button
          className="btn btn-danger"
          style={{height: 'fit-content'}}
          onClick={() => deleteAdvancedBranch()}
          disabled={!isEditable}
        >
          X
        </button>
      </div>
      <b className="mt-2">onInput Script</b>
      <div>
        <ExpandableCodeEditor
          mode="python"
          value={branch.gradingScripts.onInput}
          onChange={e => updateCode(e, 'onInput')}
          readOnly={!isEditable}
        />
      </div>
      <b className="mt-2">onOutput Script</b>
      <OutputOuter>
        <ExpandableCodeEditor
          mode="python"
          value={branch.gradingScripts.onOutput}
          onChange={e => updateCode(e, 'onOutput')}
          readOnly={!isEditable}
        />
      </OutputOuter>
      <b className="mt-2">onFinish Script</b>
      <OutputOuter>
        <ExpandableCodeEditor
          mode="python"
          value={branch.gradingScripts.onFinish}
          onChange={e => updateCode(e, 'onFinish')}
          readOnly={!isEditable}
        />
      </OutputOuter>
    </div>
  );
};

const OutputOuter = styled.div``;
