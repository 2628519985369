export interface UnitTestResults {
  /** Whether or not the provided tests were actually run, used to signal cases
   *  where the autograder fails for some reason.
   */
  testsRun: boolean;
  testResults: Array<{
    /** Was the test passed. */
    isSuccess: boolean;
    usedAi: boolean;
    rawResult: {
      /** The message to display to the user as a result of the test. */
      message?: string;
      /** The error message(s) to display to the user as a result of the test.
       * If the error is an array, it will be displayed as a list.
       */
      error?: string | any[];
    } & Record<string, any>;
    name: string;
  }>;
}

export const EMPTY_TEST_RESULT: UnitTestResults = {
  testsRun: false, // Assuming default is false since no tests have been run
  testResults: [], // Empty array since no tests have been run
};

export function allTestsPassed(unitTestResults: UnitTestResults): boolean {
  return (
    unitTestResults.testsRun &&
    unitTestResults.testResults.every(result => result.isSuccess)
  );
}

export function getFirstTestFailed(unitTestResults: UnitTestResults): string {
  return unitTestResults.testResults.find(result => !result.isSuccess)?.name;
}

export function firstTestHasError(unitTestResults: UnitTestResults): boolean {
  const firstTest = unitTestResults.testResults[0];
  if (!firstTest) {
    return false;
  }
  const rawError = firstTest.rawResult?.error;
  const hasError = rawError && rawError.length > 0;
  return hasError;
}

export function createErrorTestResult(errorMsg: string): UnitTestResults {
  return {
    testsRun: true,
    testResults: [
      {
        isSuccess: false,
        usedAi: false,
        rawResult: {error: errorMsg},
        name: 'Error',
      },
    ],
  };
}
