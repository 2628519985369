import {DeveloperToolsBadge} from 'components/developerTools/DeveloperToolsBadge';
import {createContext, ReactNode, useContext} from 'react';
import {isDevelopmentEnvironment} from 'utils/general';
interface DeveloperToolsContextType {}
const DeveloperToolsContext = createContext<DeveloperToolsContextType>({});

/**
 * Provides access to developer tools when in a development environment. In
 * production, this component will render its children without any additional
 * functionality.
 */
export const DeveloperToolsProvider = ({children}: {children: ReactNode}) => {
  if (isDevelopmentEnvironment()) {
    return (
      <DeveloperToolsContext.Provider value={{}}>
        {children}
        <DeveloperToolsBadge />
      </DeveloperToolsContext.Provider>
    );
  }
  return <>{children}</>;
};

export const useDeveloperToolsContext = (): DeveloperToolsContextType => {
  return useContext(DeveloperToolsContext);
};
