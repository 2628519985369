import {
  getDoc,
  getDocs,
  getFirestore,
  runTransaction,
  setDoc,
} from 'firebase/firestore';
import {
  getCourseTranslationDocument,
  getTranslationCollection,
  getTranslationDocument,
  useTranslateAllMessages,
} from '../firebase/models/translation';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {useCollection, useDocumentData} from 'react-firebase-hooks/firestore';
import {useParams} from 'react-router';
import {CourseId} from 'types/common';
import {
  LANGUAGE_CODE_TO_DIRECTION,
  LanguageCode,
  NamespaceId,
  TranslationFunction,
  UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
} from '../components/translation/types';
import {ProfileContext, ProfileContextData} from './ProfileContext';

export interface UseNamespaceTranslationsResult {
  /** The translations for the given namespace. */
  namespaceTranslations: Map<string, string>;
  /** Whether the Firebase translation document is currently loading. */
  namespaceTranslationsLoading: boolean;
  /** An error, if one occurred, in loading the Firebase data, otherwise
   * `undefined`.
   */
  namespaceTranslationsError: Error;
  /** Updates the translations for the given namespace. Behaves similarly to
   * the setter returned by `useState`, but notably is asynchronous and will
   * update the translations in the database.
   * @param newNamespaceTranslations - Either a new set of translations or a
   * function that takes the current set of translations and returns a new one
   * (similar to the setter returned by `useState`).
   * @remarks
   * WARNING! Be careful when using this function, as it will update the
   * translations in the database. Any keys that are not in the provided map
   * will be deleted, so be sure to include unchanged keys in the new map.
   */
  updateNamespaceTranslations: (
    newNamespaceTranslations: SetStateAction<Map<string, string>>,
  ) => Promise<void>;
}

export interface UseSupportedLanguagesResult {
  /** The set of supported languages for a specified course. */
  supportedLanguages: LanguageCode[];
  /** True if the Firebase document storing the supported languages is loading.
   * */
  supportedLanguagesLoading: boolean;
  /** An error, if one occurred, in loading the Firebase data, otherwise
   * `undefined`.
   */
  supportedLanguagesError: Error;
  /**
   * Updates The set of supported languages for a specified course. Behaves
   * similarly to the setter returned by `useState`, but notably is asynchronous
   * and will update the supported languages in the database.
   * @param newSupportedLanguages - Either a new set of supported languages or a
   * function that takes the current set of supported languages and returns a
   * new one (similar to the setter returned by `useState`).
   * @remarks
   * WARNING! Be careful when using this function, as it will update the
   * supported languages in the database. Any languages that are not in the
   * provided set will be "deleted" (removed from the supported set but the
   * underlying translation documents will remain intact), so be sure to include
   * unchanged languages in the update.
   */
  updateSupportedLanguages: (
    newSupportedLanguages: SetStateAction<LanguageCode[]>,
  ) => Promise<void>;
}

/** Options for the `useTranslateFunction` hook. */
interface UseTranslateFunctionOptions {
  /** Whether to force the translation to be a string. */
  forceString?: boolean;
}

/**
 * The context data provided by the `TranslationProvider`. This context provides
 * hooks to access and update translations for a given course and language.
 */
export interface TranslationContextData {
  /** Builds a translate function for a given namespace. A "namespace" is a
   * collection of translations for a specific part of the application (e.g.
   * `"landing"` for the landing page, `"common"` for common translations). In
   * our database, a namespace is represented as a
   * {@link src.firebase.models.translation_namespace_document.TranslationNamespaceDocument | `TranslationNamespaceDocument`}.
   *
   * NOTE: While the input and output of the translation function are both
   * {@link ReactNode | ReactNode}, the translation function can currently only
   * accept a string as input and will only return a string as output.
   *
   * TODO: #1006 - Allow the translation function to accept a set of nested
   * JSX elements as input to allow for more complex translations, e.g. inline
   * links and spans.
   *
   * TODO: #1011 - Allow the translation function to return an interactable
   * component for inline translation editing.
   *
   * @example
   * ```typescript
   * const {useTranslateFunction} = useTranslationContext();
   * const t = useTranslateFunction("landing");
   * const translatedText = t("Welcome to Code in Place!");
   * ```
   *
   * @example
   * ```typescript
   * const {useTranslateFunction} = useTranslationContext();
   * const tString = useTranslateFunction("landing", { forceString: true });
   * // The title of a doc
   * document.title = tString("Welcome to Code in Place!") as string;
   * ```
   */
  useTranslateFunction: (
    namespace: NamespaceId,
    options?: UseTranslateFunctionOptions,
  ) => TranslationFunction;
  /** The current language code in use. */
  siteLanguage: LanguageCode;
  /** The set of supported languages for a specified course (or the user's
   * current course if not provided). Additionally, provides the ability to
   * update the supported languages.
   */
  useSupportedLanguages: (courseId?: CourseId) => UseSupportedLanguagesResult;
  /** The set of valid namespaces for the user's current course and language.
   * Optionally, can choose a different language. Additionally, provides the
   * ability to update the set of valid namespaces.
   */
  useSupportedNamespaces: (
    courseId: CourseId,
    language: LanguageCode,
  ) => UseSupportedNamespacesResult;
  /** Sets the language code used for translation. */
  setSiteLanguage: (language: LanguageCode) => void;
}
/** The baseline language code for the application. All other languages use this
 * as a fallback.
 */
export const DEFAULT_LANGUAGE_CODE: LanguageCode = 'en';
// If a namespace is not found, search for translations in this namespace.
export const FALLBACK_NAMESPACE: NamespaceId = 'common';
// If a course ID is not found (e.g. if you're unauthenticated), use the public
// course.
const FALLBACK_COURSE_ID: CourseId = 'public';
const DEFAULT_CONTEXT_DATA: TranslationContextData = {
  useTranslateFunction: () => () => '',
  siteLanguage: DEFAULT_LANGUAGE_CODE,
  useSupportedLanguages: () => null,
  useSupportedNamespaces: () => null,
  setSiteLanguage: () => {},
};

const TranslationContext =
  createContext<TranslationContextData>(DEFAULT_CONTEXT_DATA);

/**
 * Returns the course ID from the URL params, or a fallback value if not found.
 * TODO: #986 - Remove this function and use `useCourseId` instead.
 */
export function useCurrentCourseIdWithFallback(): CourseId {
  const {courseId} = useParams();
  return courseId ?? FALLBACK_COURSE_ID;
}

/** Adds a namespace to the set of namespaces supported for a course for a given
 * language. If the namespace already exists, this function does nothing.
 */
export async function addNamespace(
  courseId: CourseId,
  language: LanguageCode,
  namespace: NamespaceId,
) {
  const namespaceTranslationsRef = getTranslationDocument(
    courseId,
    language,
    namespace,
  );
  const namespaceTranslationsSnapshot = await getDoc(namespaceTranslationsRef);
  if (namespaceTranslationsSnapshot.exists()) {
    return;
  }
  await setDoc(namespaceTranslationsRef, new Map());
}

// Adds a supported language to the set of languages supported for a course.
// If the language is already supported, this function does nothing.
// If the language is not supported, this function creates a new translation
// document for the language for each namespace.
async function addSupportedLanguageToFirebase(
  courseId: CourseId,
  language: LanguageCode,
) {
  try {
    const courseTranslationSnapshot = await getDoc(
      getCourseTranslationDocument(courseId),
    );
    if (!courseTranslationSnapshot.exists()) {
      return;
    }
    const supportedLanguages =
      courseTranslationSnapshot.data().supportedLanguages;
    if (supportedLanguages.includes(language)) {
      return;
    }
    // For the default language, add only the fallback namespace.
    if (language === DEFAULT_LANGUAGE_CODE) {
      await addNamespace(courseId, language, FALLBACK_NAMESPACE);
      await setDoc(getCourseTranslationDocument(courseId), {
        supportedLanguages: [...supportedLanguages, language],
      });
      return;
    }
    const courseDefaultLanguageNamespacesDocumentRefs = await getDocs(
      getTranslationCollection(courseId, DEFAULT_LANGUAGE_CODE),
    );
    // If the course already has a translation document for the default
    // language, use the existing namespaces.
    if (!courseDefaultLanguageNamespacesDocumentRefs.empty) {
      for (const namespaceSnapshot of courseDefaultLanguageNamespacesDocumentRefs.docs) {
        await addNamespace(courseId, language, namespaceSnapshot.id);
      }
      await setDoc(getCourseTranslationDocument(courseId), {
        supportedLanguages: [...supportedLanguages, language],
      });
      return;
    }
    // Otherwise, copy all namespaces present in the unauthenticated base
    // language course.
    const defaultLanguageNamespaceSnapshots = await getDocs(
      getTranslationCollection(FALLBACK_COURSE_ID, DEFAULT_LANGUAGE_CODE),
    );
    const namespaces = defaultLanguageNamespaceSnapshots.docs.map(
      doc => doc.id,
    );
    for (const namespace of namespaces) {
      await addNamespace(courseId, language, namespace);
    }
    await setDoc(getCourseTranslationDocument(courseId), {
      supportedLanguages: Array.from(
        new Set([...supportedLanguages, language]),
      ),
    });
  } catch (error) {
    console.error(`Error adding language ${language} to course ${courseId}.`);
    throw error;
  }
}

interface UseSupportedNamespacesResult {
  supportedNamespaces: NamespaceId[];
  supportedNamespacesLoading: boolean;
  supportedNamespacesError: Error;
}
function useSupportedNamespaces(
  courseId: CourseId,
  language: LanguageCode,
): UseSupportedNamespacesResult {
  const [translationDocs, translationDocsLoading, translationDocsError] =
    useCollection(getTranslationCollection(courseId, language));
  if (translationDocsLoading || translationDocsError) {
    return {
      supportedNamespaces: [],
      supportedNamespacesLoading: translationDocsLoading,
      supportedNamespacesError: translationDocsError,
    };
  }
  return {
    supportedNamespaces: translationDocs.docs.map(doc => doc.id),
    supportedNamespacesLoading: translationDocsLoading,
    supportedNamespacesError: translationDocsError,
  };
}

/**
 * Extracts the translation data for a given namespace and language.
 * If this data does not exist, an empty map is returned. Also provides the
 * ability to update the translations for the given namespace.
 */
export function useNamespaceTranslations(
  courseId: CourseId,
  language: LanguageCode,
  namespace: NamespaceId,
): UseNamespaceTranslationsResult {
  const namespaceFirebaseDocumentRef = getTranslationDocument(
    courseId,
    language,
    namespace,
  );
  const [
    namespaceFirebaseDocument,
    namespaceFirebaseLoading,
    namespaceFirebaseError,
  ] = useDocumentData(namespaceFirebaseDocumentRef);
  const currentNamespaceTranslations =
    namespaceFirebaseDocument ?? new Map<string, string>();
  const updateNamespaceTranslations = async (
    newNamespaceTranslations: SetStateAction<Map<string, string>>,
  ) => {
    if (newNamespaceTranslations instanceof Map) {
      await setDoc(namespaceFirebaseDocumentRef, newNamespaceTranslations);
      return;
    }
    // Otherwise, we need to use a transaction to update the translations, as
    // there may be contention on the document produced by several concurrent
    // updates (e.g. when loading a never seen namespace or page for the first
    // time). We cannot use the data from `useDocumentData` because it may be
    // stale.
    try {
      await runTransaction(getFirestore(), async transaction => {
        const currentNamespaceTranslations = (
          await transaction.get(namespaceFirebaseDocumentRef)
        ).data();
        const updatedNamespaceTranslations = newNamespaceTranslations(
          currentNamespaceTranslations,
        );
        transaction.set(
          namespaceFirebaseDocumentRef,
          updatedNamespaceTranslations,
        );
      });
    } catch (error) {
      console.error(
        `Failed to update translations for namespace: ${namespace} for language: ${language} for course: ${courseId}.`,
      );
      throw error;
    }
  };
  return {
    namespaceTranslations: currentNamespaceTranslations,
    namespaceTranslationsLoading: namespaceFirebaseLoading,
    namespaceTranslationsError: namespaceFirebaseError,
    updateNamespaceTranslations,
  };
}

/**
 * Adds a course translation document to the database.
 * If the document already exists, this function does nothing. Otherwise, it
 * creates a new document and adds default English support. This function should
 * be called when a new course is created.
 * @param courseId The course ID to add a translation document for.
 */
export async function addCourseTranslationDocument(courseId: CourseId) {
  const courseTranslationRef = getCourseTranslationDocument(courseId);
  const courseTranslationSnapshot = await getDoc(courseTranslationRef);
  if (courseTranslationSnapshot.exists()) {
    return;
  }
  await setDoc(courseTranslationRef, {
    supportedLanguages: [],
  });
  await addSupportedLanguageToFirebase(courseId, DEFAULT_LANGUAGE_CODE);
}

// Gets the set of supported languages for a course from the database.
function useSupportedLanguages(
  courseId?: CourseId,
): UseSupportedLanguagesResult {
  courseId = courseId ?? useCurrentCourseIdWithFallback();
  const [
    courseTranslationData,
    courseTranslationDataLoading,
    courseTranslationDataError,
  ] = useDocumentData(getCourseTranslationDocument(courseId));
  const supportedLanguages =
    courseTranslationDataLoading ||
    courseTranslationDataError ||
    !courseTranslationData
      ? [DEFAULT_LANGUAGE_CODE]
      : courseTranslationData?.supportedLanguages?.length > 0
        ? courseTranslationData?.supportedLanguages
        : [DEFAULT_LANGUAGE_CODE];
  const [supportedLanguagesError, setSupportedLanguagesError] =
    useState<Error>();
  const updateSupportedLanguages = async (
    newSupportedLanguages: SetStateAction<LanguageCode[]>,
  ) => {
    if (!courseTranslationData) {
      return;
    }
    newSupportedLanguages =
      typeof newSupportedLanguages === 'function'
        ? newSupportedLanguages(courseTranslationData?.supportedLanguages)
        : newSupportedLanguages;
    // Ensure that the new supported languages are unique.
    newSupportedLanguages = Array.from(new Set(newSupportedLanguages));
    const languagesAdded = newSupportedLanguages.filter(
      language => !supportedLanguages.includes(language),
    );
    try {
      for (const language of languagesAdded) {
        await addSupportedLanguageToFirebase(courseId, language);
      }
      const courseTranslationRef = getCourseTranslationDocument(courseId);
      await setDoc(courseTranslationRef, {
        supportedLanguages: newSupportedLanguages,
      });
    } catch (error) {
      console.error('Error updating supported languages:', error);
      setSupportedLanguagesError(error);
    }
  };

  useEffect(() => {
    if (courseTranslationDataError) {
      setSupportedLanguagesError(courseTranslationDataError);
    }
  }, [courseTranslationDataLoading]);

  return {
    supportedLanguages,
    supportedLanguagesLoading: courseTranslationDataLoading,
    supportedLanguagesError,
    updateSupportedLanguages,
  };
}

function deepCopyNestedMap(data: Map<any, any>): Map<any, any> {
  return new Map(
    Array.from(data.entries()).map(([key, value]) => [
      key,
      value instanceof Map ? deepCopyNestedMap(value) : value,
    ]),
  );
}

// The key in `localStorage` that stores the unknown translation keys seen on
// the current page.
const UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY = 'cip__unknownTranslationKeys';
// The maximum number of keys to try and translate in a single batch.
const TRANSLATION_BATCH_SIZE = 20;
interface UseTranslationUploaderResult {
  /** True if the translations are currently being uploaded. */
  translationsUploading: boolean;
  /** True if any translations have ever been uploaded, false otherwise. */
  hasUploadedTranslations: boolean;
  /** Marks a translation key as needing upload to the backend.
   * @param courseId - The course ID to mark the key for.
   * @param namespace - The namespace to mark the key for.
   * @param key - The key to mark.
   */
  addUnknownTranslationKeyToUploadCache: (
    courseId: CourseId,
    namespace: NamespaceId,
    key: string,
  ) => void;
  /** Uploads the unknown translation keys to the backend. */
  uploadUnknownTranslationKeys: () => Promise<void>;
  /** Clears the local cache (i.e. `localStorage`) of unknown translation keys.
   */
  clearLocalUnknownTranslationKeys: () => void;
  /** Uploads translations to the backend.
   * @param keysToTranslate - A map from course ID to a map from namespace to
   * a list of keys to translate.
   * @param targetLanguages - The languages to translate the keys to.
   * @param setTranslationsUploading - A function to set the state of the
   * translations uploader.
   * @param onNamespaceUploaded - A callback for when a namespace is uploaded.
   * @param onAllTranslationsUploaded - A callback for when all translations
   * are uploaded.
   */
  writeTranslationsToFirebase: (
    keysToTranslate: Record<CourseId, Record<NamespaceId, string[]>>,
    targetLanguages: LanguageCode[],
    setTranslationsUploading: Dispatch<SetStateAction<boolean>>,
    onNamespaceUploaded?: (
      courseId: CourseId,
      namespace: NamespaceId,
      keys: string[],
    ) => void,
    onAllTranslationsUploaded?: () => void,
  ) => Promise<void>;
}

/**
 * Manages the uploading of unknown translation keys to the backend. Exposes
 * functions for adding keys to the unknown key cache and uploading arbitrary
 * keys (with translations) to the backend.
 *
 * @description Whenever our `t` translate function is called, we store any keys
 * not available from the loaded translation data in a local unknown translation
 * key cache. This cache is stored in `localStorage` as a map from course ID to
 * a map from namespace to a map from key to a boolean indicating whether the
 * key has been uploaded. `localStorage` is used as an "escape hatch" from
 * React's state management system, because `t` cannot produce stateful changes
 * to our unknown key cache on render as it is not a hook. Separately, we have
 * and updates their state so they aren't uploaded twice. Since unknown keys are
 * only added when not present in the cache and the uploader only mutates keys
 * that are present in the cache without removing them, we do not need to worry
 * about races between the `t` function and the uploader.
 * @returns {@inheritdoc UseTranslationUploaderResult}
 */
export function useTranslationUploader(): UseTranslationUploaderResult {
  const [translateAllMessages] = useTranslateAllMessages();
  const [translationsUploading, setTranslationsUploading] = useState(false);
  const [hasUploadedTranslations, setHasUploadedTranslations] = useState(false);

  async function writeTranslationsToFirebase(
    keysToTranslate: Record<CourseId, Record<NamespaceId, string[]>>,
    targetLanguages: LanguageCode[],
    setTranslationsUploading: Dispatch<SetStateAction<boolean>>,
    onNamespaceUploaded?: (
      courseId: CourseId,
      namespace: NamespaceId,
      keys: string[],
    ) => void,
    onAllTranslationsUploaded?: () => void,
  ) {
    if (Object.keys(keysToTranslate).length === 0) {
      return;
    }
    setTranslationsUploading(true);
    setHasUploadedTranslations(true);
    for (const [courseId, namespaces] of Object.entries(keysToTranslate)) {
      const courseTranslationDocumentReference =
        getCourseTranslationDocument(courseId);
      const courseTranslationDocumentSnapshot = await getDoc(
        courseTranslationDocumentReference,
      );
      // Determine (or create) the set of supported languages for the course.
      const supportedLanguages = courseTranslationDocumentSnapshot.data()
        ?.supportedLanguages ?? [DEFAULT_LANGUAGE_CODE];
      if (!courseTranslationDocumentSnapshot.exists()) {
        await setDoc(courseTranslationDocumentReference, {
          supportedLanguages: Array.from(
            new Set([...supportedLanguages, ...targetLanguages]),
          ),
        });
      }
      for (const [namespace, keys] of Object.entries(namespaces)) {
        for (const language of targetLanguages) {
          const namespaceTranslationsReference = getTranslationDocument(
            courseId,
            language,
            namespace,
          );
          if (language === DEFAULT_LANGUAGE_CODE) {
            try {
              await runTransaction(getFirestore(), async transaction => {
                const namespaceTranslationsSnapshot = await transaction.get(
                  namespaceTranslationsReference,
                );
                const firebaseNamespaceTranslations =
                  namespaceTranslationsSnapshot.data() ??
                  new Map<string, string>();
                const mergedTranslations = new Map<string, string>([
                  ...keys.map(key => [key, key] as const),
                  ...Array.from(firebaseNamespaceTranslations.entries()),
                ]);
                transaction.set(
                  namespaceTranslationsReference,
                  mergedTranslations,
                );
              });
            } catch (error) {
              console.error('Failed to update translations for namespace:', {
                courseId,
                namespace,
                language,
              });
            }
            continue;
          }
          // Split the keys into batches of size `TRANSLATION_BATCH_SIZE` to
          // avoid timing out our translation function.
          const keyBatches = keys.reduce<string[][]>((acc, key, index) => {
            const batchIndex = Math.floor(index / TRANSLATION_BATCH_SIZE);
            if (!acc[batchIndex]) acc[batchIndex] = [];
            acc[batchIndex].push(key);
            return acc;
          }, []);
          // Translate each batch, filtering out any batches that fail to
          // translate and merge the results into one map.
          const newNamespaceTranslations = (
            await Promise.all(
              keyBatches.map(async batch => {
                const translations = (
                  await translateAllMessages({
                    messages: batch,
                    targetLanguage: language,
                  })
                )?.data;
                if (!translations) {
                  console.error('Failed to translate messages:', {
                    batch,
                    language,
                    namespace,
                  });
                  return null;
                }
                return new Map<string, string>(Object.entries(translations));
              }),
            )
          )
            .filter(batch => !!batch)
            .reduce(
              (acc, curr) =>
                new Map<string, string>([
                  ...Array.from(acc.entries()),
                  ...Array.from(curr.entries()),
                ]),
              new Map<string, string>(),
            );
          try {
            await runTransaction(getFirestore(), async transaction => {
              const namespaceTranslationsSnapshot = await transaction.get(
                namespaceTranslationsReference,
              );
              const firebaseNamespaceTranslations =
                namespaceTranslationsSnapshot.data() ??
                new Map<string, string>();
              // Existing translations take precedence.
              const updatedNamespaceTranslations = new Map<string, string>([
                ...Array.from(newNamespaceTranslations.entries()),
                ...Array.from(firebaseNamespaceTranslations.entries()),
              ]);
              transaction.set(
                namespaceTranslationsReference,
                updatedNamespaceTranslations,
              );
            });
          } catch (error) {
            console.error('Failed to update translations for namespace:', {
              courseId,
              namespace,
              language,
            });
          }
        }
        onNamespaceUploaded?.(courseId, namespace, keys);
      }
    }
    onAllTranslationsUploaded?.();
    setTranslationsUploading(false);
  }

  // Adds a translation key to the cache of unknown translation keys to upload.
  // Here our "upload cache" is a map from course ID to a map from namespace to
  // a map from key to whether the key has already been uploaded.
  function addUnknownTranslationKeyToUploadCache(
    courseId: CourseId,
    namespace: NamespaceId,
    key: string,
  ): void {
    if (
      !window.localStorage.getItem(UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY)
    ) {
      window.localStorage.setItem(
        UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY,
        JSON.stringify({}),
      );
    }
    const unknownTranslationKeys: Record<
      CourseId,
      Record<NamespaceId, Record<string, boolean>>
    > = JSON.parse(
      window.localStorage.getItem(UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY),
    );
    if (!unknownTranslationKeys[courseId]) {
      unknownTranslationKeys[courseId] = {};
    }
    if (!unknownTranslationKeys[courseId][namespace]) {
      unknownTranslationKeys[courseId][namespace] = {};
    }
    if (key in unknownTranslationKeys[courseId][namespace]) {
      return;
    }
    unknownTranslationKeys[courseId][namespace][key] = false;
    window.localStorage.setItem(
      UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY,
      JSON.stringify(unknownTranslationKeys),
    );
  }

  // Uploads unknown translation keys to the backend. If there are no unknown
  // translation keys to upload, this function does nothing.
  async function uploadUnknownTranslationKeys() {
    const unknownTranslationKeys: Record<
      CourseId,
      Record<NamespaceId, Record<string, boolean>>
    > = JSON.parse(
      window.localStorage.getItem(UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY) ??
        '{}',
    );
    if (Object.keys(unknownTranslationKeys).length === 0) {
      return;
    }
    const keysToUpload: Record<CourseId, Record<NamespaceId, string[]>> = {};
    for (const [courseId, namespaces] of Object.entries(
      unknownTranslationKeys,
    )) {
      for (const [namespace, keys] of Object.entries(namespaces)) {
        const keysToUploadForNamespace = Object.entries(keys)
          .filter(([, isUploaded]) => !isUploaded)
          .map(([key]) => key);
        if (keysToUploadForNamespace.length === 0) {
          continue;
        }
        keysToUpload[courseId] = keysToUpload[courseId] ?? {};
        keysToUpload[courseId][namespace] =
          keysToUpload[courseId][namespace] ?? [];
        keysToUpload[courseId][namespace] = [
          ...keysToUpload[courseId][namespace],
          ...keysToUploadForNamespace,
        ];
      }
      const supportedLanguages = (
        await getDoc(getCourseTranslationDocument(courseId))
      ).data()?.supportedLanguages ?? [DEFAULT_LANGUAGE_CODE];
      await writeTranslationsToFirebase(
        keysToUpload,
        supportedLanguages,
        setTranslationsUploading,
        (courseId, namespace, keys) => {
          for (const key of keys) {
            unknownTranslationKeys[courseId][namespace][key] = true;
          }
        },
        () => {
          window.localStorage.setItem(
            UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY,
            JSON.stringify(unknownTranslationKeys),
          );
        },
      );
    }
  }

  function clearLocalUnknownTranslationKeys() {
    window.localStorage.removeItem(UNKNOWN_TRANSLATION_KEY_LOCAL_STORAGE_KEY);
  }

  return {
    addUnknownTranslationKeyToUploadCache,
    uploadUnknownTranslationKeys,
    clearLocalUnknownTranslationKeys,
    translationsUploading,
    hasUploadedTranslations,
    writeTranslationsToFirebase,
  };
}

/**
 * Provides the logic for accessing and updating translations for a given
 * course and language. The most important export (for consumers) is the
 * `useTranslateFunction` hook, which returns a function that can be used to
 * translate a given key in a given namespace.
 *
 * A "namespace" is a collection of translations for a specific purpose. For
 * example, the "common" namespace is used for translations that are shared
 * across multiple pages. "keys" are unique identifiers within a namespace.
 *
 * @returns {@inheritdoc TranslationContextData}
 */
export const TranslationProvider = ({children}: {children: ReactNode}) => {
  const [siteLanguage, setSiteLanguage] = useState(DEFAULT_LANGUAGE_CODE);
  const courseId = useCurrentCourseIdWithFallback();
  const {supportedLanguages} = useSupportedLanguages();
  // A non-reactive map of course ID to language to namespace to key to
  // translation.
  const [cachedTranslationData, setCachedTranslationData] = useState<
    Map<CourseId, Map<LanguageCode, Map<NamespaceId, Map<string, string>>>>
  >(new Map());
  // A list of namespaces that need to be loaded to display the current page.
  const [requiredNamespaces, setRequiredNamespaces] = useState<NamespaceId[]>(
    [],
  );
  const [translationDataLoading, setTranslationDataLoading] = useState(true);
  const {addUnknownTranslationKeyToUploadCache} = useTranslationUploader();
  const {userData, loading: userDataIsLoading} = {
    // If we are outside of a logged in context (e.g. on the landing page) use
    // unset values for the user profile data.
    ...{
      userData: null,
      loading: true,
    },
    ...useContext<ProfileContextData>(ProfileContext),
  };

  // Manages the cache of translation data as the user requests different
  // languages or namespaces.
  useEffect(() => {
    const newCachedTranslationData = deepCopyNestedMap(cachedTranslationData);
    if (!newCachedTranslationData.has(courseId)) {
      newCachedTranslationData.set(courseId, new Map());
    }
    const courseTranslations = newCachedTranslationData.get(courseId);
    if (!courseTranslations.has(siteLanguage)) {
      courseTranslations.set(siteLanguage, new Map());
    }
    const languageTranslations = courseTranslations.get(siteLanguage);
    const namespacesToLoad = requiredNamespaces.filter(
      namespace => !languageTranslations.has(namespace),
    );
    if (namespacesToLoad.length === 0) {
      return;
    }
    setTranslationDataLoading(true);
    Promise.all(
      namespacesToLoad.map(async namespace => {
        const namespaceTranslationDocument = getTranslationDocument(
          courseId,
          siteLanguage,
          namespace,
        );
        const namespaceSnapshot = await getDoc(namespaceTranslationDocument);
        const snapshotData = namespaceSnapshot.data();
        const namespaceTranslations = snapshotData
          ? new Map(snapshotData.entries())
          : new Map();
        languageTranslations.set(namespace, namespaceTranslations);
      }),
    )
      .then(() => {
        setCachedTranslationData(newCachedTranslationData);
        setTranslationDataLoading(false);
      })
      .catch(error => {
        console.error(
          `Error loading translation data for course: ${courseId} and language: ${siteLanguage}. Error: ${error}`,
        );
        setTranslationDataLoading(false);
      });
  }, [siteLanguage, courseId, requiredNamespaces]);

  // If we find a preferred language in the user's profile, use it.
  useEffect(() => {
    if (userDataIsLoading) return;
    if (!userData?.preferredLanguage) {
      return;
    }
    setSiteLanguage(userData.preferredLanguage);
    localStorage.setItem(
      UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
      userData.preferredLanguage,
    );
  }, [userDataIsLoading]);

  // If on mount we don't have a preferred language in the user's profile, use
  // the unauthenticated preferred language if it exists.
  useEffect(() => {
    if (
      !localStorage.getItem(
        UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
      ) ||
      (!userDataIsLoading && userData?.preferredLanguage)
    ) {
      return;
    }
    setSiteLanguage(
      localStorage.getItem(
        UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
      ),
    );
  }, []);

  // Builds a function that can be used to translate keys. See
  // `TranslationContextData` for usage and details.
  function useTranslateFunction(
    namespace: NamespaceId,
  ): (key: ReactNode) => ReactNode {
    // The set of translations that will be displayed on the current page. This
    // is kept distinct from the cached translations to allow presenting stale
    // data while the translations are being loaded when the site language
    // changes.
    const [displayedTranslations, setDisplayedTranslations] = useState<
      Map<string, string>
    >(new Map());
    useEffect(() => {
      if (requiredNamespaces.includes(namespace)) {
        return;
      }
      setRequiredNamespaces(prevRequiredNamespaces =>
        prevRequiredNamespaces.includes(namespace)
          ? [...prevRequiredNamespaces]
          : [...prevRequiredNamespaces, namespace],
      );
    }, []);

    // If the translation data for the current course and language is not yet
    // loaded, continue displaying the previous translations.
    useEffect(() => {
      if (translationDataLoading) {
        return;
      }
      const courseData = cachedTranslationData.get(courseId);
      const languageData = courseData?.get(siteLanguage);
      const namespaceTranslations = languageData?.get(namespace);
      if (!namespaceTranslations) {
        return;
      }
      setDisplayedTranslations(namespaceTranslations ?? new Map());
    }, [siteLanguage, courseId, namespace, translationDataLoading]);
    return function translateFunctionForNamespace(key: ReactNode) {
      if (typeof key !== 'string') {
        // TODO: #1006 Support non-string keys
        console.error('Non-string keys are not currently supported.');
        return key;
      }
      const cacheHasTranslation = cachedTranslationData
        .get(courseId)
        ?.get(siteLanguage)
        ?.get(namespace)
        ?.has(key);
      if (!translationDataLoading && !cacheHasTranslation) {
        addUnknownTranslationKeyToUploadCache(courseId, namespace, key);
      }
      return displayedTranslations.get(key) ?? key;
    };
  }

  const setSiteLanguageWithFallback = (language: string) => {
    if (!supportedLanguages.includes(language)) {
      console.error(
        `Language ${language} is not supported, falling back to ${DEFAULT_LANGUAGE_CODE}. Supported languages: ${supportedLanguages}`,
      );
      setSiteLanguage(DEFAULT_LANGUAGE_CODE);
    }
    setSiteLanguage(language);
  };
  return (
    <TranslationContext.Provider
      value={{
        useTranslateFunction,
        siteLanguage,
        setSiteLanguage: setSiteLanguageWithFallback,
        useSupportedLanguages,
        useSupportedNamespaces,
      }}
    >
      <div
        dir={
          siteLanguage in LANGUAGE_CODE_TO_DIRECTION
            ? LANGUAGE_CODE_TO_DIRECTION[siteLanguage]
            : 'ltr'
        }
      >
        {children}
      </div>
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
export const useTranslateFunction = (namespace: string) => {
  const {useTranslateFunction: useTranslateFunctionFromContext} =
    useTranslationContext();
  return useTranslateFunctionFromContext(namespace);
};
