import {isDevelopmentEnvironment} from 'utils/general';

/**
 * A component that only renders its children if the current environment is
 * development. Useful for developer-only features like developer tooling.
 */
export default function DevelopmentOnly({
  children,
}: {
  children: React.ReactNode;
}) {
  if (isDevelopmentEnvironment()) {
    return <>{children}</>;
  }
  return <></>;
}
