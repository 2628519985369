import {isMinimumRole} from 'contexts/profileUtil';
import {Role} from 'types/role';

/**
 * Checks if the user has the minimum role to edit an assignment
 * @param userData - The user's data
 * @returns Whether the user has the minimum role to edit an assignment
 */
export function canEditAssignment(userData: any) {
  return isMinimumRole(userData.courseRole, Role.TA);
}
