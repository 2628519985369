import {addCommentBox} from './EditorZones';

/**
 * Show the add comment button at the current selection
 * The button is displayed at the left of the editor
 */
export const updateInstructorFeedbackButtonPosition = editorRef => {
  const selectionButton = document.getElementById('selectionButton');
  const selection = editorRef.current.getSelection();

  const endPosition = selection.getEndPosition();
  const startTop = editorRef.current.getTopForPosition(
    endPosition.lineNumber,
    endPosition.column,
  );

  const scrollTop = editorRef.current.getScrollTop();

  selectionButton.style.top = `${startTop - scrollTop}px`;
  selectionButton.style.left = `${5}px`;
};

/**
 * Setup event listeners for the add comment button
 */
export const setupButtonListeners = (
  editorRef,
  viewZoneIds,
  domNodeRefs,
  projectData,
  userData,
  courseId,
) => {
  // Show the button when the cursor is moved
  editorRef.current.onDidChangeCursorSelection(() => {
    updateInstructorFeedbackButtonPosition(editorRef);
  });

  // Update the button position when the editor is scrolled
  editorRef.current.onDidScrollChange(() => {
    updateInstructorFeedbackButtonPosition(editorRef);
  });

  // Add the comment box when the button is clicked
  const selectionButton = document.getElementById('selectionButton');
  selectionButton.addEventListener('click', () => {
    const lineNumber = editorRef.current.getPosition().lineNumber;
    addCommentBox(
      true,
      editorRef,
      lineNumber,
      viewZoneIds,
      domNodeRefs,
      projectData,
      userData,
      courseId,
    );
  });
};
