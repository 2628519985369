import React, {useRef, createContext, useState, MutableRefObject} from 'react';
import {CollabSession} from './Session';

type CollabContextType = {
  sessionRef: MutableRefObject<CollabSession>;
  isSessionRefLoaded: boolean;
  setIsSessionRefLoaded: (val: boolean) => void;
  collaborators: any;
  setCollaborators: (val: any) => void;
  clientId: string;
  setClientId: (val: string) => void;
  mice: any;
  setMice: (val: any) => void;
  children?: React.ReactNode;
};

const defaultValues = {
  sessionRef: {current: null},
  isSessionRefLoaded: false,
  setIsSessionRefLoaded: () => {},
  collaborators: {},
  setCollaborators: () => {},
  clientId: '',
  setClientId: () => {},
  mice: {},
  setMice: () => {},
};

export const CollabContext = createContext<CollabContextType>(defaultValues);

export const CollabProvider: React.FC<any> = ({children}) => {
  const sessionRef = useRef(defaultValues.sessionRef.current);

  const [isSessionRefLoaded, setIsSessionRefLoaded] = useState(
    defaultValues.isSessionRefLoaded,
  );
  const [collaborators, setCollaborators] = useState(
    defaultValues.collaborators,
  );
  const [clientId, setClientId] = useState(defaultValues.clientId);
  const [mice, setMice] = useState(defaultValues.mice);

  return (
    <CollabContext.Provider
      value={{
        sessionRef,
        isSessionRefLoaded,
        setIsSessionRefLoaded,
        collaborators,
        setCollaborators,
        clientId,
        setClientId,
        mice,
        setMice,
      }}
    >
      {children}
    </CollabContext.Provider>
  );
};
