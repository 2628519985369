import NoAccess from 'components/errors/NoAccess';
import {ProfileContext, ProfileProvider} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {
  isAdmin,
  isExperiencedStudent,
  isInstructor,
  isMinimumRole,
  isSectionLeader,
  isStudent,
} from 'contexts/profileUtil';
import {getApp} from 'firebase/app';
import {doc, getFirestore} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Outlet} from 'react-router';
import {
  StudentOnboardingContext,
  StudentOnboardingProvider,
} from '../studentOnboarding/contexts/StudentOnboardingContext';
import {ExpStudentJoinNav} from './ExpStudentJoinNav';

export const ExpStudentOnboardingPage = () => {
  return (
    <ProfileProvider>
      <Onboarding />
    </ProfileProvider>
  );
};

const Onboarding = () => {
  const {loading, userData} = useContext(ProfileContext);
  if (loading) return <></>;
  return (
    <StudentOnboardingProvider>
      <Inner />
    </StudentOnboardingProvider>
  );
};

const Inner = () => {
  const userId = useUserId();
  const courseId = useCourseId();
  const {loading, userData} = useContext(ProfileContext);
  const {onboardingLoading, chosenTimesMap, onboardingError} = useContext(
    StudentOnboardingContext,
  );

  if (loading || onboardingLoading) {
    return <></>;
  }

  if (onboardingError) {
    return (
      <div>There was an error loading the onboarding data, please refresh</div>
    );
  }

  console.log(chosenTimesMap);

  const isAllowed =
    isExperiencedStudent(userData.courseRole) ||
    isAdmin(userData.courseRole) ||
    isInstructor(userData.courseRole);

  // it only makes sense to show the onboarding if you are a student!
  if (isAllowed) {
    return (
      <>
        <ExpStudentJoinNav />
        <Outlet />
      </>
    );
  }

  return <NoAccess />;
};
