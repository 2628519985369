import {useComponentSize} from 'react-use-size';
import {Forum} from './Forum';
import {Card} from 'react-bootstrap';
import {ClassNotOpen} from 'components/errors/ClassNotOpen';
import {useContext} from 'react';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import styled from 'styled-components';
import {ForumContext} from '../../ForumContext';

export const ForumPage = ({}) => {
  const {userData} = useContext(ProfileContext);
  const {forumId} = useContext(ForumContext);

  const forumPageSize = useComponentSize();

  const usedSizeInTwoCols = 650 + 300;
  const whiteSpace = forumPageSize.width - usedSizeInTwoCols;
  const gap = whiteSpace / 2;

  // if(!isMinimumRole(courseRole, Role.TA)){
  //     return <ClassNotOpen/>
  // }

  return (
    <ForumOuter ref={forumPageSize.ref} key={`${forumId}-forum-id`}>
      <Forum />
    </ForumOuter>
  );
};

const ForumOuter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  max-height: calc(100% - 1px);
  overflow-y: hidden;
`;
