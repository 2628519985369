import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import {getCourseRoleNumber} from 'contexts/ProfileContext';
import {useWindowSize} from 'react-use-size';
import {useParams} from 'react-router-dom';
import {Role} from 'types/role';
import {CourseId} from 'types/common';
import {ProjectId} from 'ide/types';

export function getPromptPath(projectData: any): string | undefined {
  if (!projectData) {
    return undefined;
  }
  let assnId = projectData.assnId;
  if (!assnId) {
    return undefined;
  }
  let {courseId} = useParams();
  return `/assns/${courseId}/assignments/${assnId}/docs/prompt`;
}

export function getSolnPath(projectData: any): string | undefined {
  if (!projectData) {
    return undefined;
  }
  let assnId = projectData.assnId;
  if (!assnId) {
    return undefined;
  }
  let {courseId} = useParams();
  return `/assns/${courseId}/assignments/${assnId}/docs/soln`;
}

export const fetchAssignments = async courseId => {
  const db = getFirestore();
  const documentRef = doc(db, `assns/${courseId}`);
  const response = await getDoc(documentRef);

  return response.data();
};

export const getUserInfo = async userid => {
  const db = getFirestore();
  const docRef = doc(db, `users/${userid}`);
  const response = (await getDoc(docRef)).data();

  if (!response) return {};

  return response;
};

export const fetchAllAssignmentMetaData = async courseId => {
  const allAssnMetaData = {};
  const db = getFirestore();
  const collectionRef = collection(db, `assns/${courseId}/assignments`);
  const response = await getDocs(collectionRef);

  response.forEach(doc => {
    // doc.data() is never undefined for query doc snapshots
    allAssnMetaData[doc.id] = doc.data();
  });

  return allAssnMetaData;
};

export const fetchCreativeProjects = async userId => {
  const db = getFirestore();
  const docRef = doc(db, `users/${userId}/cip3/creativeProjects`);
  const response = (await getDoc(docRef)).data();

  // a few base cases
  if (!response) return [];
  if (!response.creativeProjects) return [];

  return response.creativeProjects;
};

export const fetchAssignmentInfo = async assnId => {
  const db = getFirestore();
  const docRef = doc(db, `assns/cip3/${assnId}/metaData`);
  const response = (await getDoc(docRef)).data();

  return response;
};

export const fetchProjectInfo = async projectId => {
  const db = getFirestore();
  const docRef = doc(db, `projects/${projectId}`);
  const response = (await getDoc(docRef)).data();

  return response;
};

export const loadUserAssnMap = async (
  userId: string,
  courseId: CourseId,
  onResponse: (data: any) => void,
) => {
  const db = getFirestore();
  const docRef = doc(db, `users/${userId}/${courseId}/assnMap`);
  const response = await getDoc(docRef);
  if (response.exists()) {
    const data = response.data();
    onResponse(data);
  } else {
    onResponse({});
  }
};

export const editPublishedProjectNote = async (
  projectId: ProjectId,
  courseId: CourseId,
  note: string,
) => {
  const db = getFirestore();
  const docRef = doc(db, `published/${courseId}/studentPublished/${projectId}`);
  await updateDoc(docRef, {
    note,
  });
};

export const getApprovedCourses = async (): Promise<Record<string, Role>> => {
  const approvedCoursesRef = collection(getFirestore(), 'course');
  const approvedCoursesSnapshot = await getDocs(approvedCoursesRef);
  const approvedCourses = {};
  approvedCoursesSnapshot.docs.forEach(course => {
    approvedCourses[course.id] = getCourseRoleNumber(
      course.data().minRole ?? '',
    );
  });
  return approvedCourses;
};

export const useIsMobile = (): boolean => {
  const {width} = useWindowSize();
  const isMobile = width < 768;
  return isMobile;
};

export const useIsTablet = (): boolean => {
  const {width} = useWindowSize();
  const isTablet = width < 1024;
  return isTablet;
};

/**
 * @name getNewPromise
 * @returns {object} - object with promise and resolve members
 */
export const getNewPromise = () => {
  let resolveFunc;
  // Returns object with promise member and resolve member
  return {
    promise: new Promise(function (resolve, reject) {
      resolveFunc = resolve;
    }),
    resolve: resolveFunc,
  };
};

// Based off the W3C specification:
// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const VALID_EMAIL_REGEX: RegExp =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Validates an email address, returning true if it is valid and false otherwise.
 */
export function isValidEmailAddress(email: string): boolean {
  if (!email) {
    return false;
  }
  return VALID_EMAIL_REGEX.test(email);
}

/**
 * Determines whether the current environment is development. This is useful for
 * features that should only be enabled/disabled in development mode. All tests
 * for build environment should use this function.
 */
export function isDevelopmentEnvironment(): boolean {
  return process.env.NODE_ENV === 'development';
}

/**
 * Tests if two floating point numbers are approximately equal.
 *
 * Optional configurable error threshold. Should be used whenever trying to
 * compare floating point numbers for equality to avoid floating point
 * arithmetic errors.
 */
export function floatApproximatelyEquals(
  a: number,
  b: number,
  threshold: number = 1e-5,
): boolean {
  return Math.abs(a - b) < threshold;
}

/**
 * Tests that the given object is defined but empty (i.e. `{}` or `[]`).
 * @param data - The data to test.
 */
export function isEmptyObject(data: unknown): boolean {
  return typeof data === 'object' && !!data && Object.keys(data).length === 0;
}

/**
 * Tests if the given data is null or undefined.
 *
 * Also known as a "nullish" test. Often, you can use the nullish coalescing
 * operator `??` (a ?? b === !isNullOrUndefined(a) ? a : b) instead of this
 * function.
 */
export function isNullOrUndefined(data: unknown): boolean {
  return data === null || data === undefined;
}

export function toFirebaseSafeObject(obj: unknown): any {
  try {
    if (typeof obj === 'object') {
      if (!obj) {
        return null;
      }
      if (Array.isArray(obj)) {
        return obj.map(toFirebaseSafeObject);
      } else if (obj instanceof Map) {
        return Object.fromEntries(
          Array.from(obj.entries()).map(([key, value]) => [
            key,
            toFirebaseSafeObject(value),
          ]),
        );
      } else {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key,
            toFirebaseSafeObject(value),
          ]),
        );
      }
    } else if (typeof obj === 'undefined') {
      return null;
    } else if (typeof obj === 'string') {
      return obj;
    } else if (typeof obj === 'number') {
      return obj;
    } else if (typeof obj === 'boolean') {
      return obj;
    } else {
      throw new Error(
        'Tried converting non-primitive type to Firebase safe object.',
      );
    }
  } catch (e: any) {
    throw new Error(
      'Failed to convert object to Firebase safe object. Error: ' + e,
    );
  }
}
