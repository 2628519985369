import {Skeleton} from 'antd';
import {FlagWithOverlay} from 'components/countries/LocationInfo';
import {RoleBadge} from 'course/forum/components/UserChip/UserChip';
import {doc, getFirestore} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {Link} from 'react-router-dom';
import {getTotal} from './ForumStats';
import {Role} from 'types/role';
import {firebaseStringToRole} from 'contexts/profileUtil';

export const UserRow = ({userId, stats, filters}) => {
  const courseId = useCourseId();
  const [userData, loading, error] = useDocumentDataOnce(
    doc(getFirestore(), `users/${userId}`),
  );
  const [role, loadingRole, roleError] = useDocumentDataOnce(
    doc(getFirestore(), `users/${userId}/roles/${courseId}`),
  );
  if (loading || loadingRole) return <Skeleton paragraph={false} />;

  if (isFilteredByRole(role?.role, filters, courseId)) return null;

  return (
    <tr>
      <td>
        <UserTableEntry userId={userId} userData={userData} role={role} />
      </td>
      <td>{stats.posts || 0}</td>
      <td>{stats.replies || 0}</td>
      <td>{getTotal(stats)}</td>
    </tr>
  );
};

const UserTableEntry = ({userId, userData, role}) => {
  const courseId = useCourseId();

  const country = userData?.country;
  const displayName = userData?.displayName || 'Anonymous';
  const roleString = role?.role || 'Student';
  console.log('role', roleString);
  return (
    <>
      <FlagWithOverlay country={country} height={20} />
      <Link to={`/${courseId}/user/${userId}`} className="ml-2 mr-2">
        {displayName}
      </Link>
      <RoleBadge role={roleString} />
    </>
  );
};

function isFilteredByRole(roleStr, filters, courseId) {
  if (!roleStr) return true;
  let courseRole = firebaseStringToRole(roleStr);

  // in the public course, UNREGISTERED is student!
  // else they are not in the course
  if (courseRole === Role.UNREGISTERED) {
    if (courseId == 'public') {
      return false;
    }
    return true;
  }

  // admin filter
  if (!filters.admin) {
    if (courseRole === Role.ADMIN) return true;
    if (courseRole === Role.TA) return true;
    if (courseRole === Role.INSTRUCTOR) return true;
  }

  // sl filter
  if (!filters.sl) {
    if (courseRole === Role.SECTION_LEADER) return true;
    if (courseRole === Role.MENTOR) return true;
  }

  // student filter
  if (!filters.student) {
    if (courseRole === Role.STUDENT) return true;
    if (courseRole === Role.EXPERIENCED_STUDENT) return true;
  }
  return false;
}
