import {useState, useEffect, useContext} from 'react';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {PartialLoading} from 'components/loading/Loading';
import {ProfileContext} from 'contexts/ProfileContext';
import {CourseContext} from 'contexts/CourseContext';
import {RoadmapContext} from 'course/contexts/RoadmapContext';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import Gate from 'contexts/Gate';
import {getLocalUTCTimezone} from 'components/timezones/timezoneHelper';
import {OverviewTable} from 'course/section/components/OverviewTable';
import {Spacer} from 'components/layout/Spacer';
import {getFunctions, httpsCallable} from 'firebase/functions';

const functions = getFunctions();
const adminGetAllStudentGrades = httpsCallable(
  functions,
  'adminGetAllStudentGrades',
);
const adminGetAssnSubmissions = httpsCallable(
  functions,
  'adminGetAssnSubmissions',
);
const getAssnInfo = httpsCallable(functions, 'getAssnInfo');

export const StudentOverview = () => {
  const {getAssignmentsInRoadmap} = useContext(RoadmapContext);
  const releasedAssns = getAssignmentsInRoadmap(true);
  return (
    <CoursePageBodyContainer
      mainColumn={<StudentOverviewTable releasedAssns={releasedAssns} />}
      singleColumn={<StudentOverviewTable releasedAssns={releasedAssns} />}
      rightColumn={<></>}
    />
  );
};

const StudentOverviewTable = ({releasedAssns}) => {
  const courseId = useCourseId();
  const db = getFirestore();
  const studentRosterRef = doc(db, `course/${courseId}/rosters/student`);
  const [studentRosterData, studentRosterLoading, studentRosterError] =
    useDocumentData(studentRosterRef);
  const {
    sectionTimeDelta,
    nCourseWeeks,
    firstSectionTimestamp,
    courseHasGrades,
    isFoothill,
    getNextSectionIndex,
  } = useContext(CourseContext);
  const [numSections, setNumSections] = useState(-1);
  const userTz = getLocalUTCTimezone();
  const {userData} = useContext(ProfileContext);
  const isAdmin = Gate.hasAdminRole(userData);

  // canvas / grades related
  const [membersGrades, setMembersGrades] = useState({}); // will be a dictionary of userId: Canvas grades object
  const [assnSubmissions, setAssnSubmissions] = useState({}); // will be a dictionary of assnId: {userId: submissionData}
  const [assnInfo, setAssnInfo] = useState({}); // will be a dictionary of assnId: assnInfo

  useEffect(() => {
    // fetch canvas-specific data if course has grades

    const fetchMembersGrades = async () => {
      try {
        // fetch all student grades
        const response = await adminGetAllStudentGrades({
          adminId: userData.id,
          courseId: courseId,
        });
        if (!response || !response.data) {
          // handle error
          console.log('Error fetching grades');
          return;
        }
        // membersGrades is a map of userId to grades object
        const membersGrades = Object.fromEntries(
          response.data.map(enrollmentData => {
            return [enrollmentData.firestoreUserId, enrollmentData.grades];
          }),
        );
        setMembersGrades(membersGrades);
      } catch (error) {
        console.error('Error fetching member grades: ', error);
      }
    };

    const fetchAssnInfo = async () => {
      if (!releasedAssns) return;

      const fetchPromises = releasedAssns.map(assn => {
        const assnId = assn.assnId;
        return getAssnInfo({
          adminId: userData.id,
          courseId: courseId,
          assnId: assnId,
        })
          .then(res => {
            return {
              [assnId]: res.data,
            };
          })
          .catch(error =>
            console.log(`Error fetching assn info for assn ${assnId}: `, error),
          );
      });
      try {
        const results = await Promise.all(fetchPromises);
        setAssnInfo(prev => {
          return results.reduce((acc, result) => {
            return {...acc, ...result};
          }, {});
        });
      } catch (error) {
        console.error('Error fetching assn info: ', error);
      }
    };

    const fetchAssnSubmissions = async () => {
      if (!releasedAssns) return;

      const fetchPromises = releasedAssns.map(assn => {
        const assnId = assn.assnId;
        const firestoreAssnSubmissionsRef = doc(
          db,
          `submissions/${courseId}/assignments/${assnId}`,
        );
        return Promise.allSettled([
          getDoc(firestoreAssnSubmissionsRef),
          adminGetAssnSubmissions({
            adminId: userData.id,
            courseId: courseId,
            assnId: assnId,
          }),
        ])
          .then(([firestoreAssnSubmissionsRes, canvasAssnGradesRes]) => {
            var firestoreAssnSubmissionsDoc =
              firestoreAssnSubmissionsRes.status === 'fulfilled'
                ? firestoreAssnSubmissionsRes.value
                : null;
            var firestoreAssnSubmissionsData =
              firestoreAssnSubmissionsDoc.exists()
                ? firestoreAssnSubmissionsDoc.data()
                : {};
            console.log(
              'firestoreAssnSubmissionsData',
              firestoreAssnSubmissionsData,
            );

            // array of submissions, with firestoreUserId field
            var canvasAssnGrades =
              canvasAssnGradesRes.status === 'fulfilled'
                ? canvasAssnGradesRes.value
                : null;
            if (canvasAssnGrades) {
              console.log('canvasAssnGrades', canvasAssnGrades.data);
              // update firestoreAssnSubmissionsData for each user with their canvas submission data
              canvasAssnGrades.data.forEach(submission => {
                const firestoreUserId = submission.firestoreUserId;
                firestoreAssnSubmissionsData[firestoreUserId] = {
                  ...firestoreAssnSubmissionsData[firestoreUserId],
                  score: submission.score, // add more fields here from submission if needed
                };
              });
            }

            return {
              [assnId]: firestoreAssnSubmissionsData,
            };
          })
          .catch(error =>
            console.log(
              `Error fetching submissions for assn ${assnId}: `,
              error,
            ),
          );
      });
      try {
        const results = await Promise.all(fetchPromises);
        // assnSubmissions is a dictionary of assnId: {userId: submissionData}
        // for all users who have submitted the assignment
        setAssnSubmissions(prev => {
          return results.reduce((acc, result) => {
            return {...acc, ...result};
          }, {});
        });
      } catch (error) {
        console.error('Error fetching assn submissions: ', error);
      }
    };

    if (courseHasGrades) {
      fetchMembersGrades();
      fetchAssnInfo();
      fetchAssnSubmissions();
    }
  }, [releasedAssns]);

  useEffect(() => {
    if (!firstSectionTimestamp) return;
    let nextSectionIdx = getNextSectionIndex(userTz, sectionTimeDelta); // if we don't know section time assume latest possible
    setNumSections(
      nextSectionIdx + 1 < nCourseWeeks ? nextSectionIdx + 1 : nCourseWeeks,
    );
  }, [firstSectionTimestamp]);

  // admin only, foothill + devtest only
  if (!isAdmin || !(isFoothill || courseId === 'devtest')) {
    return <></>;
  }

  if (studentRosterLoading || studentRosterError) {
    return <PartialLoading />;
  }

  return (
    <>
      <Spacer />
      <h3>Student Overview</h3>
      <OverviewTable
        membersList={Object.keys(studentRosterData)}
        numSections={numSections}
        courseHasGrades={courseHasGrades}
        membersGrades={membersGrades}
        assnInfo={assnInfo}
        assnSubmissions={assnSubmissions}
      />
    </>
  );
};
