import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {JSX} from 'react';

/**
 * An option that can be selected from a dropdown menu.
 *
 * TODO: #1014 - Change this back to React's Key type.
 */
export interface SelectOption<TValue extends string | number = string> {
  /** A user-friendly label for the option. */
  label: string;
  /** A unique value to identify the option. */
  value: TValue;
  /** An optional tooltip to display when hovering over the option. */
  tooltip?: string;
  /** An optional way to specify an option as selectable, defaults to true. */
  selectable?: boolean;
}

interface SelectDropdownConfiguration {
  /** Optional title to display on the dropdown toggle, defaults to the selected option's label if missing.*/
  toggleTitle?: JSX.Element | string;
  /** Style classes to apply to the dropdown toggle button. */
  toggleStyleClasses?: string;
  /** Inline size styling to apply to the dropdown toggle button. Defaults to 'sm'. */
  toggleSize?: 'sm' | 'lg';
  /** Alignment of the dropdown menu. Defaults to 'end'. */
  align?: 'start' | 'end';
  /** Whether the dropdown is disabled. Defaults to false. */
  disabled?: boolean;
}

/**
 * Props for the {@link SelectDropdown} component.
 */
interface SelectDropdownProps<TValue extends string | number> {
  /** An array of {@link SelectOption}s. */
  selectableOptions: SelectOption<TValue>[];
  /** The currently selected option. */
  selectedOption: SelectOption<TValue>;
  /** A function to expose the parent component to the selected option. */
  setSelectedOption: (option: SelectOption<TValue>) => void;
  /** Optional additional configuration for the dropdown. */
  configuration?: SelectDropdownConfiguration;
}

/**
 * A select dropdown menu that allows the user to choose from a list of options.
 * The specified type parameter `TValue` is the type of the `value` (i.e. unique
 * id) property of the {@link SelectOption}.
 * @param __namedParameters - {@inheritdoc SelectDropdownProps}
 */
export function SelectDropdown<TValue extends string | number = string>({
  selectableOptions,
  selectedOption,
  setSelectedOption,
  configuration,
}: SelectDropdownProps<TValue>) {
  const defaultConfiguration: SelectDropdownConfiguration = {
    toggleTitle: selectedOption.label,
    align: 'end',
    toggleSize: 'sm',
    toggleStyleClasses: '',
    disabled: false,
  };
  const {toggleTitle, toggleStyleClasses, toggleSize, align, disabled} = {
    ...defaultConfiguration,
    ...configuration,
  };
  return (
    <Dropdown align={align}>
      <Dropdown.Toggle
        className={toggleStyleClasses}
        size={toggleSize}
        disabled={disabled}
      >
        {toggleTitle ?? selectedOption.label}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {selectableOptions.map(option => (
          <Dropdown.Item
            key={option.value}
            disabled={!(option.selectable ?? true) || disabled}
            onClick={() => setSelectedOption(option)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
