import {type GraphicsUnitTest} from '../../models/unitTests/graphics';

/**
 * Data loaded from the server for running a graphics unit test.
 */
export interface LoadedGraphicsUnitTestData {
  /** The unit tests to run. */
  unitTests: GraphicsUnitTest[];
  /** The starter code for the assignment. */
  starterCode: {
    [fileName: string]: string;
  };
}

// The name of the default autograder test, used to reference the test in the UI.
export const DEFAULT_GRAPHICS_UNIT_TEST_NAME = 'Graphics Autograder Unit Test';
