import {createRoot} from 'react-dom/client';
import {getFunctions, httpsCallable} from 'firebase/functions';

// HEADS UP: this is the change that makes cs106a website
// different than code in place. It should only be
// set to cs106a in the cs106a branch.
// import {Router} from 'csbridge/Router'
// import { Router } from 'cs106a/Router';
import {Router} from './Router';
import {EnrollmentProvider} from 'contexts/EnrollmentContext';
import {NavigationHistoryProvider} from 'contexts/NavigationContext';
import {UiTrainingProvider} from 'contexts/UiTrainingContext';
import {TimeProvider} from 'contexts/TimeContext';
import {TranslationProvider} from 'contexts/TranslationContext';
import {isDevelopmentEnvironment} from 'utils/general';
import {DeveloperToolsProvider} from 'contexts/DeveloperToolsContext';

// disable console.log in production.
// console.warn and console.error will log to firebase
if (!isDevelopmentEnvironment()) {
  console.log = () => {};

  const functions = getFunctions();
  console.warn = (...logged) => {
    httpsCallable(
      functions,
      'logWarning',
    )({
      logged,
      url: window.location.href,
      version: navigator.appVersion,
    });
  };

  console.error = (...logged) => {
    httpsCallable(
      functions,
      'logError',
    )({
      logged,
      url: window.location.href,
      version: navigator.appVersion,
    });
  };
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <div className="app">
    <DeveloperToolsProvider>
      <TranslationProvider>
        <NavigationHistoryProvider>
          <TimeProvider>
            <EnrollmentProvider>
              <UiTrainingProvider>
                <Router />
              </UiTrainingProvider>
            </EnrollmentProvider>
          </TimeProvider>
        </NavigationHistoryProvider>
      </TranslationProvider>
    </DeveloperToolsProvider>
  </div>,
);
