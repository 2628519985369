// @ts-ignore
import {useEffect, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';

import {FaBookOpen, FaCheck, FaPen, FaRocket, FaPython} from 'react-icons/fa';

import {LandingNav} from '../../../../landing/components/LandingNav';
// @ts-ignore
import {useWindowSize} from 'react-use-size';
import {useAuthState} from 'react-firebase-hooks/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {isApplicationLive} from '../../../../config';
import {Authenticated} from 'components/auth/Authenticated';
import {
  ApplicationStatus,
  computeApplicationStatus,
} from 'course/application/sectionLeaderApplication/splash/ApplicationStatus';
import {useUserId} from 'hooks/user/useUserId';
import {getApp} from 'firebase/app';
import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import styled from 'styled-components';
import {CompletionContext} from 'contexts/CompletionContext';
import {useAppStatusServerRecorder} from 'hooks/application/useAppStatusServerRecorder';
import {useReferralHook} from '../hooks/referralHook';
import {useConfetti} from 'components/confetti/useConfetti';
import {Role} from 'types/role';
import {EnrollmentContext} from 'contexts/EnrollmentContext';

const innerWidth = '640px';
let READER_URL =
  'https://compedu.stanford.edu/karel-reader/docs/python/en/intro.html';

export const StudentApplication = () => {
  if (!isApplicationLive) {
    return <UnreleasedAlert />;
  }
  return <Authenticated component={StudentApplicationMain} />;
};

export const StudentApplicationMain = () => {
  // @ts-ignore
  const [user] = useAuthState(firebase.auth());
  const {targetCourseId} = useParams();
  const {isCompletionLoading} = useContext(CompletionContext);
  useReferralHook();

  const fbStudentApplicationPath = `users/${user.uid}/${targetCourseId}/studentApplication`;

  const db = getFirestore();
  const appDocRef = doc(db, fbStudentApplicationPath);

  const [appData, appDataLoading, appDataError] = useDocumentData(appDocRef);

  const dataPath = `course/${targetCourseId}`;

  const windowSize = useWindowSize();
  const {confettiParticles, fireConfetti} = useConfetti({windowSize});
  const {roles, isLoadingRoles} = useContext(EnrollmentContext);
  useEffect(() => {
    const courseRole = roles?.[targetCourseId];
    const isAdmit = courseRole === Role.STUDENT;
    if (isAdmit) {
      fireConfetti();
    }
  }, [roles]);

  const [courseData, courseDataLoading, courseDataError] = useDocumentData(
    doc(db, dataPath),
  );

  if (appDataLoading || isCompletionLoading || courseDataLoading) {
    return <></>;
  }

  if (appDataError || courseDataError) {
    return <>Error loading application. Please try again later</>;
  }

  if (!courseData) {
    return <>Error: unknown course {targetCourseId}</>;
  }

  const appOpen = courseData?.studentAppOpen;
  return (
    <JoinOuter>
      {confettiParticles}
      <LandingNav applicationType={'student'} subpage={null} />
      <div className="d-flex justify-content-center">
        <MainCard
          appData={appData}
          appDataPath={fbStudentApplicationPath}
          appOpen={appOpen}
          courseData={courseData}
        />
      </div>
    </JoinOuter>
  );
};

const MainCard = ({appData, appDataPath, appOpen, courseData}) => {
  const {targetCourseId} = useParams();
  const {assnProgress, lessonsProgress, setSelfCheckCompleted} =
    useContext(CompletionContext);
  const [onAppStatusUpdate] = useAppStatusServerRecorder(
    appData,
    appDataPath,
    'student',
  );

  const allData = {appData, assnProgress, lessonsProgress};
  const appStatus = computeApplicationStatus(allData, allChecks);
  onAppStatusUpdate(appStatus);

  return (
    <JoinInner className="container mb-2 mt-3">
      <div className="row mb-3 mt-3">
        <div className="col">
          <Header
            appStatus={appStatus}
            appDataPath={appDataPath}
            appOpen={appOpen}
            courseData={courseData}
          />
          <TodoCard
            title="1. Tell us about yourself"
            content={[
              {
                appOpen,
                title: 'About Code in Place 2024',
                to: `/public/join/${targetCourseId}/aboutCourse`,
                icon: FaRocket,
                isComplete: learnAboutCourseComplete(allData),
              },
              {
                appOpen,
                title: 'Fill in this form',
                to: `/public/join/${targetCourseId}/about`,
                icon: FaPen,
                isComplete: hasAppliction(allData),
              },
            ]}
          />
          <TodoCard
            title="2. Learn Karel basics"
            content={[
              {
                appOpen,
                title: 'Welcome',
                to: `/public/learn/welcome-to-karel`,
                icon: FaBookOpen,
                isComplete: lessonsProgress['welcome-to-karel'],
              },
              {
                appOpen,
                title: 'Control Flow',
                to: `/public/learn/control-flow`,
                icon: FaBookOpen,
                isComplete: lessonsProgress['control-flow'],
              },
              // {
              //   appOpen,
              //   title: "Read chapters 1 through 5",
              //   to: READER_URL,
              //   externalLink: true,
              //   preClick: () => {
              //     markReadingDone(appDataPath);
              //   },
              //   icon: faBookOpen,
              //   isComplete: readingDone(allData),
              // },
            ]}
          />
          <TodoCard
            title="3. Solve some problems"
            content={[
              {
                appOpen,
                title: 'Warmup',
                to: `/public/ide/a/warmup`,
                icon: FaPython,
                isComplete: assnProgress['warmup'],
              },
              {
                appOpen,
                title: "Karel's Home",
                to: `/public/ide/a/housekarel`,
                icon: FaPython,
                isComplete: assnProgress['housekarel'],
              },
              {
                appOpen,
                title: 'Piles',
                to: `/public/ide/a/piles`,
                icon: FaPython,
                isComplete: assnProgress['piles'],
              },
            ]}
          />
        </div>
      </div>
    </JoinInner>
  );
};

const Header = ({appStatus, appDataPath, appOpen, courseData}) => {
  // @ts-ignore
  const [user] = useAuthState(firebase.auth());

  let userGreetingStr = getDisplayName(user, {});
  const isComplete = appStatus === 'complete';

  const {targetCourseId} = useParams();
  const userId = useUserId();
  const db = getFirestore(getApp());
  const userRef = doc(db, `users/${userId}`);
  const [userData, userDataLoading, userDataError] = useDocumentData(userRef);

  if (userDataLoading) return <></>;
  const courseRole = userData?.roles?.[targetCourseId];

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card" style={{maxWidth: innerWidth}}>
        <div className="card-body">
          <h1>Welcome {userGreetingStr}</h1>
          <DueDate appOpen={appOpen} courseData={courseData} />
          <ApplicationMessage
            isComplete={isComplete}
            appOpen={appOpen}
            userData={userData}
            courseData={courseData}
          />
          <div style={{height: '20px'}} />
          {/* <AppInfo 
            appOpen={appOpen} 
            isComplete={isComplete} 
            courseData={courseData}
          /> */}

          <ApplicationStatus appStatus={appStatus} appDataPath={appDataPath} />
        </div>
      </div>
    </div>
  );
};

const ApplicationMessage = ({appOpen, isComplete, userData, courseData}) => {
  const {targetCourseId} = useParams();

  const {roles, isLoadingRoles} = useContext(EnrollmentContext);

  const courseRole = roles?.[targetCourseId];
  console.log(courseRole);
  // don't use the functions in Gate or profile_util as this string
  // has not been processed by ProfileContext
  const isAdmit = courseRole === Role.STUDENT;
  const isExpStudent = courseRole === Role.EXPERIENCED_STUDENT;
  const isStaff =
    courseRole === Role.ADMIN ||
    courseRole === Role.TA ||
    courseRole === Role.INSTRUCTOR;

  if (isExpStudent) {
    return (
      <div className="bordered">
        {/* <p>Hi {userData?.displayName},</p> */}
        <p>
          Wow! It looks like you are *already* an amazing programmer. We would
          like to welcome you to Code in Place 2024 in the special{' '}
          <b>Experienced Student</b> track. There will be no live meetings for
          this track, but you can participate in all of the other parts of the
          course, including getting a course certificate at the end.
        </p>
        <Link
          to={`/${targetCourseId}/onboarding/experiencedstudent`}
          className="btn btn-primary"
        >
          Experienced Student Onboarding
        </Link>
      </div>
    );
  }

  if (isAdmit) {
    return (
      <div className="bordered">
        {/* <p>Hi {userData?.displayName},</p> */}
        <p>
          Congratulations! You have been accepted to Code in Place. We were
          impressed by your application and look forward to having you in class.
          Please confirm your place in the course by the end of the day by noon
          on Sun April 23rd, 2023 <a href="https://time.is/PT">Pacific Time</a>.
          Section sign ups are first come, first serve, so come join as soon as
          you can.
        </p>
        <Link
          to={`/${targetCourseId}/onboarding/student`}
          className="btn btn-primary"
        >
          Student Onboarding
        </Link>
      </div>
    );
  }
  // if(!isStaff && !isAdmit && isComplete){
  //   return <div className="bordered">
  //     Thank you for taking the time to apply.
  //     The course filled up before we could get to your application.
  //     As you can appreciate, there is a maximum
  //     number of students that we can support.
  //     While this might not be the result you wanted, we would like to gently note that there are many wonderful place where you can go learn python.
  //   </div>
  // }
  // if (!isStaff && !isAdmit && !isComplete) {
  //   return <div className="bordered">
  //     We already have a full class. Feel free to explore the application and all the best on your learning journey!
  //   </div>
  // }
  if (isStaff) {
    <div className="bordered">Wahoo! You are on the staff team.</div>;
  }
  return (
    <>We 🌱 hope 🌱 you 🌱 have 🌱 fun 🌱 with 🌱 these 🌱 Karel 🌱 puzzles! </>
  );
};

const AppInfo = ({appOpen, isComplete, courseData}) => {
  const closedAndIncomplete = !appOpen && !isComplete;
  const courseName = courseData?.name;
  return (
    <>
      <div className="mb-2">
        <p>
          {!appOpen && (
            <span>
              Applications are closed and we have already downloaded
              applications for review.
              <br />
            </span>
          )}
          <div className="">
            Check this page on{' '}
            <span className="badge bg-primary">April 20th</span> to see if your
            application was selected.
          </div>
        </p>
        Apply to be part of {courseName} as a student. We 🌱 hope 🌱 you 🌱 have
        🌱 fun 🌱 with 🌱 these 🌱 Karel 🌱 puzzles! Learn more{' '}
        <a target="_blank" href="/">
          about the course
        </a>
        .
      </div>
    </>
  );
};

const DueDate = ({appOpen, courseData}) => {
  if (appOpen) {
    return <h4>Due {courseData?.studentAppDueDate}</h4>;
  } else {
    return <h4 className="">Applications Closed</h4>;
  }
};

export const TodoCard = ({title, content}) => {
  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card mt-3 w-100" style={{maxWidth: innerWidth}}>
        <div className="card-body">
          <h4 className="mb-3">{title}</h4>
          {content.map((item, index) => {
            return (
              <div key={index}>
                <h4>
                  <ItemLink item={item} />
                  {item.isComplete && <FaCheck className="ml-2 text-success" />}
                </h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ItemLink = ({item}) => {
  const navigate = useHistoryNavigate();
  const linkText = (
    <>
      {item.icon && <item.icon className="mr-2" />}
      {item.title}
    </>
  );

  // make it look like a link
  const linkStyle = {
    fontWeight: 500,
    cursor: 'pointer',
    color: '#337ab7',
  };

  async function onClick(e) {
    e.preventDefault();
    await item.preClick();

    // open item.to in new tab
    window.open(item.to, '_blank');
  }

  if (item.preClick) {
    return (
      <a style={linkStyle} href={item.to} onClick={e => onClick(e)}>
        {linkText}
      </a>
    );
  }

  return (
    <a style={linkStyle} onClick={() => navigate(item.to)} tabIndex={0}>
      {linkText}
    </a>
  );
};

const markReadingDone = async appDataPath => {
  const db = getFirestore();
  const appDocRef = doc(db, appDataPath);
  await setDoc(
    appDocRef,
    {
      readingDone: true,
    },
    {merge: true},
  );
};

function hasAppliction(allData) {
  return allData.appData !== undefined && allData.appData['name'];
}

function hasLesson(allData, lessonId) {
  return allData.lessonsProgress[lessonId];
}

function hasAssn(allData, assnId) {
  return allData.assnProgress[assnId];
}

function learnAboutCourseComplete(allData) {
  return allData.appData !== undefined && allData.appData['hasReadAboutCourse'];
}

const allChecks = [
  hasAppliction,
  d => hasLesson(d, 'welcome-to-karel'),
  d => hasLesson(d, 'control-flow'),
  d => hasAssn(d, 'warmup'),
  d => hasAssn(d, 'housekarel'),
  d => hasAssn(d, 'piles'),
];

const makeWhiteAlpha = alpha => {
  return `rgba(255,255,255,${1 - alpha})`;
};

function getDisplayName(user, aboutData) {
  if (aboutData && aboutData['name']) {
    return aboutData['name'];
  }

  return user.displayName;
}

const JoinOuter = styled.div`
  min-height: 100vh;
  background-image: url('/splash3.jpeg');
  overflow: auto;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const JoinInner = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  max-width: ${innerWidth};
  min-height: 800px;
`;

const backgroundStyle = {};

const UnreleasedAlert = () => {
  return (
    <div style={backgroundStyle}>
      <LandingNav applicationType={'student'} subpage={null} />
      <div className="d-flex justify-content-center">
        <div className="container mb-2 mt-3" style={{maxWidth: innerWidth}}>
          <div className="row mb-3 mt-3">
            <div className="col">
              <div className="alert alert-primary" role="alert">
                <b>Coming soon!</b> Check back next week.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
