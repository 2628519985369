import {getAdvancedConsoleUnitTestReference} from '../../models/unitTests/advancedConsole';
import {
  UnitTestLoaderParameters,
  UnitTestSchema,
  WithRunnerData,
} from '../UnitTestSchema';
import {getDoc} from 'firebase/firestore';
import {getFirstTestFailed, UnitTestResults} from '../unitTestResults';
import {
  AdvancedAutograderResultTypes,
  calculateMaxTrials,
  LoadedAdvancedConsoleUnitTestData,
  runTrial,
  verifyRandomVariables,
} from './advancedConsoleUtils';
import {AdvancedConsoleEditor} from './AdvancedConsoleEditor';

async function loader({
  courseId,
  assignmentId,
}: UnitTestLoaderParameters): Promise<LoadedAdvancedConsoleUnitTestData | null> {
  const advancedConsoleUnitTestReference = getAdvancedConsoleUnitTestReference(
    courseId,
    assignmentId,
  );
  const advancedConsoleUnitTestDoc = await getDoc(
    advancedConsoleUnitTestReference,
  );
  if (!advancedConsoleUnitTestDoc.exists()) {
    return null;
  }
  const advancedConsoleUnitTests = advancedConsoleUnitTestDoc.data();
  return {
    unitTests: advancedConsoleUnitTests,
  };
}

async function runner({
  loadedData,
  pyodideClient,
  filesCode,
  fileStructure,
}: WithRunnerData<LoadedAdvancedConsoleUnitTestData>): Promise<UnitTestResults> {
  const {unitTests} = loadedData ?? {unitTests: []};
  // Iterate over each test case/"branch" (each has a different script)
  for (const branch of unitTests) {
    // ! Object for holding vars across iterations. Updated by SIDE EFFECT from `runTrial`
    let randomVars = new Map();

    // ! This is updated after the first iteration. Uses recorded variables to calculate min trials for (default) tolerance=0.0001
    let trialsToRun = 1;

    // Run code `trialsToRun` times to make sure results are only falsely wrong with < `tolerance` probability
    for (let i = 0; i < trialsToRun; i++) {
      const {
        advancedAutograderState: {registeredVars, errorState, stopRequested},
        error,
      } = await runTrial(
        pyodideClient,
        filesCode,
        fileStructure,
        branch.gradingScripts,
        randomVars,
      );

      // Check for "early stopping" (i.e. if the user clicked the stop button).
      if (stopRequested) {
        return {
          testResults: [],
          testsRun: false,
        };
      }

      // Check if autograder ran into an error
      if (errorState.get('has_error')) {
        return {
          testResults: [
            {
              isSuccess: false,
              name: AdvancedAutograderResultTypes.AdvancedAutograderError,
              usedAi: false,
              rawResult: {message: errorState.get('message')},
            },
          ],
          testsRun: true,
        };
      }

      // Check if there is a Python runtime/syntax error, tell student to run the code
      if (error.length > 0) {
        return {
          testResults: [
            {
              isSuccess: false,
              name: AdvancedAutograderResultTypes.PythonError,
              usedAi: false,
              rawResult: {},
            },
          ],
          testsRun: true,
        };
      }

      // If on first iteration, use observed random variables to update # iterations
      if (i === 0) {
        trialsToRun = calculateMaxTrials(registeredVars);
      }
    }

    // Check distribution of vars accumulated over all trials
    const {allCorrect: allRangesCorrect, errorMessage} =
      verifyRandomVariables(randomVars);

    // Fail if some range is incorrect. Otherwise, move on to next branch!
    if (!allRangesCorrect) {
      return {
        testResults: [
          {
            isSuccess: false,
            name: AdvancedAutograderResultTypes.RandomVarError,
            usedAi: false,
            rawResult: {message: errorMessage},
          },
        ],
        testsRun: true,
      };
    }
  }

  // If no branches had incorrect ranges/other errors, we passed!
  return {
    testResults: [
      {
        isSuccess: true,
        name: AdvancedAutograderResultTypes.Success,
        usedAi: false,
        rawResult: {},
      },
    ],
    testsRun: true,
  };
}

function getFailureMessage(results: UnitTestResults) {
  const firstTestFailed = getFirstTestFailed(results);
  let text =
    "The program finish running, but something wasn't what we expected.";
  if (firstTestFailed === AdvancedAutograderResultTypes.PythonError) {
    text =
      "We couldn't run your program because we encountered an error. Run your program in the IDE to see what happened.";
  } else if (
    firstTestFailed === AdvancedAutograderResultTypes.AdvancedAutograderError
  ) {
    text = results.testResults[0].rawResult?.message || text;
  } else if (firstTestFailed === AdvancedAutograderResultTypes.RandomVarError) {
    text =
      results.testResults[0].rawResult?.message ||
      'There was an error in one of your random variables. Double check how you create and use them.';
  }

  return text;
}

export const ADVANCED_CONSOLE_UNIT_TEST_SCHEMA: UnitTestSchema<LoadedAdvancedConsoleUnitTestData> =
  {
    loader,
    runner,
    EditorComponent: AdvancedConsoleEditor,
    unitTestType: 'advanced_console',
    displayName: 'Reactive Console Test',
    tooltipDescription:
      'Runs a stateful console test that can react to student input.',
    getFailureMessage,
  };
