import {CopyButton} from 'components/reusableButtons/CopyButton';
import {Dispatch, SetStateAction} from 'react';
import {Stack} from 'react-bootstrap';
import styled from 'styled-components';

export const TITLE_BORDER_SIZE = '3px';
const TITLE_FONT_SIZE = '2rem';

interface EditableAssignmentTitleProps {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  editable: boolean;
  isMobile: boolean;
}

const StyledTitle = styled.input`
  border: ${TITLE_BORDER_SIZE} solid transparent;
  background-color: transparent;
  font-size: ${TITLE_FONT_SIZE};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    border: ${props =>
      props.disabled
        ? `${TITLE_BORDER_SIZE} solid transparent`
        : `${TITLE_BORDER_SIZE} solid #ccc`};
  }
  &:focus {
    border: ${props =>
      props.disabled
        ? `${TITLE_BORDER_SIZE} solid transparent`
        : `${TITLE_BORDER_SIZE} solid #ccc`};
  }
`;

// The max length of a title in the editor in desktop and mobile, in characters.
// This is to prevent the title from being too long and interfering with other
// UI components. We use the `size` prop rather than `width` because `input`
const MAX_DESKTOP_TITLE_LENGTH = 25;
const MAX_MOBILE_TITLE_LENGTH = 10;

export function EditableAssignmentTitle({
  title,
  setTitle,
  editable,
  isMobile,
}: EditableAssignmentTitleProps) {
  const maxLength = isMobile
    ? MAX_MOBILE_TITLE_LENGTH
    : MAX_DESKTOP_TITLE_LENGTH;
  return (
    <Stack direction="horizontal" gap={0}>
      <div className="d-inline-block">
        <StyledTitle
          className="p-0 m-0"
          value={title}
          onChange={e => setTitle(e.target.value)}
          disabled={!editable}
          size={Math.min(title.length, maxLength)}
        />
      </div>
      <CopyButton toCopy={title} tooltip="Copy Assignment Title" />
    </Stack>
  );
}
