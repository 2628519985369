import {CoursePageSingleCol} from 'components/layout/CoursePageSingleCol';
import {CourseContext} from 'contexts/CourseContext';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {useServerTime} from 'contexts/TimeContext';
import {
  doc,
  getFirestore,
  increment,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {useContext} from 'react';
import {useDocumentData} from 'react-firebase-hooks/firestore';
import {Link} from 'react-router-dom';
import {MakeupZoomSectionButton} from './MakeupSectionZoomButton';
import Loading from 'react-loading';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';

// you can use this many makeups per course
const ALLOWED_MAKEUPS = 3;
// you can join up to this many mins before the section
const EARLY_JOIN_MINS = 15;
// you can join up to this many mins after the section
const LATE_JOIN_MINS = 5;

export const MakeupSection = () => {
  const column = <MakeupSectionColumn />;
  return (
    <CoursePageBodyContainer
      mainColumn={column}
      singleColumn={column}
      rightColumn={null}
    />
  );
};

const MakeupSectionColumn = () => {
  const {currCourseWeek, sectionTimeDelta, firstSectionTimestamp} =
    useContext(CourseContext);
  // correct for the off by one with currCourseWeek
  const currCourseWeekCorrected = currCourseWeek - 1;
  const currWeekFirstSectionTime = getCurrWeekFirstSectionTime(
    firstSectionTimestamp,
    currCourseWeekCorrected,
  );

  const currWeekLastSectionTime = new Date(
    currWeekFirstSectionTime.getTime() + sectionTimeDelta * 60 * 60 * 1000,
  );

  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    day: 'numeric',
    month: 'short',
    weekday: 'short',
  };

  const currWeekSectionTimeLocal = currWeekFirstSectionTime.toLocaleString(
    navigator.language,
    options,
  );
  const currWeekLastSectionTimeLocal = currWeekLastSectionTime.toLocaleString(
    navigator.language,
    options,
  );

  return (
    <div className="mt-4">
      <h3>Makeup Section</h3>
      <p>
        If you can't make your section week, you can attend a makeup section.
        Makeup sections will run every single hour between{' '}
        <b>{currWeekSectionTimeLocal}</b> until{' '}
        <b>{currWeekLastSectionTimeLocal}</b>. All times are in your timezone.
        The Join Makeup button will only be clickable at the beginning of a
        section. You may join a section up to {EARLY_JOIN_MINS} mins before the
        section starts, and up to {LATE_JOIN_MINS} mins after it starts.
      </p>
      <MakeupSectionContent />
    </div>
  );
};

const MakeupSectionContent = ({}) => {
  const courseId = useCourseId();
  const {sectionTimeDelta, currCourseWeek} = useContext(CourseContext);
  const {sectionData, userData} = useContext(ProfileContext);
  const courseRole = userData.courseRole;
  const serverTimeMs = useServerTime();

  // this garuntees that makeupData will be populated even if it does not exist in the db
  const {makeupDataSafe, makeupDataLoading, setUsedMakeups} = useUserMakeup();

  // calculate the next sectionTimeIndex and the time until that section
  const {
    nextSectionTimeIndex, // number of hours from the first section of the week
    minsUntilNextSection, // how many mins until the above time slot (neg means you are late)
  } = useNextSectionInfo();

  const loading = makeupDataLoading || nextSectionTimeIndex == undefined;
  if (loading) return <Loading />;

  const makeupsLeft = ALLOWED_MAKEUPS - makeupDataSafe.makeupCount;

  // recall: sectionTimeDelta is the number of hours of section a week
  const isPastLastSection = nextSectionTimeIndex >= sectionTimeDelta;
  if (isPastLastSection) {
    return (
      <div>You are past the last section of the week. See you next week!</div>
    );
  }

  // if you aren't a student, you can't make up sections
  if (courseRole != Role.STUDENT && courseRole != Role.ADMIN) {
    return <div>Insufficient permissions</div>;
  }

  // if you aren't in a section, you must first sign up...
  if (!sectionData) {
    return (
      <div>
        You are not in a section yet. Sign up for a section here:
        <Link to={`/${courseId}/sectionswitch`}>sections switch</Link>
      </div>
    );
  }

  // if you have a fresh zoom link, show a rejoin!
  if (isZoomLinkFresh(makeupDataSafe, serverTimeMs)) {
    const zoomLink = makeupDataSafe.zoomLink;
    return (
      <div>
        You have a fresh zoom link! Join from{' '}
        <Link to={zoomLink}>zoom link</Link>
      </div>
    );
  }

  // get the first section from the sectionData map
  const mySection = Object.values(sectionData)[0];
  if (nextSectionTimeIndex == mySection.timeIndex) {
    return (
      <div>
        It is time for your section! Join from{' '}
        <Link to={`/${courseId}/section`}>your section page</Link>
      </div>
    );
  }

  const isDisabled =
    makeupsLeft <= 0 ||
    minsUntilNextSection > EARLY_JOIN_MINS ||
    minsUntilNextSection < -LATE_JOIN_MINS;

  // ceiling because its not 15mins if its 15.5mins away
  const roundedMins = Math.ceil(minsUntilNextSection);

  // the server sectionweeks start at 0
  const adjustedWeek = currCourseWeek - 1;
  return (
    <div>
      {/* make sure mins are */}
      <>
        Time until next section: <b>{displayMinsUntil(roundedMins)}</b>
        <br />
        <br />
      </>

      <MakeupZoomSectionButton
        disabled={isDisabled}
        setUsedMakeups={setUsedMakeups}
        nextSectionTimeIndex={nextSectionTimeIndex}
        weekIndex={adjustedWeek}
      />
      <br />
      <p className="alert alert-warning mt-4">
        Reminder: You can only attend 3 makeup sections per course. You
        currently have {makeupsLeft} left.
      </p>
      {courseRole == Role.ADMIN && (
        <>Next section time index: {nextSectionTimeIndex}</>
      )}
    </div>
  );
};

/**
 * What is the next section in Code in Place?
 */
const useNextSectionInfo = () => {
  // recall that currCourseWeek changes on monday at 9am
  const {currCourseWeek, firstSectionTimestamp} = useContext(CourseContext);
  const serverTimeMs = useServerTime();

  // this is used for testing
  const pretendServerTimeMs = serverTimeMs;

  // if the server hasn't loaded yet
  if (!serverTimeMs) {
    return {
      nextSectionTimeIndex: undefined,
      minsUntilNextSection: undefined,
    };
  }

  // correct for the off by one with currCourseWeek
  const currCourseWeekCorrected = currCourseWeek - 1;

  // get the current weeks first section time
  const currWeekFirstSectionTime = getCurrWeekFirstSectionTime(
    firstSectionTimestamp,
    currCourseWeekCorrected,
  );
  return getTimeUntilNextSection(currWeekFirstSectionTime, pretendServerTimeMs);
};

/**
 * What is the timestamp of the current week's first section
 * typically a Wed at 3p.
 */
function getCurrWeekFirstSectionTime(firstSectionTimestamp, currCourseWeek) {
  const msPerWeek = 7 * 24 * 60 * 60 * 1000;
  const currWeekDelta = currCourseWeek * msPerWeek;
  const firstDate = new Date(firstSectionTimestamp); // Converts to Date object if needed
  return new Date(firstDate.getTime() + currWeekDelta);
}

/**
 * What is the current section time index?
 *
 * if you are 5 mins away from your next section
 * minsUntilNextSection = 5
 *
 * if you are 10 mins past your next section
 * minsUntilNextSection = -10
 *
 * if you are 24 hours past a next section
 * minsUntilNextSection = -1440 (but we will keep reducing by one hour)
 *
 * we don't need to handle the case where its past the last section.
 * Instead we will return a timeIndex which the caller can recognize as
 * past the last section
 */
function getTimeUntilNextSection(firstSectionTimestamp, serverTimeMs) {
  const firstSectionTimeMs = firstSectionTimestamp.getTime();
  let minsUntilNextSection = (firstSectionTimeMs - serverTimeMs) / (1000 * 60);

  // if its more than 15 mins from the first section, return 0
  if (minsUntilNextSection > EARLY_JOIN_MINS) {
    return {
      nextSectionTimeIndex: 0,
      minsUntilNextSection,
    };
  }
  let nextSectionTimeIndex = 0;
  // keep adding an hour until we are at most 10 mins past the next section
  // not efficient, but easy to reason about
  while (minsUntilNextSection < -LATE_JOIN_MINS) {
    minsUntilNextSection += 60;
    nextSectionTimeIndex += 1;
  }
  return {
    nextSectionTimeIndex,
    minsUntilNextSection,
  };
}

/**
 * The zoom link stays fresh for 1 hour and 15 mins so that you can rejoin
 * a section that you have started. This could prevent you from doing two
 * makeup sections in a row. That might be a reasonable use case if you
 * get dropped from the call? But that is a feature for the future...
 */
function isZoomLinkFresh(makeupData, serverTimeMs) {
  if (!makeupData) return false;
  if (!makeupData?.lastMakeup) return false;
  const lastMakeup = makeupData.lastMakeup.toDate();
  const msSinceLastMakeup = serverTimeMs - lastMakeup.getTime();
  const freshMs = (60 + 15) * 60 * 1000;
  return msSinceLastMakeup < freshMs;
}

const useUserMakeup = () => {
  const userId = useUserId();
  const courseId = useCourseId();
  const path = `users/${userId}/${courseId}/makeup`;
  const userMakeupDocRef = doc(getFirestore(), path);
  const [makeupData, makeupDataLoading, makeupDataError] =
    useDocumentData(userMakeupDocRef);

  const setUsedMakeups = async (zoomLink, sectionTimeIndex) => {
    await setDoc(
      userMakeupDocRef,
      {
        makeupCount:
          makeupData && 'makeupCount' in makeupData ? increment(1) : 1,
        zoomLink,
        lastMakeup: serverTimestamp(),
        lastMakeupTimeIndex: sectionTimeIndex,
      },
      {merge: true},
    );
  };

  const makeupDataSafe = {
    ...makeupData,
  };
  if (!('makeupCount' in makeupDataSafe)) {
    makeupDataSafe.makeupCount = 0;
  }

  return {makeupDataSafe, makeupDataLoading, setUsedMakeups};
};

function displayMinsUntil(roundedMins) {
  let nHours = 0;
  let nMins = roundedMins;
  while (nMins >= 60) {
    nMins -= 60;
    nHours += 1;
  }
  if (nHours > 0) {
    return `${nHours} hours and ${nMins} mins`;
  }
  return `${nMins} mins`;
}
