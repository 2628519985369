import {setDoc} from 'firebase/firestore';
import {
  getKarelUnitTestReference,
  isKarelUnitTest,
  KarelUnitTest,
} from '../../models/unitTests/karel';
import {uuidv4} from 'lib0/random';
import {type LoadedKarelUnitTestData} from './types';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {deepCopy} from '@firebase/util';
import {WorldEditor} from 'components/pyodide/KarelLib/WorldEditor';
import {FaTrash} from 'react-icons/fa';
import {WithEditorData} from '../UnitTestSchema';
import {areWorldsEqual} from 'components/pyodide/KarelLib/util';
import {useDebounce} from 'use-debounce';
import Swal from 'sweetalert2';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from '../constants';
import {IKarelState} from 'components/pyodide/KarelLib/karelInterfaces';

export const KarelEditor = ({
  unitTestLoaderParameters,
  loadedData,
  isEditable,
}: WithEditorData<LoadedKarelUnitTestData>) => {
  const {courseId, assignmentId} = unitTestLoaderParameters;
  const {unitTests: serverUnitTests} = loadedData;
  const initUnitTests: KarelUnitTest[] = !!serverUnitTests
    ? serverUnitTests.filter(isKarelUnitTest)
    : [];
  const [unitTests, setUnitTests] = useState<KarelUnitTest[]>(initUnitTests);
  const [previouslyUploadedUnitTests, setPreviouslyUploadedUnitTests] =
    useState<KarelUnitTest[]>(initUnitTests);
  function areUnitTestsEqual(a: KarelUnitTest[], b: KarelUnitTest[]): boolean {
    return (
      a.length === b.length &&
      a.every(
        (unitTest, index) =>
          unitTest.key === b[index].key &&
          unitTest.name === b[index].name &&
          areWorldsEqual(unitTest.pre, b[index].pre) &&
          areWorldsEqual(unitTest.post, b[index].post),
      )
    );
  }
  const [debouncedUnitTests] = useDebounce(
    unitTests,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
    {
      equalityFn: areUnitTestsEqual,
    },
  );
  const firebaseUnitTestDocumentReference = getKarelUnitTestReference(
    courseId,
    assignmentId,
  );
  const [unitTestsDocument, unitTestsDocumentLoading, unitTestsDocumentError] =
    useDocumentDataOnce(firebaseUnitTestDocumentReference);
  useEffect(() => {
    if (
      unitTestsDocumentLoading ||
      unitTestsDocumentError ||
      !unitTestsDocument
    ) {
      return;
    }
    setUnitTests(unitTestsDocument);
    setPreviouslyUploadedUnitTests(unitTestsDocument);
  }, [unitTestsDocumentLoading]);
  const shouldSave =
    !unitTestsDocumentLoading &&
    !unitTestsDocumentError &&
    !areUnitTestsEqual(debouncedUnitTests, previouslyUploadedUnitTests);
  useEffect(() => {
    if (!shouldSave) return;
    setDoc(firebaseUnitTestDocumentReference, debouncedUnitTests)
      .then(() => {
        setPreviouslyUploadedUnitTests(debouncedUnitTests);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving unit tests',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving unit tests', error);
      });
  }, [shouldSave]);

  const deleteUnitTest = i => {
    setUnitTests(oldUnitTests => {
      const newUnitTest = deepCopy(oldUnitTests);
      newUnitTest.splice(i, 1);
      return newUnitTest;
    });
  };

  const editUnitTest = (i, key, value) => {
    if (i < 0 || i >= unitTests.length) {
      return;
    }
    setUnitTests(oldUnitTests => {
      const newUnitTest = deepCopy(oldUnitTests);
      newUnitTest[i][key] = value;
      return newUnitTest;
    });
  };

  const addUnitTest = () => {
    const pre = makeDefaultWorld();
    const post = makeDefaultWorld();
    setUnitTests(oldUnitTests => {
      // since unitTest is a compound object
      // we use this way of updating state (otherwise view
      // wont react)
      const newUnitTest = deepCopy(oldUnitTests);
      newUnitTest.push({
        pre,
        post,
        name: `${pre.nRows}x${pre.nCols}`,
        key: uuidv4(),
      });
      return newUnitTest;
    });
  };

  return (
    <div>
      <div className="d-flex flex-column mt-3">
        {unitTests.map(function (unitTestData, i) {
          if (!unitTests[i].key) {
            unitTests[i].key = uuidv4();
          }
          return (
            <EditableKarelUnitTest
              unitTestData={unitTestData}
              deleteUnitTest={() => deleteUnitTest(i)}
              editUnitTest={(k, v) => editUnitTest(i, k, v)}
              key={unitTests[i].key}
              isEditable={isEditable}
            />
          );
        })}
      </div>
      <button
        onClick={() => addUnitTest()}
        disabled={unitTests.length >= 1 || !isEditable}
        className="btn btn-secondary "
      >
        Add Unit Test
      </button>
    </div>
  );
};

function makeDefaultWorld(): IKarelState {
  return {
    nRows: 3,
    nCols: 4,
    karelRow: 0,
    karelCol: 0,
    karelDir: 'East',
    walls: {},
    beepers: {},
    paint: {},
    actionHistory: [],
  };
}

interface EditableKarelUnitTestProps {
  unitTestData: KarelUnitTest;
  deleteUnitTest: () => void;
  editUnitTest: (key: string, value: any) => void;
  isEditable: boolean;
}
const EditableKarelUnitTest = ({
  unitTestData,
  deleteUnitTest,
  editUnitTest,
  isEditable,
}: EditableKarelUnitTestProps) => {
  const setPreState: Dispatch<SetStateAction<IKarelState>> = valueOrUpdater => {
    if (typeof valueOrUpdater === 'function') {
      const updater = valueOrUpdater;
      editUnitTest('pre', updater(unitTestData.pre));
    } else {
      editUnitTest('pre', valueOrUpdater);
    }
  };

  const setPostState: Dispatch<
    SetStateAction<IKarelState>
  > = valueOrUpdater => {
    if (typeof valueOrUpdater === 'function') {
      const updater = valueOrUpdater;
      editUnitTest('post', updater(unitTestData.post));
    } else {
      editUnitTest('post', valueOrUpdater);
    }
  };

  const updateName = newName => {
    editUnitTest('name', newName);
  };

  return (
    <>
      <b>Pre:</b>
      <WorldEditor
        worldState={unitTestData.pre}
        setWorldState={setPreState}
        isEditable={isEditable}
      />
      <b>Post:</b>
      <WorldEditor
        worldState={unitTestData.post}
        setWorldState={setPostState}
        isEditable={isEditable}
      />
      <b>Name:</b>
      <input
        type="text"
        className="form-control"
        placeholder="Enter title"
        value={unitTestData.name}
        onChange={e => {
          updateName(e.target.value);
        }}
        disabled={!isEditable}
      />
      <div>
        <button
          onClick={deleteUnitTest}
          className="btn btn-light"
          disabled={!isEditable}
        >
          <FaTrash />
        </button>
        <hr />
      </div>
    </>
  );
};
