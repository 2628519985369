import Editor from '@monaco-editor/react';
import {useEffect, useRef, useState} from 'react';

export const ExpandableCodeEditor = ({value, onChange, readOnly, mode = 'python'}) => {
  const [editorHeight, setEditorHeight] = useState('400px'); // Default height
  
  const handleEditorDidMount = (editor, monaco) => {
    // every time the content changes, recalculate the height
    recalculateEditorHeight(monaco, editor)
    editor.onDidChangeModelContent(() => {
      recalculateEditorHeight(monaco, editor)
    });
  };

  const recalculateEditorHeight = (monaco, editor) => {
    // needs access to the editor, which is only available in handleEditorDidMount
    const lineCount = editor.getModel()?.getLineCount() || 1;
    const lineHeight = editor.getOption(monaco.editor.EditorOption.lineHeight);
    const newHeight = lineCount * lineHeight;
    setEditorHeight(`${newHeight + 5}px`);
  }

  return (
    <Editor
      width={'100%'}
      height={editorHeight}
      value={value}
      onChange={onChange}
      defaultLanguage={mode}
      options={{
        readOnly: readOnly,
        fontSize: 14,
        padding: {top: 0, bottom: 0},
        scrollBeyondLastColumn: 0,
        scrollBeyondLastLine: false,
        scrollbar: {
          vertical: 'hidden',
          horizontal: 'hidden',
          alwaysConsumeMouseWheel: false,
        },

        minimap: {
          enabled: false,
        },
      }}
      onMount={handleEditorDidMount}
    />
  );
};
