/**
 * The metadata for a file in the IDE. This allows the IDE to look up the file
 * by its project file ID and path within the project file system.
 */
export interface IdeFileData {
  /** The path of the file within the project file system. */
  name: string;
  /** The project file ID of the file. */
  id: ProjectFileId;
}

/**
 * A map of the project file ID to the file's contents.
 */
export interface ProjectFilesCode {
  /** The project file ID of the file. */
  [projectFileId: ProjectFileId]: {
    /** The contents of the file. */
    content: string;
  };
}

export const PROJECT_FOLDER_TYPE = 'folder';
export interface ProjectFolder {
  /** The name of the folder. */
  name: string;
  /** The type of the folder. */
  type: typeof PROJECT_FOLDER_TYPE;
  /** The files in the folder. */
  files: ProjectFileStructure;
}

export const PROJECT_FILE_TYPE = 'file';
export interface ProjectFile {
  /** The name of the file. */
  name: string;
  /** The project file ID of the file. */
  id: ProjectFileId;
  /** The type of the file. */
  type: typeof PROJECT_FILE_TYPE;
  /** The format of the file (e.g. "doc", "image"). */
  format?: string;
  /** Whether this file was generated by the starter code of the assignment. */
  starterFile?: boolean;
  /** The URL of the file (for images). */
  url?: string;
}

/**
 * The file structure of a user project. Typically represented as an array of
 * one element, the root folder of the project, which in turn contains the
 * files and folders of the project.
 */
export type ProjectFileStructure = (ProjectFolder | ProjectFile)[];

/**
 * The ID of a file in the project file system.
 */
export type ProjectFileId = string;
/** The ID of a Project (a unique set of files owned by a user). */
export type ProjectId = string;

// Typescript boilerplate to verify types at runtime
export function isProjectFile(data: any): data is ProjectFile {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return (
    'id' in data &&
    typeof data.id === 'string' &&
    'name' in data &&
    typeof data.name === 'string' &&
    'type' in data &&
    data.type === PROJECT_FILE_TYPE &&
    (typeof data.format === 'undefined' || typeof data.format === 'string') &&
    (typeof data.starterFile === 'undefined' ||
      typeof data.starterFile === 'boolean') &&
    (typeof data.url === 'undefined' || typeof data.url === 'string')
  );
}

export function isProjectFolder(data: any): data is ProjectFolder {
  if (!data || typeof data !== 'object') {
    return false;
  }
  return (
    'name' in data &&
    typeof data.name === 'string' &&
    data.type === PROJECT_FOLDER_TYPE &&
    Array.isArray(data.files) &&
    data.files.every(entry => {
      if (entry.type === PROJECT_FILE_TYPE) {
        return isProjectFile(entry);
      } else if (entry.type === PROJECT_FOLDER_TYPE) {
        return isProjectFolder(entry);
      }
      return false;
    })
  );
}

export function isProjectFileStructure(
  data: any,
): data is ProjectFileStructure {
  if (!data || !Array.isArray(data)) {
    return false;
  }
  return data.every(entry => {
    if (entry.type === PROJECT_FILE_TYPE) {
      return isProjectFile(entry);
    } else if (entry.type === PROJECT_FOLDER_TYPE) {
      return isProjectFolder(entry);
    }
    return false;
  });
}
