import {
  Container,
  Row,
  Col,
  Stack,
  Button,
  OverlayTrigger,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Placeholder,
} from 'react-bootstrap';
import {FaPlay} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {isDevelopmentEnvironment} from 'utils/general';
import {
  EditableAssignmentTitle,
  TITLE_BORDER_SIZE,
} from './EditableAssignmentTitle';
import {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';

interface AssignmentHeader {
  assignmentTitle: string;
  setAssignmentTitle: (title: string) => void;
  assignmentEditable: boolean;
  setAssignmentEditable: (editable: boolean) => void;
  assignmentType: string;
  assignmentPath: string;
  isMobile: boolean;
  shouldShowPlaceholder?: boolean;
  setShouldShowPlaceholder?: (show: boolean) => void;
}

export const AssignmentHeader = ({
  assignmentTitle,
  setAssignmentTitle,
  assignmentEditable,
  setAssignmentEditable,
  assignmentType,
  assignmentPath,
  isMobile,
  shouldShowPlaceholder,
  setShouldShowPlaceholder,
}: AssignmentHeader) => {
  const navigate = useNavigate();

  return (
    <Container className="bg-white">
      <Row className="align-items-center">
        <Col xs={8} className="justify-content-start p-0">
          <Stack direction="horizontal" gap={0}>
            <EditableAssignmentTitle
              title={assignmentTitle}
              setTitle={setAssignmentTitle}
              editable={assignmentEditable}
              isMobile={isMobile}
            />
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Open in the IDE</Tooltip>}
            >
              <Button
                className="btn-light"
                onClick={() => navigate(assignmentPath)}
              >
                <FaPlay />
              </Button>
            </OverlayTrigger>
          </Stack>
        </Col>
        <Col xs={4} className="d-flex justify-content-end">
          <SetAssignmentEditableButton
            editable={assignmentEditable}
            setEditable={setAssignmentEditable}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>
            Assignment Type:{' '}
            <span className="text-muted">{assignmentType}</span>
          </h3>
        </Col>
        {isDevelopmentEnvironment() && (
          <Col className="d-flex justify-content-end">
            <PlaceholderToggleButton
              shouldShowPlaceholder={shouldShowPlaceholder}
              setShouldShowPlaceholder={setShouldShowPlaceholder}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

// Placeholders are larger than the normal text
const TITLE_PLACEHOLDER_FONT_SIZE = '1.25rem';

interface AssignmentHeaderPlaceholderProps {
  shouldShowPlaceholder: boolean;
  setShouldShowPlaceholder: Dispatch<SetStateAction<boolean>>;
}

export function AssignmentHeaderPlaceholder({
  shouldShowPlaceholder,
  setShouldShowPlaceholder,
}: AssignmentHeaderPlaceholderProps) {
  return (
    <Container className="bg-white">
      <Row className="align-items-center py-1">
        <Col sm={'auto'} className="justify-content-start p-0">
          <Placeholder as="p" animation="glow" className="user-select-none">
            <Placeholder
              style={{
                fontSize: TITLE_PLACEHOLDER_FONT_SIZE,
                border: `${TITLE_BORDER_SIZE} solid transparent`,
              }}
            >
              Placeholder Title for Loading
            </Placeholder>
          </Placeholder>
        </Col>
        <Col>
          <OverlayTrigger
            placement="right"
            overlay={<Tooltip>Loading...</Tooltip>}
          >
            <Placeholder.Button variant="light" disabled>
              <FaPlay />
            </Placeholder.Button>
          </OverlayTrigger>
        </Col>
        <Col className="d-flex justify-content-end">
          <Placeholder.Button variant="secondary" disabled>
            Start Editing
          </Placeholder.Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            Assignment Type:{' '}
            <span className="text-muted">
              <Placeholder animation="glow" className="user-select-none">
                <Placeholder className="text-secondary">Console</Placeholder>
              </Placeholder>
            </span>
          </h4>
        </Col>
        {isDevelopmentEnvironment() && (
          <Col sm={'auto'} className="d-flex justify-content-end">
            <PlaceholderToggleButton
              shouldShowPlaceholder={shouldShowPlaceholder}
              setShouldShowPlaceholder={setShouldShowPlaceholder}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}

interface SetAssignmentEditableButtonProps {
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
}
function SetAssignmentEditableButton({
  editable,
  setEditable,
}: SetAssignmentEditableButtonProps) {
  return (
    <ToggleButtonGroup type="checkbox">
      <ToggleButton
        id="editable-toggle"
        checked={editable}
        value={1}
        variant={editable ? 'primary' : 'secondary'}
        onChange={e => setEditable(e.currentTarget.checked)}
      >
        {editable ? 'Stop Editing' : 'Start Editing'}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

interface PlaceholderToggleButtonProps {
  shouldShowPlaceholder: boolean;
  setShouldShowPlaceholder: Dispatch<SetStateAction<boolean>>;
}
function PlaceholderToggleButton({
  shouldShowPlaceholder: showPlaceholder,
  setShouldShowPlaceholder: setShowPlaceholder,
}: PlaceholderToggleButtonProps) {
  return (
    <OverlayTrigger
      placement="left"
      overlay={<Tooltip>Shows placeholder (for testing)</Tooltip>}
    >
      <Button
        variant={showPlaceholder ? 'secondary' : 'primary'}
        onClick={() => setShowPlaceholder(!showPlaceholder)}
      >
        {showPlaceholder ? 'Hide Placeholder' : 'Show Placeholder'}
      </Button>
    </OverlayTrigger>
  );
}
