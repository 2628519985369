/** A per-course unique ID for an assignment. */
export type AssignmentId = string;
/** A per-assignment unique ID for a user submission. */
export type SubmissionId = string;

/** The canonical frontend list of supported assignment types. */
export const SUPPORTED_ASSIGNMENT_TYPES = [
  'karel',
  'console',
  'graphics',
] as const;

/** The canonical frontend list of supported autograder types. Should be updated
 *  whenever a new autograder type is added.
 * - `karel`: Karel pre/post world comparison.
 * - `console`: Console input/output comparison.
 * - `advanced_console`: Reactive console test.
 * - `graphics`: Graphics canvas comparison.
 * - `ai`: AI-based evaluation.
 * - `self_graded`: Self-graded.
 * - `none`: No autograder.
 */
export const SUPPORTED_ASSIGNMENT_UNIT_TEST_TYPES = [
  'karel',
  'console',
  'advanced_console',
  'graphics',
  'ai',
  'self_graded',
  'none',
] as const;

/** The canonical frontend list of supported autograder behaviors. That is, when
 *  to run the autograder for a given assignment.
 * - `on_run`: Run the autograder every time the student runs their code.
 * - `on_check_correct`: Run the autograder when the student checks their code
 *   for correctness with the "Check Correct" button
 */
export const SUPPORTED_ASSIGNMENT_UNIT_TEST_BEHAVIORS = [
  'on_run',
  'on_check_correct',
] as const;

/** The canonical frontend list of supported show solution behaviors. These
 *  behaviors determine when to show the solution in the IDE.
 * - `always`: Always show the solution.
 * - `if_student_solved`: Show the solution if the student has solved the
 *   assignment.
 * - `never`: Never show the solution.
 */
export const SUPPORTED_SHOW_SOLUTION_BEHAVIORS = [
  'always',
  'if_student_solved',
  'never',
] as const;

/** When generating dialogue for a basic console autograding flow, a line may
 *  either be an output (print) or an input with some additional output.
 */
export const CONSOLE_UNIT_TEST_DIALOGUE_TYPES = ['print', 'input'] as const;

export const ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES: Record<
  AssignmentType,
  AssignmentUnitTestType[]
> = {
  karel: ['karel', 'self_graded', 'none'],
  console: ['console', 'advanced_console', 'ai', 'self_graded', 'none'],
  graphics: ['graphics', 'ai', 'self_graded', 'none'],
};

export const ASSIGNMENT_TYPE_TO_DISPLAY_NAME: Record<AssignmentType, string> = {
  karel: 'Karel',
  console: 'Console',
  graphics: 'Graphics',
};

export const ASSIGNMENT_UNIT_TEST_TYPE_TO_DISPLAY_NAME: Record<
  AssignmentUnitTestType,
  string
> = {
  karel: 'Karel Pre/Post World Comparison',
  console: 'Console Input/Output Script',
  advanced_console: 'Reactive Console Test',
  graphics: 'Graphics Canvas Match',
  ai: 'AI-based Evaluation',
  self_graded: 'Self-Graded',
  none: 'None',
};

export const ASSIGNMENT_UNIT_TEST_TOOLTIPS: Record<
  AssignmentUnitTestType,
  string
> = {
  karel:
    'Compare the world state before and after running the student code to the solution.',
  console:
    'Compares a transcript of input/output pairs against the student code.',
  advanced_console:
    'Runs a stateful console test that can react to student input.',
  graphics:
    'Compares the graphics canvas state between the student code and the solution.',
  ai: 'Use an AI model to evaluate the student code.',
  self_graded: 'Allow students to grade themselves.',
  none: 'No autograder.',
};

export const ASSIGNMENT_UNIT_TEST_BEHAVIOR_TO_DISPLAY_NAME: Record<
  AssignmentUnitTestBehavior,
  string
> = {
  on_run: 'On Run',
  on_check_correct: 'On Check Correct',
};

export const ASSIGNMENT_UNIT_TEST_BEHAVIOR_TOOLTIPS: Record<
  AssignmentUnitTestBehavior,
  string
> = {
  on_run: 'Run the autograder when the student runs their code.',
  on_check_correct:
    'Run the autograder when the student checks their code for correctness.',
};

export const SHOW_SOLUTION_BEHAVIOR_DISPLAY_NAMES: Record<
  ShowSolutionBehavior,
  string
> = {
  always: 'Always',
  if_student_solved: 'If Student Solved',
  never: 'Never',
};

export const SHOW_SOLUTION_BEHAVIOR_TOOLTIPS: Record<
  ShowSolutionBehavior,
  string
> = {
  always: 'Always show the solution (e.g. for a practice assignment).',
  if_student_solved:
    'Show the solution if the student has already solved the assignment.',
  never: 'Never show the solution (e.g. for homework assignments).',
};

// Below is boilerplate code to validate the types at runtime.
export type AssignmentType = typeof SUPPORTED_ASSIGNMENT_TYPES[number];
export function isAssignmentType(input: any): input is AssignmentType {
  return (
    typeof input === 'string' &&
    SUPPORTED_ASSIGNMENT_TYPES.includes(input as AssignmentType)
  );
}

export type AssignmentUnitTestType =
  typeof SUPPORTED_ASSIGNMENT_UNIT_TEST_TYPES[number];
export function isAssignmentUnitTestType(
  input: any,
): input is AssignmentUnitTestType {
  return (
    typeof input === 'string' &&
    SUPPORTED_ASSIGNMENT_UNIT_TEST_TYPES.includes(
      input as AssignmentUnitTestType,
    )
  );
}

export type AssignmentUnitTestBehavior =
  typeof SUPPORTED_ASSIGNMENT_UNIT_TEST_BEHAVIORS[number];
export function isUnitTestBehavior(
  input: any,
): input is AssignmentUnitTestBehavior {
  return (
    typeof input === 'string' &&
    SUPPORTED_ASSIGNMENT_UNIT_TEST_BEHAVIORS.includes(
      input as AssignmentUnitTestBehavior,
    )
  );
}

export type ShowSolutionBehavior =
  typeof SUPPORTED_SHOW_SOLUTION_BEHAVIORS[number];
export function isShowSolutionBehavior(
  input: any,
): input is ShowSolutionBehavior {
  return (
    typeof input === 'string' &&
    SUPPORTED_SHOW_SOLUTION_BEHAVIORS.includes(input as ShowSolutionBehavior)
  );
}

export type ConsoleUnitTestDialogueType =
  typeof CONSOLE_UNIT_TEST_DIALOGUE_TYPES[number];
export function isConsoleAutograderDialogueType(
  input: any,
): input is ConsoleUnitTestDialogueType {
  return (
    typeof input === 'string' &&
    CONSOLE_UNIT_TEST_DIALOGUE_TYPES.includes(
      input as ConsoleUnitTestDialogueType,
    )
  );
}
