import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {Role} from 'types/role';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useParams} from 'react-router-dom';

export const HandoutPage = ({}) => {
  return (
    <CoursePageBodyContainer
      mainColumn={<HandoutInner />}
      rightColumn={<></>}
      singleColumn={<HandoutInner />}
    />
  );
};

const HandoutInner = () => {
  const courseId = useCourseId();
  const handoutId = useParams().handoutId;

  return (
    <div style={{marginTop: 20}}>
      <AdminRichTextEditor
        firebaseDocPath={`course/${courseId}/handouts/${handoutId}`}
        minRole={Role.TA}
      />
    </div>
  );
};
