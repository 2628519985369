import {deepCopy} from '@firebase/util';
import {setDoc} from 'firebase/firestore';
import {useState, useEffect} from 'react';
import {getProjectType} from 'ide/utils/general';
import {
  AiHiddenExample,
  AiHiddenExampleCorrectness,
  getAiHiddenExamplesReference,
  isAiHiddenExample,
  SUPPORTED_AI_HIDDEN_EXAMPLE_CORRECTNESS_VALUES,
} from '../../models/unitTests/aiHiddenExamples';
import {uuidv4} from 'lib0/random';
import {FaTrash} from 'react-icons/fa';
import {Button, Dropdown, DropdownButton} from 'react-bootstrap';
import {WithEditorData} from '../UnitTestSchema';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {useDebounce} from 'use-debounce';
import Swal from 'sweetalert2';
import {ExpandableCodeEditor} from 'components/editor/ExpandableCodeEditor';
import {ASSIGNMENT_DATA_DEBOUNCE_MS} from '../constants';
import {
  SelectDropdown,
  SelectOption,
} from 'components/reusableButtons/SelectDropdown';

export const AiHiddenExampleEditor = ({
  unitTestLoaderParameters,
  isEditable,
}: WithEditorData<{}>) => {
  const {courseId, assignmentId, serverAssignmentData} =
    unitTestLoaderParameters;
  const serverHiddenExamples = serverAssignmentData?.hiddenExamples ?? [];
  const initHiddenExamples = Array.isArray(serverHiddenExamples)
    ? serverHiddenExamples.filter(isAiHiddenExample)
    : [];
  const [hiddenExamples, setHiddenExamples] =
    useState<AiHiddenExample[]>(initHiddenExamples);
  const [
    previouslyUploadedHiddenExamples,
    setPreviouslyUploadedHiddenExamples,
  ] = useState<AiHiddenExample[]>(initHiddenExamples);
  const [debouncedHiddenExamples] = useDebounce(
    hiddenExamples,
    ASSIGNMENT_DATA_DEBOUNCE_MS,
  );
  const assnType = getProjectType({}, serverAssignmentData) ?? '';
  const firebaseHiddenExamplesReference = getAiHiddenExamplesReference(
    courseId,
    assignmentId,
  );
  const [
    hiddenExamplesDocument,
    hiddenExamplesDocumentLoading,
    hiddenExamplesDocumentError,
  ] = useDocumentDataOnce(firebaseHiddenExamplesReference);

  function haveHiddenExamplesChanged() {
    if (hiddenExamplesDocumentLoading || hiddenExamplesDocumentError) {
      return false;
    }
    if (
      debouncedHiddenExamples.length !==
      previouslyUploadedHiddenExamples?.length
    ) {
      return true;
    }
    return debouncedHiddenExamples.some((example, i) => {
      return example !== previouslyUploadedHiddenExamples?.[i];
    });
  }
  useEffect(() => {
    if (
      hiddenExamplesDocumentLoading ||
      hiddenExamplesDocumentError ||
      !hiddenExamplesDocument
    ) {
      return;
    }
    setHiddenExamples(hiddenExamplesDocument);
    setPreviouslyUploadedHiddenExamples(hiddenExamplesDocument);
  }, [hiddenExamplesDocumentLoading]);
  const shouldSave = haveHiddenExamplesChanged();
  useEffect(() => {
    if (!shouldSave) {
      return;
    }
    setDoc(firebaseHiddenExamplesReference, debouncedHiddenExamples)
      .then(() => {
        setPreviouslyUploadedHiddenExamples(debouncedHiddenExamples);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error saving hidden examples',
          icon: 'error',
          toast: true,
          position: 'bottom',
          showConfirmButton: false,
        });
        console.error('Error saving hidden examples: ', error);
      });
  }, [shouldSave]);

  const deleteHiddenExample = i => {
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExample = deepCopy(oldHiddenExamples);
      newHiddenExample.splice(i, 1);
      return newHiddenExample;
    });
  };

  const addHiddenExample = () => {
    switch (assnType) {
      case 'karel':
        break;
      case 'console':
        setHiddenExamples(oldHiddenExamples => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const starterCode = serverAssignmentData?.starterCode ?? '';
          const newHiddenExample = deepCopy(oldHiddenExamples);
          newHiddenExample.push({
            name: 'New Hidden Example',
            correctness: 'correct',
            code: starterCode['main.py'] ?? '',
            key: uuidv4(),
          });
          return newHiddenExample;
        });
        break;
      case 'graphics':
        setHiddenExamples(oldHiddenExamples => {
          // since unitTest is a compound object
          // we use this way of updating state (otherwise view
          // wont react)
          const starterCode = serverAssignmentData?.starterCode ?? '';
          const newHiddenExample = deepCopy(oldHiddenExamples);
          newHiddenExample.push({
            name: 'New Hidden Example',
            correctness: 'correct',
            code: starterCode['main.py'] ?? '',
            key: uuidv4(),
          });
          return newHiddenExample;
        });
        break;
    }
  };

  function setCode(i: number, code: string) {
    if (i < 0 || i >= hiddenExamples.length) {
      return;
    }
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExamples = deepCopy(oldHiddenExamples);
      newHiddenExamples[i].code = code;
      return newHiddenExamples;
    });
  }

  function setName(i: number, name: string) {
    if (i < 0 || i >= hiddenExamples.length) {
      return;
    }
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExamples = deepCopy(oldHiddenExamples);
      newHiddenExamples[i].name = name;
      return newHiddenExamples;
    });
  }

  function setCorrectness(i: number, correctness: AiHiddenExampleCorrectness) {
    if (i < 0 || i >= hiddenExamples.length) {
      return;
    }
    setHiddenExamples(oldHiddenExamples => {
      const newHiddenExamples = deepCopy(oldHiddenExamples);
      newHiddenExamples[i].correctness = correctness;
      return newHiddenExamples;
    });
  }

  return (
    <div className="">
      <div className="d-flex flex-column mt-3">
        <h4>Hidden Examples</h4>
        <p>
          These code samples will be used for the autograder and automatic
          generation of unit tests.
        </p>

        {hiddenExamples.map(function (hiddenExampleData, i) {
          if (!hiddenExamples[i].key) {
            hiddenExamples[i].key = uuidv4();
          }
          switch (assnType) {
            case 'karel':
              break;
            case 'console':
              return (
                <EditableHiddenExample
                  hiddenExampleData={hiddenExampleData}
                  setCode={code => setCode(i, code)}
                  setName={name => setName(i, name)}
                  setCorrectness={correctness => setCorrectness(i, correctness)}
                  deleteHiddenExample={() => deleteHiddenExample(i)}
                  isEditable={isEditable}
                />
              );
            case 'graphics':
              return (
                <EditableHiddenExample
                  hiddenExampleData={hiddenExampleData}
                  setCode={code => setCode(i, code)}
                  setName={name => setName(i, name)}
                  setCorrectness={correctness => setCorrectness(i, correctness)}
                  deleteHiddenExample={() => deleteHiddenExample(i)}
                  isEditable={isEditable}
                />
              );
          }
        })}
      </div>
      <button
        onClick={() => addHiddenExample()}
        className="btn btn-secondary "
        disabled={!isEditable}
      >
        Add Hidden Example
      </button>
    </div>
  );
};

interface EditableHiddenExampleProps {
  hiddenExampleData: AiHiddenExample;
  setName: (name: string) => void;
  setCorrectness: (correctness: AiHiddenExampleCorrectness) => void;
  setCode: (code: string) => void;
  deleteHiddenExample: () => void;
  isEditable: boolean;
}
const EditableHiddenExample = ({
  hiddenExampleData,
  setName,
  setCorrectness,
  setCode,
  deleteHiddenExample,
  isEditable,
}: EditableHiddenExampleProps) => {
  const {name, correctness, code} = hiddenExampleData;
  const correctnessOptions: SelectOption<AiHiddenExampleCorrectness>[] =
    SUPPORTED_AI_HIDDEN_EXAMPLE_CORRECTNESS_VALUES.map(value => ({
      value,
      label: value.charAt(0).toUpperCase() + value.slice(1),
    }));
  const selectedOption =
    correctnessOptions.find(option => option.value === correctness) ??
    correctnessOptions[0];
  const setSelectedOption = (
    option: SelectOption<AiHiddenExampleCorrectness>,
  ) => {
    setCorrectness(option.value);
  };
  return (
    <div className="border rounded p-2 mb-3 gap-2">
      <div className="d-flex flex-row ">
        <input
          type={'text'}
          className="form-control mb-2"
          placeholder="Example Name"
          value={name}
          disabled={!isEditable}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <Button
          onClick={deleteHiddenExample}
          variant="light"
          className="mb-2 ml-2"
          disabled={!isEditable}
        >
          <FaTrash />
        </Button>
      </div>

      <b className="mt-2">Example correctness:</b>
      <SelectDropdown
        selectableOptions={correctnessOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        configuration={{disabled: !isEditable}}
      />

      <b className="mt-2">Example code:</b>
      <ExpandableCodeEditor
        mode="python"
        value={code}
        onChange={e => setCode(e)}
        readOnly={!isEditable}
      />
    </div>
  );
};
