import TranscriptFeedbackDisclaimer from './components/Disclaimer';
import SectionLeaderFeedbackComponents from './SectionLeaderFeedbackComponents';
import StudentFeedbackComponents from './StudentFeedbackComponents';
import StaticStudentFeedback from './components/StaticStudentFeedback';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {useContext, useState, useEffect} from 'react';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {doc, getFirestore} from 'firebase/firestore';
import {Loading} from 'components/loading/Loading';
import MessageBoard from './components/MessageBoard';
import {Link} from 'react-router-dom';
import {CompletionContext} from 'contexts/CompletionContext';
import {is} from 'date-fns/locale';
import {isMinimumRole} from 'contexts/profileUtil';
import {ExperimentContext} from 'contexts/ExperimentContext';

export const SLTranscriptFeedback = ({week}) => {
  const db = getFirestore();
  const {userData} = useContext(ProfileContext);
  const {isSectionCompleted} = useContext(CompletionContext);
  const {expData, expLoading} = useContext(ExperimentContext);
  const userId = userData.id;
  const courseId = useCourseId();

  // Note: this used to be userMap and it had a key called sectionIds.
  // It is now called attendanceMap and has a key called attendedSections
  const sectionParticipationPath = `transcriptFeedback/${courseId}/${week}/attendanceMap/data/${userId}`;

  console.log('sectionParticipationPath', sectionParticipationPath);

  // get user map data
  const sectionAttendanceRef = doc(db, sectionParticipationPath);
  const [
    sectinAttendanceDoc,
    sectionAttendanceLoading,
    sectionAttendanceError,
  ] = useDocumentDataOnce(sectionAttendanceRef);

  if (sectionAttendanceLoading) return <></>;

  // anyone bellow a SL (or mentor) is a student
  const isStudent = !isMinimumRole(userData.courseRole, Role.MENTOR);
  const treatmentGroup = expData?.transcriptFeedback || 'StudentSLFeedback';
  const isControl = treatmentGroup === 'Control';

  const missingSectionData =
    !sectinAttendanceDoc ||
    sectinAttendanceDoc.attendedSections == undefined ||
    sectinAttendanceDoc.attendedSections?.length === 0;

  // If a student attendent section, but we don't have their data, that means we had an issue finding their transcript
  const weekStr = week.replace('week', '');
  const isStudentPresent = isSectionCompleted(weekStr);
  console.log('isStudentPresent', isStudentPresent);
  const missedSection = !isStudentPresent;

  // if they did not go to a section, show them the static reflection
  if (isStudent && missedSection && missingSectionData) {
    return <StaticStudentFeedback />;
  }

  // if we don't have section information for them, show them the static reflection
  if (missingSectionData) {
    return (
      <MessageBoard
        message={
          'Your transcript feedback for this week has not been processed yet.'
        }
      />
    );
  }

  // if student is present and belongs to control group
  if (isStudent && isControl) {
    return <StaticStudentFeedback />;
  }

  // this is for section leaders who missed section
  if (missedSection && missingSectionData) {
    return (
      <MessageBoard
        message={
          'Oops, your feedback is not available. Did you miss your section?'
        }
      />
    );
  }
  return (
    <>
      <div className="row">
        <div className="col">
          <SplashPage
            displayName={userData.displayName}
            sections={sectinAttendanceDoc.attendedSections}
            week={week}
          />
        </div>
      </div>
      {/* <TranscriptFeedbackDisclaimer /> */}
    </>
  );
};

const SplashPage = ({displayName, sections, week}) => {
  const {userData} = useContext(ProfileContext);
  const role = userData.courseRole;
  const weekName = `week ${parseInt(week.slice(4)) + 1}`;

  return (
    <div className="mt-4">
      <div>
        <h2>Hello {displayName}</h2>
        <h3>Let’s review your section for {weekName}</h3>
      </div>
      <div>
        {role == Role.STUDENT && (
          <div>
            <p>
              This feedback tool uses Zoom transcripts from section to provide
              class stats following each section.
            </p>
            <p>
              This tool is not perfect, but can hopefully still be helpful in
              your learning journey. Please know that this tool will NOT be used
              to assess your performance as an student.
            </p>
            <p>
              Code In Place wouldn’t exist without enthusiastic students like
              yourself. Keep up the great work!
            </p>
          </div>
        )}
        {isMinimumRole(role, Role.MENTOR) && (
          <div>
            <p>
              This feedback tool uses Zoom transcripts from section to provide
              class stats following each section.
            </p>
            <p>
              This tool is not perfect, but can hopefully still be helpful in
              your learning journey. Please know that this tool will NOT be used
              to assess your performance as an SL.
            </p>
            <p>
              Code In Place wouldn’t exist without amazing SL volunteers like
              yourself. You rock!
            </p>
          </div>
        )}
      </div>
      <div className="mt-4">
        <h4>View Feedback:</h4>
        {sections.map((section, index) => (
          <div className="mb-2" key={index}>
            <Link to={`${section}`}>
              <button className="btn btn-lg btn-primary">
                {toTitleCase(weekName)} Section{' '}
                {index != 0 ? `, ${index + 1}` : ''}
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
