import {useEffect, useState, useContext} from 'react';
import {getAuth} from 'firebase/auth';
import 'firebase/compat/firestore';
import {useFirebaseDocOnce} from '../../hooks/database/useFirebaseDocOnce';
import './stories.css';
import {
  doc,
  collection,
  query,
  where,
  documentId,
  getCountFromServer,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {StoriesWithData} from './StoriesWithData';
import {StoriesNotOpen} from 'components/errors/ClassNotOpen';
import {isMinimumRole} from 'contexts/profileUtil';
import {useCourseId} from 'hooks/router/useUrlParams';

export const Stories = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const db = getFirestore();
  const userPath = `users/${user.uid}`;
  const {userData, loading: userIsLoading} = useContext(ProfileContext);

  const courseRole = userData.courseRole;

  if (!isMinimumRole(courseRole, Role.STUDENT)) {
    return <StoriesNotOpen />;
  }
  return <StoriesGated />;
};

const StoriesGated = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  const courseId = useCourseId();
  const db = getFirestore();
  const userPath = `users/${user.uid}`;
  const {userData, loading: userIsLoading} = useContext(ProfileContext);

  const storyPath = `stories/${courseId}/${user.uid}/myStory`; // TODO: inconsistency between course id cp23 and cip3 in stories collection
  const [storyIsLoading, storyData] = useFirebaseDocOnce(
    storyPath,
    {
      content: '',
      photoUrl:
        'https://firebasestorage.googleapis.com/v0/b/codingplace2023.appspot.com/o/placeholder-image.png?alt=media&token=8e03aa2b-0de3-4e0c-b304-5c9ecdd7a40b',
      contactInfo: '',
    },
    true,
  );

  const privateStoryDataPath = `stories/${courseId}/${user.uid}/private`;
  const [privateStoryDataIsLoading, privateStoryData] = useFirebaseDocOnce(
    privateStoryDataPath,
    {
      myFavorites: [],
      myFeatured: [],
    },
    true,
  );

  const privacySettingsPath = `stories/${courseId}/${user.uid}/privacySettings`;
  const [privacySettingsDataIsLoading, privacySettingsData] =
    useFirebaseDocOnce(
      privacySettingsPath,
      {
        displayNameAnon: false,
        countryAnon: false,
      },
      true,
    );

  const storiesLogPath = `stories_logs/${user.uid}`;
  // the log may be very big. we do not want to download all of its data because it is very big and also we do not need to read it.
  // check if user's log exists without downloading it (because it is very big and also we do not need to read it)
  const [userLogExists, setUserLogExists] = useState(null);
  const [logIsLoading, setLogIsLoading] = useState(true);
  useEffect(() => {
    async function checkIfUserLogExists() {
      const coll = collection(db, 'stories_logs');
      const q = query(coll, where(documentId(), '==', user.uid));
      const snapshot = await getCountFromServer(q);
      setUserLogExists(!!snapshot.data().count);
    }
    checkIfUserLogExists();
  }, []);

  useEffect(() => {
    async function makeSubcollection(subCollectionPath, defaultValue) {
      const db = getFirestore();
      const docRef = doc(db, subCollectionPath, 'template');
      await setDoc(docRef, defaultValue);
    }
    // if user log exists, do nothing (we will just return logPath)
    // if not, create new doc with subcollections initialized to template values
    if (userLogExists != null) {
      if (!userLogExists) {
        const storyChangeLogPath = `stories_logs/${user.uid}/story_change`;
        makeSubcollection(storyChangeLogPath, {
          new_content: 'some_new_content',
        });

        const readMoreClicksLogPath = `stories_logs/${user.uid}/read_more_clicks`;
        makeSubcollection(readMoreClicksLogPath, {
          author_uid: '123',
        });

        const saveClicksLogPath = `stories_logs/${user.uid}/save_clicks`;
        makeSubcollection(saveClicksLogPath, {
          author_uid: '123',
          save: true, // false if this click unsaves the story
        });

        const contactInfoClicksPath = `stories_logs/${user.uid}/contact_info_clicks`;
        makeSubcollection(contactInfoClicksPath, {
          author_uid: '123',
        });

        const searchesPath = `stories_logs/${user.uid}/searches`;
        makeSubcollection(searchesPath, {
          search_terms: 'keywordkeyword',
        });

        const pageVisitsPath = `stories_logs/${user.uid}/page_visits`;
        makeSubcollection(pageVisitsPath, {
          device_type: 'desktop', // one of either "mobile", "tablet", "desktop"
        });

        const tabVisitsPath = `stories_logs/${user.uid}/tab_visits`;
        makeSubcollection(tabVisitsPath, {
          tab: 'all', // which tab on stories page user clicked to, either "all", "saved", or "edit"
        });
      }
      setLogIsLoading(false);
    }
  }, [userLogExists]);

  if (
    storyIsLoading ||
    privateStoryDataIsLoading ||
    privacySettingsDataIsLoading ||
    logIsLoading
  )
    return <></>;

  return (
    <StoriesWithData
      user={user}
      db={db}
      userPath={userPath}
      userData={userData}
      storyPath={storyPath}
      storyData={storyData}
      privateStoryDataPath={privateStoryDataPath}
      privateStoryData={privateStoryData}
      privacySettingsPath={privacySettingsPath}
      privacySettingsData={privacySettingsData}
      logPath={storiesLogPath}
      // logData = {storiesLogData}
    />
  );
};
