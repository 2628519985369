import {CourseContext} from 'contexts/CourseContext';
import {useServerTime} from 'contexts/TimeContext';
import {useContext} from 'react';
import {doc, updateDoc, deleteField, getFirestore} from '@firebase/firestore';
import Swal from 'sweetalert2';
import {isStaffRole} from 'contexts/profileUtil';
import {FaTimes} from 'react-icons/fa';
import {needsCoverCheck} from './MonitorSections';

export const CoverButton = ({section, sectionId, sectionNumber, myInfo}) => {
  const covers = section?.covers;
  const {firstSectionTimestamp, lastSectionTimestamp, currCourseWeek} =
    useContext(CourseContext);
  const weekDelta = (currCourseWeek - 1) * 7 * 24 * 60 * 60 * 1000;
  const firstSectionTimestampDate = new Date(firstSectionTimestamp);
  const lastSectionTimestampDate = new Date(lastSectionTimestamp);
  const firstSectionTimeDateOfWeek = new Date(
    firstSectionTimestampDate.getTime() + weekDelta,
  );
  const lastSectionTimeDateOfWeek = new Date(
    lastSectionTimestampDate.getTime() + weekDelta,
  );
  const serverTimeMs = useServerTime(30000);

  const initiateCover = () => {
    const db = getFirestore();
    const sectionRef = doc(db, 'sections', sectionId);
    updateDoc(sectionRef, {
      covers: {
        [sectionNumber]: myInfo,
      },
    });
  };

  const cancelCover = () => {
    // first fire a swal to confirm
    Swal.fire({
      title: 'Cancel Cover',
      text: 'Are you sure you want to cancel covering this section?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cancel it!',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        const db = getFirestore();
        const sectionRef = doc(db, 'sections', sectionId);
        updateDoc(sectionRef, {
          [`covers.${sectionNumber}`]: deleteField(),
        });
      }
    });
  };

  const onClick = () => {
    Swal.fire({
      title: 'Cover Section',
      text: 'Are you sure you want to cover this section?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, cover it!',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        initiateCover();
      }
    });
  };

  // If someone is already covering the section, show their name, no button
  if (covers && covers[sectionNumber]) {
    const myCover = myInfo['userId'] == covers[sectionNumber]['userId'];
    const mySection = myInfo['userId'] == section.sectionLeader;
    const showCancel =
      myCover || mySection || isStaffRole(myInfo['courseRole']);
    const coverInfo = covers[sectionNumber];
    const tag = coverInfo?.name ?? 'Anon';
    // render a delete button next to the tag
    return (
      <>
        {tag}
        {showCancel && (
          <button
            onClick={cancelCover}
            className="btn btn-sm btn text-danger"
            aria-label="Close"
          >
            <FaTimes />
          </button>
        )}
      </>
    );
  }

  if (
    needsCoverCheck(
      section,
      sectionNumber,
      firstSectionTimeDateOfWeek,
      serverTimeMs,
      lastSectionTimeDateOfWeek,
    )
  ) {
    return (
      <button onClick={onClick} className="btn btn-sm btn-primary">
        I Will Cover
      </button>
    );
  }
  return <></>;
};

// const CoverButton = ({ section, sectionId, sectionNumber, myInfo }) => {
//     const covers = section?.covers
//     const { firstSectionTimestamp, sectionTimeDelta, currSectionWeek } = useContext(CourseContext);
//     const { getServerTimeMs } = useContext(TimeContext)
//     const [now, setNow] = useState(null)
//     const [nextSectionDate, setNextSectionDate] = useState(null)
//     const [lastSectionTimestamp, setLastSectionTimestamp] = useState(null)
//     const [firstSectionTimeStampOfWeek, setFirstSectionTimeStampOfWeek] = useState(new Date())

//     useEffect(() => { setNow(new Date(getServerTimeMs())) }, [])
//     useEffect(() => {
//       const firstSectionTimestampAsDate = new Date(firstSectionTimestamp)
//       const firstSectionTimestampAsTime = firstSectionTimestampAsDate.getTime()
//       setLastSectionTimestamp(new Date(firstSectionTimestampAsTime + (currSectionWeek * 7 * 24 * 60 * 60 * 1000) + (sectionTimeDelta * 60 * 60 * 1000)))
//       setFirstSectionTimeStampOfWeek(new Date(firstSectionTimestampAsTime + ((currSectionWeek - 1) * 7 * 24 * 60 * 60 * 1000)))
//     }, [])

//     useEffect(() => {
//       if(!section) return
//       if(!firstSectionTimestamp) return
//       const timeIndex = section?.timeIndex
//       if (!timeIndex) return
//       const firstSectionDate = new Date(firstSectionTimestamp)
//       const addHours = timeIndex * 60 * 60 * 1000 + ((currSectionWeek-1) * 7 * 24 * 60 * 60 * 1000)
//       const nextSectionDate = new Date(firstSectionDate.getTime() + addHours)
//       setNextSectionDate(nextSectionDate)
//     }, [])

//     const initiateCover = () => {
//       const db = getFirestore(getApp())
//       const sectionRef = doc(db, 'sections', sectionId)
//       updateDoc(sectionRef, {
//         covers: {
//           [sectionNumber]: myInfo
//         }
//       })
//     }

//     const cancelCover = () => {
//       // first fire a swal to confirm
//       Swal.fire({
//         title: 'Cancel Cover',
//         text: 'Are you sure you want to cancel covering this section?',
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonText: 'Yes, cancel it!',
//         cancelButtonText: 'No',
//         reverseButtons: true
//       }).then((result) => {
//         if (result.isConfirmed) {

//           const db = getFirestore(getApp())
//           const sectionRef = doc(db, 'sections', sectionId)
//           updateDoc(sectionRef, {
//             [`covers.${sectionNumber}`]: deleteField()
//           })
//         }
//       })
//     }

//     const onClick = () => {
//       Swal.fire({
//         title: 'Cover Section',
//         text: 'Are you sure you want to cover this section?',
//         icon: 'warning',
//         showCancelButton: true,
//         confirmButtonText: 'Yes, cover it!',
//         cancelButtonText: 'No',
//         reverseButtons: true

//       }).then((result) => {
//         if (result.isConfirmed) {
//           initiateCover()
//         }
//       })
//     }

//     if (covers && covers[sectionNumber]) {
//       const myCover = myInfo['userId'] == covers[sectionNumber]['userId']
//       const mySection = myInfo['userId'] == section.sectionLeader
//       const showCancel = myCover || mySection || isStaffRole(myInfo['courseRole'])
//       const coverInfo = covers[sectionNumber]
//       const tag = coverInfo?.name ?? 'Anon'
//       // render a delete button next to the tag
//       return <>
//         {tag}
//         {showCancel && <button onClick={cancelCover} className="btn btn-sm btn text-danger" aria-label="Close"><FaTimes /></button>}
//         </>
//     }

//     if (needsCoverCheck(section, sectionNumber, firstSectionTimeStampOfWeek, now, lastSectionTimestamp)) {
//       return <button onClick={onClick} className="btn btn-sm btn-primary">I Will Cover</button>
//     }
//     return <></>
//   }
