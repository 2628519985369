import {ForumContext} from 'course/forum/ForumContext';
import {Forum} from 'course/forum/pages/forum/Forum';
import {useCourseId} from 'hooks/router/useUrlParams';
import {QueryClient, QueryClientProvider} from 'react-query';

import {Link, useNavigate, useParams} from 'react-router-dom';
import {Banner} from './components/Banner';
import {useContext} from 'react';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {FaAngleLeft, FaArrowLeft, FaBackward} from 'react-icons/fa';
import Gate from 'contexts/Gate';
import styled from 'styled-components';

const queryClient = new QueryClient();

const SectionDiscussion = () => {
  const {sectionId} = useParams();

  return (
    <ForumContext.Provider
      value={{
        forumId: sectionId,
        forumType: 'Section',
        privatePostThreshold: Role.SECTION_LEADER,
        setPinnedThreshold: Role.SECTION_LEADER,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SectionForumContent />
      </QueryClientProvider>
    </ForumContext.Provider>
  );
};

const SectionForumContent = () => {
  const sectionId = useParams().sectionId;

  const {userData, sectionData} = useContext(ProfileContext);

  if (!Gate.hasStaffRole(userData) && !sectionData[sectionId]) {
    return <>You don't have permission to view this section!</>;
  }

  return (
    <SectionForumOuter>
      <Banner
        section={sectionId}
        height={100}
        rounded={false}
        editable={false}
      />
      <BackButtonAbsolute />
      <Forum />
    </SectionForumOuter>
  );
};

const SectionForumOuter = styled.div``;

const BackButtonAbsolute = () => {
  const sectionId = useParams().sectionId;
  const courseId = useCourseId();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(`/${courseId}/section/${sectionId}`)}
      className="btn btn-light"
      style={{
        position: 'absolute',
        top: 45,
        right: 20,
      }}
    >
      Section Home
    </button>
  );
};

export default SectionDiscussion;
