import {useContext} from 'react';
// import { Router, Link } from "@reach/router"
import {useCourseId} from 'hooks/router/useUrlParams';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {ProfileNav} from 'landing/components/LandingNav';
import {useLocation, useNavigate} from 'react-router-dom';
import {NavigationHistoryContext} from 'contexts/NavigationContext';
import {CourseContext} from 'contexts/CourseContext';
import {FaHome} from 'react-icons/fa';

export const OnboardingNav = ({onboardingKey, title}) => {
  const courseId = useCourseId();

  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      bg="light"
      variant="light"
      sticky="top"
    >
      <div className="container-fluid">
        {/* <BackHomeButton applicationType={applicationType} subpage={subpage} /> */}
        <BackHomeButton onboardingKey={onboardingKey} />

        <span className="ml-2">{title}</span>

        <Nav className="ms-auto">
          <ProfileNav />
        </Nav>
      </div>
    </Navbar>
  );
};

const BackHomeButton = ({onboardingKey}) => {
  const courseId = useCourseId();
  const {getPreviousLocation} = useContext(NavigationHistoryContext);
  const {isFoothill} = useContext(CourseContext);

  const navigate = useNavigate();
  const location = useLocation();
  const currPath = location.pathname;
  const previousLocation = getPreviousLocation([currPath]);

  const className = 'btn btn-sm btn-outline-secondary';

  if (isFoothill) {
    // foothill has no student onboarding; redirect to student home
    return (
      <button
        className={className}
        onClick={() => navigate(`/${courseId}/studenthome`)}
      >
        <FaHome />
      </button>
    );
  }

  return (
    <button
      className={className}
      onClick={() => navigate(`/${courseId}/onboarding/${onboardingKey}`)}
    >
      <FaHome />
    </button>
  );
};
