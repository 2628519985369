import {Dispatch, useState} from 'react';

import {SetStateAction} from 'react';
import {Badge, Button, FormControl, InputGroup} from 'react-bootstrap';
import {CloseButton} from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa';

interface TagEditorProps {
  assignmentTags: string[];
  setAssignmentTags: Dispatch<SetStateAction<string[]>>;
  isEditable: boolean;
}
export const TagEditor = ({
  assignmentTags,
  setAssignmentTags,
  isEditable,
}: TagEditorProps) => {
  const [input, setInput] = useState('');

  function addTag() {
    const trimmedInput = input.trim();
    if (trimmedInput !== '' && !assignmentTags.includes(trimmedInput)) {
      setAssignmentTags([...assignmentTags, trimmedInput]);
      setInput('');
    }
  }

  function removeTag(removedTag) {
    setAssignmentTags(assignmentTags.filter(tag => tag !== removedTag));
  }

  return (
    <div>
      {assignmentTags.map((tag, index) => (
        <Badge pill className="mr-2" bg="secondary" key={index}>
          {tag}
          {isEditable && (
            <CloseButton
              onClick={() => removeTag(tag)}
              variant="white"
              style={{marginLeft: '2px', height: '10px'}}
            />
          )}
        </Badge>
      ))}
      {isEditable && (
        <InputGroup className="mt-3">
          <FormControl
            placeholder="New tag"
            value={input}
            onChange={e => setInput(e.target.value)}
          />
          <Button variant="outline-secondary" onClick={addTag}>
            <FaPlus />
          </Button>
        </InputGroup>
      )}
    </div>
  );
};
