import {useContext, useEffect} from 'react';
import {FinalProjectContext} from './FinalProjectContext';
import {useCourseId} from 'hooks/router/useUrlParams';
import {useUserId} from 'hooks/user/useUserId';
import {useNavigate} from 'react-router';
import {ExperimentContext, setExperimentFlag} from 'contexts/ExperimentContext';
import {hashString} from 'react-hash-string';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole} from 'contexts/profileUtil';

export const FinalProjectSplash = () => {
  const courseId = useCourseId();
  const userId = useUserId();
  const {expLoading} = useContext(ExperimentContext);
  const navigate = useNavigate();
  const {projects, projectsLoading, createProjectAndNavigate} =
    useContext(FinalProjectContext);

  if (projectsLoading || expLoading) return <></>;

  return <FinalProjectSplashWithData projects={projects} />;
};

const FinalProjectSplashWithData = ({projects}) => {
  const {expData} = useContext(ExperimentContext);
  const courseId = useCourseId();
  const userId = useUserId();
  const navigate = useNavigate();
  const {createProjectAndNavigate} = useContext(FinalProjectContext);
  const flagId = 'finalProjectProposalExp';
  const {userData} = useContext(ProfileContext);
  const courseRole = userData.courseRole;
  const isAdmin = isMinimumRole(courseRole, Role.TA);

  useEffect(() => {
    // first check if the experiment flag is
    if (!expData || !(flagId in expData)) {
      const salt = flagId;
      const userHash = Math.abs(hashString(userId + salt));
      // 90% of users are in the experiment
      const result = userHash % 10 != 0 || isAdmin;
      // set the flag
      setExperimentFlag(userId, courseId, flagId, result);
      console.log('Set Experiment Flag', result);
      return;
    }

    // then check if it is false (should be true for 90% of people)
    if (flagId in expData && !expData[flagId]) {
      console.log('Control');
      navigate(`/${courseId}/handout/finalproject`, {replace: true});
      return;
    }

    // the user has no projects
    if (Object.keys(projects).length === 0) {
      // create a new document in the final project collection
      createProjectAndNavigate();
      console.log('Create first project');
      return;
    }

    // the user has a project!
    let primaryProjectId = null;
    let primarySetAt = null;
    for (const projectId in projects) {
      if (projects[projectId].primarySetAt) {
        const currPrimarySetAt = projects[projectId].primarySetAt;
        if (!primaryProjectId || currPrimarySetAt > primarySetAt) {
          primaryProjectId = projectId;
          primarySetAt = currPrimarySetAt;
        }
      }
    }

    // navigate to the most recent project (based on the createdAt field)
    const sortedProjects = Object.keys(projects).sort((a, b) => {
      // primary project should be first
      if (a === primaryProjectId) return -1;
      // Check if 'b' is the primaryProjectId, it should come first
      if (b === primaryProjectId) return 1;
      return projects[b].createdAt - projects[a].createdAt;
    });

    const lastProjectId = sortedProjects[0];
    console.log('Time to navigate!');
    navigate(`/${courseId}/finalProjectProposal/${lastProjectId}`, {
      replace: true,
    });
  }, []);

  return <></>;
};
