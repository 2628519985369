const KAREL_DIRECTIONS = ['North', 'East', 'South', 'West'] as const;
export type KarelDirection = typeof KAREL_DIRECTIONS[number];
export function isKarelDirection(input: any): input is KarelDirection {
  return (
    typeof input === 'string' &&
    KAREL_DIRECTIONS.includes(input as KarelDirection)
  );
}

export interface IKarelState {
  nRows: number;
  nCols: number;
  walls?: {
    [wallLoc: string]: boolean;
  };
  beepers?: {
    [beeperLoc: string]: number;
  };
  paint?: {
    [beeperLoc: string]: string;
  };
  karelRow: number;
  karelCol: number;
  karelDir: string;
  actionHistory?: string[];
}

export function isIKarelState(testObject: any): testObject is IKarelState {
  if (
    !testObject ||
    typeof testObject !== 'object' ||
    typeof testObject.nRows !== 'number' ||
    typeof testObject.nCols !== 'number' ||
    (testObject.walls && typeof testObject.walls !== 'object') ||
    (testObject.beepers && typeof testObject.beepers !== 'object') ||
    (testObject.paint && typeof testObject.paint !== 'object') ||
    typeof testObject.karelRow !== 'number' ||
    typeof testObject.karelCol !== 'number' ||
    !isKarelDirection(testObject.karelDir) ||
    (testObject.actionHistory && !Array.isArray(testObject.actionHistory))
  ) {
    return false;
  }
  if (testObject.nRows < 1 || testObject.nCols < 1) {
    console.warn(`Invalid world size: ${testObject.nRows}x${testObject.nCols}`);
  }
  for (const [wallLocation, doesExist] of Object.entries(
    testObject.walls ?? {},
  )) {
    if (typeof wallLocation !== 'string' || typeof doesExist !== 'boolean') {
      console.debug(
        `Invalid wall location: ${wallLocation}, doesExist: ${doesExist}. testObject: ${testObject}`,
      );
      return false;
    }
  }
  for (const [beeperLocation, count] of Object.entries(
    testObject.beepers ?? {},
  )) {
    if (typeof beeperLocation !== 'string' || typeof count !== 'number') {
      console.debug(
        `Invalid beeper location: ${beeperLocation}, count: ${count}. testObject: ${testObject}`,
      );
      return false;
    }
  }
  for (const [paintLocation, color] of Object.entries(testObject.paint ?? {})) {
    if (typeof paintLocation !== 'string' || typeof color !== 'string') {
      console.debug(
        `Invalid paint location: ${paintLocation}, color: ${color}. testObject: ${testObject}`,
      );
      return false;
    }
  }
  for (const action of testObject.actionHistory ?? []) {
    if (typeof action !== 'string') {
      console.debug(`Invalid action: ${action}. testObject: ${testObject}`);
      return false;
    }
  }
  return true;
}
