import {useCourseId} from 'hooks/router/useUrlParams';
import {Route, Routes, useParams, useNavigate, Link} from 'react-router-dom';
import {
  useStudentsSubmittedTN,
  readInitialReactionEmoji,
  unclaimReview,
} from '../teacherPuzzles/TNFirebase';
import {ProfileContext} from 'contexts/ProfileContext';
import {useContext} from 'react';
import {
  firebaseStringToRole,
  isATypeOfStudent,
  isAdmin,
  isStaffRole,
  roleToForumString,
} from 'contexts/profileUtil';
import NoAccess from 'components/errors/NoAccess';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FaCheck, FaCircle, FaTimes} from 'react-icons/fa';
import {Loading} from 'components/loading/Loading';
import {ReviewStudent} from './Evaluate';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {doc, getFirestore} from 'firebase/firestore';
import {studentAppStructure} from 'course/application/studentApplication/about/AboutForm';
import {Button} from 'react-bootstrap';

const ReviewContainer = styled.div`
  margin: auto;
  padding: 20px;
`;

const ReviewTitle = styled.h1`
  color: #555;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
`;

const StatusTag = styled.span`
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  background-color: ${({status}) =>
    status === 'completed'
      ? '#cff4fc'
      : status === 'pending'
      ? '#ffeeba'
      : '#e2e3e5'};
  color: ${({status}) =>
    status === 'completed'
      ? '#055160'
      : status === 'pending'
      ? '#856404'
      : '#5c636a'};
  font-weight: bold;
`;

const formatDate = timestamp => {
  const milliseconds =
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
  const date = new Date(milliseconds);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

// Students submit an application for review. Once they submit, we set their 'applicationStatus' to 'submitted'.
// If they have submitted, they cannot submit until we have reviewed their application
// Once we reveiw, if they have been accepted, we set their applicationStatus to accepted
// If they have been rejected, we set their applicationStatus to rejected
// If they have been rejected, they can submit again.

// While reviewing, we want to avoid collisions between reviewers.
// When somoene clicks a user to start a review, we set that student user's 'reviewed' to 'pending' and 'applicationStatus' to 'in review'

const IntialReactionBadge = ({initialReaction}) => {
  return (
    <span
      className="badge"
      style={{
        color: 'white',
        backgroundColor: 'lightpink',
        fontSize: '32px',
      }}
    >
      {initialReaction}
    </span>
  );
};

const RoleBadge = ({role}) => {
  function roleToDisplayString(role) {
    if (role == 'student') {
      return 'Student';
    } else if (role == 'experiencedstudent') {
      return 'Experienced Student';
    } else {
      return role;
    }
  }

  const colorRoleMap = {
    instructor: '#a9a9a9', // Dark Gray
    admin: '#6a0dad', // Dark Purple
    ta: '#4682b4', // Steel Blue
    sl: '#3cb371', // Medium Sea Green
    student: '#ff6347', // Tomato Red
    experiencedstudent: '#20b2aa', // Light Sea Green
  };

  const color = colorRoleMap[role];

  return (
    <span
      className="badge"
      style={{
        color: 'white',
        backgroundColor: color,
      }}
    >
      {roleToDisplayString(role)}
    </span>
  );
};

const StudentRow = ({student}) => {
  const studentUserId = student.userId;
  const courseId = useCourseId();
  const [userData, loading, error] = useDocumentDataOnce(
    doc(getFirestore(), `users/${studentUserId}`),
  );
  const [role, loadingRole, roleError] = useDocumentDataOnce(
    doc(getFirestore(), `users/${studentUserId}/roles/${courseId}`),
  );

  const handleUnclaim = async () => {
    await unclaimReview(courseId, studentUserId);
  };

  if (loading || loadingRole) return <p>Loading..</p>;

  return (
    <>
      <td>
        <Link to={studentUserId}>{userData.displayName}</Link>
      </td>
      <td>
        <RoleBadge role={role?.role} />
      </td>
      {/* <td>{student.reviewed === 'completed' && <div><FaCircle style={{ color: 'green' }} /> Reviewed</div>}
          {student.reviewed === 'pending' && <div><FaCircle style={{ color: 'lightpink' }} /> In Review</div>}
          {student.reviewStatus && <StatusTag status={student.reviewed}>{student.reviewStatus}</StatusTag>}</td> */}
      <td>
        {student.reviewed === 'pending' ||
        (student.reviewed === 'completed' && student.reviewerName)
          ? student.reviewerName
          : ''}
      </td>
      <td>
        {student.reviewed === 'pending' ||
        (student.reviewed === 'completed' && student.claimTimestamp)
          ? formatDate(student.claimTimestamp)
          : ''}
      </td>
      <td>
        {student.readyToTeachStatus === 'ready' && (
          <div>
            <FaCheck style={{color: 'green'}} /> Ready to teach
          </div>
        )}
        {student.readyToTeachStatus === 'not_ready' && (
          <div>
            <FaTimes style={{color: 'red'}} /> Not ready to teach
          </div>
        )}
      </td>
      <td>
        <IntialReactionBadge initialReaction={student.initialReaction} />
      </td>
      <td>
        {student.reviewed === 'pending' && student.reviewerName && (
          <Button variant="outline-danger" onClick={handleUnclaim}>
            Unclaim
          </Button>
        )}
      </td>
    </>
  );
};

const ReviewList = ({names}) => {
  function getReviewStatusColor(status) {
    if (status === 'pending') {
      return 'table-warning';
    } else if (status === 'completed') {
      return 'table-success';
    } else {
      return '';
    }
  }

  const numSubmitted = names.length;

  return (
    <ReviewContainer>
      <div className="d-flex justify-content-between">
        <ReviewTitle>Review Students for Teach Now</ReviewTitle>
        <div style={{fontSize: '20px', marginTop: '7px'}}>
          Applicants: {numSubmitted}
        </div>
      </div>
      <table className="table">
        <thead>
          <tr key={'header'}>
            <th>Student</th>
            <th>Role</th>
            <th>Reviewer</th>
            <th>Claimed Time</th>
            <th>Decision</th>
            <th>Initial Reaction</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {names.map(student => (
            <tr
              key={student.userId}
              className={getReviewStatusColor(student.reviewed)}
            >
              <StudentRow student={student} />
            </tr>
          ))}
        </tbody>
      </table>
    </ReviewContainer>
  );
};

const ReviewStudentContainer = () => {
  const courseId = useCourseId();
  const [names, setNames] = useState([]);
  const [students, studentsLoading, error] = useStudentsSubmittedTN(courseId);

  useEffect(() => {
    if (studentsLoading) return;
    const allNames = students.map(student => ({
      displayName: student.displayName,
      userId: student.userId,
      reviewed: student.reviewed ?? false,
      reviewStatus: student.reviewStatus ?? '',
      claimTimestamp: student.claimTimestamp ?? '',
      reviewerName: student.reviewerName ?? '',
      readyToTeachStatus: student.readyToTeachStatus ?? '',
    }));
    setNames(allNames);
  }, [students, studentsLoading]);

  if (studentsLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <div style={{width: '100%', height: '100vh', overflowY: 'visible'}}>
            <ReviewStudentsOverviewOuter names={names} />
          </div>
        }
      />
      <Route path=":userId" element={<ReviewStudent students={names} />} />
    </Routes>
  );
};

export const ReviewStudentTNContainer = () => {
  const {userData} = useContext(ProfileContext);
  const role = userData?.courseRole;

  if (!isStaffRole(role)) {
    return <NoAccess />;
  }

  return <ReviewStudentContainer />;
};

const ReviewStudentsOverviewOuter = ({names}) => {
  const courseId = useCourseId();
  const [studentsWithReactions, setStudentsWithReactions] = useState(names);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialReactions = async () => {
      setLoading(true);
      const promises = names.map(async student => {
        let initialReaction = '';
        try {
          initialReaction = await readInitialReactionEmoji(
            courseId,
            student.userId,
          );
        } catch (error) {
          console.error(
            'Failed to fetch initial reaction for',
            student.displayName,
            error,
          );
          initialReaction = '';
        }
        return {...student, initialReaction};
      });

      const updatedStudents = await Promise.all(promises);
      setStudentsWithReactions(updatedStudents);
      setLoading(false);
    };

    fetchInitialReactions();
  }, [names, courseId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <CoursePageBodyContainer
      mainColumn={<ReviewList names={studentsWithReactions} />}
      rightColumn={<></>}
      singleColumn={<ReviewList names={studentsWithReactions} />}
    />
  );
};
