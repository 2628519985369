// PYODIDE PROVIDER
import {createContext, useEffect, useState} from 'react';
import {
  setPyodide,
  PyodideApi,
  PyodideUserDefinedEndHandler,
  PyodideUserDefinedImgHandler,
  PyodideUserDefinedStdinHandler,
  PyodideUserDefinedStdoutHandler,
  PyodideUserDefinedStderrHandler,
} from './PyodideRunner';
import {IdeFileData, ProjectFileId} from 'ide/types';
import { GradingScripts } from 'assignments/models/unitTests/advancedConsole';

// this is akin to a global variable state
export const PyodideContext = createContext(null);

export class PyodideClient {
  private pyapi: PyodideApi;

  constructor() {
    this.pyapi = new PyodideApi();
  }

  /**
   *
   * @param code
   * @param activeFile
   * @param stepMode
   * @param uninterrupted
   * @param canvasId
   * @returns
   */
  async runCode(
    code: string,
    activeFile: IdeFileData = {name: 'main.py', id: ''},
    stepMode = true,
    uninterrupted = 2000,
    canvasId = 'canvas',
  ) {
    return await this.pyapi.runPython(
      code,
      activeFile,
      stepMode,
      uninterrupted,
      0,
      canvasId,
    );
  }

  /**
   *
   * @param filesCode: The entire project's code (i.e. all files and their contents)
   * @param fileToTest: The name of the file to test
   * @param input: The input to test the code with (for console unit tests)
   * @param gradingScripts: The grading scripts to use (for advanced console unit tests)
   */
  async testCode(
    code: string,
    fileToTest: IdeFileData,
    input: string[] = [],
    gradingScripts: GradingScripts | null = null,
  ) {
    return this.pyapi.test(
      code,
      input,
      fileToTest,
      gradingScripts,
    );
  }

  setHandlers(
    endFunc: PyodideUserDefinedEndHandler,
    outputFunc: PyodideUserDefinedStdoutHandler,
    errorFunc: PyodideUserDefinedStderrHandler,
    inputFunc: PyodideUserDefinedStdinHandler,
    getImageUrl: PyodideUserDefinedImgHandler,
  ) {
    this.pyapi.setUserDefinedFuncs(
      endFunc,
      outputFunc,
      errorFunc,
      inputFunc,
      getImageUrl,
    );
  }

  isPyRunning() {
    return this.pyapi.getRunningFlag();
  }

  raisePyStopFlag() {
    this.pyapi.setRunningFlag();
  }

  getStepInfo() {
    return {
      list: self.stepInfo.frames,
      logs: self.stepInfo.stdout,
    };
  }

  setKarelSleepTime(time) {
    self.karelInfo.sleepTime = time;
  }

  stepKarel(state) {
    if (self.karelInfo.client) {
      self.karelInfo.client.setKarelState(state);
    }
  }

  stepGraphics(state) {
    if (self.canvasInfo.client) {
      self.canvasInfo.client.redrawStep(state);
    }
  }

  async loadFiles(
    fileEntries: IdeFileData[],
    filesCode: Record<ProjectFileId, {content: string}>,
  ) {
    return await this.pyapi.loadFiles(fileEntries, filesCode);
  }

  async loadFile(path, contents) {
    return await this.pyapi.loadFile(path, contents);
  }

  setKarelInfo(startState, setWorldState, sleepTime) {
    this.pyapi.setKarelInfo(startState, setWorldState, sleepTime);
  }

  async setReplSession(exitRepl) {
    return await this.pyapi.enterReplMode(exitRepl);
  }

  async handleReplCommand(command) {
    return await this.pyapi.interpreter(command);
  }

  async endRepl() {
    return await this.pyapi.endRepl();
  }
}

const loadCodeInPlacePyodide = async setPyodideLoadingState => {
  console.log('Loading Pyodide');
  setPyodideLoadingState('Pyodide Loading ...');
  await setPyodide();
};

export default function PyodideProvider({children}) {
  const [isPyodideLoading, setIsPyodideLoading] = useState(true);
  const [pyodideLoadingState, setpyodideLoadingState] = useState(true);

  useEffect(() => {
    loadCodeInPlacePyodide(setpyodideLoadingState).then(() => {
      setIsPyodideLoading(false);
    });
  }, []);

  return (
    <PyodideContext.Provider
      value={{
        isPyodideLoading,
      }}
    >
      {children}
    </PyodideContext.Provider>
  );
}
