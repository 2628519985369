import {ForumContext} from 'course/forum/ForumContext';
import {Forum} from 'course/forum/pages/forum/Forum';
import {useCourseId} from 'hooks/router/useUrlParams';
import {QueryClient, QueryClientProvider} from 'react-query';

import {useParams} from 'react-router-dom';
import {useContext} from 'react';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import Gate from 'contexts/Gate';

const queryClient = new QueryClient();

export const ProjectForum = () => {
  const {forumId} = useParams();
  const courseId = useCourseId();
  const {userData} = useContext(ProfileContext);

  let userIsInThisGroup = false;
  const finalProjectCommunities = userData?.finalProjectCommunities;
  if (finalProjectCommunities && courseId in finalProjectCommunities) {
    const projectForumIds = finalProjectCommunities[courseId];
    // NOTE: we assume user only has one final project group
    userIsInThisGroup = projectForumIds[0] === forumId;
  }

  if (!Gate.hasStaffRole(userData) && !userIsInThisGroup) {
    return <>You don't have permission to view this forum!</>;
  }

  return (
    <ForumContext.Provider
      value={{
        forumId: forumId,
        forumType: 'Project',
        privatePostThreshold: Role.SECTION_LEADER,
        setPinnedThreshold: Role.SECTION_LEADER,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Forum />
      </QueryClientProvider>
    </ForumContext.Provider>
  );
};
