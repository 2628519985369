import {callGptAutograder} from '../../../utils/gpt';
import Swal from 'sweetalert2';
import {doc, getFirestore, serverTimestamp, setDoc} from 'firebase/firestore';

/* ---- swals and toasts ---- */
export const AUTOGRADER_RUN_TOAST = Swal.mixin({
  title: 'Running autograder',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 30000, // 30 seconds, usually GPT call takes around this amount of time
  timerProgressBar: true,
  icon: 'info',
});

export const UNABLE_TO_RUN_TOAST = Swal.mixin({
  title: 'Unable to run autograder, please try again later',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 5000, // 5 seconds
  timerProgressBar: true,
  icon: 'info',
});

export const AI_AUTOGRADER_NOT_CORRECT_SWAL = Swal.mixin({
  icon: 'info',
  text: "The tests finished running but your solution wasn't what we expected.",
  confirmButtonText: 'Keep working',
  showDenyButton: true,
  denyButtonText: 'My code is right!',
  denyButtonColor: '#99979C',
});

export const STUDENT_DISAGREE_SWAL = Swal.mixin({
  title: 'Marked as correct',
  text: 'We are still adjusting our autograder. Thanks for your feedback, your work has been marked as correct.',
  icon: 'success',
  confirmButtonText: 'Onwards!',
  showCancelButton: true,
  cancelButtonText: 'Keep working',
  cancelButtonColor: '#99979C',
});

/* ---- api functionality ---- */
export const runGPTAutograderRaw = async ({code, assnId, courseId}) => {
  /* makes GPT API call, 
	tries again in the case of GPT API error or malformed response,
	logs any errors.
	will throw error as well */

  const callParams = {
    assnId,
    courseId,
    studentCode: code,
  };

  let failedOnce,
    failedTwice = false;
  let response, score, err;

  try {
    response = await callGptAutograder(callParams);
    score = getScoreFromGPTResponse(response);
  } catch (error) {
    failedOnce = true;
    err = error;
  }

  if (failedOnce) {
    // try again
    try {
      response = await callGptAutograder(callParams);
      score = getScoreFromGPTResponse(response);
    } catch (error) {
      failedTwice = true;
      err = error;
    }
  }
  let malformationError = undefined;
  if (failedTwice) {
    // @ts-ignore
    if (response?.error) {
      malformationError = response;
    } else {
      malformationError = err.toString();
    }
  }

  // log the autograder results
  logAiAutograderRaw({
    courseId,
    assnId,
    code,
    gptResponse: response,
    malformationError: malformationError,
  });

  if (failedTwice) {
    throw err;
  }
  return response;
};

export function logAiAutograderRaw({
  courseId,
  assnId,
  code,
  gptResponse,
  malformationError = undefined,
}) {
  let log: Record<string, any> = {
    assnId,
    code,
    gptResponse,
    serverTimestamp: serverTimestamp(), // This is the real timestamp to use
  };

  // conditionally build the log object based on what information is given
  if (malformationError) {
    log.malformationError = malformationError;
  }

  const db = getFirestore();
  const dateString = new Date().toISOString();
  const docPath = `aiAssnGradingLogs/${courseId}/fontendLog/${dateString}`;
  setDoc(doc(db, docPath), log);
}

export function logAiAutograderUserFeedback({
  results,
  userId,
  courseId,
  assnId,
}) {
  const log = {
    userId,
    courseId,
    assnId,
    results,
    serverTimestamp: serverTimestamp(),
  };
  const db = getFirestore();
  const dateString = new Date().toISOString();
  const docPath = `aiAssnGradingLogs/${courseId}/disagreement/${dateString}`;
  setDoc(doc(db, docPath), log);
}

export const extractJSON = jsonString => {
  const trimmedJsonString = jsonString
    .replace(/^```json/, '')
    .replace(/```$/, '');
  return JSON.parse(trimmedJsonString);
};

export const getScoreFromGPTResponse = response => {
  const jsonString = response.choices[0].message.content;
  const {score} = extractJSON(jsonString);
  const isCorrect = score >= 8;
  return {score, isCorrect};
};
