import {
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from 'firebase/firestore';
import {inferUnitTestTypeFromMetadata} from 'ide/UnitTest/runUnitTests';
import {
  AssignmentUnitTestType,
  AssignmentType,
  AssignmentUnitTestBehavior,
  AssignmentId,
  ShowSolutionBehavior,
  isShowSolutionBehavior,
} from 'assignments/types';
import {CourseId} from 'types/common';
import {isNullOrUndefined} from 'utils/general';

/**
 * The metadata for an assignment. If you are adding a new field, make sure
 * to update the `isAssignmentMetadata` function to check for the new field.
 */
export interface AssignmentMetadata {
  /** A human-readable name for the assignment. */
  title: string;
  /** The unique ID for the assignment. Also called the "assignment id". */
  uid: string;
  /** The type of assignment. */
  type: AssignmentType;
  /** The autograder type for the assignment. */
  unitTestType: AssignmentUnitTestType;
  /** When to run the autograder for the assignment. */
  unitTestBehavior: AssignmentUnitTestBehavior;
  /** @deprecated Use `showSolutionBehavior` instead. Whether the assignment is
   * a worked example (i.e. has provided solution code). Defaults as `false`
   * when missing. */
  workedExample?: boolean;
  /** Tags for the assignment to help group them (e.g. "easy", "loops"). */
  tags?: string[];
  /** An optional link to show to students after submitting an assignment.
   * This can be used, for example, to direct students to an external upload
   * page.
   */
  onSubmitLink?: string;
  /** The Canvas assignment ID for the assignment. */
  canvasAssnId?: number;
  /** Whether to show the solution behavior in the IDE. */
  showSolutionBehavior?: ShowSolutionBehavior;
}

export function isAssignmentMetadata(
  metadata: any,
): metadata is AssignmentMetadata {
  return (
    metadata &&
    typeof metadata === 'object' &&
    typeof metadata.title === 'string' &&
    typeof metadata.uid === 'string' &&
    typeof metadata.type === 'string' &&
    typeof metadata.unitTestType === 'string' &&
    typeof metadata.unitTestBehavior === 'string' &&
    (isNullOrUndefined(metadata.workedExample) ||
      typeof metadata.workedExample === 'boolean') &&
    (isNullOrUndefined(metadata.tags) ||
      (Array.isArray(metadata.tags) &&
        metadata.tags.every(tag => typeof tag === 'string'))) &&
    (isNullOrUndefined(metadata.onSubmitLink) ||
      typeof metadata.onSubmitLink === 'string') &&
    (isNullOrUndefined(metadata.canvasAssnId) ||
      typeof metadata.canvasAssnId === 'number') &&
    (isNullOrUndefined(metadata.showSolutionBehavior) ||
      isShowSolutionBehavior(metadata.showSolutionBehavior))
  );
}

const ASSIGNMENT_METADATA_DOC_CONVERTER: FirestoreDataConverter<AssignmentMetadata> =
  {
    toFirestore(metadata: AssignmentMetadata): DocumentData {
      const toUpload = {...metadata, lastUpdated: Timestamp.now()};
      // The source of truth for the assignment ID is the document ID.
      delete toUpload.uid;
      return toUpload;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot<DocumentData>,
      options: SnapshotOptions,
    ): AssignmentMetadata {
      const data = snapshot.data(options);
      if (!data) {
        console.debug('No data found in snapshot.');
        return null;
      }
      // The metadata document ID is the assignment ID.
      data.uid = snapshot.id;
      if (!data.unitTestType) {
        data.unitTestType = inferUnitTestTypeFromMetadata(data);
      }
      if (!data.unitTestBehavior) {
        data.unitTestBehavior = 'on_run';
      }
      if (!data.showSolutionBehavior) {
        data.showSolutionBehavior = inferShowSolutionBehavior(
          data as AssignmentMetadata,
        );
      }
      if (data.canvasAssnId && typeof data.canvasAssnId !== 'number') {
        data.canvasAssnId = Number(data.canvasAssnId);
        if (isNaN(data.canvasAssnId)) {
          delete data.canvasAssnId;
        }
      }
      if (!isAssignmentMetadata(data)) {
        console.warn('Invalid data found in snapshot: ', data);
        return null;
      }
      return {
        ...data,
      };
    },
  };

export function getAssignmentMetadataReference(
  courseId: CourseId,
  assignmentId: AssignmentId,
): DocumentReference<AssignmentMetadata> {
  return doc(
    getFirestore(),
    `/assns/${courseId}/assignments/${assignmentId}`,
  ).withConverter(ASSIGNMENT_METADATA_DOC_CONVERTER);
}

export function inferShowSolutionBehavior(
  metadata: AssignmentMetadata,
): ShowSolutionBehavior {
  if (!metadata) {
    return 'never';
  }
  if (metadata.showSolutionBehavior) {
    return metadata.showSolutionBehavior;
  }
  if (metadata.workedExample) {
    return 'always';
  }
  return 'never';
}
