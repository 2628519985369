import {useCourseId} from 'hooks/router/useUrlParams';
import {Role} from 'types/role';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';

export const SectionPractice = () => {
  return (
    <CoursePageBodyContainer
      mainColumn={<Content />}
      rightColumn={<></>}
      singleColumn={<Content />}
    />
  );
};

export const Content = () => {
  const courseId = useCourseId();

  return (
    <div className="mt-3">
      <AdminRichTextEditor
        firebaseDocPath={`course/${courseId}/handouts/sectionPractice`}
        minRole={Role.TA}
      />
    </div>
  );
};
