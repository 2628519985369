import { useParams } from "react-router-dom";
import { CourseId } from "types/common";

/**
 * Gets the current course ID from the URL params, or failing that attempts to
 * infer it from the URL path with a final fallback to "spr23".
 *
 * TODO: #986 - Refactor this fallback behavior.
 */
export const useCourseId = (): CourseId => {
  const { courseId } = useParams();

  if (!courseId) {
    const path = window.location.pathname;
    if (path.split("/").length > 1 && path.split("/")[1] === "alumni") {
      return "alumni";
    }
    return "spr23";
  }

  return courseId;
};

export const isSpanishCourse = () => {
  const url = window.location.pathname;
  return url.includes("uniandes");
};
