import React, {useEffect, useRef, useContext} from 'react';
import useState from 'react-usestateref';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import {FaICursor, FaPlay, FaStop, FaTerminal, FaTrash} from 'react-icons/fa';
import {PyodideClient, PyodideContext} from '../../../pyodide/PyodideProvider';
import {deepCopy} from '@firebase/util';

// tiptap
import {NodeViewWrapper} from '@tiptap/react';
import {Node, mergeAttributes} from '@tiptap/core';
import {ReactNodeViewRenderer} from '@tiptap/react';
import {
  getDefaultWorldState,
  isValidWorldState,
} from '../../../pyodide/KarelLib/util';
import {EditButtons} from '../../../pyodide/KarelLib/WorldEditor';
import {RunnableKarelWorld} from './runnableKarel/RunnableKarelWorld';
import {MonacoTipTap} from './runnableKarel/TipTapCodeEditor';

const NODE_KEY = 'runnable-karel';

export const RenderKarelCode = props => {
  const startWorld = props.node.attrs.worldState;
  const code = props.node.attrs.code;

  const [isRunning, setIsRunning] = useState(false);
  const [editType, setEditType] = useState('moveKarel');
  const [startState, setStartState] = useState(null);
  // this is not the saved start state, but the viewed run state
  const [viewWorldState, setViewWorldState] = useState(startWorld);
  const pyodideClientRef = useRef(null);

  // we should not need this. workaround beacuse "editable" is not reactive
  const [isEditable, setIsEditable, editableRef] = useState(
    props.editor.isEditable,
  );
  useEffect(() => {
    props.editor.on('transaction', () => {
      if (props.editor.isEditable != editableRef.current) {
        setIsEditable(props.editor.isEditable);
      }
    });
  }, []);

  // NOTE FOR NODE
  // Should add a startingState to node
  // Change startingState when user manually changes karel, but not when we change karel

  useEffect(() => {
    pyodideClientRef.current = new PyodideClient();
    pyodideClientRef.current.setHandlers(
      onRunEnd,
      stdout => console.log(stdout),
      stdout => console.log(stdout),
    );
    setStartState(startWorld);
  }, []);

  useEffect(() => {
    if (!isRunning) {
      setStartState(props.node.attrs.worldState);
      setViewWorldState(props.node.attrs.worldState);
    }
  }, [props.node.attrs.worldState]);

  const run = async () => {
    pyodideClientRef.current.setKarelInfo(startState, state => {
      setViewWorldState(state);
    });
    const activeFile = 'main.py';
    setIsRunning(true);
    await pyodideClientRef.current.loadFile(activeFile, code);

    await pyodideClientRef.current.runCode(code, activeFile, false);
  };

  const stop = () => {
    // PyodideClient.current.raisePyStopFlag
  };

  const onRunEnd = () => {
    setIsRunning(false);
  };

  const deleteNode = () => {
    props.deleteNode();
  };

  const onCodeChange = newCode => {
    props.updateAttributes({
      code: newCode,
    });
  };

  // update to the start state
  const setWorldState = newStateFn => {
    const newState = newStateFn(startState);
    props.updateAttributes({
      worldState: newState,
    });
  };

  return (
    <NodeViewWrapper data-drag-handle>
      <div
        style={{
          border: 'lightgrey',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
        spellCheck="false"
        draggable="true"
      >
        <div className="d-flex">
          <MonacoTipTap
            mode="python"
            value={code}
            onChange={e => onCodeChange(e)}
            readOnly={!isEditable}
          />

          <div style={{width: 300}}>
            <RunnableKarelWorld
              worldState={viewWorldState}
              setWorldState={setWorldState}
              editType={editType}
              isEditable={isEditable}
            />
          </div>
        </div>
        <ButtonRow
          worldState={startState}
          setWorldState={setWorldState}
          isRunning={isRunning}
          runCode={() => run()}
          stopExecution={() => stop()}
          editType={editType}
          setEditType={setEditType}
          deleteNode={deleteNode}
          isEditable={isEditable}
        />
      </div>
    </NodeViewWrapper>
  );
};

export const RunnableKarel = Node.create({
  name: NODE_KEY,
  group: 'block',
  atom: true,
  draggable: true,
  addAttributes() {
    return {
      code: {
        default: STARTER_CODE,
      },
      worldState: {
        default: deepCopy(getDefaultWorldState(3, 4)),
      },
    };
  },

  parseHTML() {
    return [{tag: NODE_KEY}];
  },

  renderHTML({HTMLAttributes}) {
    return [NODE_KEY, mergeAttributes(HTMLAttributes)];
  },

  renderText({node}) {
    return node.attrs.code + '\n';
  },

  addNodeView() {
    return ReactNodeViewRenderer(RenderKarelCode);
  },
});

const ButtonRow = ({
  isRunning,
  isEditable,
  runCode,
  stopExecution,
  worldState,
  setWorldState,
  editType,
  setEditType,
  deleteNode,
}) => {
  if (!worldState) return <></>;
  const {isPyodideLoading} = useContext(PyodideContext);
  let runStopText = (
    <span>
      <FaPlay /> {'Run'}
    </span>
  );
  if (isRunning) {
    runStopText = (
      <span>
        <FaStop /> {'Stop'}
      </span>
    );
  }

  const onRunStopClick = () => {
    if (isRunning) {
      stopExecution();
    } else {
      runCode();
    }
  };

  const setState = (key, value) => {
    setWorldState(oldState => {
      const newState = {...oldState};
      newState[key] = value;
      if (isValidWorldState(newState)) {
        return newState;
      } else {
        return oldState;
      }
    });
  };

  return (
    <div className="d-flex justify-content-between">
      <div>
        <button
          disabled={isPyodideLoading}
          onClick={() => onRunStopClick()}
          className="btn btn-light btn-sm "
          style={{width: '80px'}}
        >
          {runStopText}
        </button>
      </div>
      {isEditable && (
        <div className="d-flex align-items-center">
          <EditButtons
            editType={editType}
            setEditType={setEditType}
            worldState={worldState}
            setState={setState}
          />
          <button onClick={() => deleteNode()} className="btn btn-light">
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
};

const STARTER_CODE = `from karel.stanfordkarel import *

def main():
    move()

if __name__ == '__main__':
    main()`;
