import {Accordion, Form, Button} from 'react-bootstrap';
import {RoadmapContext} from 'course/contexts/RoadmapContext';
import {AssnContext} from 'course/contexts/AssnContext';
import {LessonsContext} from 'course/contexts/LessonsContext';
import {TrainingContext} from 'course/contexts/TrainingContext';
import {useParams} from 'react-router';
import {useContext, useEffect, useState} from 'react';
import {FaCamera, FaPlus} from 'react-icons/fa';
import {
  RoadmapModuleHeader,
  RoadmapModuleItem,
} from './components/ModuleComponents';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import {showSelectRoadmapItemSwal} from './RoadmapAlerts';
import {EditModuleModal} from './RoadmapAlerts';
import {uploadPhoto} from 'utils/uploadFile';
import Swal from 'sweetalert2';
import {ProfileContext} from '../../../contexts/ProfileContext';
import Gate from 'contexts/Gate';
import NoAccess from 'components/errors/NoAccess';
import {itemSwitchSpeedbump} from '../lessonEditor/utils';
import {CourseContext} from 'contexts/CourseContext';
import {
  isATypeOfStudent,
  isStudent,
  roleToAdminString,
  firebaseStringToRole,
} from 'contexts/profileUtil';

export const RoadmapEditor = () => {
  const {userData} = useContext(ProfileContext);
  const isAdmin = Gate.hasAdminRole(userData);
  if (!isAdmin) {
    return <NoAccess />;
  }

  return <RoadmapEditorPage />;
};

export const RoadmapEditorPage = () => {
  return (
    <div>
      <div className="splashCardMainTitle mt-4">Manage To Do List Content</div>
      <div style={{height: 20}} />
      <RoadmapTable />
      <div style={{height: 60}} />
    </div>
  );
};

const RoadmapTable = () => {
  const {
    roadmapItemMap,
    createModuleInDB,
    swapItemOrderInDB,
    createItemInDB,
    roadmapType,
    setRoadmapType,
  } = useContext(RoadmapContext);
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false);
  const {roadmapList} = useContext(CourseContext);
  const [isRegularStudent, setIsRegularStudent] = useState(false);

  useEffect(() => {
    setIsRegularStudent(isStudent(firebaseStringToRole(roadmapType)));
  }, [roadmapType]);

  const createModule = async () => {
    setIsModuleModalOpen(true);
  };

  const onNewModuleFormSubmit = async data => {
    setIsModuleModalOpen(false);

    if (!data || !data.title) {
      return;
    }
    await createModuleInDB(data);
  };

  const sortMods = (a, b) => {
    if (!roadmapItemMap[a] || !roadmapItemMap[b]) {
      return 1;
    }
    const aDate = roadmapItemMap[a]['startDate'];
    const bDate = roadmapItemMap[b]['startDate'];
    if (!aDate) {
      return 1;
    }
    if (!bDate) {
      return -1;
    }
    const aDatetime = new Date(aDate);
    const bDatetime = new Date(bDate);
    return aDatetime.getTime() - bDatetime.getTime();
  };

  const handleTabSwitch = role => {
    setRoadmapType(role);
  };

  const getRoadmapButtons = () => {
    return roadmapList.map(roadmap => {
      return (
        <Button
          variant={roadmapType === roadmap ? 'primary' : 'outline-primary'}
          onClick={() => handleTabSwitch(roadmap)}
          key={`roadmap-${roadmap}`}
        >
          {roleToAdminString(firebaseStringToRole(roadmap))}
        </Button>
      );
    });
  };

  return (
    <div>
      <div className="p-2 m-2">
        <div className="btn-group" role="group" aria-label="Toggle Roadmap">
          {getRoadmapButtons()}
        </div>
        <button className="btn btn-success m-2" onClick={createModule}>
          Add Module <FaPlus />
        </button>
      </div>
      <EditModuleModal
        onSubmit={onNewModuleFormSubmit}
        showModal={isModuleModalOpen}
        onCancel={() => setIsModuleModalOpen(false)}
        isModule={true}
        defaultValues={{}}
      />

      <Accordion>
        {Object.keys(roadmapItemMap)
          .sort(sortMods)
          .map(key => {
            if (
              !key ||
              !roadmapItemMap[key] ||
              roadmapItemMap[key]['roadmapType'] !== roadmapType
            ) {
              return null;
            }
            return (
              <RoadmapModule
                moduleId={key}
                todoModule={roadmapItemMap[key]}
                items={roadmapItemMap[key]['items']}
                swapItemOrderInDB={swapItemOrderInDB}
                key={key}
                createItemInDB={createItemInDB}
                roadmapType={roadmapType}
              />
            );
          })}
      </Accordion>
    </div>
  );
};

const RoadmapModule = ({
  moduleId,
  todoModule,
  swapItemOrderInDB,
  items,
  createItemInDB,
  roadmapType,
}) => {
  /*
   * Here we setup and pull the selections for items
   * We need the:
   * - List of assignments
   * - List of lessons
   */
  const {assnMetaData} = useContext(AssnContext);
  const assnSelection = Object.keys(assnMetaData).map(assnId => ({
    completionId: assnId,
    label: assnMetaData[assnId].title,
  }));

  const {lessonsMap} = useContext(
    isATypeOfStudent(firebaseStringToRole(roadmapType))
      ? LessonsContext
      : TrainingContext,
  );
  const lessonSelection = Object.keys(lessonsMap).map(lessonId => {
    const itemMap = lessonsMap[lessonId].items;

    const firstItemKey =
      itemMap.length > 0
        ? Object.keys(itemMap).reduce((a, b) =>
            itemMap[a].index < itemMap[b].index ? a : b,
          )
        : lessonId;
    return {
      completionId: lessonId,
      label: lessonsMap[lessonId].title,
      urlId: `${firstItemKey}`,
    };
  });

  const {courseId} = useParams();
  const [createItemModelOpen, setCreateItemModelOpen] = useState(false);
  const [lastNewItemType, setLastNewItemType] = useState(null);

  // Sensors for dragging
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100, // delay in ms
        tolerance: 3, // minimum movement to start the drag operation
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  async function handleDragEnd(event) {
    const speedbump = await itemSwitchSpeedbump(
      'Are you sure you want to switch these two items?',
    );
    if (!speedbump) {
      return;
    }
    const {active, over} = event;
    if (active && over && active.id !== over.id) {
      swapItemOrderInDB(active, over, moduleId);
    }
  }

  const onAddItemClick = async e => {
    e.stopPropagation();
    const itemType = await showSelectRoadmapItemSwal();
    if (!itemType) {
      return;
    }

    setLastNewItemType(itemType);
    setCreateItemModelOpen(true);
  };

  return (
    <div className="m-3" key={moduleId}>
      <Accordion.Item eventKey={moduleId}>
        <Accordion.Header>
          <RoadmapModuleHeader todoModule={todoModule} moduleId={moduleId} />
        </Accordion.Header>
        <Accordion.Body>
          <div className="splashCardRightTitle mb-2">Items</div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map(item => {
                if (!item) {
                  return null;
                }
                let relevantSelectionList = [];
                if (item.itemType === 'Assignment') {
                  relevantSelectionList = assnSelection;
                } else if (item.itemType === 'Lesson') {
                  relevantSelectionList = lessonSelection;
                }

                return (
                  <RoadmapModuleItem
                    item={item}
                    moduleId={moduleId}
                    relevantSelectionList={relevantSelectionList}
                    key={item.id}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
          <button className="btn btn-dark" onClick={onAddItemClick}>
            <FaPlus /> Add Item
          </button>
          <EditModuleModal
            itemType={lastNewItemType}
            defaultValues={{itemType: lastNewItemType}}
            isModule={false}
            showModal={createItemModelOpen}
            onSubmit={async data => {
              if (!data) {
                return;
              }
              await createItemInDB(data, moduleId);
              setCreateItemModelOpen(false);
            }}
            onCancel={() => {
              setCreateItemModelOpen(false);
            }}
            idList={
              lastNewItemType !== 'Lesson' && lastNewItemType !== 'Assignment'
                ? []
                : lastNewItemType === 'Lesson'
                ? lessonSelection
                : assnSelection
            }
            isNew={true}
          />

          <div className="splashCardRightTitle mb-2 mt-2">Announcement</div>

          <div className="border rounded">
            <AdminRichTextEditor
              firebaseDocPath={`roadmap/${courseId}/descriptions/${moduleId}`}
            />
          </div>
          <div className="splashCardRightTitle mb-2 mt-2">Thumbnail</div>
          <ThumbnailUpload
            thumbnailUrl={
              todoModule.thumbnailUrl ? todoModule.thumbnailUrl : ''
            }
            moduleId={moduleId}
          />
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

const ThumbnailUpload = ({thumbnailUrl, moduleId}) => {
  const {addModuleImageLink} = useContext(RoadmapContext);
  const [isUploading, setIsUploading] = useState(false);
  const {courseId} = useParams();

  const onProgress = progress => {
    setIsUploading(true);
  };

  const onUploadError = () => {
    setIsUploading(false);
  };

  const onUploadComplete = async newUrl => {
    await addModuleImageLink(newUrl, moduleId);
    Swal.fire({
      title: `Success!`,
      toast: true,
      showClass: {
        popup: 'none',
      },
      icon: 'success',
      timer: 1000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
    setIsUploading(false);
  };

  const onThumbnailUpload = () => {
    if (!isUploading) {
      const storagePath = `roadmap/${courseId}/${moduleId}`;
      uploadPhoto(storagePath, onUploadComplete, onProgress, onUploadError);
    }
  };

  return (
    <div>
      <button className="btn" onClick={onThumbnailUpload}>
        <FaCamera /> Upload Photo
      </button>
      {thumbnailUrl ? (
        <img
          style={{height: '10rem', objectFit: 'cover'}}
          src={thumbnailUrl}
          alt="thumbnail"
        />
      ) : null}
    </div>
  );
};
