import {Role} from 'types/role';
import {createContext} from 'react';

export type ForumType = 'Course' | 'Section' | 'Reporting' | 'Project';

type ForumContext = {
  forumId: string;
  forumType: ForumType;
  privatePostThreshold: Role;
  setPinnedThreshold: Role;
  isReporting?: boolean;
};

export const ForumContext = createContext<ForumContext>({
  forumId: 'main',
  forumType: 'Course',
  privatePostThreshold: Role.SECTION_LEADER,
  setPinnedThreshold: Role.ADMIN,
  isReporting: false,
});
