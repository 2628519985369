import {
  SelectOption,
  SelectDropdown,
} from 'components/reusableButtons/SelectDropdown';
import {useContext, useEffect} from 'react';
import {ProfileContext} from 'contexts/ProfileContext';
import {FaGlobe} from 'react-icons/fa';
import {
  DEFAULT_LANGUAGE_CODE,
  useTranslationContext,
} from 'contexts/TranslationContext';
import {
  LANGUAGE_CODE_TO_NAME,
  UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
} from './types';
import styled from 'styled-components';
import {useIsMobile} from 'utils/general';
import {Modal} from 'react-bootstrap';

/** Props for building a LanguageSelector object */
interface LanguageSelectorProps {
  /** Whether to render the dropdown with a transparent background/border, such
  as when placed in the navbar */
  useTransparentBackground: boolean;
}

/**
 * Manager for language selection within the application.
 *
 * @remarks
 * This component controls both the language selection dropdown and the actual
 * management of the site language state. It coordinates with the
 * {@link contexts.ProfileContext.ProfileProvider | ProfileProvider} (when
 * available) to load the user's preferred language and synchronize it to
 * firebase on change.
 * @param __namedParameters - {@inheritdoc LanguageSelectorProps}
 */
export const LanguageSelector = ({
  useTransparentBackground,
}: LanguageSelectorProps) => {
  const {setUserData, loading: userDataIsLoading} = useContext(ProfileContext);
  const {siteLanguage, setSiteLanguage, useSupportedLanguages} =
    useTranslationContext();
  const {supportedLanguages} = useSupportedLanguages();
  const isMobile = useIsMobile();

  const selectableOptions: SelectOption<string>[] =
    supportedLanguages.length > 0
      ? supportedLanguages.map(languageCode => ({
          label: LANGUAGE_CODE_TO_NAME?.[languageCode] ?? languageCode,
          value: languageCode,
        }))
      : [
          {
            label: LANGUAGE_CODE_TO_NAME[DEFAULT_LANGUAGE_CODE],
            value: DEFAULT_LANGUAGE_CODE,
          },
        ];
  const selectedOption =
    selectableOptions.find(option => option.value === siteLanguage) ??
    selectableOptions[0];
  /**
   * Handler for updating the language selection when the dropdown is clicked.
   * Syncs the change to the user's profile if available.
   * @param option - The selected language option
   * @event
   */
  const handleSelect = (option: SelectOption<string>) => {
    setSiteLanguage(option.value);
    localStorage.setItem(
      UNAUTHENTICATED_PREFERRED_LANGUAGE_LOCAL_STORAGE_KEY,
      option.value,
    );
    if (!userDataIsLoading && setUserData) {
      setUserData({preferredLanguage: option.value});
    }
  };
  let toggleStyleClasses = 'p-1';
  if (useTransparentBackground) {
    toggleStyleClasses += ' bg-transparent border-0';
  }
  const toggleTitle = (
    <>
      <FaGlobe />
      {'    '}
      {isMobile ? selectedOption.value.toUpperCase() : selectedOption?.label}
    </>
  );

  return (
    supportedLanguages?.length > 1 && (
      <SelectDropdown
        selectableOptions={selectableOptions}
        selectedOption={selectedOption}
        setSelectedOption={handleSelect}
        configuration={{
          toggleStyleClasses,
          toggleSize: 'lg',
          toggleTitle,
          align: 'end',
        }}
      />
    )
  );
};

interface LanguageSelectorModalProps {
  show: boolean;
  onHide: () => void;
}

const StyledSelectorModal = styled(Modal)<{isMobile: boolean}>`
  .modal-dialog {
    max-width: ${props => (props.isMobile ? '90vw' : '30vw')};
  }
`;

export function LanguageSelectorModal({
  show,
  onHide,
}: LanguageSelectorModalProps) {
  const {useTranslateFunction} = useTranslationContext();
  const t = useTranslateFunction('common');
  const isMobile = useIsMobile();
  return (
    <StyledSelectorModal show={show} onHide={onHide} isMobile={isMobile}>
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title>{t('Select Language')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <LanguageSelector useTransparentBackground={false} />
      </Modal.Body>
    </StyledSelectorModal>
  );
}
