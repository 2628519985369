import {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {PartialLoading} from 'components/loading/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  FaLink,
  FaCopy,
  FaCheck,
  FaDownload,
  FaWindowClose,
  FaCheckCircle,
  FaClipboard,
} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {CopyButton} from 'components/reusableButtons/CopyButton';
import {ProfileContext} from 'contexts/ProfileContext';
import {CourseContext} from 'contexts/CourseContext';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import {
  getAllFileNamesWithoutImages,
  getProjectFilesCode,
  getAllImages,
  getNextSubmissionId,
} from '../../ide/utils/general';
import {useLoadedAssignmentData} from '../../ide/hooks/loadIdeData';
import Swal from 'sweetalert2';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import Gate from 'contexts/Gate';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {
  getLocalUTCTimezone,
  parseAndFormatISODate,
} from 'components/timezones/timezoneHelper';
import {useDocumentDataOnce} from 'react-firebase-hooks/firestore';
import {GlobalCommentVisibilityToggle} from 'course/instructorFeedback/components/CommentVisibilityToggle';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

const functions = getFunctions();
const adminGetAssnSubmissions = httpsCallable(
  functions,
  'adminGetAssnSubmissions',
);
const getAssnInfo = httpsCallable(functions, 'getAssnInfo');

export const DownloadStudentAssnsPage = () => {
  const {courseId, assnId} = useParams();
  const {userData} = useContext(ProfileContext);
  const {isFoothill} = useContext(CourseContext);
  const isAdmin = Gate.hasAdminRole(userData);
  const db = getFirestore();

  const [submittedAssnData, submittedAssnLoading, submittedAssnError] =
    useDocumentDataOnce(
      doc(db, `submissions/${courseId}/assignments/${assnId}`),
    );

  // TODO @miranda: this is hardcoded for now
  const isDevTest = courseId === 'devtest';
  if (!(isAdmin && (isFoothill || isDevTest))) {
    return <div>Nothing to see here...</div>;
  }

  const [studentData, setStudentData] = useState([]);
  const [allStudentIds, setAllStudentIds] = useState([]);
  const [canvasAssnInfo, setCanvasAssnInfo] = useState({});
  const [canvasAssnInfoLoading, setCanvasAssnInfoLoading] = useState(true);
  const [canvasAssnSubmissions, setCanvasAssnSubmissions] = useState({});
  const [adminSubmissionsData, setAdminSubmissionsData] = useState({}); // firestore doc: submissions/{courseId}/assignments/{assnId}
  const [assnSubmissionsLoading, setAssnSubmissionsLoading] = useState(true);
  const [assnDataLoading, assnData] = useLoadedAssignmentData(
    courseId,
    assnId,
    () => {
      console.log('error loading assn data!');
    },
  );

  useEffect(() => {
    // get each student's project ID, display name, and whether they passed all unit tests for this assignment
    const fetchStudentData = async () => {
      const studentRosterRef = doc(db, `course/${courseId}/rosters/student`);
      const studentRoster = (await getDoc(studentRosterRef)).data();
      const studentIds = Object.keys(studentRoster);
      setAllStudentIds(studentIds);
      const fetchPromises = studentIds.map(userId => {
        const userRef = doc(db, `users/${userId}`);
        const trackablesPath = `/users/${userId}/${courseId}`;
        const assnProgressDocRef = doc(db, `${trackablesPath}/assnProgress`);
        const assnMapRef = doc(db, `${trackablesPath}/assnMap`);
        const visibilityDocRef = doc(
          db,
          `submissions/${courseId}/assignments/${assnId}/users/${userId}/feedback/visibility`,
        );

        return Promise.allSettled([
          getDoc(userRef),
          getDoc(assnProgressDocRef),
          getDoc(assnMapRef),
          getDoc(visibilityDocRef),
        ])
          .then(([userResult, assnResult, assnMapResult, visibilityResult]) => {
            var userDoc =
              userResult.status === 'fulfilled' ? userResult.value : null;
            var assnDoc =
              assnResult.status === 'fulfilled' ? assnResult.value : null;
            var assnMap =
              assnMapResult.status === 'fulfilled' ? assnMapResult.value : null;
            var visibilityDoc =
              visibilityResult.status === 'fulfilled'
                ? visibilityResult.value
                : null;

            return {
              userId,
              displayName: userDoc?.data()?.displayName ?? 'Unknown',
              testsPassed: assnDoc?.data()?.[assnId] ?? false,
              projectId: assnMap?.data()?.[assnId] ?? null, // TODO fetch lastSubmissionRef instead?
              visibility: visibilityDoc?.data()?.visibility ?? false,
            };
          })
          .catch(error =>
            console.log(`Error fetching data for user ${userId}: `, error),
          );
      });

      try {
        const results = await Promise.all(fetchPromises);
        setStudentData(prev => results.filter(result => result !== undefined));
      } catch (error) {
        console.error('Error fetching student progress: ', error);
      }
    };

    fetchStudentData();

    const fetchAssnInfo = async () => {
      try {
        const response = await getAssnInfo({
          adminId: userData.id,
          courseId: courseId,
          assnId: assnId,
        });
        if (!response || !response.data) {
          // handle error
          console.log('Error fetching assn info');
          return;
        }
        setCanvasAssnInfo(response.data);
        setCanvasAssnInfoLoading(false);
      } catch (error) {
        console.error('Error fetching assignment info: ', error);
        setCanvasAssnInfoLoading(false);
      }
    };

    fetchAssnInfo();

    const fetchAssnSubmissions = async () => {
      try {
        // get firebase submissions doc
        // user id: map of lastSubmissionRef, score, status, timestamp, scoreTimestamp
        const adminSubmissionsDoc = doc(
          db,
          `submissions/${courseId}/assignments/${assnId}`,
        );
        const adminSubmissionsSnap = await getDoc(adminSubmissionsDoc);
        if (adminSubmissionsSnap.exists()) {
          setAdminSubmissionsData(adminSubmissionsSnap.data());
        }

        // fetch assignment submissions from Canvas
        const response = await adminGetAssnSubmissions({
          adminId: userData.id,
          courseId: courseId,
          assnId: assnId,
        });
        if (!response || !response.data) {
          // handle error
          console.log('Error fetching assn submissions');
          return;
        }
        const submissions = Object.fromEntries(
          response.data.map(submission => {
            return [submission.firestoreUserId, submission];
          }),
        );
        setCanvasAssnSubmissions(submissions);

        setAssnSubmissionsLoading(false);
      } catch (error) {
        console.error('Error fetching assignment submissions: ', error);
        setAssnSubmissionsLoading(false);
      }
    };

    fetchAssnSubmissions();
  }, []);

  if (
    assnDataLoading ||
    canvasAssnInfoLoading ||
    assnSubmissionsLoading ||
    studentData.length === 0
  ) {
    return <PartialLoading />;
  }

  return (
    <CoursePageBodyContainer
      mainColumn={
        <DownloadStudentAssnsMain
          db={db}
          courseId={courseId}
          assnId={assnId}
          studentData={studentData}
          assnData={assnData}
          canvasAssnInfo={canvasAssnInfo}
          canvasAssnSubmissions={canvasAssnSubmissions}
          adminSubmissionsData={adminSubmissionsData}
          submittedAssnData={submittedAssnData}
          allStudentIds={allStudentIds}
        />
      }
      rightColumn={<></>}
      singleColumn={
        <DownloadStudentAssnsMain
          db={db}
          courseId={courseId}
          assnId={assnId}
          studentData={studentData}
          assnData={assnData}
          canvasAssnInfo={canvasAssnInfo}
          canvasAssnSubmissions={canvasAssnSubmissions}
          adminSubmissionsData={adminSubmissionsData}
          submittedAssnData={submittedAssnData}
          allStudentIds={allStudentIds}
        />
      }
    />
  );
};

const DownloadStudentAssnsMain = ({
  db,
  courseId,
  assnId,
  studentData,
  assnData,
  canvasAssnInfo,
  canvasAssnSubmissions,
  adminSubmissionsData,
  submittedAssnData,
  allStudentIds,
}) => {
  const userTz = getLocalUTCTimezone();
  const nextSubmissionId = getNextSubmissionId(assnData);
  return (
    <div className="mt-3">
      <h2>
        {assnData.metaData.title} ({assnData.metaData.type}) submissions for{' '}
        {courseId}
      </h2>
      <h3>Due: {parseAndFormatISODate(canvasAssnInfo.due_at, userTz)}</h3>

      <div>
        <CanvasLinkButton canvasUrl={canvasAssnInfo.html_url} />
        <DownloadGradesButton
          db={db}
          assnId={assnId}
          courseId={courseId}
          studentData={studentData}
          assnData={assnData}
        />
        <StartGradingButton
          courseId={courseId}
          nextSubmissionId={nextSubmissionId}
        />
      </div>
      <GlobalCommentVisibilityToggle
        db={db}
        courseId={courseId}
        assnId={assnId}
        studentIds={allStudentIds}
      />

      <StudentSubmissionTable
        courseId={courseId}
        studentData={studentData}
        canvasAssnInfo={canvasAssnInfo}
        canvasAssnSubmissions={canvasAssnSubmissions}
        adminSubmissionsData={adminSubmissionsData}
        submittedAssnData={submittedAssnData}
      />
    </div>
  );
};

const StudentSubmissionTable = ({
  courseId,
  studentData,
  canvasAssnInfo,
  canvasAssnSubmissions,
  adminSubmissionsData,
  submittedAssnData,
}) => {
  const studentDataWithSubmissions = studentData.map(student => {
    const submission = canvasAssnSubmissions[student.userId];
    const firebaseScore =
      student.userId in adminSubmissionsData
        ? adminSubmissionsData[student.userId]['score']
        : null;
    // if the score is null in Canvas (e.g. if score was posted but not made visible on Canvas), use Firebase score
    const score = submission?.score ?? firebaseScore;
    const lastSubmissionRef =
      student.userId in adminSubmissionsData
        ? adminSubmissionsData[student.userId]['lastSubmissionRef']
        : null;
    const workflow_state =
      student.userId in submittedAssnData
        ? submittedAssnData[student.userId]['status']
        : 'no submission';
    return {
      ...student,
      ...submission,
      score,
      lastSubmissionRef,
      workflow_state,
    };
  });

  const columns = [
    {
      dataField: 'userId',
      text: 'UID',
      formatter: (cell, row) => {
        return <CopyButton toCopy={cell} icon={<FaCopy />} />;
      },
    },
    {
      dataField: 'displayName',
      text: 'Student',
      sort: true,
      formatter: (cell, row) => {
        const userId = row.userId;
        if (
          userId in submittedAssnData &&
          submittedAssnData[userId]['status'] === 'submitted'
        ) {
          return (
            <span className="text-primary">
              {cell} <FaCheckCircle />
            </span>
          );
        } else {
          return <span className="text-secondary">{cell}</span>;
        }
      },
    },
    {
      dataField: 'testsPassed',
      text: 'Tests Passed',
      sort: true,
      formatter: (cell, row) =>
        cell ? <FaCheck style={{color: 'green'}} /> : <FaWindowClose />,
    },
    {
      //     dataField: 'projectId',
      //     text: 'Code',
      //     formatter: (cell, row) => {
      //         return <Link to={`/${courseId}/ide/p/${cell}`}><FaLink /></Link>
      //     }
      // },{
      dataField: 'score',
      text: 'Score',
      formatter: (cell, row) => {
        return cell ? `${cell} / ${canvasAssnInfo.points_possible}` : '-';
      },
    },
    {
      dataField: 'workflow_state',
      text: 'Status', // TODO formatter?
      sort: true, // TODO custom sort?
    },
    {
      dataField: 'visibility',
      text: 'Comment visibility',
      formatter: (cell, row) => {
        return cell ? <FaEye /> : <FaEyeSlash />;
      },
    },
  ];

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      const userId = row.userId;
      if (
        userId in submittedAssnData &&
        submittedAssnData['status'] !== 'unsubmitted'
      ) {
        window.open(`/${courseId}/ide/p/${row.projectId}`, '_blank');
      }
    },
    onMouseEnter: (e, row, rowIndex) => {
      e.target.style.cursor = 'pointer';
    },
  };

  return (
    <BootstrapTable
      bordered={false}
      striped={true}
      hover={true}
      bootstrap4={true}
      keyField="userId"
      data={studentDataWithSubmissions}
      columns={columns}
      rowEvents={tableRowEvents}
    />
  );
};

const DownloadGradesButton = ({
  db,
  assnId,
  courseId,
  studentData,
  assnData,
}) => {
  // download all student code for this assignment
  // creates a zip file where the root folder is named courseId_assnId
  // each student's code is in a folder named userId_projectId
  // each student's code folder contains all the files in their project
  // also includes a metadata.txt file with the assignment type and date downloaded
  // and a student_metadata.csv file with the student's UID, display name, project ID, and whether they passed all unit tests
  const [loading, setLoading] = useState(false);
  const downloadProject = async () => {
    setLoading(true);
    try {
      const projectDocsPromises = studentData.map(async student => {
        const projectId = student.projectId;
        if (!projectId) return;
        const userId = student.userId;
        const displayName = student.displayName;
        const testsPassed = student.testsPassed;

        const docRef = doc(db, `projects/${projectId}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return {
            projectId,
            userId,
            displayName,
            testsPassed,
            ...docSnap.data(),
          };
        } else {
          console.log(`No document found for project ID: ${projectId}`);
          return null;
        }
      });

      const projectDocs = await Promise.all(projectDocsPromises);
      const filteredProjectDocs = projectDocs.filter(doc => doc !== undefined);

      const zip = new JSZip();
      const root = zip.folder(`${courseId}_${assnId}`);
      let csvContent = `UID, Display name, Project ID, Passed all unit tests\n`;

      for (const doc of filteredProjectDocs) {
        const files = getAllFileNamesWithoutImages(doc.files[0].files);
        const filesCode = await getProjectFilesCode(doc.projectId);
        const images = await getAllImages(doc.files[0].files);

        const studentFolder = root.folder(`${doc.userId}_${doc.projectId}`);
        files.forEach(file => {
          if (filesCode.hasOwnProperty(file.id)) {
            studentFolder.file(file.name, filesCode[file.id].content);
          }
        });

        for (var image of Object.keys(images)) {
          if (images[image]) {
            const response = await fetch(images[image]);
            const blob = await response.blob();
            studentFolder.file(image, blob);
          }
        }

        csvContent += `${doc.userId}, ${doc.displayName}, ${doc.projectId}, ${
          doc.testsPassed ? 'Yes' : 'No'
        }\n`;
      }

      root.file(
        'metadata.txt',
        `Assn: ${assnId}, Type: ${assnData.metaData.type}, Downloaded: ${new Date().toLocaleString()}`,
      );
      root.file('student_metadata.csv', csvContent);

      const content = await zip.generateAsync({
        type: 'blob',
      });
      saveAs(content, `${courseId}_${assnId}.zip`);
      setLoading(false);
    } catch (e) {
      await Swal.fire(
        `There was an error downloading student submissions`,
        e.message,
      );
    }
  };

  return (
    <Button onClick={downloadProject} disabled={loading}>
      <FaDownload /> {loading ? 'Downloading...' : 'Download student code'}
    </Button>
  );
};

const CanvasLinkButton = ({canvasUrl}) => {
  return (
    <Link to={canvasUrl} className="btn btn-primary">
      <FaLink /> Canvas assignment
    </Link>
  );
};

const StartGradingButton = ({courseId, nextSubmissionId}) => {
  if (!nextSubmissionId)
    return (
      <Button disabled className="btn btn-success">
        <FaClipboard /> Done grading!
      </Button>
    );
  return (
    <Link
      to={`/${courseId}/ide/p/${nextSubmissionId}`}
      className="btn btn-success"
    >
      <FaClipboard /> Start grading
    </Link>
  );
};
