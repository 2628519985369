import {getKarelUnitTestReference} from '../../models/unitTests/karel';
import {
  UnitTestLoaderParameters,
  UnitTestSchema,
  WithRunnerData,
} from '../UnitTestSchema';
import {
  EMPTY_TEST_RESULT,
  getFirstTestFailed,
  UnitTestResults,
} from '../unitTestResults';
import {deepCopy} from '@firebase/util';
import {areWorldsEqual} from 'components/pyodide/KarelLib/util';
import {getDoc} from 'firebase/firestore';
import {KarelEditor} from './KarelEditor';
import {type LoadedKarelUnitTestData} from './types';
import {getAllFileNames} from 'ide/utils/general';

async function loader({
  courseId,
  assignmentId,
}: UnitTestLoaderParameters): Promise<LoadedKarelUnitTestData | null> {
  const karelUnitTestReference = getKarelUnitTestReference(
    courseId,
    assignmentId,
  );
  const karelUnitTestDoc = await getDoc(karelUnitTestReference);
  if (!karelUnitTestDoc.exists) {
    return null;
  }
  const unitTests = karelUnitTestDoc.data();
  return {
    unitTests,
  };
}

async function runner({
  loadedData,
  pyodideClient,
  filesCode,
  fileStructure,
}: WithRunnerData<LoadedKarelUnitTestData>): Promise<UnitTestResults> {
  const {unitTests} = loadedData;
  // make sure that the unitTests are valid
  if (!unitTests || unitTests.length === 0) {
    return EMPTY_TEST_RESULT;
  }

  const testResults = [];
  const allFileNames = getAllFileNames(fileStructure);
  const mainFile = allFileNames.find(entry => entry.name === 'main.py');
  if (!mainFile) {
    return {
      testsRun: true,
      testResults: [
        {
          isSuccess: false,
          name: 'Karel Pre/Post World Comparison',
          usedAi: false,
          rawResult: 'No main.py file found',
        },
      ],
    };
  }
  for (const unitTest of unitTests) {
    pyodideClient.setKarelInfo(deepCopy(unitTest.pre), () => {}, 0);
    const rawResult = await pyodideClient.testCode(
      filesCode[mainFile.id].content,
      mainFile,
    );
    const observedPost = rawResult.karel;
    const expectedPost = unitTest.post;
    const hasError = rawResult.error && rawResult.error.length > 0;
    const worldsEqual = areWorldsEqual(expectedPost, observedPost);
    const isSuccess = worldsEqual && !hasError;
    testResults.push({
      isSuccess,
      name: unitTest.name,
      usedAi: false,
      rawResult: rawResult,
    });
  }
  return {
    testsRun: true,
    testResults,
  };
}

function getFailureMessage(results: UnitTestResults) {
  const firstTestFailed = getFirstTestFailed(results);
  const numTests = results.testResults.length;
  let text = "The program finished running, but didn't solve the problem.";
  if (numTests === 1) {
    text = `Karel finished running but didn't solve the problem. Either Karel is in the wrong place or the beepers are.`;
  } else {
    text = `Karel finished running but didn't solve the problem.
      Check the world named "${firstTestFailed}".`;
  }
  return text;
}

export const KAREL_UNIT_TEST_SCHEMA: UnitTestSchema<LoadedKarelUnitTestData> = {
  loader,
  runner,
  EditorComponent: KarelEditor,
  unitTestType: 'karel',
  displayName: 'Karel Pre/Post World Comparison',
  tooltipDescription:
    'Compare the world state before and after running the student code to the solution.',
  getFailureMessage,
};
