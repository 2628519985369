import {ConnectionCard} from './components/ConnectionCard';
import {FaInfoCircle} from 'react-icons/fa';
import {ReactComponent as Balloon} from '../components/icons/balloon.svg';
import {useContext} from 'react';
import {useCourseId} from 'hooks/router/useUrlParams';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {getFirestore, doc, setDoc} from 'firebase/firestore';
import {Spacer} from 'components/layout/Spacer';
import {CoursePageSingleCol} from 'components/layout/CoursePageSingleCol';
import {ConnectionsContext} from 'course/contexts/ConnectionsContext';
import styled from 'styled-components';
import {hashString} from 'react-hash-string';
import {useUserId} from 'hooks/user/useUserId';
import {ExperimentContext} from 'contexts/ExperimentContext';
import Swal from 'sweetalert2';

export const Connections = () => {
  return <CoursePageSingleCol column={<ConnectionsMain />} />;
};

const ConnectionsMain = () => {
  const courseId = useCourseId();
  const userId = useUserId();
  // use a context so that its fast to load if you go and come back
  // it also allows loading as soon as the user logs in
  const {userData} = useContext(ProfileContext);
  const {isLoading} = useContext(ConnectionsContext);
  const {expData} = useContext(ExperimentContext);

  const isMinor = userData?.isMinor;
  const eligibleForSuggestions = isEligable(expData, userId, courseId, isMinor);

  if (isLoading) {
    return <></>;
  }

  return (
    <div>
      {eligibleForSuggestions && (
        <>
          <Suggestions />
          <Spacer />
        </>
      )}
      <RequestsView />
      <Spacer />
      <ConnectionsView />
      <Spacer />
    </div>
  );
};

const Suggestions = () => {
  const {suggestions} = useContext(ConnectionsContext);

  return (
    <>
      <h4 className="mb-3 mt-4">
        Some other Code in Placers <ConnectionsInfo />
      </h4>
      <ConnectionsListOuter>
        {
          // connections view, show contact info
          suggestions.map((userId, index) => {
            console.log('userId', userId);
            return (
              <ConnectionCard
                userId={userId}
                key={`${userId}-${index}-suggestions`}
                textKey={'aboutMe'}
                btnVariant={'primary'}
                btnText={'View profile'}
              />
            );
          })
        }
      </ConnectionsListOuter>
    </>
  );
};

const RequestsView = () => {
  const {requestsList, isLoading} = useContext(ConnectionsContext);
  const numRequests = requestsList?.length;
  if (numRequests == 0) {
    return <></>;
  }
  return (
    <>
      <h4 className="mb-3 mt-4">Connection Requests 🌱</h4>
      <ConnectionsListOuter>
        {
          // connections view, show contact info
          requestsList.map(userId => (
            <ConnectionCard
              userId={userId}
              key={userId}
              textKey={'message'}
              btnVariant={'success'}
              btnText={'View request'}
            />
          ))
        }
      </ConnectionsListOuter>
    </>
  );
};

const ConnectionsView = () => {
  const {connectionsList} = useContext(ConnectionsContext);
  const nConnections = connectionsList.length;
  if (nConnections == 0) {
    return (
      <>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          <Balloon className="mb-2" style={{width: '400px'}} />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <p style={{maxWidth: '80%'}}>
            You have no connections yet! Send a request by visiting someone's
            profile.
          </p>
        </div>
      </>
    );
  }
  return (
    <div>
      <>
        <h4 className="mb-3 mt-4">Your Connections</h4>
        <ConnectionsListOuter>
          {
            // connections view, show contact info
            connectionsList.map(userId => (
              <ConnectionCard
                userId={userId}
                key={userId}
                textKey={'contact'}
                btnVariant={'primary'}
                btnText={'View profile'}
              />
            ))
          }
        </ConnectionsListOuter>
      </>
    </div>
  );
};

function isEligable(expData, userId, courseId, isMinor) {
  if (isMinor) {
    return false;
  }
  // cache the result
  const experimentKey = 'connections';
  if (expData && experimentKey in expData) {
    return expData[experimentKey];
  }
  /**
   * Important: this is the exact same function as on the server, so that we can both good decisions!
   * Only a subset of users are in the experiment. If they are not in the experiment they will
   * neither get a suggestion list, nor will they be suggested to others.
   */
  const salt = 'miranda';
  const userHash = Math.abs(hashString(userId + salt));
  // 80% of users are eligible
  console.log('userHash', userHash % 5);
  const result = userHash % 5 != 0;

  // save it to the db
  const db = getFirestore();

  const path = `users/${userId}/${courseId}/experimentFlags`;
  const docRef = doc(db, path);
  const data = {[experimentKey]: result};
  setDoc(docRef, data, {merge: true});

  return result;
}

export const ConnectionsInfo = props => {
  const onClick = () => {
    Swal.fire({
      title: 'Some other Code in Placers',
      text: `Every 12 hours we will update this list with four other users in the course. You can view their profile and send them a connection request.`,
      icon: 'info',
    });
  };

  const infoStyle = {
    marginTop: '-2px',
  };

  return (
    <button
      onClick={onClick}
      className="btn btn-link"
      style={infoStyle}
      aria-label="info"
    >
      <FaInfoCircle />
    </button>
  );
};

const ConnectionsListOuter = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
`;
