import {
  SelectDropdown,
  SelectOption,
} from 'components/reusableButtons/SelectDropdown';
import {Dispatch, SetStateAction, useState} from 'react';
import {
  ASSIGNMENT_TYPE_TO_DISPLAY_NAME,
  AssignmentType,
  SUPPORTED_ASSIGNMENT_TYPES,
} from 'assignments/types';

interface AssignmentTypeSelectorProps {
  selectedType: AssignmentType;
  setSelectedType: Dispatch<SetStateAction<AssignmentType>>;
}
export default function AssignmentTypeSelector({
  selectedType,
  setSelectedType,
}: AssignmentTypeSelectorProps) {
  const ALL_TYPE_OPTIONS: SelectOption<AssignmentType>[] =
    SUPPORTED_ASSIGNMENT_TYPES.map(type => ({
      value: type,
      label: ASSIGNMENT_TYPE_TO_DISPLAY_NAME[type],
    }));
  const SELECTED_TYPE_OPTION = ALL_TYPE_OPTIONS.find(
    ({value}) => value === selectedType,
  );
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-auto">
          <b>Assignment Type:</b>
        </div>
        <div className="col-md-auto">
          <SelectDropdown
            selectableOptions={ALL_TYPE_OPTIONS}
            selectedOption={SELECTED_TYPE_OPTION}
            setSelectedOption={option => {
              setSelectedType(option.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
