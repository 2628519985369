import {
  getDocs,
  getFirestore,
  collection,
  doc,
  runTransaction,
} from 'firebase/firestore';
import {useEffect, useState, useContext} from 'react';
import {Link, useParams} from 'react-router-dom';
import {
  FaCode,
  FaShapes,
  FaRobot,
  FaPlus,
  FaArrowAltCircleRight,
} from 'react-icons/fa';
import {Accordion} from 'react-bootstrap';
import {AdminRichTextEditor} from 'course/components/AdminRichTextEditor';
import Swal from 'sweetalert2';
import {alertError} from 'components/completion/Alerts';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {isMinimumRole} from 'contexts/profileUtil';

export const CourseResources = () => {
  const assignmentPath = 'assns/cip3/assignments';
  const [assignments, setAssignments] = useState([]);
  const lessonPath = 'lessons/cip3/lessonsList';
  const [lessons, setLessons] = useState([]);
  const docsPath = 'docs/cip3/libraries';
  const [docs, setDocs] = useState([]);
  const [docId, setDocId] = useState('');
  const db = getFirestore();
  const {userData} = useContext(ProfileContext);
  const courseRole = userData?.courseRole;
  const canEdit = isMinimumRole(courseRole, Role.ADMIN);

  const {courseId} = useParams();

  if (!canEdit) {
    return (
      <div className="d-flex flex-column align-items-center">
        <p>Only course admins can access this page.</p>
      </div>
    );
  }

  useEffect(() => {
    const getList = async path => {
      const querySnapshot = await getDocs(collection(db, path));
      const newList = [];
      querySnapshot.forEach(docResp => {
        newList.push({
          id: docResp.id,
          ...docResp.data(),
        });
      });
      return newList;
    };

    const fetchAll = async () => {
      const newAssignments = await getList(assignmentPath);
      setAssignments(newAssignments);
      const newLessons = await getList(lessonPath);
      setLessons(newLessons);
      const newDocs = await getList(docsPath);
      setDocs(newDocs);
    };

    fetchAll();
  }, []);

  const getIcon = type => {
    switch (type) {
      case 'karel':
        return <FaRobot />;
      case 'graphics':
        return <FaShapes />;
      default:
        return <FaCode />;
    }
  };

  const copyAssignment = async id => {
    // CIP ref
    const assnRef = doc(db, `${assignmentPath}/${id}`);
    const assnDocsRef = collection(db, `${assignmentPath}/${id}/docs`);
    const thisCourseAssignmentRef = doc(
      db,
      `assns/${courseId}/assignments/${id}`,
    );
    const thisCourseAssignmentDocsRef = `assns/${courseId}/assignments/${id}/docs`;
    await docAndSubDocsCopyTransaction(
      assnRef,
      thisCourseAssignmentRef,
      assnDocsRef,
      thisCourseAssignmentDocsRef,
    );
  };

  const copyLesson = async id => {
    const lessonRef = doc(db, `${lessonPath}/${id}`);
    const lessonDocsRef = collection(db, `${lessonPath}/${id}/itemsList`);
    const thisCourseLessonRef = doc(
      db,
      `lessons/${courseId}/lessonsList/${id}`,
    );
    const thisCourseLessonDocsRef = `lessons/${courseId}/lessonsList/${id}/itemsList`;
    await docAndSubDocsCopyTransaction(
      lessonRef,
      thisCourseLessonRef,
      lessonDocsRef,
      thisCourseLessonDocsRef,
    );
  };

  const docAndSubDocsCopyTransaction = async (
    sourceDocRef,
    copyDocRef,
    sourceColRef,
    copyColPath,
  ) => {
    // copy the assignment through transaction
    const transaction = async transaction => {
      // Get source assignment data
      const sourceAssignmentSnapshot = await transaction.get(sourceDocRef);
      if (!sourceAssignmentSnapshot.exists()) {
        throw new Error('Source assignment does not exist!');
      }

      // Set destination assignment data
      transaction.set(copyDocRef, sourceAssignmentSnapshot.data());

      // Get source docs data
      const sourceDocsSnapshot = await getDocs(sourceColRef);

      // Set destination docs data
      sourceDocsSnapshot.forEach(docSnapshot => {
        const destinationDocRef = doc(db, copyColPath, docSnapshot.id);
        transaction.set(destinationDocRef, docSnapshot.data());
      });
    };

    try {
      await runTransaction(db, transaction);
    } catch (e) {
      console.log(e);
      alertError(e.message);
      return;
    }

    await Swal.fire({
      title: 'Success!',
      text: 'Assignment copied to your course!',
      icon: 'success',
      confirmButtonText: 'Cool',
    });

    return;
  };

  const copyDocs = async id => {
    // CIP ref
    const docsRef = doc(db, `${docsPath}/${id}`);
    const thisCourseDocsRef = doc(db, `docs/${courseId}/libraries/${id}`);
    // copy the assignment through transaction
    const transaction = async transaction => {
      const docsDoc = await transaction.get(docsRef);
      transaction.set(thisCourseDocsRef, docsDoc.data());
    };
    try {
      await runTransaction(db, transaction);
    } catch (e) {
      alertError(e.message);
      return;
    }

    await Swal.fire({
      title: 'Success!',
      text: 'Assignment copied to your course!',
      icon: 'success',
      confirmButtonText: 'Cool',
    });

    return;
  };

  return (
    <div>
      <div className="splashCardMainTitle mt-4 d-flex align-items-center ">
        Course Resources
      </div>
      <p>
        Over three Code In Place terms, we have developed a lot of educational
        content. Below, you can see lessons and assignments that you can pull
        directly into you're own course plan.
      </p>
      <div className="d-flex flex-wrap"></div>
      <Accordion className="m-2">
        <Accordion.Item eventKey="lessons">
          <Accordion.Header>
            <h2>Lessons</h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap">
              {lessons.map(lesson => {
                return (
                  <div className="justify-content-around m-2 p-3 border rounded w-25 d-flex flex-column ">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p>
                        {getIcon(lesson.type)}
                        {lesson.title}{' '}
                      </p>
                      <Link to={`/cip3/learn/${lesson.id}`}>
                        View <FaArrowAltCircleRight />
                      </Link>
                    </div>
                    <button
                      className="btn border m-2"
                      onClick={() => copyLesson(lesson.id)}
                    >
                      <FaPlus /> Add to your course!
                    </button>
                  </div>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="m-2">
        <Accordion.Item eventKey="assn">
          <Accordion.Header>
            <h2>Assignments</h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap">
              {assignments.map(assn => {
                return (
                  <div className="justify-content-around m-2 p-3 border rounded w-25 d-flex flex-column ">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p>
                        {getIcon(assn.type)}
                        {assn.title}{' '}
                      </p>
                      <Link to={`/cip3/ide/a/${assn.id}`}>
                        View <FaArrowAltCircleRight />
                      </Link>
                    </div>
                    <button
                      className="btn border m-2"
                      onClick={() => copyAssignment(assn.id)}
                    >
                      <FaPlus /> Add to your course!
                    </button>
                  </div>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion className="m-2">
        <Accordion.Item eventKey="docs">
          <Accordion.Header>
            <h2>Docs</h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex flex-wrap">
              {docs.map(document => {
                return (
                  <div className="justify-content-around m-2 p-3 border rounded w-25 d-flex flex-column ">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p>{document.name} </p>
                      <button
                        className="btn text-primary"
                        onClick={() => {
                          setDocId(document.id);
                        }}
                      >
                        View <FaArrowAltCircleRight />
                      </button>
                    </div>
                    <button
                      className="btn border m-2"
                      onClick={() => copyDocs(document.id)}
                    >
                      <FaPlus /> Add to your course!
                    </button>
                  </div>
                );
              })}
              {docId && (
                <AdminRichTextEditor
                  firebaseDocPath={`docs/cip3/libraries/${docId}`}
                />
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
