import {
  ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES,
  ASSIGNMENT_UNIT_TEST_BEHAVIOR_TO_DISPLAY_NAME,
  ASSIGNMENT_UNIT_TEST_BEHAVIOR_TOOLTIPS,
  AssignmentType,
  AssignmentUnitTestBehavior,
  AssignmentUnitTestType,
  SUPPORTED_ASSIGNMENT_UNIT_TEST_BEHAVIORS,
} from 'assignments/types';
import {UNIT_TEST_TYPE_TO_SCHEMA} from 'assignments/unitTest/UnitTestSchemaRegistry';
import {SelectDropdown} from 'components/reusableButtons/SelectDropdown';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

interface UnitTestMetadataEditorProps {
  assignmentType: AssignmentType;
  unitTestType: AssignmentUnitTestType;
  setUnitTestType: Dispatch<SetStateAction<AssignmentUnitTestType>>;
  unitTestBehavior: AssignmentUnitTestBehavior;
  setUnitTestBehavior: Dispatch<SetStateAction<AssignmentUnitTestBehavior>>;
  isEditable: boolean;
}

export const UnitTestMetadataEditor = ({
  assignmentType,
  unitTestType,
  setUnitTestType,
  unitTestBehavior,
  setUnitTestBehavior,
  isEditable,
}: UnitTestMetadataEditorProps) => {
  const typeOptions = ASSIGNMENT_TYPE_TO_SUPPORTED_UNIT_TEST_TYPES[
    assignmentType
  ].map(unitTestType => {
    const schema = UNIT_TEST_TYPE_TO_SCHEMA[unitTestType];
    return {
      value: unitTestType,
      label: schema.displayName,
      tooltip: schema.tooltipDescription,
    };
  });

  const selectedTypeOption =
    typeOptions.find(({value}) => value === unitTestType) ?? typeOptions[0];

  // Behavior selector options
  const behaviorOptions = SUPPORTED_ASSIGNMENT_UNIT_TEST_BEHAVIORS.map(
    type => ({
      value: type,
      label: ASSIGNMENT_UNIT_TEST_BEHAVIOR_TO_DISPLAY_NAME[type],
      tooltip: ASSIGNMENT_UNIT_TEST_BEHAVIOR_TOOLTIPS[type],
    }),
  );
  const selectedBehaviorOption =
    behaviorOptions.find(({value}) => value === unitTestBehavior) ??
    behaviorOptions[0];

  // Update the local state on mount if the server state includes an
  // invalid value (e.g. a value that is not in the list of supported
  // unit test types).
  useEffect(() => {
    if (unitTestType === selectedTypeOption.value) {
      return;
    }
    setUnitTestType(selectedTypeOption.value);
  }, []);

  useEffect(() => {
    if (unitTestBehavior === selectedBehaviorOption.value) {
      return;
    }
    setUnitTestBehavior(selectedBehaviorOption.value);
  }, []);

  return (
    <>
      <h4>Autograder Details</h4>
      <Container>
        <Row className="align-items-center mb-3">
          <Col md="auto">
            <b>Autograder Type:</b>
          </Col>
          <Col md="auto">
            <SelectDropdown
              selectableOptions={typeOptions}
              selectedOption={selectedTypeOption}
              setSelectedOption={option => {
                setUnitTestType(option.value as AssignmentUnitTestType);
              }}
              configuration={{disabled: !isEditable}}
            />
          </Col>
          <Col md="auto">
            <b>Autograder Behavior:</b>
          </Col>
          <Col md="auto">
            <SelectDropdown
              selectableOptions={behaviorOptions}
              selectedOption={selectedBehaviorOption}
              setSelectedOption={option => {
                setUnitTestBehavior(option.value as AssignmentUnitTestBehavior);
              }}
              configuration={{disabled: !isEditable}}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
