import React from 'react';

import {ProfileContext} from './ProfileContext';
import {Role} from 'types/role';
import type {CourseUser} from './ProfileContext';
import {isMinimumRole} from './profileUtil';

type GatePredicate = (user: CourseUser) => boolean;

/**
 * The Gate component allows for rendering content based on the user
 * permissions without each client needing to understand the internal
 * implementation of the permissioning model.
 *
 * It must be used within a ProfileProvider; otherwise it defaults
 * to hiding all content.
 */
type GateProps = {
  children: React.ReactNode;
  when: GatePredicate;
};
const Gate = ({children, when}: GateProps) => {
  const {userData} = React.useContext(ProfileContext);
  if (!userData) {
    return null;
  }

  if (!when(userData)) {
    return null;
  }

  return <>{children}</>;
};

// checks if a user has this role OR GREATER!
Gate.hasRole = (role: Role) => (user: CourseUser) => {
  return user && isMinimumRole(user.courseRole, role);
};

Gate.hasSectionLeaderRole = Gate.hasRole(Role.SECTION_LEADER);
Gate.hasAdminRole = Gate.hasRole(Role.ADMIN);
Gate.hasStudentRole = Gate.hasRole(Role.STUDENT);
Gate.hasStaffRole = Gate.hasRole(Role.TA);
Gate.hasExperiencedStudentRole = Gate.hasRole(Role.EXPERIENCED_STUDENT);
Gate.hasMentorRole = Gate.hasRole(Role.MENTOR);

Gate.isInCourse = Gate.hasRole(Role.STUDENT);

export default Gate;
