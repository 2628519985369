import {useContext, useEffect, useState} from 'react';
import {getApp} from 'firebase/app';
import {
  collection,
  where,
  query,
  getDocs,
  getFirestore,
  or,
} from 'firebase/firestore';
import {useCourseId} from 'hooks/router/useUrlParams';
import {Link} from 'react-router-dom';
import {Badge} from 'react-bootstrap';
import Swal from 'sweetalert2';
import {useCollection} from 'react-firebase-hooks/firestore';
import {PartialLoading} from 'components/loading/Loading';
import {PracticeProblemsTable} from './PracticeProblemsTable';
import {CompletionContext} from 'contexts/CompletionContext';
import {LessonsContext} from 'course/contexts/LessonsContext';
import {RoadmapContext} from 'course/contexts/RoadmapContext';
import {checkIsDefault} from './PracticeProblemsFilters';
import {filterDefaults} from './filterDefaults';
import {FaCheck, FaRegCircle, FaInfoCircle, FaBars} from 'react-icons/fa';
import {useHistoryNavigate} from 'hooks/router/useHistoryNavigate';
import {HistoryLink} from 'hooks/router/HistoryLink';
import {useProSidebar} from 'react-pro-sidebar';
import {CoursePageSingleCol} from 'components/layout/CoursePageSingleCol';

const columns = [
  {
    dataField: 'completed',
    text: 'Done?',
    formatter: completed => {
      return completed ? <FaCheck className="text-success" /> : <FaRegCircle />;
    },
    sort: true,
    headerStyle: () => {
      return {width: '100px'};
    },
    style: () => {
      return {width: '100px'};
    },
  },
  {
    dataField: 'problemLink',
    text: 'Example',

    style: () => {
      return {width: '400px'};
    },
  },
  {
    dataField: 'unit',
    text: 'Unit',
  },
];

export const PracticeProblemsSplash = () => {
  // db tings
  const db = getFirestore(getApp());
  const navigate = useHistoryNavigate();
  const courseId = useCourseId();
  const assnsRef = collection(db, `assns/${courseId}/assignments`);
  const [assnsCollection, assnsDataLoading, assnsDataError] =
    useCollection(assnsRef);

  // data
  const [practiceProblemData, setPracticeProblemData] = useState([]);
  const [filteredPracticeProblemData, setFilteredPracticeProblemData] =
    useState([]);

  const [activeFilters, setActiveFilters] = useState({
    completedFilter: filterDefaults.completed, // or bool
    lessonFilter: filterDefaults.lesson,
  });

  const [isDefault, setIsDefault] = useState(true);

  // helper to check assn completion
  const {isAssnCompleted} = useContext(CompletionContext);
  // helpers to map worked examples to lessons
  const {getLessonsInRoadmap} = useContext(RoadmapContext);
  const {getAllWorkedExamples} = useContext(LessonsContext);

  useEffect(() => {
    async function getPracticeProblems() {
      // fetches array of dictionaries, where each dictionary corresponds to the firestore doc of a worked example
      // sets practiceProblemData to populated arr of dicts

      // helper functions to map assignments to lesson titles
      const workedExamplesInAssnGroup = getAllWorkedExamples();
      const lessons = getLessonsInRoadmap();
      function getAssnGroup(id) {
        const example = workedExamplesInAssnGroup.find(
          item => item.assnId === id,
        );
        return example?.assnGroup;
      }
      function getLessonIndex(lessonId) {
        const idx = lessons.findIndex(item => item.lessonId == lessonId);
        return idx >= 0 ? idx : undefined;
      }
      function getLessonTitle(lessonId) {
        const title = lessons.find(item => item.lessonId == lessonId)?.title;
        return title ? title.replace(/^(Lesson: )/, '') : 'No Lesson';
      }
      function getLessonUnit(id, lessonId) {
        const example = workedExamplesInAssnGroup.find(
          item => item.assnId === id,
        );
        const assnGroup = example?.assnGroup?.title;
        return assnGroup || '';
      }

      const q = query(
        assnsRef,
        or(
          where('workedExample', '==', true),
          where('showSolutionBehavior', '==', 'always'),
        ),
      );
      const querySnapshot = await getDocs(q);
      const practiceProblems = [];
      querySnapshot.forEach(doc => {
        const lessonId = getAssnGroup(doc.id)?.id;
        const lessonTitle = getLessonTitle(lessonId);
        const lessonUnit = getLessonUnit(doc.id, lessonId);
        if (lessonTitle === 'No Lesson') {
          return;
        }

        practiceProblems.push({
          id: doc.id,
          problemLink: (
            <Link
              to={`/${courseId}/ide/a/${doc.id}`}
              state={{from: `/${courseId}/practice`}}
            >
              {doc.data().title}
            </Link>
          ),
          completed: isAssnCompleted(doc.id) ? 1 : 0,
          title: doc.data().title,
          type: doc.data().type,
          assnGroup: getAssnGroup(doc.id)?.id,
          lessonLink: (
            <HistoryLink to={`/${courseId}/learn/${lessonId}`}>
              {lessonTitle}
            </HistoryLink>
          ),
          lessonTitle: lessonTitle,
          unit: lessonUnit,
          lessonIndex: getLessonIndex(getAssnGroup(doc.id)?.id),
          difficulty: doc.data()?.difficulty,
          tags:
            'tags' in doc.data()
              ? doc.data().tags.map((str, index) => (
                  <Badge key={index} bg="secondary" style={{margin: '2px'}}>
                    {str}
                  </Badge>
                ))
              : [],
        });
      });
      practiceProblems.sort((a, b) => {
        if (a.lessonIndex === undefined && b.lessonIndex === undefined) {
          return 0;
        } else if (a.lessonIndex === undefined) {
          return 1;
        } else if (b.lessonIndex === undefined) {
          return -1;
        } else {
          return a.lessonIndex - b.lessonIndex;
        }
      });
      setPracticeProblemData(practiceProblems);
    }

    getPracticeProblems();
  }, [db, assnsCollection]);

  useEffect(() => {
    if (!practiceProblemData) {
      return;
    }

    // apply filters
    // note that filters apply as if they are joined by AND (as opposed to being joined by OR)
    let newFiltered = practiceProblemData;
    if (activeFilters['completedFilter'] != filterDefaults.completed) {
      newFiltered = newFiltered.filter(
        problemData =>
          problemData.completed == activeFilters['completedFilter'],
      );
    }
    if (activeFilters['lessonFilter'] != filterDefaults.lesson) {
      newFiltered = newFiltered.filter(
        problemData => problemData.lessonTitle == activeFilters['lessonFilter'],
      );
    }
    setFilteredPracticeProblemData(newFiltered);
    setIsDefault(checkIsDefault(activeFilters));
  }, [practiceProblemData, activeFilters]);

  if (assnsDataLoading || !filteredPracticeProblemData)
    return <PartialLoading />;

  const handleClearFilters = () => {
    setActiveFilters({
      completedFilter: filterDefaults.completed, // or bool
      lessonFilter: filterDefaults.lesson,
    });
  };

  function Filters() {
    return (
      <div className="d-flex align-items-center justify-content-between px-1">
        <h3 className="mt-4">
          Examples with Solutions <PracticeProblemsInfo />
        </h3>
        <ToggleAside />
      </div>
    );
  }

  return (
    <CoursePageSingleCol
      column={
        <div className="d-flex flex-column" style={{overflow: 'hidden'}}>
          <Filters />
          <div style={{flex: 1, overflowY: 'auto'}}>
            <PracticeProblemsTable
              practiceProblemData={filteredPracticeProblemData}
              columns={columns}
            />
          </div>
        </div>
      }
    />
  );
};

const ToggleAside = props => {
  const {broken, toggleSidebar} = useProSidebar();
  return (
    <>
      {broken && (
        <button
          className="btn btn-sm btn-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
    </>
  );
};

export const PracticeProblemsInfo = props => {
  const onClick = () => {
    Swal.fire({
      title: 'Examples with Solutions?',
      text: `Here are all of the practice problems we've written so that you can get extra practice understanding the lecture material! All of these practice problems come with solutions. We recommend you use this as a resource rather than running it through front to back -- pick which concepts you need the most practice with, find a problem (or multiple!) that covers those concepts and looks fun, and have a good time!`,
      icon: 'info',
    });
  };

  const infoStyle = {
    marginTop: '-2px',
  };

  return (
    <button
      onClick={onClick}
      className="btn btn-link"
      style={infoStyle}
      aria-label="info"
    >
      <FaInfoCircle />
    </button>
  );
};
