import {UnitTestSchema, WithRunnerData} from './UnitTestSchema';
import {AI_HIDDEN_EXAMPLE_UNIT_TEST_SCHEMA} from './ai/aiHiddenExample';
import {GRAPHICS_UNIT_TEST_SCHEMA} from './graphics/graphics';
import {type LoadedGraphicsUnitTestData} from './graphics/types';
import {EMPTY_TEST_RESULT} from './unitTestResults';
import {CONSOLE_UNIT_TEST_SCHEMA} from './console/console';
import {LoadedConsoleUnitTestData} from './console/types';
import {type LoadedKarelUnitTestData} from './karel/types';
import {KAREL_UNIT_TEST_SCHEMA} from './karel/karel';
import {ADVANCED_CONSOLE_UNIT_TEST_SCHEMA} from './advancedConsole/advancedConsole';
import {type LoadedAdvancedConsoleUnitTestData} from './advancedConsole/advancedConsoleUtils';
import {AiHiddenExampleLoadedData} from './ai/aiHiddenExample';
import Swal from 'sweetalert2';

/**
 * A map of unit test types to their corresponding schema types. This is a bit
 * of Typescript magic to allow for the `UNIT_TEST_TYPE_TO_SCHEMA` to be typed
 * as a map of unit test types to their corresponding schema types (and enforce
 * that all unit test types *must* have a schema defined at compile time). New
 * unit test types must be added to this map.
 */
export interface UnitTestTypeToSchemaTypeMap {
  graphics: UnitTestSchema<LoadedGraphicsUnitTestData>;
  console: UnitTestSchema<LoadedConsoleUnitTestData>;
  karel: UnitTestSchema<LoadedKarelUnitTestData>;
  advanced_console: UnitTestSchema<LoadedAdvancedConsoleUnitTestData>;
  ai: UnitTestSchema<AiHiddenExampleLoadedData>;
  self_graded: UnitTestSchema<{}>;
  none: UnitTestSchema<{}>;
}

/**
 * The unit test schema map, every unit test type must be defined here.
 */
export const UNIT_TEST_TYPE_TO_SCHEMA: {
  [UnitTestType in keyof UnitTestTypeToSchemaTypeMap]: UnitTestTypeToSchemaTypeMap[UnitTestType];
} = {
  graphics: GRAPHICS_UNIT_TEST_SCHEMA,
  console: CONSOLE_UNIT_TEST_SCHEMA,
  karel: KAREL_UNIT_TEST_SCHEMA,
  advanced_console: ADVANCED_CONSOLE_UNIT_TEST_SCHEMA,
  ai: AI_HIDDEN_EXAMPLE_UNIT_TEST_SCHEMA,
  self_graded: {
    loader: async () => {
      return {};
    },
    runner: async ({silent}: WithRunnerData<{}>) => {
      if (silent) {
        return EMPTY_TEST_RESULT;
      }
      const response = await Swal.fire({
        title: 'Self-graded Assessment',
        text: 'This assignment is self-graded. When you are ready to submit, click the "Submit" button.',
        icon: 'info',
        confirmButtonText: 'Submit',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });
      if (response.isConfirmed) {
        return {
          testResults: [
            {
              isSuccess: true,
              usedAi: false,
              rawResult: {message: 'Nice job!'},
              name: 'Self-graded assessment',
            },
          ],
          testsRun: true,
        };
      } else {
        return EMPTY_TEST_RESULT;
      }
    },
    EditorComponent: () => <></>,
    unitTestType: 'self_graded',
    displayName: 'Self-Graded',
    tooltipDescription: 'Allow students to grade themselves.',
    getFailureMessage: () => '',
  },
  none: {
    loader: async () => {
      return {};
    },
    runner: async () => ({testResults: [], testsRun: true}),
    EditorComponent: () => <></>,
    unitTestType: 'none',
    displayName: 'None',
    tooltipDescription: 'No autograder.',
    getFailureMessage: () => '',
  },
};
