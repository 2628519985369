import {UnitTestSchema, WithRunnerData} from '../UnitTestSchema';
import {
  AUTOGRADER_RUN_TOAST,
  runGPTAutograderRaw,
  UNABLE_TO_RUN_TOAST,
} from 'assignments/unitTest/ai/GptAutograderUtils';
import {getScoreFromGPTResponse} from 'assignments/unitTest/ai/GptAutograderUtils';
import {UnitTestResults} from '../unitTestResults';
import {AiHiddenExampleEditor} from './AiHiddenExampleEditor';
import {getAllFileNames} from 'ide/utils/general';

export type AiHiddenExampleLoadedData = {};
// Since the test comprises of a call to GPT and Firebase on local data, we
// don't need to load any data.
async function loader(): Promise<AiHiddenExampleLoadedData> {
  return {};
}

async function runner({
  unitTestLoaderParameters,
  filesCode,
  fileStructure,
}: WithRunnerData<AiHiddenExampleLoadedData>): Promise<UnitTestResults> {
  const {courseId, assignmentId} = unitTestLoaderParameters;
  try {
    let startTime = Date.now();
    const allFileNames = getAllFileNames(fileStructure);
    const mainFileId = allFileNames.find(file => file.name === 'main.py')?.id;
    if (!mainFileId) {
      AUTOGRADER_RUN_TOAST.close();
      return {
        testsRun: true,
        testResults: [
          {
            isSuccess: false,
            name: 'GPT Autograder',
            usedAi: true,
            rawResult: 'No main.py file found',
          },
        ],
      };
    }
    const mainFileCode = filesCode[mainFileId]?.content ?? '';
    const gptResponse = await runGPTAutograderRaw({
      code: mainFileCode,
      courseId,
      assnId: assignmentId,
    });
    startTime = Date.now();
    // the code has not been edited while awaiting the autograder
    const {isCorrect} = getScoreFromGPTResponse(gptResponse);
    AUTOGRADER_RUN_TOAST.close();
    return {
      testsRun: true,
      testResults: [
        {
          isSuccess: isCorrect,
          name: 'GPT Autograder',
          usedAi: true,
          rawResult: gptResponse,
        },
      ],
    };
  } catch (error) {
    // any errors are logged within the runGPTAutograder function
    UNABLE_TO_RUN_TOAST.fire();
    return {
      testsRun: false,
      testResults: [
        {
          isSuccess: false,
          name: 'GPT Autograder',
          usedAi: true,
          rawResult: error,
        },
      ],
    };
  }
}

export const AI_HIDDEN_EXAMPLE_UNIT_TEST_SCHEMA: UnitTestSchema<AiHiddenExampleLoadedData> =
  {
    loader,
    runner,
    EditorComponent: AiHiddenExampleEditor,
    unitTestType: 'ai',
    displayName: 'AI-based Evaluation',
    tooltipDescription: 'Use an AI model to evaluate the student code.',
    getFailureMessage: () => '',
  };
