import BootstrapTable from 'react-bootstrap-table-next';
import styled from 'styled-components';

const StickyHeaderTable = styled.div`
  .react-bootstrap-table {
    thead {
      position: sticky;
      top: 0;
    }
  }
`;

export const PracticeProblemsTable = ({practiceProblemData, columns}) => {
  return (
    <StickyHeaderTable>
      <BootstrapTable
        bordered={false}
        striped={true}
        hover={true}
        bootstrap4={true}
        keyField="assnId"
        data={practiceProblemData}
        columns={columns}
      />
    </StickyHeaderTable>
  );
};
