import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FaTools} from 'react-icons/fa';
import {
  Badge,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Tooltip,
  Row,
  Spinner,
} from 'react-bootstrap';
import DevelopmentOnly from 'components/DevelopmentOnly';
import {useIsMobile} from 'utils/general';
import {useTranslationUploader} from 'contexts/TranslationContext';
import Swal from 'sweetalert2';

const BadgeContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const StyledBadge = styled(Badge)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--bs-primary);
  padding: 0;

  &:hover {
    transform: scale(1.1);
    background-color: var(--bs-primary-dark);
  }
`;

const Panel = styled.div<{isOpen: boolean}>`
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isOpen ? 'block' : 'none')};
  min-width: 200px;
`;
const DEVELOPER_MESSAGES = [
  "You're crushing it!",
  'You got this!',
  'Keep up the great work!',
  'Code wizardry in progress!',
  'Your code is looking sharp!',
  'Bug hunting champion!',
  'Stay hydrated, take a stretch!',
  'Testing like a pro!',
  'Debugging master at work!',
];
const getRandomInt = (max: number) => Math.floor(Math.random() * max);

/**
 * Displays a badge with developer tools for use in development
 * environments in the bottom right corner of the screen. Serves as a useful
 * central location for developer tools.
 */
export const DeveloperToolsBadge = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [messageIndex, setMessageIndex] = useState(
    getRandomInt(DEVELOPER_MESSAGES.length),
  );
  const isMobile = useIsMobile();
  if (!isVisible) {
    return <></>;
  }
  function togglePanel() {
    // Randomize the message when the panel is opened
    if (!isPanelOpen) {
      setMessageIndex(getRandomInt(DEVELOPER_MESSAGES.length));
    }
    setIsPanelOpen(!isPanelOpen);
  }
  const message = DEVELOPER_MESSAGES[messageIndex];
  return (
    <DevelopmentOnly>
      <BadgeContainer>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="developer-badge-information-tooltip">
              Developer Tools
            </Tooltip>
          }
        >
          <StyledBadge
            onClick={() => togglePanel()}
            onContextMenu={e => {
              e.preventDefault();
              setIsVisible(false);
            }}
          >
            <FaTools color="white" size={24} />
          </StyledBadge>
        </OverlayTrigger>
        <Panel isOpen={isPanelOpen}>
          <Container>
            <Row className="pb-1">
              <Col>
                <UploadUnknownTranslationKeysButton />
              </Col>
            </Row>
            <Row>
              <Col>
                <ClearLocalTranslationKeyCacheButton />
              </Col>
            </Row>
            <hr className="py-0" />
            <Row>
              <Col>
                <small className="text-muted mb-1 d-block">
                  <i>{message}</i>
                  <br />
                  {!isMobile && 'Right-click the badge to hide it.'}
                </small>
              </Col>
            </Row>
            {isMobile && (
              <Row className="mb-2">
                <Col className="d-flex justify-content-end">
                  <Button
                    variant="secondary"
                    className="p-0"
                    onClick={() => setIsVisible(false)}
                  >
                    Hide the Panel
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        </Panel>
      </BadgeContainer>
    </DevelopmentOnly>
  );
};

/**
 * Uploads unknown translation keys to the backend on click.
 *
 * This component uses the
 * {@link src/contexts/TranslationContext#useTranslationUploader | `useTranslationUploader`}
 * hook to handle the upload process.
 */
const UploadUnknownTranslationKeysButton = () => {
  const {
    uploadUnknownTranslationKeys,
    translationsUploading,
    hasUploadedTranslations,
  } = useTranslationUploader();

  useEffect(() => {
    if (!hasUploadedTranslations || !translationsUploading) {
      return;
    }
    Swal.fire({
      icon: 'info',
      title: "Uploading translation keys... please don't reload!",
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
    });
  }, [translationsUploading, hasUploadedTranslations]);

  useEffect(() => {
    if (!hasUploadedTranslations || translationsUploading) {
      return;
    }
    Swal.fire({
      icon: 'success',
      title: 'Translations successfully uploaded!',
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
    });
  }, [translationsUploading, hasUploadedTranslations]);

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="upload-keys-tooltip">
          Uploads any strings you've rendered that don't have translations.
        </Tooltip>
      }
    >
      <Button
        onClick={uploadUnknownTranslationKeys}
        disabled={translationsUploading}
      >
        {translationsUploading ? (
          <>
            <span>Uploading... </span>
            <Spinner animation="border" size="sm" />
          </>
        ) : (
          'Upload Translation Keys'
        )}
      </Button>
    </OverlayTrigger>
  );
};

/**
 * Renders a button that clears the local cache of translation keys
 * and displays a success toast message upon clearing.
 */
const ClearLocalTranslationKeyCacheButton = () => {
  const {clearLocalUnknownTranslationKeys} = useTranslationUploader();
  const clearKeysAndFireToast = () => {
    clearLocalUnknownTranslationKeys();
    Swal.fire({
      icon: 'success',
      title: 'Local translation key cache cleared!',
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    });
  };

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="upload-keys-tooltip">
          Clears the local cache of unknown translation keys. Useful in case of
          upload issues or if you want to start fresh.
        </Tooltip>
      }
    >
      <Button onClick={clearKeysAndFireToast}>
        Clear Local Translation Key Cache
      </Button>
    </OverlayTrigger>
  );
};
