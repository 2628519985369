import {useState} from 'react';
import {FaCopy, FaEdit, FaTrash, FaTrashAlt} from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {getSingleValue} from 'course/editors/OnEventAlerts';
import './editable_map.css';
import {CopyButton} from 'components/reusableButtons/CopyButton';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

interface EditableMapProps {
  currentMapping: Record<string, string>;
  setCurrentMapping: (value: Record<string, string>) => void;
}
export const EditableMap = ({
  currentMapping,
  setCurrentMapping,
}: EditableMapProps) => {
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const handleAddItem = () => {
    if (!newKey) {
      return;
    }
    const addItem = () => {
      setCurrentMapping({...currentMapping, [newKey]: newValue});
      setNewKey('');
      setNewValue('');
    };
    if (!currentMapping.hasOwnProperty(newKey)) {
      addItem();
      return;
    }
    withReactContent(Swal)
      .fire({
        title: `Warning: overwriting existing key`,
        html: (
          <div className="update-warning">
            <span>
              You won't be able to revert this!
              <br />
              Key: <code>"{newKey}"</code>
              <br />
              Old value: <code>"{currentMapping[newKey]}"</code>
              <br />
              New value: <code>"{newValue}"</code>
            </span>
          </div>
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add it!',
      })
      .then(result => {
        if (result.isConfirmed) {
          addItem();
        }
      });
  };

  const handleDeleteItem = key => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        const {[key]: deletedValue, ...remaining} = currentMapping; // Destructuring to remove the key-value pair
        setCurrentMapping(remaining);
      }
    });
  };

  const handleEditItem = async key => {
    const oldValue = currentMapping[key];
    const newValue = await getSingleValue('Edit Map Item', 'Value', oldValue);

    if (newValue !== null && newValue !== undefined) {
      setCurrentMapping({...currentMapping, [key]: newValue});
    }
  };

  const onPaste = async () => {
    const text = await navigator.clipboard.readText();
    try {
      const parsed = JSON.parse(text);
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Pasting will overwrite the current map.',
        icon: 'warning',
        showCancelButton: true,
      });
      if (result.isConfirmed) {
        setCurrentMapping(parsed);
      }
    } catch (e) {
      Swal.fire({
        title: 'Invalid JSON',
        text: 'The copied text is not a valid JSON object.',
        icon: 'error',
      });
    }
  };

  const onCopy = async () => {
    await navigator.clipboard.writeText(JSON.stringify(currentMapping));
    Swal.fire({
      title: 'Copied!',
      text: 'The map has been copied to your clipboard.',
      icon: 'success',
    });
  };

  return (
    <div className="container" onPaste={onPaste} onCopy={onCopy}>
      <div className="input-group mb-3 row justify-content-md-center">
        <div className="col-md-auto">
          <input
            type="text"
            className="form-control"
            placeholder="New key"
            value={newKey}
            onChange={e => setNewKey(e.target.value)}
          />
        </div>
        <div className="col-md-auto">
          <input
            type="text"
            className="form-control"
            placeholder="New value"
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
          />
        </div>
        <div className="col-md-auto">
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleAddItem}
            >
              Add Item
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <small className="text-muted">
          You can paste a JSON object to overwrite the current map. You can also
          copy the current map as JSON.
        </small>
      </div>
      <div className="row align-center">
        <div className="col-md-auto align-self-center">
          Copy as JSON: <CopyButton toCopy={JSON.stringify(currentMapping)} />
        </div>
        <div className="col-md-auto align-self-center">
          Clear all:{' '}
          <button
            className="btn btn-light"
            onClick={async () => {
              const result = await Swal.fire({
                title: 'Are you sure?',
                text: 'This will clear all items in the map.',
                icon: 'warning',
                showCancelButton: true,
              });
              if (result.isConfirmed) {
                setCurrentMapping({});
              }
            }}
          >
            <FaTrashAlt />
          </button>
        </div>
      </div>

      <table className="table text-left align-middle table-striped">
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(currentMapping).map(([key, value]) => (
            <MapItem
              key={key}
              id={key}
              value={value}
              handleDeleteItem={() => handleDeleteItem(key)}
              handleEditItem={() => handleEditItem(key)}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const MapItem = ({id, value, handleDeleteItem, handleEditItem}) => {
  return (
    <tr key={id} className="map-item">
      <td>{id}</td>
      <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
      <td>
        <span className="d-flex">
          <OverlayTrigger overlay={<Tooltip>Edit Item</Tooltip>}>
            <button className="btn btn-sm btn-light" onClick={handleEditItem}>
              <FaEdit />
            </button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>Delete Item</Tooltip>}>
            <button onClick={handleDeleteItem} className="btn btn-sm btn-light">
              <FaTrash />
            </button>
          </OverlayTrigger>
        </span>
      </td>
    </tr>
  );
};
