import {useParams} from 'react-router';
import {CoursePageBodyContainer} from 'components/layout/CoursePageBodyContainer';
import {getDocsList, loadDocData, createNewDoc, deleteDocSet} from './utils';
import {useEffect, useState, useContext} from 'react';
import {FaTrash, FaEdit} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {TipTap} from 'components/richTextEditor/TipTap/TipTap';
import {AssnEditorButtonBar} from 'components/richTextEditor/TipTap/buttonbars/AssnEditorButtonBar';
import {isMinimumRole} from 'contexts/profileUtil';
import NoAccess from 'components/errors/NoAccess';
import {ProfileContext} from 'contexts/ProfileContext';
import {Role} from 'types/role';
import {onSnapshot, collection, getFirestore} from 'firebase/firestore';
import {getDoubleValue, confirmDeleteAlert} from '../OnEventAlerts';
import {useNavigate} from 'react-router';
import {Stack, Form, Button} from 'react-bootstrap';
import Swal from 'sweetalert2';

export const DocsEditor = () => {
  const [docs, setDocs] = useState([]);
  const {userData} = useContext(ProfileContext);
  const courseRole = userData?.courseRole;
  const {courseId, docsId} = useParams();

  // admin only!!
  if (!isMinimumRole(courseRole, Role.ADMIN)) {
    return <NoAccess />;
  }

  let unsubscribe;

  useEffect(() => {
    const fillDocs = async () => {
      const respDocs = await getDocsList(courseId);
      setDocs(respDocs);
    };
    fillDocs();

    if (isMinimumRole(courseRole, Role.ADMIN)) {
      unsubscribe = onSnapshot(
        collection(getFirestore(), `docs/${courseId}/libraries`),
        snapshot => {
          setDocs(snapshot.docs);
        },
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const onCreateDocClick = async () => {
    const values = await getDoubleValue('Docs Info', 'Title', 'Docs ID');
    const title = values.item1;
    const docId = values.item2;
    if (!!title && !!docId && !(docId in docs.map(d => d.id))) {
      await createNewDoc(title, docId, courseId);
    }
  };

  const onDeleteDocClick = async id => {
    const isConfirmed = await confirmDeleteAlert();

    if (isConfirmed) {
      await deleteDocSet(id, courseId);
    }
  };

  const docList = docs.map(doc => {
    return (
      <div
        key={doc.id}
        className="rounded border p-1 m-3 d-flex justify-content-between"
      >
        <h1>{doc.data().name}</h1>
        <div>
          <button className="btn" onClick={() => onDeleteDocClick(doc.id)}>
            <FaTrash />
          </button>
          <Link className="btn p-3" to={`/${courseId}/docseditor/${doc.id}`}>
            <FaEdit />
          </Link>
        </div>
      </div>
    );
  });

  if (!docsId || docsId == 'splash') {
    return (
      <div className="m-3">
        <DocsCreationForm courseId={courseId} allDocIds={docs.map(d => d.id)} />
        <div className="splashCardMainTitle mt-4">Documentation Editor</div>
        {docList}
      </div>
    );
  }

  const [isLoading, docData] = [false, {}];

  if (isLoading) {
    return <></>;
  }

  return (
    <CoursePageBodyContainer
      mainColumn={<DocsEditorMain docsId={docsId} courseId={courseId} />}
      rightColumn={<></>}
      singleColumn={<DocsEditorMain docsId={docsId} courseId={courseId} />}
    />
  );
};

const DocsEditorMain = ({docsId, courseId}) => {
  const [docData, setDocData] = useState({});
  useEffect(() => {
    const getDocData = async () =>
      setDocData(await loadDocData(docsId, courseId));
    getDocData();
  }, []);

  return (
    <div>
      <div className="splashCardMainTitle mt-4">
        {docData ? docData.name : null}
      </div>
      <TipTap
        editable={true}
        firebaseDocPath={`/docs/${courseId}/libraries/${docsId}`}
        buttonBar={AssnEditorButtonBar}
      />
    </div>
  );
};

export const DocsCreationForm = ({
  courseId,
  allDocIds,
  navigateToDocs = true,
}) => {
  const [newDocsId, setNewDocsId] = useState('');
  const [newDocsTitle, setNewDocsTitle] = useState('');
  const navigate = useNavigate();

  const onDocsCreated = newDocsId => {
    const docsEditUrl = `/${courseId}/docseditor/${newDocsId}`;
    // Because we navigate to the new assignment's page on creation, we do not need to manually tell the assignment editor to reload to reflect the new assignment.
    if (navigateToDocs) {
      navigate(docsEditUrl);
    }
  };

  return (
    <Stack direction="vertical" gap={3}>
      <Form.Control
        className="me-auto"
        placeholder="Enter the new assignment Title"
        value={newDocsTitle}
        onChange={e => setNewDocsTitle(e.target.value)}
      />
      <Form.Control
        className="me-auto"
        placeholder="Enter the new assignment ID"
        value={newDocsId}
        onChange={e => setNewDocsId(e.target.value)}
      />
      <Button
        variant="primary"
        disabled={newDocsId === ''}
        onClick={() =>
          verifyAndAddDocs(
            courseId,
            newDocsId,
            newDocsTitle,
            allDocIds,
            onDocsCreated,
          )
        }
      >
        Submit
      </Button>
    </Stack>
  );
};

async function verifyAndAddDocs(
  courseId,
  newDocsId,
  newDocsTitle,
  allDocsIds,
  callback,
) {
  if (newDocsId === '') {
    Swal.fire('Oops...', "You can't have an empty docs ID!", 'error');
    return;
  }

  if (newDocsTitle === '') {
    Swal.fire('Oops...', "You can't have an empty docs Title!", 'error');
    return;
  }

  if (allDocsIds.filter(d => d === newDocsId).length > 0) {
    Swal.fire(
      'Oops...',
      'There is already an docs with this ID! Please pick another.',
      'error',
    );
    return;
  }

  await createNewDoc(courseId, newDocsId, newDocsTitle);

  callback(newDocsId);
}
