import {
  UnitTestLoaderParameters,
  UnitTestSchema,
  WithRunnerData,
} from '../UnitTestSchema';
import {EMPTY_TEST_RESULT, UnitTestResults} from '../unitTestResults';
import {getGraphicsUnitTestRef} from '../../models/unitTests/graphics';
import {getDoc, setDoc} from 'firebase/firestore';
import {
  GraphicsShape,
  isGraphicsShape,
  ShapeId,
  areShapeMapsEqual,
} from 'types/graphics';
import {GraphicsEditor} from './GraphicsEditor';
import {
  DEFAULT_GRAPHICS_UNIT_TEST_NAME,
  type LoadedGraphicsUnitTestData,
} from './types';
import {getAllFileNames} from 'ide/utils/general';

async function loader({
  courseId,
  assignmentId,
  serverAssignmentData,
}: UnitTestLoaderParameters): Promise<LoadedGraphicsUnitTestData | null> {
  const graphicsUnitTestReference = getGraphicsUnitTestRef(
    courseId,
    assignmentId,
  );
  const graphicsUnitTestDoc = await getDoc(graphicsUnitTestReference);
  if (!graphicsUnitTestDoc.exists()) {
    await setDoc(graphicsUnitTestReference, []);
  }
  const unitTests = graphicsUnitTestDoc.exists()
    ? graphicsUnitTestDoc.data()
    : [];
  const starterCode = serverAssignmentData.starterCode ?? {'main.py': ''};
  return {
    unitTests,
    starterCode,
  };
}

async function runner({
  filesCode,
  pyodideClient,
  fileStructure,
  loadedData,
}: WithRunnerData<LoadedGraphicsUnitTestData>): Promise<UnitTestResults> {
  console.log('Running Standard Graphics Test', Date.now());
  const {unitTests} = loadedData ?? {unitTests: []};

  // Make sure that the unitTests are valid
  if (!unitTests || unitTests.length === 0) {
    console.log('No unit tests found.');
    return EMPTY_TEST_RESULT;
  }

  const allFileNames = getAllFileNames(fileStructure);
  const mainFile = allFileNames.find(file => file.name === 'main.py');
  if (!mainFile) {
    return {
      testResults: [
        {
          isSuccess: false,
          name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
          usedAi: false,
          rawResult: {message: 'No main.py file found'},
        },
      ],
      testsRun: true,
    };
  }
  const codeToRun = filesCode?.[mainFile.id]?.content ?? '';
  if (codeToRun === '') {
    return {
      testResults: [
        {
          isSuccess: false,
          name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
          usedAi: false,
          rawResult: {message: 'No code to run'},
        },
      ],
      testsRun: true,
    };
  }
  const unitTest = unitTests[0];
  await pyodideClient.loadFiles(allFileNames, filesCode);
  const studentCodeResult = await pyodideClient.testCode(
    codeToRun,
    mainFile,
    [],
  );
  if ((studentCodeResult.error?.length ?? 0) > 0) {
    return {
      testResults: [
        {
          isSuccess: false,
          name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
          usedAi: false,
          rawResult: studentCodeResult,
        },
      ],
      testsRun: true,
    };
  }
  const studentCanvasData = new Map<ShapeId, GraphicsShape>(
    Object.entries(studentCodeResult.graphics)
      .filter(([, value]) => isGraphicsShape(value))
      .filter(([, value]) => isGraphicsShape(value))
      .map(([key, value]) => [key, value as GraphicsShape] as const),
  );
  const solutionCanvasData = unitTest.map;

  console.log('Student Canvas Data', studentCanvasData);
  console.log('Solution Canvas Data', solutionCanvasData);

  if (!areShapeMapsEqual(studentCanvasData, solutionCanvasData)) {
    return {
      testResults: [
        {
          isSuccess: false,
          name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
          usedAi: false,
          rawResult: {
            message: `Your canvas and the solution canvas don't match up, keep trying!`,
          },
        },
      ],
      testsRun: true,
    };
  }

  return {
    testResults: [
      {
        isSuccess: true,
        name: DEFAULT_GRAPHICS_UNIT_TEST_NAME,
        usedAi: false,
        rawResult: {
          message: 'Your answer is correct!',
        },
      },
    ],
    testsRun: true,
  };
}

function getFailureMessage(unitTestResults: UnitTestResults) {
  const firstTestSuccess = unitTestResults.testResults[0].isSuccess;
  if (firstTestSuccess) {
    return '';
  }
  return unitTestResults.testResults[0].rawResult.message;
}

export const GRAPHICS_UNIT_TEST_SCHEMA: UnitTestSchema<LoadedGraphicsUnitTestData> =
  {
    loader,
    runner,
    EditorComponent: GraphicsEditor,
    unitTestType: 'graphics',
    displayName: 'Graphics Canvas Match',
    tooltipDescription:
      'Compares the graphics canvas state between the student code and the solution.',
    getFailureMessage,
  };
