import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useContext, useEffect, useState} from 'react';
import {FaBars} from 'react-icons/fa';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {CourseSidebar} from './sidebar/CourseSidebar';

import {CourseNavbar} from './CourseNavbar';
// import CourseNavbar from "../../components/Navbar"
import {NoAccess} from 'components/errors/NoAccess';
import {Page404} from 'components/errors/Page404';
import {Loading} from 'components/loading/Loading';
import {CompletionProvider} from 'contexts/CompletionContext';
import {isMinimumRole, isProfileComplete} from 'contexts/profileUtil';
import {courseMaxWidth} from 'course/constants/courseContants';
import {ConnectionsProvider} from 'course/contexts/ConnectionsContext';
import {LessonsProvider} from 'course/contexts/LessonsContext';
import {RoadmapProvider} from 'course/contexts/RoadmapContext';
import {TrainingProvider} from 'course/contexts/TrainingContext';
import {ProSidebarProvider, useProSidebar} from 'react-pro-sidebar';
import {useWindowSize} from 'react-use-size';
import {getApprovedCourses} from 'utils/general';
import {isProduction} from '../../config';
import {ProfileContext, ProfileProvider} from '../../contexts/ProfileContext';
import {Role} from 'types/role';
import {AssnProvider} from '../contexts/AssnContext';
import {CompleteProfile} from './CompleteProfile';
import {FinalProjectProvider} from 'course/finalproject/portal/FinalProjectContext';
import {LanguageSelectorModal} from 'components/translation/LanguageSelector';

/**
 * This is the main wrapper for the course! All subpages will
 * go through this wrapper. This is helpful for several reasons:
 * 1. We can set up the sidebar in just one place
 * 2. The sidebar doesn't re-render when you navigate
 * 3. We can load data that will persist between page changes.
 */

const DESKTOP_BREAKPOINT = 1200;
const SINGLE_COL_BREAKPOINT = 1075;
const SINGLE_COL_COLLAPSE_BREAKPOINT = 800;
const MOBILE_BREAKPOINT = 575;

// later this will be in the server
// export const APPROVED_COURSES = {
//   cip3: Role.STUDENT,
//   test: '',
//   spr23: ''
// }

export const CoursePage = props => {
  const {courseId} = useParams();
  const [approvedCourses, setApprovedCourses] = useState({});
  const [loaded, setLoaded] = useState(false);

  // make sure its a real course
  useEffect(() => {
    const getAc = async () => {
      const ac = await getApprovedCourses();
      setApprovedCourses(ac);
      setLoaded(true);
    };
    getAc();
  }, []);

  if (!(courseId in approvedCourses) && loaded) {
    return <Page404 message={`Unknown url path: ${courseId}`} />;
  }

  if (!loaded) {
    <Loading />;
  }

  return (
    <div key={courseId}>
      <ProfileProvider>
        <CompletionProvider courseId={courseId}>
          <RoadmapProvider courseId={courseId}>
            <AssnProvider courseId={courseId}>
              <LessonsProvider courseId={courseId}>
                <TrainingProvider courseId={courseId}>
                  <ConnectionsProvider>
                    <FinalProjectProvider>
                      <CoursePageWithContext
                        {...props}
                        approvedCourses={approvedCourses}
                      />
                    </FinalProjectProvider>
                  </ConnectionsProvider>
                </TrainingProvider>
              </LessonsProvider>
            </AssnProvider>
          </RoadmapProvider>
        </CompletionProvider>
      </ProfileProvider>
    </div>
  );
};

const CoursePageWithContext = ({noSidebar, approvedCourses}) => {
  const {courseId} = useParams();
  const navigate = useNavigate();
  const {loading: userIsLoading, userData} = useContext(ProfileContext);

  if (userIsLoading) return <></>;

  // does the user have the minimum role?
  const courseRole = userData.courseRole;
  const requiredRole = approvedCourses[courseId];

  if (requiredRole) {
    if (!isMinimumRole(courseRole, requiredRole)) {
      // if user role unassigned, redirect to student application
      if (!courseRole) {
        console.log('redirecting to student application');
        console.log('courseRole', courseRole);
        console.log('requiredRole', requiredRole);
        navigate(`/${courseId}/holding`);
        return <></>;
      }
      // only admins can see the course page for now
      if (isProduction) {
        return <NoAccess />;
      }
    }
    if (courseRole === Role.SECTION_LEADER) {
      // you need to make sure that they have confirmedSectionLeader, this courseId
      // in their user doc
      let confirmed = userData?.confirmedSectionLeader?.[courseId];
      if (!confirmed) {
        navigate(`/public/applyteach/${courseId}`);
        return <></>;
      }
    }
  }

  return (
    <ProSidebarProvider>
      <CourseView />
    </ProSidebarProvider>
  );
};

const CourseView = () => {
  const profileContext = useContext(ProfileContext);
  const [showLanguageSelectorModal, setShowLanguageSelectorModal] =
    useState(false);

  // is this a focus page?
  const isFocusPage = checkFocusPage(location.pathname);
  if (isFocusPage) {
    return <Outlet />;
  }

  if (!isProfileComplete(profileContext)) {
    return <CompleteProfile />;
  }

  return (
    <>
      <CourseNavbar
        setShowLanguageSelectorModal={setShowLanguageSelectorModal}
      />
      <LanguageSelectorModal
        show={showLanguageSelectorModal}
        onHide={() => setShowLanguageSelectorModal(false)}
      />
      <div
        className="d-flex flex-1 justify-content-center"
        style={{width: '100vw', backgroundColor: '#f9f9f9'}}
      >
        <div
          className="flex-1 w-100"
          style={{maxWidth: courseMaxWidth, backgroundColor: 'white'}}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'calc(100vh - 46px)',
              overflow: 'hidden',
            }}
          >
            <CourseContainer />
          </div>
        </div>
      </div>
    </>
  );
};

const CourseContainer = () => {
  const {collapseSidebar, toggleSidebar, broken} = useProSidebar();
  const windowSize = useWindowSize();

  useEffect(() => {
    const w = windowSize.width;

    const collapseForTwoCols =
      w > SINGLE_COL_BREAKPOINT && w < DESKTOP_BREAKPOINT;
    const collapseForOneCol =
      w < SINGLE_COL_COLLAPSE_BREAKPOINT && w > MOBILE_BREAKPOINT;
    const isCollapsed = collapseForTwoCols || collapseForOneCol;
    collapseSidebar(isCollapsed);
  }, [windowSize]);
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexGrow: 2,
        overflow: 'auto',
      }}
    >
      <CourseSidebar />
      <main className="courseContentContainer">
        <Outlet />
      </main>
    </div>
  );
};

const MenuToggle = ({deviceType, setShowMenu, showMenu}) => {
  function toggle() {
    setShowMenu(!showMenu);
  }

  if (deviceType === 'mobile') {
    return (
      <button
        onClick={() => toggle()}
        type="button"
        id="sidebarCollapse"
        className="mobile-only btn btn-dark"
      >
        <FaBars />
        <span></span>
      </button>
    );
  }
  return <></>;
};

export const checkFocusPage = pathname => {
  // This means that the sidebar and navbar are not shown

  const pathSegments = location.pathname
    .split('/')
    .filter(segment => segment !== '');

  const subPage = pathSegments[1].toLowerCase();
  const nSegments = pathSegments.length;

  const fullPageSubPages = [
    'ide',
    'join',
    'applyteach',
    'peer',
    'teacherpuzzles',
    'teachnowtraining',
  ];

  if (fullPageSubPages.includes(subPage)) {
    return true;
  }

  if (subPage === 'learn' && nSegments >= 3) {
    return true;
  }
  if (subPage === 'training' && nSegments >= 3) {
    return true;
  }
  if (subPage === 'reviewteachnowstudents' && nSegments >= 3) {
    return true;
  }
  return false;
};
